import {
  Box,
  Button,
  Container,
  Flex,
  FormLabel,
  Input,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UserType, useUserQuery } from "../generated/graphql";
import { useSearchQuery } from "./../utils/useQuery";
import { Layout } from "./Layout";
import PageHeading from "./ui/PageHeading";
const Prim = () => {
  const query = useSearchQuery();
  const [userId, setUserId] = useState<string | null | undefined>(
    query.get("userId")
  );
  const [startAt, setStartAt] = useState(dayjs().format("YYYY-MM-01"));
  const [endAt, setEndAt] = useState(dayjs().format("YYYY-MM-31"));
  const [tempStart, setTempStart] = useState(dayjs().format("YYYY-MM-01"));
  const [tempEnd, setTempEnd] = useState(dayjs().format("YYYY-MM-31"));
  const [prim, setPrim] = useState("");
  const { refetch, data, loading, error } = useUserQuery({
    variables: {
      first: 50,
      skip: 0,
      userType: UserType.Worker
    }
  });

  let month: any = dayjs().format("MM");
  month = month - 1;
  console.log("🌵💜🐢", month.toString().length);

  month = month.toString().length === 1 ? "0" + month : month;

  React.useEffect(() => {
    if (userId) {
      axios
        .get(
          `${process.env.REACT_APP_APIURL}primHesapla?startDate=${startAt}&endDate=${endAt}&staffId=${userId}`
        )
        .then((res) => {
          setPrim(res.data);
          refetch();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [endAt, month, refetch, startAt, userId]);
  const handleDateChange = (e: any) => {
    setTempStart(dayjs(e).format("YYYY-MM-DD"));
  };
  const handleEndDateChange = (e: any) => {
    setTempEnd(dayjs(e).format("YYYY-MM-DD"));
  };

  const handleSubmitDate = () => {
    setStartAt(tempStart);
    setEndAt(tempEnd);
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading
          title={`${dayjs(startAt).format("DD MMM YYYY")} - 
          ${dayjs(endAt).format("DD MMM YYYY")}  Tarihli Primler`}
        />
        <Box bgColor={"gray.100"} py={4} px={4} mb={6}>
          <Flex
            alignItems={"center"}
            flexDirection={{
              base: "column",
              md: "row"
            }}
            justifyContent="start"
          >
            <Flex
              flexDirection={{
                base: "column",
                md: "row"
              }}
              alignItems={"center"}
            >
              <FormLabel>Başlangıç: </FormLabel>
              <Input
                type="date"
                value={dayjs(tempStart.toString()).format("YYYY-MM-DD")}
                onChange={(e: any) => handleDateChange(e.target.value)}
              />
            </Flex>
            <Flex
              mt={{
                base: 2,
                md: 0
              }}
              flexDirection={{
                base: "column",
                md: "row"
              }}
              ml={{
                base: 0,
                md: 4
              }}
              alignItems="center"
            >
              <FormLabel>Bitiş: </FormLabel>
              <Input
                type="date"
                value={dayjs(tempEnd.toString()).format("YYYY-MM-DD")}
                onChange={(e: any) => handleEndDateChange(e.target.value)}
              />
            </Flex>
            <Box ml={4}>
              <Button
                mt={{
                  base: 2,
                  md: 0
                }}
                variant={"outline"}
                colorScheme="blue"
                onClick={handleSubmitDate}
              >
                Raporu Oluştur
              </Button>
            </Box>
          </Flex>
        </Box>
        <Box>
          {loading ? (
            <Spinner />
          ) : (
            <Table>
              <Thead>
                <Tr>
                  <Th>Adı</Th>
                  <Th>Soyadı</Th>
                  <Th>Action</Th>
                  <Th>-</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.users.users.map((user) => (
                  <Tr key={user.id}>
                    <Td>{user.name}</Td>
                    <Td>{user.surname}</Td>
                    <Td>
                      <Link
                        onClick={() => setUserId(user.id)}
                        to={"/prim?userId=" + user.id}
                      >
                        Primi Hesapla
                      </Link>
                    </Td>
                    <Td w={"20%"} mr={0}>
                      {userId ? (
                        prim.includes(user.name + " " + user.surname) && (
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: prim
                                .replaceAll(".", "-")
                                .replaceAll(",", ".")
                                .replaceAll("-", ",")
                            }}
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default Prim;
