import { useEffect, useState } from "react";
import { Layout } from "../Layout";
import { useCategoriesQuery } from "../../generated/graphql";
import dayjs from "dayjs";
import axios from "axios";
import { calculateRoundUp } from "../../utils/calculateRoundUp";
import "dayjs/locale/tr";

import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Select,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  GridItem,
  Button
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";
import {
  PriceType,
  useCalculatedProductGroupsQuery
} from "../../generated/graphql";
import formatMoney from "../../utils/formatMoney";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";

dayjs.locale("tr");

enum Currency {
  TRY = "TRY",
  EUR = "EUR",
  USD = "USD"
}
const CalculatedPrice = () => {
  const [euro, setEuro] = useState(32.33);
  const [dolar, setDolar] = useState(29.34);
  const [gold, setGold] = useState(2060.71);
  const [gramGold, setGramGold] = useState(0);
  const [workerCost, setWorkerCost] = useState(0);
  const [mihlamaPower, setMihlamaPower] = useState(0.4);

  const [diamondCost, setDiamondCost] = useState(450);
  const [stoneCost, setStoneCost] = useState(40);
  const [currency, setCurrency] = useState<Currency>(Currency.TRY);
  const { data, loading, refetch } = useCalculatedProductGroupsQuery();
  const toast = useToast();
  const updatePrice = async (type: string, price: number, sku: string) => {
    const data = await axios.get(
      `${process.env.REACT_APP_APIURL}changeGoldProductPrice?priceType=${type}&price=${price}&sku=${sku}`
    );
    if (data.data.id) {
      toast({
        title: "Fiyat güncellendi",
        status: "success",
        duration: 500
      });
      refetch();
    } else {
      toast({
        title: "Fiyat güncellenemedi",
        status: "error"
      });
    }
  };

  const { data: categoriesData, loading: categoriesLoading } =
    useCategoriesQuery({
      variables: {
        category: "ckl1c634o0e300919ddn6fsn6"
      }
    });
  // Piercing ekstra maliyet 400

  //
  // useEffect(() => {
  //   const url =
  //     "https://cors.cr.yunusacar.dev/https://api.finage.co.uk/last/forex/USDTRY?apikey=API_KEY7bA4T4KNRHJLXZCE3B8NXKV7B8OREXCV";
  //   axios({
  //     method: "GET",
  //     url
  //   }).then((r) => {
  //     if (r.data) {
  //       setDolar(r.data.ask);
  //     }
  //   });

  //   const euroUrl =
  //     "https://cors.cr.yunusacar.dev/https://api.finage.co.uk/last/forex/EURTRY?apikey=API_KEY7bA4T4KNRHJLXZCE3B8NXKV7B8OREXCV";
  //   axios({
  //     method: "GET",
  //     url: euroUrl
  //   }).then((r) => {
  //     if (r.data) {
  //       setEuro(r.data.ask);
  //     }
  //   });

  //   const onsUrl =
  //     "https://cors.cr.yunusacar.dev/https://api.finage.co.uk/last/forex/XAUUSD?apikey=API_KEY7bA4T4KNRHJLXZCE3B8NXKV7B8OREXCV";
  //   axios({
  //     method: "GET",
  //     url: onsUrl
  //   }).then((r) => {
  //     if (r.data) {
  //       setGold(r.data.ask);
  //       console.log("🌵💜🐢", r.data.ask);
  //     }
  //   });
  // }, []);
  useEffect(() => {
    if (dolar && gold) {
      console.log("🌵💜🐢gold", gold);

      setWorkerCost((56.91 * dolar * 10) / 100);
      setGramGold(56.91 * dolar * 0.85);
      console.log("🌵💜🐢gram", gold / 31.1);
    }
  }, [dolar, gold]);
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Mevcut ve Olması Gereken Fiyatlar" />
        <Button
          colorScheme={"purple"}
          variant="outline"
          onClick={(e) => {
            var table_elt = document.getElementById("print");
            var workbook = XLSX.utils.table_to_book(table_elt);
            var ws = workbook.Sheets["Sheet1"];
            XLSX.utils.sheet_add_aoa(
              ws,
              [["Created " + new Date().toISOString()]],
              { origin: -1 }
            );
            XLSX.writeFile(workbook, "gold-report.xlsx");
          }}
        >
          <SiMicrosoftexcel />
        </Button>
        <SimpleGrid
          columns={{
            base: 1,
            md: 3
          }}
          gap={6}
        >
          <Table variant={"striped"} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>Kur </Th>
                <Th textAlign={"right"}>Fiyat</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Dolar (USDTRY)</Td>
                <Td textAlign={"right"}>{dolar} TRY</Td>
              </Tr>
              <Tr>
                <Td>Euro (EURTRY)</Td>
                <Td textAlign={"right"}>{euro} TRY</Td>
              </Tr>
              <Tr>
                <Td>Spot Altın (XAUUSD)</Td>
                <Td textAlign={"right"}>{gold} USD</Td>
              </Tr>
              <Tr>
                <Td>Gram Altın (XAUUSDG)</Td>
                <Td textAlign={"right"}>{(56.91).toFixed(2)} USD</Td>
              </Tr>
              <Tr>
                <Td>24 Ayar Gram Altın (XAUTRYG)</Td>
                <Td textAlign={"right"}>{(56.91 * dolar).toFixed(2)} TRY</Td>
              </Tr>
              <Tr>
                <Td>18 Ayar Gram Altın (XAUTRYG)</Td>
                <Td textAlign={"right"}>
                  {(56.91 * dolar * 0.85).toFixed(2)} TRY
                </Td>
              </Tr>
              <Tr>
                <Td>14 Ayar Gram Altın (XAUTRYG)</Td>
                <Td textAlign={"right"}>
                  {(56.91 * dolar * 0.585).toFixed(2)} TRY
                </Td>
              </Tr>

              <Tr>
                <Td>İşçilik Maliyeti</Td>
                <Td textAlign={"right"}>{workerCost.toFixed(2)} TRY</Td>
              </Tr>
              <Tr>
                <Td>Pırlanta Maliyeti</Td>
                <Td textAlign={"right"}>{diamondCost.toFixed(2)} TRY</Td>
              </Tr>
              <Tr>
                <Td>Diğer Taş Maliyeti ({formatMoney(stoneCost, "USD")}) </Td>
                <Td textAlign={"right"}>
                  {(stoneCost * dolar).toFixed(2)} TRY
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <GridItem colSpan={2}>
            <Table variant={"striped"} className="w-full table-auto">
              <Thead>
                <Tr>
                  <Th>Kategori </Th>
                  <Th textAlign={"right"}>Ekstra Maliyet</Th>
                  <Th textAlign={"right"}>Katsayı</Th>
                  <Th textAlign={"right"}>Katsayı Yurtdışı</Th>
                  <Th textAlign={"right"}>Katsayı Toptan</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!categoriesLoading &&
                  categoriesData &&
                  categoriesData.categories.map((c) => {
                    return (
                      <Tr key={c.id}>
                        <Td>
                          {c.description[0].title}
                          <br />
                          {c.sku}
                        </Td>
                        <Td textAlign={"right"}>
                          {c.extraCost && formatMoney(c.extraCost, "TRY")}
                        </Td>
                        <Td textAlign={"right"}>{c.pricePower}</Td>
                        <Td textAlign={"right"}>{c.pricePowerForeign}</Td>
                        <Td textAlign={"right"}>{c.pricePowerWholesale}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </GridItem>
        </SimpleGrid>

        <PageHeading title="Değişkenler" />
        <SimpleGrid columns={6} gap={6}>
          <FormControl>
            <FormLabel>Döviz</FormLabel>
            <Select
              onChange={(e) => setCurrency(e.target.value as Currency)}
              value={currency}
            >
              <option value={Currency.TRY}>TRY</option>
              <option value={Currency.EUR}>EUR</option>
              <option value={Currency.USD}>USD</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Mıhlama</FormLabel>
            <Input
              type="number"
              step={0.001}
              onChange={(e) => setMihlamaPower(Number(e.target.value))}
              value={mihlamaPower}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Pırlanta</FormLabel>
            <Input
              type="number"
              step={0.001}
              onChange={(e) => setDiamondCost(Number(e.target.value))}
              value={diamondCost}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Diğer Taş (USD)</FormLabel>
            <Input
              type="number"
              step={0.001}
              onChange={(e) => setStoneCost(Number(e.target.value))}
              value={stoneCost}
            />
          </FormControl>
        </SimpleGrid>

        <PageHeading title={`Ürünler - (${currency})`} />
        {loading ? (
          <Spinner />
        ) : categoriesLoading ? (
          <Spinner />
        ) : (
          <Box overflowX={"auto"}>
            <Table id="print" variant={"striped"} className="w-full table-auto">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Fotoğraf</Th>
                  <Th>SKU</Th>
                  <Th textAlign={"center"}>Gram</Th>
                  <Th textAlign={"center"}>Taş Adet</Th>
                  <Th textAlign={"center"}>Taş CT</Th>
                  <Th textAlign={"center"}>Toplam CT</Th>
                  <Th textAlign={"right"}>Altın</Th>
                  <Th textAlign={"right"}>Mıhlama</Th>
                  <Th textAlign={"right"}>Taş Fiyat</Th>
                  <Th textAlign={"right"}>Maliyet</Th>
                  <Th textAlign={"right"}>Dolar Maliyet</Th>
                  <Th textAlign={"right"}>Satış Fiyatı</Th>
                  <Th textAlign={"right"}>Mevcut Satış</Th>
                  <Th textAlign={"right"}>Mevcut Euro Satış</Th>
                  <Th textAlign={"right"}>Mevcut Euro Toptan</Th>
                  <Th textAlign={"right"}>Mevcut Dolar Satış</Th>
                  <Th textAlign={"right"}>Mevcut Dolar Toptan</Th>
                  <Th textAlign={"right"}>Yurtdışı Satış</Th>
                  <Th textAlign={"right"}>Euro Toptan</Th>
                  <Th textAlign={"right"}>Dolar Toptan</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.calculatedProductGroups.map((p, i) => {
                    let extraCost = 0;
                    let pricePower = 0;
                    let pricePowerForeign = 0;
                    let pricePowerWholesale = 0;
                    const stone = p.stoneType;

                    const findCategory = categoriesData?.categories.find(
                      (c) => c.sku === p.category.sku
                    );
                    if (findCategory) {
                      extraCost = findCategory.extraCost
                        ? findCategory.extraCost
                        : 0;
                      pricePower = findCategory.pricePower
                        ? findCategory.pricePower
                        : 0;
                      pricePowerForeign = findCategory.pricePowerForeign
                        ? findCategory.pricePowerForeign
                        : 0;
                      pricePowerWholesale = findCategory.pricePowerWholesale
                        ? findCategory.pricePowerWholesale
                        : 0;
                    }

                    const rWeight = p.weight ? p.weight : 0;
                    const rCarat = p.carat ? p.carat : 0;
                    const rStone = p.stone ? p.stone : 0;
                    const rGoldPrice = rWeight * 1.1 * (gramGold + workerCost);
                    const rMihlama = rStone * mihlamaPower * dolar;
                    let rDiamond = dolar * rStone * rCarat;
                    if (stone === "DIAMOND") {
                      rDiamond = rDiamond * diamondCost;
                    }

                    if (stone === "OTHER") {
                      rDiamond = rDiamond * stoneCost;
                    }

                    const rRealCost = Math.round(
                      rDiamond + rMihlama + rGoldPrice + extraCost
                    );
                    const findRetailPrice = p.price.find(
                      (pp) => pp.priceType === PriceType.Retail
                    );
                    const findSizeRetailPrice = p.sizePrices[1].prices.find(
                      (pp) => pp.priceType === PriceType.Retail
                    );
                    const findForeignPrice = p.price.find(
                      (pp) => pp.priceType === PriceType.Foreign
                    );
                    const findSizeForeignPrice = p.sizePrices[1].prices.find(
                      (pp) => pp.priceType === PriceType.Foreign
                    );
                    const findForeignUsdPrice = p.price.find(
                      (pp) => pp.priceType === PriceType.Wholesaleus
                    );
                    const findSizeForeignUsdPrice = p.sizePrices[1].prices.find(
                      (pp) => pp.priceType === PriceType.Wholesaleus
                    );
                    const findWholesalePrice = p.price.find(
                      (pp) => pp.priceType === PriceType.Wholesale
                    );
                    const findSizeWholesalePrice = p.sizePrices[1].prices.find(
                      (pp) => pp.priceType === PriceType.Wholesale
                    );
                    const foreignEuroCost = Math.round(
                      (rRealCost / euro) * pricePowerForeign
                    );

                    const wholesaleEuroCost = Math.round(
                      (rRealCost / euro) * pricePowerWholesale
                    );

                    const wholesaleDolarCost = Math.round(
                      (rRealCost / dolar) * pricePowerWholesale
                    );

                    return (
                      <Tr key={p.id}>
                        <Td>{i + 1}</Td>
                        <Td>
                          <img
                            src={`${process.env.REACT_APP_CDNURL}/${p.photos[0]}?trim=30&extend=20&format=webp&quality=50&width=150&height=150&bg=ffffff&fit=contain`}
                            alt="J Gold"
                            width={150}
                            height={150}
                          />
                          <p
                            hidden
                          >{`${process.env.REACT_APP_CDNURL}/${p.photos[0]}?trim=30&extend=20&format=webp&quality=50&width=150&height=150&bg=ffffff&fit=contain`}</p>
                        </Td>
                        <Td>
                          {p.category.sku}-{p.sku} <br />
                          <Box fontSize={"xs"}> {p.stoneType}</Box>{" "}
                        </Td>
                        <Td textAlign={"center"}>
                          {Number(rWeight * 1.1).toFixed(2)} Gram
                        </Td>
                        <Td textAlign={"center"}>{rStone} Adet</Td>
                        <Td textAlign={"center"}>{rCarat} CT</Td>
                        <Td textAlign={"center"}>
                          {(rCarat * rStone).toFixed(2)}
                        </Td>
                        <Td textAlign={"right"}>
                          {currency === Currency.TRY &&
                            formatMoney(rGoldPrice, "TRY")}
                          <br />
                          {currency === Currency.EUR &&
                            formatMoney(rGoldPrice / euro, "EUR")}
                          <br />

                          {currency === Currency.USD &&
                            formatMoney(rGoldPrice / dolar, "USD")}
                        </Td>
                        <Td textAlign={"right"}>
                          {" "}
                          {currency === Currency.TRY &&
                            formatMoney(rMihlama, "TRY")}
                          <br />
                          {currency === Currency.EUR &&
                            formatMoney(rMihlama / euro, "EUR")}
                          <br />
                          {currency === Currency.USD &&
                            formatMoney(rMihlama / dolar, "USD")}
                        </Td>
                        <Td textAlign={"right"}>
                          {" "}
                          {currency === Currency.TRY &&
                            formatMoney(rDiamond, "TRY")}
                          <br />
                          {currency === Currency.EUR &&
                            formatMoney(rDiamond / euro, "EUR")}
                          <br />
                          {currency === Currency.USD &&
                            formatMoney(rDiamond / dolar, "USD")}
                        </Td>
                        <Td textAlign={"right"}>
                          {currency === Currency.TRY &&
                            formatMoney(rRealCost, "TRY")}
                          <br />

                          {currency === "EUR" &&
                            formatMoney(rRealCost / euro, "EUR")}
                          <br />

                          {currency === Currency.USD &&
                            formatMoney(rRealCost / dolar, "USD")}
                        </Td>
                        <Td>{formatMoney(rRealCost / dolar, "USD")}</Td>

                        <Td textAlign={"right"} fontWeight="bold">
                          <Box color="blue.200">
                            {formatMoney(rRealCost * pricePower, "TRY")}
                          </Box>
                          <br />
                          {formatMoney(
                            calculateRoundUp(
                              Math.round(rRealCost * pricePower)
                            ),
                            "TRY"
                          )}
                          <br />

                          <Button
                            onClick={() =>
                              updatePrice(
                                "RETAIL",
                                calculateRoundUp(
                                  Math.round(rRealCost * pricePower)
                                ),
                                p.sku
                              )
                            }
                          >
                            update
                          </Button>
                        </Td>
                        <Td textAlign={"right"}>
                          {findRetailPrice &&
                            findSizeRetailPrice &&
                            formatMoney(
                              findRetailPrice.price + findSizeRetailPrice.price,
                              findRetailPrice.currency.code
                            )}
                        </Td>
                        <Td textAlign={"right"}>
                          {findForeignPrice &&
                            findSizeForeignPrice &&
                            formatMoney(
                              findForeignPrice.price +
                                findSizeForeignPrice.price,
                              "EUR"
                            )}
                          <br />
                        </Td>
                        <Td textAlign={"right"}>
                          {findWholesalePrice &&
                            findSizeWholesalePrice &&
                            formatMoney(
                              findWholesalePrice.price +
                                findSizeWholesalePrice.price,
                              "EUR"
                            )}
                          <br />
                        </Td>

                        <Td fontWeight={"bold"} textAlign={"right"}>
                          <Box color="blue.200">
                            {findForeignUsdPrice &&
                              findSizeForeignUsdPrice &&
                              formatMoney(
                                Math.round(
                                  findForeignUsdPrice.price * 1.8 +
                                    findSizeForeignUsdPrice.price * 1.8
                                ),
                                "USD"
                              )}
                          </Box>
                          {/* <Box>
                            {formatMoney(
                              calculateRoundUp(
                                Math.round(
                                  (rRealCost / dolar) * pricePowerForeign
                                )
                              ),
                              "USD"
                            )}
                          </Box> */}
                        </Td>
                        <Td fontWeight={"bold"} textAlign={"right"}>
                          <Box color="blue.200">
                            {findForeignUsdPrice &&
                              findForeignUsdPrice &&
                              formatMoney(
                                Math.round(
                                  findForeignUsdPrice.price +
                                    findForeignUsdPrice.price
                                ),
                                "USD"
                              )}
                          </Box>
                          {/* <Box>
                            {formatMoney(
                              calculateRoundUp(
                                Math.round(
                                  (rRealCost / dolar) * pricePowerForeign
                                )
                              ),
                              "USD"
                            )}
                          </Box> */}
                        </Td>
                        <Td textAlign={"right"} fontWeight="bold">
                          <Box color="blue.200">
                            {formatMoney(
                              (rRealCost / euro) * pricePowerForeign,
                              "EUR"
                            )}
                          </Box>
                          <br />
                          {formatMoney(
                            calculateRoundUp(Math.round(foreignEuroCost)),
                            "EUR"
                          )}
                          <br />
                          <Button
                            onClick={() =>
                              updatePrice(
                                "FOREIGN",
                                calculateRoundUp(Math.round(foreignEuroCost)),
                                p.sku
                              )
                            }
                          >
                            FOREIGN
                          </Button>
                        </Td>

                        <Td textAlign={"right"} fontWeight="bold">
                          <Box color="blue.200">
                            {formatMoney(
                              (rRealCost / euro) * pricePowerWholesale,
                              "EUR"
                            )}
                          </Box>
                          <br />

                          {formatMoney(
                            calculateRoundUp(Math.round(wholesaleEuroCost)),
                            "EUR"
                          )}
                          <br />
                          <Button
                            onClick={() =>
                              updatePrice(
                                "WHOLESALE",
                                calculateRoundUp(Math.round(wholesaleEuroCost)),
                                p.sku
                              )
                            }
                          >
                            WHOLESALE
                          </Button>
                        </Td>

                        <Td textAlign={"right"} fontWeight="bold">
                          <Box color="blue.200">
                            {formatMoney(
                              (rRealCost / dolar) * pricePowerWholesale,
                              "USD"
                            )}
                          </Box>
                          <br />

                          {formatMoney(
                            calculateRoundUp(
                              Math.round(wholesaleDolarCost),
                              i === 10
                            ),
                            "USD"
                          )}
                          <br />
                          <Button
                            onClick={() =>
                              updatePrice(
                                "WHOLESALEUS",
                                calculateRoundUp(
                                  Math.round(wholesaleDolarCost),
                                  i === 10
                                ),
                                p.sku
                              )
                            }
                          >
                            WHOLESALE
                          </Button>
                        </Td>
                        <Td textAlign={"right"}>
                          <Button
                            onClick={() => {
                              updatePrice(
                                "RETAIL",
                                calculateRoundUp(
                                  Math.round(rRealCost * pricePower)
                                ),
                                p.sku
                              );
                              updatePrice(
                                "WHOLESALEUS",
                                calculateRoundUp(
                                  Math.round(wholesaleDolarCost),
                                  i === 10
                                ),
                                p.sku
                              );
                              updatePrice(
                                "WHOLESALE",
                                calculateRoundUp(Math.round(wholesaleEuroCost)),
                                p.sku
                              );
                              updatePrice(
                                "FOREIGN",
                                calculateRoundUp(Math.round(foreignEuroCost)),
                                p.sku
                              );
                            }}
                          >
                            ALL UPDATE
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default CalculatedPrice;
