import dayjs from "dayjs";
import "dayjs/locale/tr";
import { FcPrint } from "react-icons/fc";
import { ImFilePdf } from "react-icons/im";
import { SiMicrosoftexcel } from "react-icons/si";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  useOrderQuery,
  useToggleOrderUpdateMutation
} from "../../generated/graphql";
import formatMoney from "../../utils/formatMoney";
import RenderMaterials from "../../utils/renderMaterials";
import { Layout } from "../Layout";

import {
  Box,
  Button,
  Container,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { getSizeFromSku } from "../../utils/getSizeFromSku";
import PageHeading from "../ui/PageHeading";

const DeliveryDetail = () => {
  const params = useParams();
  let totalPrice = 0;

  let currency = "TRY";
  const [toggleOrderUpdate] = useToggleOrderUpdateMutation();

  const { data, loading, error, refetch } = useOrderQuery({
    variables: {
      id: params.id as string
    }
  });

  const handleOrderUpdate = async (status: boolean) => {
    try {
      const result = await toggleOrderUpdate({
        variables: {
          id: params.id as string,
          isRead: status
        }
      });
      if (result) {
        return refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderOrder = () => {
    if (loading) {
      return (
        <Box>
          <Spinner mx={"auto"} />
        </Box>
      );
    }
    if (error) {
      return <Box>Bir hata oluştu. {error.message}</Box>;
    }
    if (data) {
      // eslint-disable-next-line array-callback-return
      data.order.products.map((p) => {
        totalPrice = totalPrice + p.price.price;
        currency = p.price.currency.code || "TRY";
      });
      return (
        <Box>
          <Box id="printArea">
            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={"semibold"}
                  color="blue.500"
                >
                  Sevk Yapan
                </Box>
                <Table className="w-full table-auto">
                  <Tbody>
                    <Tr>
                      <Th width={"150px"}> İsim Soyisim</Th>
                      <Td>
                        {data.order.user?.name} {data.order.user?.surname}
                      </Td>
                    </Tr>

                    <Tr>
                      <Th>E-Mail</Th>
                      <Td>{data.order.user?.email}</Td>
                    </Tr>
                    <Tr>
                      <Th>Telefon</Th>
                      <Td>{data.order.user?.phoneNumber}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>

            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={"semibold"}
                  color="blue.500"
                >
                  Sevk Bilgileri
                </Box>
                <Table className="w-full table-auto">
                  <Tbody>
                    <Tr>
                      <Th width={"150px"}>Mağaza</Th>
                      <Td>{data.order.store?.title}</Td>
                    </Tr>

                    <Tr>
                      <Th>Sevk Adresi</Th>
                      <Td>{data.order.store?.address}</Td>
                    </Tr>
                    <Tr>
                      <Th>Telefon</Th>
                      <Td>{data.order.store.phoneNumber}</Td>
                    </Tr>
                    <Tr>
                      <Th>Vergi Bilgileri</Th>
                      <Td>
                        {data.order.store.taxOffice} -{" "}
                        {data.order.store.taxNumber}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th width="150px">Sevk Tarihi</Th>
                      <Td>
                        {dayjs(data.order.createdAt).format(
                          "DD MMMM YYYY HH:mm:ss"
                        )}
                      </Td>
                    </Tr>

                    <Tr>
                      <Th>Toplam Tutar</Th>
                      <Td>
                        {currency ? formatMoney(totalPrice, currency) : "-"}
                      </Td>
                    </Tr>

                    <Tr>
                      <Th>KDV Hariç</Th>
                      <Td>
                        {currency
                          ? formatMoney(totalPrice / 1.2, currency)
                          : "-"}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>KDV</Th>
                      <Td>
                        {currency
                          ? formatMoney(totalPrice - totalPrice / 1.2, currency)
                          : "-"}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>

            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={"semibold"}
                  color="blue.500"
                >
                  Ürünler
                </Box>
                <Table variant={"striped"} className="printArea">
                  <Thead
                    sx={{
                      "@media print": {
                        display: "table-header-group",
                        visibility: "visible"
                      }
                    }}
                    display={{
                      base: "none",
                      md: "table-header-group"
                    }}
                  >
                    <Tr>
                      <Th className="w-40 px-5 py-2 text-center bg-default-600">
                        Fotoğraf
                      </Th>
                      <Th className="px-5 py-2 whitespace-nowrap bg-default-600">
                        SKU
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Ürün</Th>
                      <Th className="px-5 py-2 text-center w-42 bg-default-600">
                        Beden
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Seçenekler</Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        KDV Hariç
                      </Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        KDV
                      </Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        Fiyat
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.order.products.map((p, i) => {
                      const description = p.product.productGroup.description;
                      let title = "Ürün İsimi Yok";
                      if (description) {
                        const getTitle = description.find(
                          (d) => d.language === "tr"
                        );
                        title = getTitle?.title ? getTitle.title : "";
                      }
                      return (
                        <Tr key={i}>
                          <Td
                            sx={{
                              "@media print": {
                                display: "none",
                                visibility: "hidden"
                              }
                            }}
                            display={{
                              base: "table-cell",
                              md: "none"
                            }}
                          >
                            <Box bgColor={"gray.100"} pb={4} textAlign="center">
                              <Box mb={2}>
                                <img
                                  src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=500&height=500&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}
                                  className="mx-auto"
                                  alt="product"
                                />
                              </Box>
                              <Box mb={2} fontWeight="semibold">
                                {title}
                              </Box>
                              <Box mb={2}>
                                <Box>{p.product.sku}</Box>
                                <Box>
                                  <div className="text-xs text-gray-500">
                                    {p.product.barcode}
                                  </div>
                                </Box>
                              </Box>
                              <Box my={4}>
                                {getSizeFromSku(p.product.sku)}{" "}
                                {p.ringSize && ` - ${p.ringSize}`}
                              </Box>
                              <Box>
                                <RenderMaterials
                                  locale="tr"
                                  sku={p.product.sku!}
                                />
                              </Box>
                            </Box>
                          </Td>
                          <Td
                            sx={{
                              "@media print": {
                                display: "table-cell",
                                visibility: "visible"
                              }
                            }}
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=50&quality=60&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}
                              className="mx-auto"
                              alt="product"
                            />
                          </Td>
                          <Td
                            sx={{
                              "@media print": {
                                display: "table-cell",
                                visibility: "visible"
                              }
                            }}
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {p.product.sku}
                          </Td>
                          <Td
                            sx={{
                              "@media print": {
                                display: "table-cell",
                                visibility: "visible"
                              }
                            }}
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            <div className="text-xs text-gray-500">
                              {p.product.barcode}
                            </div>
                            <div>{title}</div>

                            {p.additionalInfo && (
                              <div className="font-semibold">
                                <hr className="my-2" />
                                {p.additionalInfo}
                              </div>
                            )}
                          </Td>
                          <Td
                            sx={{
                              "@media print": {
                                display: "table-cell",
                                visibility: "visible"
                              }
                            }}
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {getSizeFromSku(p.product.sku)}{" "}
                            {p.ringSize && ` - ${p.ringSize}`}
                          </Td>
                          <Td
                            sx={{
                              "@media print": {
                                display: "table-cell",
                                visibility: "visible"
                              }
                            }}
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            <RenderMaterials locale="tr" sku={p.product.sku!} />
                          </Td>
                          <Td
                            sx={{
                              "@media print": {
                                display: "table-cell",
                                visibility: "visible"
                              }
                            }}
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {formatMoney(
                              p.price.price / 1.2,
                              p.price.currency.code
                            )}
                          </Td>
                          <Td
                            sx={{
                              "@media print": {
                                display: "table-cell",
                                visibility: "visible"
                              }
                            }}
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {formatMoney(
                              p.price.price - p.price.price / 1.2,
                              p.price.currency.code
                            )}
                          </Td>
                          <Td
                            sx={{
                              "@media print": {
                                display: "table-cell",
                                visibility: "visible"
                              }
                            }}
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {formatMoney(p.price.price, p.price.currency.code)}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    return <Box>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Box>;
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Sevk Bilgisi" />
        <Box my={4} bgColor="gray.100" p={4}>
          <Flex justifyContent={"flex-end"} experimental_spaceX={4}>
            <Button
              colorScheme={"blue"}
              variant="outline"
              onClick={() => {
                handleOrderUpdate(true);
                window.print();
              }}
            >
              <FcPrint className="text-xl" />
            </Button>

            <Button
              colorScheme={"purple"}
              variant="outline"
              onClick={(e) => {
                var table_elt = document.getElementById("printArea");
                var workbook = XLSX.utils.table_to_book(table_elt);
                var ws = workbook.Sheets["Sheet1"];
                XLSX.utils.sheet_add_aoa(
                  ws,
                  [["Created " + new Date().toISOString()]],
                  { origin: -1 }
                );
                XLSX.writeFile(workbook, "Report.xlsx");
              }}
            >
              <SiMicrosoftexcel />
            </Button>
            <Button
              as={"a"}
              variant="outline"
              colorScheme={"green"}
              target="_blank"
              href={`https://juju-fatura-pdf.vercel.app/${data?.order.id}`}
              rel="noreferrer"
            >
              <ImFilePdf />
            </Button>
          </Flex>
        </Box>
        <Box>{renderOrder()}</Box>
      </Container>
    </Layout>
  );
};

export default DeliveryDetail;
