import { useState } from "react";
import {
  useOrderReportsQuery,
  useStoreQuery,
  useCurrencyQuery,
  OrderReportsQuery
} from "../../generated/graphql";
import { Layout } from "./../Layout";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import formatMoney from "../../utils/formatMoney";
import "dayjs/locale/tr";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Button,
  FormLabel,
  Input,
  Tfoot,
  List,
  ListItem
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";

dayjs.locale("tr");

const Dashboard = () => {
  const params = useParams();
  const [tempStart, setTempStart] = useState(
    dayjs().format("YYYY-MM-DDT00:00:00.sssZ")
  );
  const [tempEnd, setTempEnd] = useState(
    dayjs().format("YYYY-MM-DDT23:59:59.sssZ")
  );
  const [startAt, setStartAt] = useState(
    dayjs().format("YYYY-MM-DDT00:00:00.sssZ")
  );
  const [endAt, setEndAt] = useState(
    dayjs().format("YYYY-MM-DDT23:59:59.sssZ")
  );
  const { data, loading } = useOrderReportsQuery({
    variables: {
      startAt,
      endAt,
      store: params.storeId as string
    }
  });

  const { data: currencyData } = useCurrencyQuery({
    variables: {
      id: "cku3v5lneg00b0859qf500t3i"
    }
  });

  const { data: storeData } = useStoreQuery({
    variables: {
      id: params.storeId as string
    }
  });
  let headerTotal = 0;
  let headerTotalCreditCard = 0;
  let headerTotalCash = 0;
  let headerTotalTransfer = 0;
  const renderFooter = (data: OrderReportsQuery) => {
    if (data.orderReports.length === 0) {
      return (
        <Tr>
          <Td colSpan={14}>Veri bulunmamaktadır</Td>
        </Tr>
      );
    }

    let totalCurrency = "TRY";

    let totalTotalPrice = 0;
    let totalTotalCreditCard = 0;
    let totalTotalCash = 0;
    let totalTotalGift = 0;
    let totalTotalTransfer = 0;
    let totalTotalOther = 0;
    let totalDiscount = 0;
    let totalTotal = 0;
    let totalTotalWeight = 0;
    let totalTotalWeightPrice = 0;

    data.orderReports.map((store: any, i) => {
      let totalPrice = 0;
      let totalCreditCard = 0;
      let totalCash = 0;
      let totalGift = 0;
      let totalTransfer = 0;
      let totalOther = 0;
      let totalWeight = 0;
      let totalWeightPrice = 0;

      store.products.map((p: any) => {
        totalWeight = totalWeight + p.product.productGroup.weight;
        totalWeightPrice =
          totalWeightPrice +
          currencyData!.currency.buy * p.product.productGroup.weight;
      });

      store.payment.map((d: any) => {
        let findPaidPrice = 0;
        let iyzicoCurrency = "";

        if (d.itemTransactions && d.itemTransactions.length > 0) {
          d.itemTransactions.map((i: any) => {
            if (i.convertedPayout) {
              findPaidPrice =
                findPaidPrice + i.convertedPayout.merchantPayoutAmount;
              iyzicoCurrency = i.convertedPayout.currency;
            }
          });
        } else {
          findPaidPrice = d.paidPrice;
          iyzicoCurrency = "TRY";
        }

        totalPrice = totalPrice + findPaidPrice;
        totalCurrency = iyzicoCurrency;
        if (d.paymentType === "CREDITCARD") {
          totalCreditCard = totalCreditCard + findPaidPrice;
        }
        if (d.paymentType === "CASH") {
          totalCash = totalCash + findPaidPrice;
        }
        if (d.paymentType === "GIFTCARD") {
          totalGift = totalGift + findPaidPrice;
        }
        if (d.paymentType === "TRANSFER") {
          totalTransfer = totalTransfer + findPaidPrice;
        }
        if (d.paymentType === "OTHER") {
          totalOther = totalOther + findPaidPrice;
        }
      });

      totalTotalPrice = totalTotalPrice + totalPrice - totalGift;
      totalTotalCreditCard = totalTotalCreditCard + totalCreditCard;
      totalTotalCash = totalTotalCash + totalCash;
      totalTotalGift = totalTotalGift + totalGift;
      totalTotalTransfer = totalTotalTransfer + totalTransfer;
      totalTotalOther = totalTotalOther + totalOther;
      totalDiscount = totalDiscount + store.discount;
      totalTotalWeight = totalTotalWeight + totalWeight;
      totalTotalWeightPrice = totalTotalWeightPrice + totalWeightPrice;
      headerTotal = totalTotalCash + totalTotalCreditCard + totalTotalTransfer;
      headerTotalCash = headerTotalCash + totalCash;
      headerTotalCreditCard = headerTotalCreditCard + totalCreditCard;
      headerTotalTransfer = headerTotalTransfer + totalTransfer;
    });

    return (
      <tr>
        <Td colSpan={5}></Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {" "}
            {totalTotalWeight ? totalTotalWeight.toFixed(2) : "-"}
          </Box>
        </Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {totalTotalWeightPrice ? totalTotalWeightPrice.toFixed(4) : "-"}
          </Box>
        </Td>
        <Td colSpan={1}>&nbsp;</Td>
        <Td colSpan={1}>&nbsp;</Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {formatMoney(totalTotalCreditCard, "TRY")}{" "}
          </Box>
        </Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {formatMoney(totalTotalCash, "TRY")}{" "}
          </Box>
        </Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {formatMoney(totalTotalGift, "TRY")}{" "}
          </Box>
        </Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {formatMoney(totalTotalTransfer, "TRY")}
          </Box>
        </Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {formatMoney(totalTotalOther, "TRY")}
          </Box>
        </Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {formatMoney(totalDiscount, "TRY")}
          </Box>
        </Td>
        <Td colSpan={1}>
          <Box as="span" fontWeight={"bold"}>
            {formatMoney(totalTotalPrice, "TRY")}
          </Box>
        </Td>
      </tr>
    );
  };

  const renderData = (data: OrderReportsQuery) => {
    let f = 0;
    if (data.orderReports.length === 0) {
      return (
        <Tr>
          <Td colSpan={14}>Veri bulunmamaktadır</Td>
        </Tr>
      );
    }

    return data.orderReports.map((store: any, i) => {
      let totalPrice = 0;
      let totalCreditCard = 0;
      let totalCash = 0;
      let totalGift = 0;
      let totalTransfer = 0;
      let totalOther = 0;
      let weightTotal = 0;

      let totalCurrency = "TRY";
      store.payment.map((d: any) => {
        console.log(d.paymentType, "PAYMENT TYPE");
        let findPaidPrice = 0;
        let iyzicoCurrency = "";

        if (d.itemTransactions && d.itemTransactions.length > 0) {
          d.itemTransactions.map((i: any) => {
            if (i.convertedPayout) {
              findPaidPrice =
                findPaidPrice + i.convertedPayout.merchantPayoutAmount;
              iyzicoCurrency = i.convertedPayout.currency;
            }
          });
        } else {
          findPaidPrice = d.paidPrice;
          iyzicoCurrency = "TRY";
        }

        totalPrice = totalPrice + findPaidPrice;

        totalCurrency = d.currency;
        if (d.paymentType === "CREDITCARD") {
          totalCreditCard = totalCreditCard + findPaidPrice;
        }
        if (d.paymentType === "CASH") {
          console.log("cash geldi ");
          totalCash = totalCash + findPaidPrice;
        }
        if (d.paymentType === "GIFTCARD") {
          totalGift = totalGift + findPaidPrice;
        }
        if (d.paymentType === "TRANSFER") {
          totalTransfer = totalTransfer + findPaidPrice;
        }
        if (d.paymentType === "OTHER") {
          totalOther = totalOther + findPaidPrice;
        }
      });
      return store.products.map((p: any, l: any) => {
        weightTotal = weightTotal + p.product.productGroup.weight;
        f = f + 1;
        console.log(totalCash, "TT CASH");
        // excelData.push({
        //   "Tarih / Saat": `${dayjs(store.createdAt).format("DD.MM.YY")} - ${dayjs(store.createdAt).format("HH:mm")}`,
        //   "Personel": store.seller ? `${store.seller.name} ${store.seller.surname}` : "",
        //   "Ağırlık": p.product.productGroup.weight ? p.product.productGroup.weight.toFixed(2) : "-",
        //   "Maliyet": p.product.productGroup.weight
        //     ? (currencyData.currency.buy * p.product.productGroup.weight).toFixed(4)
        //     : "-",
        //   "Kod": p.product.sku,
        //   "Fiyat": formatMoney(p.price.price, p.price.currency.code),
        //   "Kredi Kartı": formatMoney(totalCreditCard, "TRY"),
        //   "Nakit": formatMoney(totalCash, "TRY"),
        //   "Hediye": formatMoney(totalGift, "TRY"),
        //   "Havale": formatMoney(totalTransfer, "TRY"),
        //   "Diğer": formatMoney(totalOther, "TRY"),
        //   "İndirim": formatMoney(store.discount, "TRY"),
        //   "Toplam": formatMoney(totalPrice, "TRY")
        // })
        return (
          <Tr key={`${store.id}-${l}-${p.id}`}>
            <Td>{f}</Td>
            <Td>
              {dayjs(store.createdAt).format("DD.MM.YY")} <br />{" "}
              {dayjs(store.createdAt).format("HH:mm")}
            </Td>
            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <Box fontSize="xs">
                  {store.seller
                    ? `${store.seller.name} ${store.seller.surname}`
                    : "-"}
                </Box>
                <Box
                  my="2"
                  bg="purple"
                  px="2"
                  py="1"
                  textAlign={"center"}
                  rounded="xl"
                  color={"white"}
                >
                  <Link to={`/siparis-detayi/${store.id}`}>Detay</Link>
                </Box>
              </Td>
            )}
            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <Box fontSize="xs">
                  {store.seller
                    ? `${store.paymentFirstName} ${store.paymentLastName}`
                    : "-"}
                </Box>
              </Td>
            )}
            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <Box fontSize="xs">{store.isInvoice ? "Faturalı" : ""}</Box>
              </Td>
            )}
            <Td>
              <div className="text-xs">
                {p.product.productGroup.weight
                  ? p.product.productGroup.weight.toFixed(2)
                  : "-"}
              </div>
            </Td>
            <Td>
              <div className="text-xs">
                {p.product.productGroup.weight
                  ? (
                      currencyData!.currency.buy * p.product.productGroup.weight
                    ).toFixed(4)
                  : "-"}{" "}
              </div>
            </Td>
            <Td>
              <div className="text-xs">{p.product.sku}</div>
            </Td>
            <Td>
              <div className="text-xs">
                {formatMoney(p.price.price, p.price.currency.code)}
              </div>
            </Td>

            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <div className="text-xs">
                  {formatMoney(totalCreditCard, "TRY")}
                </div>
              </Td>
            )}
            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <div className="text-xs">{formatMoney(totalCash, "TRY")}</div>
              </Td>
            )}
            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <div className="text-xs">{formatMoney(totalGift, "TRY")}</div>
              </Td>
            )}
            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <div className="text-xs">
                  {formatMoney(totalTransfer, "TRY")}
                </div>
              </Td>
            )}
            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <div className="text-xs">{formatMoney(totalOther, "TRY")}</div>
              </Td>
            )}
            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <div className="text-xs">
                  {formatMoney(store.discount, "TRY")}
                </div>
              </Td>
            )}

            {l === 0 && (
              <Td rowSpan={store.products.length}>
                <div className="text-xs">{formatMoney(totalPrice, "TRY")}</div>
              </Td>
            )}
          </Tr>
        );
      });
    });
  };

  const handleDateChange = (e: any) => {
    setTempStart(dayjs(e).format("YYYY-MM-DDT00:00:00.sssZ"));
  };
  const handleEndDateChange = (e: any) => {
    setTempEnd(dayjs(e).format("YYYY-MM-DDT23:59:59.sssZ"));
  };

  const handleSubmitDate = () => {
    setStartAt(tempStart);
    setEndAt(tempEnd);
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading
          title={`${storeData && storeData.store.title} (${dayjs(
            startAt
          ).format("DD MMM YYYY")} - 
          ${dayjs(endAt).format("DD MMM YYYY")})`}
        />
        <Box display={"none"}>
          <Box overflowX={"auto"}>
            <Table id="price-list">
              <Tbody>
                {loading && (
                  <Tr>
                    <Td colSpan={14}>
                      <Spinner mx="auto" />
                    </Td>
                  </Tr>
                )}
              </Tbody>
              <Tfoot>
                {!loading && data && currencyData && renderFooter(data)}
              </Tfoot>
            </Table>
          </Box>
        </Box>

        <Box bgColor={"gray.100"} py={4} px={4} mb={6}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Flex
              alignItems={"center"}
              flexDirection={{
                base: "column",
                md: "row"
              }}
              justifyContent="start"
            >
              <Flex
                flexDirection={{
                  base: "column",
                  md: "row"
                }}
                alignItems={"center"}
              >
                <FormLabel>Başlangıç: </FormLabel>
                <Input
                  type="date"
                  defaultValue={dayjs(tempStart.toString()).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e: any) => handleDateChange(e.target.value)}
                />
              </Flex>
              <Flex
                mt={{
                  base: 2,
                  md: 0
                }}
                flexDirection={{
                  base: "column",
                  md: "row"
                }}
                ml={{
                  base: 0,
                  md: 4
                }}
                alignItems="center"
              >
                <FormLabel>Bitiş: </FormLabel>
                <Input
                  type="date"
                  defaultValue={dayjs(tempEnd.toString()).format("YYYY-MM-DD")}
                  onChange={(e: any) => handleEndDateChange(e.target.value)}
                />
              </Flex>
              <Box ml={4}>
                <Button
                  mt={{
                    base: 2,
                    md: 0
                  }}
                  variant={"outline"}
                  colorScheme="blue"
                  onClick={handleSubmitDate}
                >
                  Raporu Oluştur
                </Button>
              </Box>
            </Flex>
            <Box>
              <List>
                <ListItem>
                  Nakit Tutar:{" "}
                  {!loading &&
                    data &&
                    currencyData &&
                    formatMoney(headerTotalCash, "TRY")}
                </ListItem>
                <ListItem>
                  Kredi Kartı Tutar:{" "}
                  {!loading &&
                    data &&
                    currencyData &&
                    formatMoney(headerTotalCreditCard, "TRY")}
                </ListItem>
                <ListItem>
                  Havale Tutar:{" "}
                  {!loading &&
                    data &&
                    currencyData &&
                    formatMoney(headerTotalTransfer, "TRY")}
                </ListItem>
                <ListItem>
                  Toplam Tutar:{" "}
                  {!loading &&
                    data &&
                    currencyData &&
                    formatMoney(headerTotal, "TRY")}
                </ListItem>
              </List>
            </Box>
          </Flex>
        </Box>
        <Box overflowX={"auto"}>
          <Table id="price-list">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Tarih / Saat</Th>
                <Th>Personel</Th>
                <Th>Müşteri</Th>
                <Th>Fatura Durumu</Th>
                <Th>Ağırlık</Th>
                <Th>Maliyet</Th>
                <Th>Kod</Th>
                <Th>Fiyat</Th>

                <Th>Kredi Kartı</Th>
                <Th>Nakit</Th>
                <Th>Hediye</Th>
                <Th>Havale</Th>
                <Th>Diğer</Th>
                <Th>İndirim</Th>

                <Th>Toplam</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading && (
                <Tr>
                  <Td colSpan={14}>
                    <Spinner mx="auto" />
                  </Td>
                </Tr>
              )}
              {!loading && data && currencyData && renderData(data)}
            </Tbody>
            <Tfoot>
              {!loading && data && currencyData && renderFooter(data)}
            </Tfoot>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default Dashboard;
