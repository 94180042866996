/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Layout } from "../Layout";
import {
  Container,
  Box,
  Flex,
  Text,
  Image,
  Spinner,
  SimpleGrid
} from "@chakra-ui/react";
import Heading from "../ui/PageHeading";
import { PriceType, useProductGroupQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";
import Select from "react-select";
import formatMoney from "../../utils/formatMoney";

export const ProductGroupDetail = () => {
  const [photo, setPhoto] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [priceType, setPriceType] = React.useState(PriceType.Retail);
  const { id } = useParams();

  const { data, loading, error } = useProductGroupQuery({
    variables: {
      id: id || ""
    }
  });
  // let price = 0;
  let currency = "TRY";
  React.useEffect(() => {
    if (data && data.productGroup) {
      setPhoto(data.productGroup.photos[0]);
      const getPrice = data.productGroup.price.find(
        (p) => p.priceType === priceType
      );
      if (getPrice) {
        setPrice(getPrice.price);
        currency = getPrice.currency.code;
      }
    }
  }, [data, priceType]);
  const priceTypes = [
    {
      label: "TL Satış",
      value: PriceType.Retail
    },
    {
      label: "Euro Satış",
      value: PriceType.Foreign
    },
    {
      label: "Euro Toptan",
      value: PriceType.Wholesale
    },
    {
      label: "Dolar",
      value: PriceType.Retailus
    },
    {
      label: "Dolar Toptan",
      value: PriceType.Wholesaleus
    },
    {
      label: "Kanada Fiyatı",
      value: PriceType.Wholesaleca
    }
  ];
  if (error) return <div>Bir Hata Oluştu! Sayfayı Yenileyin</div>;
  return (
    <Layout>
      <Container maxW={"container.xl"}>
        {loading ? (
          <Box>
            <Spinner />
          </Box>
        ) : (
          <Box>
            <Heading title={data?.productGroup?.description[0].title || ""} />
            <Box w="35%">
              <strong>Fiyat Türü</strong>
              <Select
                onChange={(e) => setPriceType(e?.value || PriceType.Retail)}
                options={priceTypes}
              />
            </Box>
            {data && (
              <Box mt={4} mx="auto" w={["full"]}>
                <Flex
                  direction={{
                    base: "column",
                    md: "row"
                  }}
                >
                  <Box>
                    {data && (
                      <Box mb={6}>
                        <img
                          src={`${process.env.REACT_APP_CDNURL}/${photo}?trim=30&extend=20&format=webp&quality=50&width=700&height=700&bg=ffffff&fit=contain`}
                          alt=""
                          width={500}
                          height={500}
                        />
                      </Box>
                    )}
                    <SimpleGrid
                      gap={4}
                      columns={{
                        base: 4,
                        md: 6
                      }}
                    >
                      {data &&
                        data.productGroup?.photos.map((p, i) => {
                          return (
                            <Box
                              border="1px solid"
                              borderColor={"gray.100"}
                              rounded="md"
                              overflow={"hidden"}
                              p={1}
                              key={i + 1}
                            >
                              <img
                                onClick={() => {
                                  setPhoto(p);
                                }}
                                src={`${process.env.REACT_APP_CDNURL}/${p}`}
                                alt="Juju"
                                width={100}
                                height={100}
                              />
                            </Box>
                          );
                        })}
                    </SimpleGrid>
                  </Box>
                  <Box
                    width={"100%"}
                    mt={{
                      base: 6,
                      md: 0
                    }}
                    bgColor="gray.50"
                    rounded="sm"
                    p={4}
                    textAlign={{
                      base: "center",
                      md: "left"
                    }}
                  >
                    <Box
                      fontWeight={"bold"}
                      textColor={"black"}
                      fontSize={{
                        base: "2xl",
                        md: "3xl"
                      }}
                    >
                      {data && data?.productGroup?.description[0].title}
                    </Box>

                    <Box
                      fontSize={{
                        base: "lg",
                        md: "lg"
                      }}
                      color="gray.500"
                    >
                      {data &&
                        `${data?.productGroup?.category.sku}-${data?.productGroup?.sku}`}
                    </Box>
                    <Box
                      fontSize={{
                        base: "xl",
                        md: "xl"
                      }}
                    >
                      {data &&
                        data?.productGroup?.category.description[0].title}
                    </Box>
                    <Box fontSize={"xl"} fontWeight={"bold"} my={2}>
                      Ağırlık
                      <Text fontSize={"lg"} fontWeight={"normal"} mr="2">
                        {data.productGroup?.weight}GR
                      </Text>
                    </Box>

                    <Box fontSize={"xl"} fontWeight={"bold"} my="2">
                      Seçenekler
                      <Box>
                        {data &&
                          data?.productGroup?.materials.map((m) => {
                            return (
                              <Box key={m.id} fontWeight={"normal"} mr="2">
                                {m.description[0].title}{" "}
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                    <Box fontSize={"xl"} fontWeight={"bold"} my="2">
                      Satış Fiyatı
                    </Box>
                    <Flex flexDirection={"column"}>
                      {data &&
                        data?.productGroup?.sizePrices.map((s) => {
                          let sizePrice = s.prices.find(
                            (sp) => sp.priceType === PriceType.Retail
                          )?.price;
                          if (!sizePrice) {
                            return (
                              <Box
                                fontSize={"lg"}
                                key={s.size.id}
                                fontWeight={"normal"}
                                mr="2"
                              >
                                {s.size.sku} -{" "}
                                {formatMoney(Number(price), currency)}
                              </Box>
                            );
                          }
                          return (
                            <Box key={s.size.id} fontWeight={"normal"} mr="2">
                              <Text fontSize={"lg"}>
                                {s.size.sku}-{" "}
                                {formatMoney(
                                  Number(price + sizePrice),
                                  currency
                                )}
                              </Text>
                            </Box>
                          );
                        })}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Layout>
  );
};
