import dayjs from "dayjs";
import { FcBookmark, FcCheckmark } from "react-icons/fc";
import { useParams } from "react-router-dom";
import {
  useInvoicedOrderMutation,
  useOrderQuery
} from "../../generated/graphql";
import formatMoney from "../../utils/formatMoney";
import RenderMaterials from "../../utils/renderMaterials";
import { Layout } from "./../Layout";

import "dayjs/locale/tr";

import {
  Box,
  Container,
  FormControl,
  SimpleGrid,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import { getSizeFromSku } from "../../utils/getSizeFromSku";
import PageHeading from "../ui/PageHeading";

dayjs.locale("tr");

const Dashboard = () => {
  const params = useParams();
  let totalPrice = 0;
  let totalPaidPrice = 0;
  let iyzico = 0;
  let iyzicoCurrency = "TRY";
  let currency = "TRY";
  const toast = useToast();
  const [invoicedOrder] = useInvoicedOrderMutation();
  const { data, loading, error, refetch } = useOrderQuery({
    variables: {
      id: params.orderId as string
    }
  });

  const handleInvoice = async () => {
    await invoicedOrder({
      variables: {
        orderId: params.orderId as string
      }
    });
    refetch();
    return toast({
      title: "Fatura Kesildi",
      description: "Fatura başarıyla kesildi.",
      status: "success",
      duration: 9000,
      isClosable: true
    });
  };
  const renderOrder = () => {
    if (loading) {
      return (
        <Box>
          <Spinner mx={"auto"} />
        </Box>
      );
    }
    if (error) {
      return <Box>Bir hata oluştu. {error.message}</Box>;
    }
    if (data) {
      data.order.payment.map((p) => {
        totalPrice = totalPrice + p.price;
        currency = p.currency ? p.currency : "TRY";
        totalPaidPrice = totalPaidPrice + p.paidPrice;
      });
      return (
        <Box>
          {data.order.isInvoiced ? (
            <Tag colorScheme="green" size="sm">
              <FcCheckmark /> Fatura Kesildi
            </Tag>
          ) : (
            <Tag
              onClick={handleInvoice}
              cursor={"pointer"}
              colorScheme="red"
              size="sm"
            >
              <FcBookmark /> Fatura Kes
            </Tag>
          )}
          <Box id="printArea">
            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={"semibold"}
                  color="blue.500"
                >
                  Üye Bilgileri
                </Box>
                <Table className="w-full table-auto">
                  <Tbody>
                    <Tr>
                      <Th width={"150px"}> İsim Soyisim</Th>
                      <Td>
                        {data.order.user?.name} {data.order.user?.surname}
                      </Td>
                    </Tr>

                    <Tr>
                      <Th>E-Mail</Th>
                      <Td>{data.order.user?.email}</Td>
                    </Tr>
                    <Tr>
                      <Th>Telefon</Th>
                      <Td>{data.order.user?.phoneNumber}</Td>
                    </Tr>
                    <Tr>
                      <Th>T.C. No / Vergi No</Th>
                      <Td>{data.order.user?.identityNumber}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>

            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={"semibold"}
                  color="blue.500"
                >
                  Sipariş Bilgileri
                </Box>
                <Table className="w-full table-auto">
                  <Tbody>
                    <Tr>
                      <Th width="150px">Fatura Durumu</Th>
                      <Td>
                        {data.order.isInvoice ? (
                          <Text>Fatura gerekli </Text>
                        ) : (
                          <Text>Faturasız</Text>
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th width="150px">Tarih</Th>
                      <Td>
                        {dayjs(data.order.createdAt).format(
                          "DD MMMM YYYY HH:mm:ss"
                        )}
                      </Td>
                    </Tr>

                    <Tr>
                      <Th>Dil</Th>
                      <Td>{data.order.language}</Td>
                    </Tr>

                    <Tr>
                      <Th>Sipariş Durumu</Th>
                      <Td>{data.order.orderStatus}</Td>
                    </Tr>

                    <Tr>
                      <Th>Sipariş No:</Th>
                      <Td>{data.order.conversationId}</Td>
                    </Tr>

                    <Tr>
                      <Th>İyzico Tutarı</Th>
                      <Td>
                        {data.order.payment.map((p) => {
                          p.itemTransactions &&
                            p.itemTransactions.length > 0 &&
                            p.itemTransactions.map((i) => {
                              if (i.convertedPayout) {
                                // iyzico = iyzico + i.convertedPayout?.merchantPayoutAmount ? i.convertedPayout.merchantPayoutAmount : 0;
                                iyzicoCurrency = i.convertedPayout.currency
                                  ? i.convertedPayout.currency
                                  : "TRY";
                              }
                            });
                        })}
                        {formatMoney(iyzico, iyzicoCurrency)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Toplam Tutar</Th>
                      {data.order.store.id === "ckoqhdhaw1vt60759sz8nso01" ? (
                        <Td>
                          {currency
                            ? formatMoney(
                                data.order?.payment[0]?.price,
                                currency
                              )
                            : "-"}
                        </Td>
                      ) : (
                        <Td>
                          {currency ? formatMoney(totalPrice, currency) : "-"}
                        </Td>
                      )}
                    </Tr>
                    <Tr>
                      <Th>Ödenen Tutar</Th>
                      {data.order.store.id ? (
                        <Td>
                          {currency
                            ? formatMoney(
                                data.order?.payment[0]?.paidPrice,
                                currency
                              )
                            : "-"}
                        </Td>
                      ) : (
                        <Td>
                          {currency
                            ? formatMoney(totalPaidPrice, currency)
                            : "-"}
                        </Td>
                      )}
                    </Tr>
                    <Tr>
                      <Th>KDV Hariç</Th>
                      <Td>
                        {currency
                          ? formatMoney(
                              data.order?.payment[0]?.paidPrice / 1.2,
                              currency
                            )
                          : "-"}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>KDV</Th>
                      {data.order.store.id ? (
                        <Td>
                          {currency
                            ? formatMoney(
                                data.order?.payment[0]?.paidPrice * 0.2,
                                currency
                              )
                            : "-"}
                        </Td>
                      ) : (
                        <Td>
                          {currency
                            ? formatMoney(totalPrice * 0.2, currency)
                            : "-"}
                        </Td>
                      )}
                    </Tr>
                    <Tr>
                      <Th>İndirim</Th>
                      <Td>
                        {currency
                          ? formatMoney(
                              data.order.discount ? data.order.discount : 0,
                              currency
                            )
                          : ""}{" "}
                        {data.order.couponCode
                          ? `[${data.order.couponCode.code}]`
                          : ""}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Taksit Sayısı</Th>
                      <Td>{data.order.installment} Taksit</Td>
                    </Tr>
                    <Tr>
                      <Th>Ödeme Türü</Th>
                      <Td>{data.order.paymentType}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>

            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={"semibold"}
                  color="blue.500"
                >
                  Ürünler
                </Box>
                <Table variant={"striped"}>
                  <Thead
                    display={{
                      base: "none",
                      md: "table-header-group"
                    }}
                  >
                    <Tr>
                      <Th className="w-40 px-5 py-2 text-center bg-default-600">
                        Fotoğraf
                      </Th>
                      <Th className="px-5 py-2 whitespace-nowrap bg-default-600">
                        SKU
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Ürün</Th>
                      <Th className="px-5 py-2 text-center w-42 bg-default-600">
                        Beden
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Seçenekler</Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        KDV Hariç
                      </Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        KDV
                      </Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        Fiyat
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.order.products.map((p, i) => {
                      const description = p.product.productGroup.description;
                      let title = "Ürün İsimi Yok";
                      if (description) {
                        const getTitle = description.find(
                          (d) => d.language === "tr"
                        );
                        title = getTitle?.title ? getTitle.title : "";
                      }
                      return (
                        <Tr key={i}>
                          <Td
                            display={{
                              base: "table-cell",
                              md: "none"
                            }}
                          >
                            <Box
                              py={4}
                              bgColor="gray.100"
                              rounded="md"
                              textAlign={"center"}
                            >
                              <Box
                                display="flex"
                                justifyContent={"center"}
                                mb={2}
                              >
                                <img
                                  src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=250&quality=60`}
                                  className="mx-auto"
                                  alt="product"
                                />
                              </Box>
                              <Box mb={2} fontSize="lg" fontWeight={"semibold"}>
                                {title}
                              </Box>
                              <Box mb={1} fontSize="sm">
                                {p.product.sku}
                              </Box>
                              <Box mb={2} fontSize="sm">
                                {p.product.barcode}
                              </Box>

                              {p.additionalInfo && (
                                <Box mb={2}>
                                  <hr className="my-2" />
                                  {p.additionalInfo}
                                </Box>
                              )}

                              <Box>
                                Beden: {getSizeFromSku(p.product.sku)}{" "}
                                {p.ringSize && ` - ${p.ringSize}`}
                              </Box>
                              <Box my={4}>
                                <RenderMaterials
                                  locale="tr"
                                  sku={p.product.sku!}
                                />
                              </Box>
                              <SimpleGrid columns={3} gap={2}>
                                <Box>
                                  <FormControl>Kdv Hariç</FormControl>
                                  {formatMoney(
                                    p.price.price / 1.2,
                                    p.price.currency.code
                                  )}
                                </Box>

                                <Box>
                                  <FormControl>Kdv</FormControl>
                                  {formatMoney(
                                    p.price.price - p.price.price / 1.2,
                                    p.price.currency.code
                                  )}
                                </Box>

                                <Box>
                                  <FormControl>Fiyat</FormControl>
                                  {formatMoney(
                                    p.price.price,
                                    p.price.currency.code
                                  )}
                                </Box>
                              </SimpleGrid>
                            </Box>
                          </Td>
                          <Td
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=50&quality=60`}
                              className="mx-auto"
                              alt="product"
                            />
                          </Td>
                          <Td
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {p.product.sku}
                          </Td>
                          <Td
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            <div className="text-xs text-gray-500">
                              {p.product.barcode}
                            </div>
                            <div>{title}</div>

                            {p.additionalInfo && (
                              <div className="font-semibold">
                                <hr className="my-2" />
                                {p.additionalInfo}
                              </div>
                            )}
                          </Td>
                          <Td
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {getSizeFromSku(p.product.sku)}{" "}
                            {p.ringSize && ` - ${p.ringSize}`}
                          </Td>
                          <Td
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            <RenderMaterials locale="tr" sku={p.product.sku!} />
                          </Td>
                          <Td
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {formatMoney(
                              p.price.price / 1.2,
                              p.price.currency.code
                            )}
                          </Td>
                          <Td
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {formatMoney(
                              p.price.price - p.price.price / 1.2,
                              p.price.currency.code
                            )}
                          </Td>
                          <Td
                            display={{
                              base: "none",
                              md: "table-cell"
                            }}
                          >
                            {formatMoney(p.price.price, p.price.currency.code)}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Box>

            <SimpleGrid
              columns={{
                base: 1,
                md: 2
              }}
              gap={4}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={"semibold"}
                    color="blue.500"
                  >
                    Fatura Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th>T.C Kimlik</Th>
                        <Td>{data.order.identityNumber}</Td>
                      </Tr>
                      <Tr>
                        <Th>İsim Soyisim</Th>
                        <Td>
                          {data.order.paymentFirstName}{" "}
                          {data.order.paymentLastName}
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>E-Mail</Th>
                        <Td>{data.order.paymentEmail}</Td>
                      </Tr>
                      <Tr>
                        <Th>Telefon</Th>
                        <Td>{data.order.paymentPhoneNumber}</Td>
                      </Tr>

                      <Tr>
                        <Th>Adres</Th>
                        <Td>{data.order.paymentAddress}</Td>
                      </Tr>

                      <Tr>
                        <Th>Şehir</Th>
                        <Td>{data.order.paymentCity}</Td>
                      </Tr>
                      <Tr>
                        <Th>Ülke</Th>
                        <Td>{data.order.paymentCountry}</Td>
                      </Tr>
                      <Tr>
                        <Th>Firma Ünvanı</Th>
                        <Td>{data.order.paymentCompany}</Td>
                      </Tr>
                      <Tr>
                        <Th>Vergi Dairesi</Th>
                        <Td>{data.order.paymentTaxOffice}</Td>
                      </Tr>
                      <Tr>
                        <Th>Vergi Numarası</Th>
                        <Td>{data.order.paymentTaxNumber}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>

              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={"semibold"}
                    color="blue.500"
                  >
                    Kargo Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th>İsim Soyisim</Th>
                        <Td>
                          {data.order.shippingFirstName}{" "}
                          {data.order.shippingLastName}
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Adres</Th>
                        <Td>
                          {data.order.shippingAddress}{" "}
                          {data.order.shippingPostalCode}
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Şehir</Th>
                        <Td>{data.order.shippingCity}</Td>
                      </Tr>
                      <Tr>
                        <Th>Ülke</Th>
                        <Td>{data.order.shippingCountry}</Td>
                      </Tr>

                      <Tr>
                        <Th>Kargo Firması</Th>
                        <Td>{data.order.shippingMethod}</Td>
                      </Tr>

                      <Tr>
                        <Th>Kargo Durumu</Th>
                        <Td>{data.order.shippingStatus}</Td>
                      </Tr>

                      <Tr>
                        <Th>Kargo Ücreti</Th>
                        <Td>
                          {data.order.shippingPrice &&
                          data.order.shippingPrice.price
                            ? formatMoney(
                                data.order.shippingPrice.price,
                                data.order.shippingPrice.currency.code
                              )
                            : "-"}
                        </Td>
                      </Tr>
                      {data.order.orderStatus === "SUCCESS" &&
                        data.order.shippingStatus === "PENDING" &&
                        !data.order.isCompleted && (
                          <Tr>
                            <Th>Kargo Bilgisi</Th>
                            <Td>kargo</Td>
                          </Tr>
                        )}

                      <Tr>
                        <Th>Kargo Numarası</Th>
                        <Td>
                          <div className="flex items-center justify-between space-x-2">
                            <div>
                              {data.order.shippingNumber
                                ? data.order.shippingNumber
                                : "???"}
                            </div>
                            teslim
                          </div>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
          </Box>
        </Box>
      );
    }
    return <Box>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Box>;
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Siparişler" />
        <Box>{renderOrder()}</Box>
      </Container>
    </Layout>
  );
};

export default Dashboard;
