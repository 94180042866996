import { useEffect, useState } from "react";
import { Layout } from "./../Layout";
import { useParams, Link } from "react-router-dom";
import { useSearchQuery } from "../../utils/useQuery";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import axios from "axios";
import "dayjs/locale/tr";

import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Select,
  Spinner,
  FormControl
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";

dayjs.locale("tr");

interface StockItem {
  _sum: {
    amount: number;
  };
  productGroup: string;
}
const Dashboard = () => {
  const params = useParams();
  const query = useSearchQuery();

  const [storeId, setStoreId] = useState<string | null | undefined>(
    query.get("storeId")
  );
  const { isLoading: storesLoading, data: storesData } = useQuery(
    "query-get-stores",
    async () => {
      return await axios({
        method: "GET",
        url: `${process.env.REACT_APP_APIURL}external/stores?key=${process.env.REACT_APP_EXTERNALKEY}`
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  const { isLoading, data, refetch } = useQuery(
    "query-get-stocks-by-category",
    async () => {
      const url = `${process.env.REACT_APP_STOCKURL}/stock/byCategory?storeId=${storeId}&categoryId=${params.productGroupId}`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  useEffect(() => {
    refetch();
  }, [storeId, refetch]);
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading
          title={`${
            params.productGroupId ? params.productGroupId : ""
          } Stoklar`}
        />
        <Box mb={4}>
          <FormControl mb={2}>Mağaza Seçimi</FormControl>
          {storesLoading ? (
            <Spinner />
          ) : (
            <Select
              onChange={(e) => {
                setStoreId(e.target.value);
              }}
              value={storeId ? storeId : ""}
              name="storeSelect"
              colorScheme={"whiteAlpha"}
            >
              <option value={""}>Tüm Mağazalar</option>
              {storesData?.data.map((store: { id: string; title: string }) => {
                return (
                  <option key={store.id} value={store.id}>
                    {store.title}
                  </option>
                );
              })}
            </Select>
          )}
        </Box>
        <Box overflowX={"auto"}>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Ürün </Th>
                <Th>Kategori </Th>
                <Th textAlign={"right"}>Adet</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={3}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : (
                data?.data.data.stockData.map((item: StockItem, i: number) => {
                  return (
                    <Tr key={i}>
                      <Td width="50px">{i + 1}</Td>
                      <Td>
                        <Link
                          to={`/stok/${params.productGroupId}/${item.productGroup}?storeId=${storeId}`}
                        >
                          {" "}
                          <Box textDecor={"underline"} color="blue.500">
                            {" "}
                            {item.productGroup}
                          </Box>
                        </Link>
                      </Td>
                      <Td>
                        <Link to={`/stok?storeId=${storeId}`}>
                          {" "}
                          <Box textDecor={"underline"} color="blue.500">
                            {" "}
                            {params.productGroupId}
                          </Box>
                        </Link>
                      </Td>
                      <Td width="100px" textAlign={"right"}>
                        {item._sum.amount}
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default Dashboard;
