import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { useRemoveStockFromStoreMutation } from "../../generated/graphql";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Spinner,
  useDisclosure,
  useToast,
  Flex
} from "@chakra-ui/react";
import dayjs from "dayjs";
import formatMoney from "../../utils/formatMoney";

interface Props {
  sku: string;
  storeId: string;
  refetch: any;
  sum?: number;
}
const StoreProductDetail: React.FC<Props> = ({
  sku,
  storeId,
  refetch,
  sum = 0
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [removeStockFromStore, { loading: removeStockFromStoreLoading }] =
    useRemoveStockFromStoreMutation();
  const toast = useToast();

  const { isLoading, data } = useQuery(
    "query-get-stocks-by-detail",
    async () => {
      const url = `${process.env.REACT_APP_STOCKURL}/stock/storeProductsDetail?storeId=${storeId}&sku=${sku}`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: isEnabled,
      retry: 1
    }
  );

  const handleMakeZero = async () => {
    try {
      const result = await axios({
        method: "POST",
        url: "https://juju-stok-manager.cr.interaktifis.cloud/stock/makezero",
        data: {
          sku,
          storeId
        }
      });

      toast({
        status: "success",
        description: "Ürün stoktan düşüldü"
      });
      setId("");
      setIsEnabled(false);
      onClose();
      return refetch();
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleRemoveProduct = async () => {
    if (!id) {
      return toast({
        status: "error",
        description: "Stoktan düşülecek ürünün seçilmesi gereklidir."
      });
    }
    try {
      await removeStockFromStore({
        variables: {
          removeStockFromStoreId: id
        }
      });
      toast({
        status: "success",
        description: "Ürün stoktan düşüldü"
      });
      setId("");
      setIsEnabled(false);
      onClose();
      return refetch();
    } catch (error) {
      return toast({
        status: "error",
        description: "Bir hata oluştu."
      });
    }
  };
  return (
    <Box>
      <Flex experimental_spaceX={4}>
        <Button
          size="xs"
          colorScheme={"green"}
          onClick={() => {
            setIsEnabled(true);
            return onOpen();
          }}
        >
          Stoktan Düş
        </Button>

        {sum < 0 && (
          <Button
            size="xs"
            colorScheme={"red"}
            onClick={() => handleMakeZero()}
          >
            Sıfırla
          </Button>
        )}
      </Flex>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{sku}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table>
              <Thead>
                <Tr>
                  <Th>Eklenme Tarihi</Th>
                  <Th>Fiyat</Th>
                  <Th>Seçim</Th>
                </Tr>
              </Thead>
              <Tbody>
                {isEnabled && isLoading ? (
                  <Tr>
                    <Td colSpan={3}>
                      <Spinner />
                    </Td>
                  </Tr>
                ) : (
                  data &&
                  data?.data?.data?.stockData.map((s: any) => {
                    return (
                      <Tr
                        background={s.id === id ? "green.200" : "transparent"}
                        key={s.id}
                      >
                        <Td>
                          {dayjs(s.createdAt).format("DD MMMM YYYY HH:mm")}
                        </Td>
                        <Td>{formatMoney(s.price, s.currency)}</Td>
                        <Td>
                          <Button width="full" onClick={() => setId(s.id)}>
                            Seç
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Vazgeç
            </Button>
            <Button
              isDisabled={removeStockFromStoreLoading}
              isLoading={removeStockFromStoreLoading}
              variant="ghost"
              onClick={handleRemoveProduct}
            >
              Stoktan Düş
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default StoreProductDetail;
