export const data = {
  "data": {
    "productGroups": [
      {
        "sku": "200",
        "description": [
          {
            "id": "ckl46icyi0yz609192g4po861",
            "title": "Dense Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54drwkjiq0i084044t0qq9p",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54drwkniq0k0840wl6lxg6p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54drwkpiq0l0840jkjfuqwr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54drwkziq0p0840mk6potn5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54drwl4iq0r08406n72kcqu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54drwl8iq0s08406u5a8tfz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54drwlniq0w08408h06dwjy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54drwlqiq0y0840cz7c4j0a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54drwltiq0z0840k67fce2r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2yijfjqdpt7084075wipyyg",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2yijt82dpv90840npnjdvke",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl46icy80yz50919hrz28hmw",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315261418-ccb-200-1.jpg",
          "1613315261433-ccb-200-4.jpg",
          "1613315261443-ccb-200-6.jpg",
          "1613315261437-ccb-200-5.jpg"
        ]
      },
      {
        "sku": "275",
        "description": [
          {
            "id": "ckl48uwwa13wf091962xrujjs",
            "title": "Pipeline Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54a4josia6b0840aotfdw36",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54a4joyia6d0840qisbbptj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54a4jp1ia6e0840qmchhn6z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54a4jpeia6i08405reyrfj1",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54a4jpgia6k08407ekzlfff",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54a4jpiia6l084077ywj1p6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54a4jpsia6p0840va7wc5g8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54a4jpvia6r0840ksost4wi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54a4jpxia6s08404c9hqwem",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl48vixo13xb0919uzajkpzm",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckl490lz814210919xvnvrzku",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl48uww513we0919u4ugpfbq",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315700949-ccb-275-1.jpg",
          "1613315700954-ccb-275-2.jpg",
          "1645022689824-275-grey.png"
        ]
      },
      {
        "sku": "276",
        "description": [
          {
            "id": "ckl48yyy513zy0919uv5wlm9q",
            "title": "Yürüyen Merdiven Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5492txri5gi08409ww2mlv7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5492txvi5gk0840hdvwsnqk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5492txyi5gl0840z0887dja",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5492ty6i5gp08400pzab8gf",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5492ty9i5gr084088i61p8d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5492tyai5gs0840lj0is7d3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5492tzji5gw0840epz0gn5u",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5492tzli5gy084083l3l000",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5492tzni5gz0840jafn2g7s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4900d61410091984gzzor0",
            "price": 105,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckl490ull142d0919gb7k8nrx",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl48yyy013zx0919eplyqzjg",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315711662-ccb-276-1.jpg",
          "1613315711674-ccb-276-2.jpg"
        ]
      },
      {
        "sku": "280",
        "description": [
          {
            "id": "ckl4923at143k0919ryan4ung",
            "title": "Merdiven Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1knmgy39o5y0859ogmwo84q",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1knmgy59o600859ki38rhy4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1knmgy79o610859c0z67hlv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1knmgyd9o650859bs8750pe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1knmgye9o670859rhmk6ju1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1knmgyg9o680859v4woa0p5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1knmgyl9o6c0859i0kgl8ky",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1knmgyn9o6e0859omt2xffj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1knmgyo9o6f0859lzvsvcyl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4934qz14510919kbdhkayp",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckl495qu6146n09191d1tqagr",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4923al143j09195d6coqhx",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315726022-ccb-280-1.jpg",
          "1613315726029-ccb-280-2.jpg",
          "1613315726067-ccb-280-3.jpg",
          "1613315726059-ccb-280-5.jpg"
        ]
      },
      {
        "sku": "281",
        "description": [
          {
            "id": "ckl499idh148f09192c4yaxgv",
            "title": "Disk Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54eucjkiyqj08404yo34n3g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54eucjsiyql0840i03sggc1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eucjviyqm08406x5ejcs5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54euck7iyqq08407qff35er",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54euckaiyqs0840ijzbphj3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54euckciyqt0840b791xuyi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54euckkiyqx084023f7hg85",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54euckmiyqz0840hxtybtt4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54euckoiyr00840ft28ha7p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl49a83y149i091919dr2aw4",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckl49b1to149z0919kahywj9l",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl499id7148e0919s193c910",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315750311-ccb-281-1.jpg",
          "1613315750356-ccb-281-5.jpg"
        ]
      },
      {
        "sku": "290",
        "description": [
          {
            "id": "ckl49d9q214b30919ai5ny6zo",
            "title": "Sütun Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6g8n4vyinec0840klt2y48r",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl6g8n4w2inee0840n2tt0awq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6g8n4w4inef08405ec9e7kn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6g8n4wdinej08407ubou2j5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl6g8n4wginel08404dbb256c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6g8n4whinem0840a6bmqkej",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6g8n4wpineq0840atue3ij4",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl6g8n4wrines0840dz8tq1l9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6g8n4wsinet0840xtv1giav",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl49e52814ct09195maa8v67",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckl49eioq14da0919rjcqdlxy",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl49d9pu14b20919vyn9bsaz",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315769857-ccb-290-1.jpg",
          "1613315769851-ccb-290-2.jpg",
          "1613315769861-ccb-290-3.jpg",
          "1613315769865-ccb-290-5.jpg"
        ]
      },
      {
        "sku": "291",
        "description": [
          {
            "id": "ckl49h4e814g509192n51jz7v",
            "title": "Stone Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54ex3qyizfa0840mvogd9z5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54ex3r2izfc08405vrzme9n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ex3r5izfd0840b0w3hyjj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ex3rgizfh0840tiro5cva",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54ex3rkizfj0840s1t49ek9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ex3rnizfk0840ojq0byis",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ex3s0izfo0840nkw6em1s",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54ex3s2izfq08404cp1c9do",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ex3s5izfr0840zjun7mtj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl49i21814hv09193irn4p6f",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5cfwfe2ubj0897rdl2oeuc",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl49h4dx14g40919u9tb5oh9",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315807619-ccb-291-1.jpg",
          "1613315807624-ccb-291-2.jpg",
          "1613315807635-ccb-291-4.jpg",
          "1613315807643-ccb-291-5.jpg"
        ]
      },
      {
        "sku": "300",
        "description": [
          {
            "id": "ckl49vnhm14kk0919wtlnzokn",
            "title": "Ok Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wdm5786n5j0840smzr6s9s",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wdm57d6n5l0840hzr9mtv5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdm57g6n5m084035gk8btb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdm57v6n5q0840b4ylpm49",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5wdm57y6n5s0840d4x1r8id",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdm5816n5t0840x8diitln",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdm58b6n5x0840zv807rky",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5wdm58d6n5z0840244al2cr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdm58e6n600840ria3kbt1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl49w3l414ld0919513cx0f2",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckleg4dsh07at08974jsymd47",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl49vnhc14kj0919gh255046",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315836711-ccb-300-3.jpg",
          "1613315836694-ccb-300-2.jpg",
          "1613315836728-ccb-300-8.jpg"
        ]
      },
      {
        "sku": "301",
        "description": [
          {
            "id": "ckl49xung14m30919l6ilf05v",
            "title": "Piano Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1knxy3q9r020859r3tiexcr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1knxy3s9r040859vqqjc6sg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1knxy3u9r050859050oxd5w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1knxy409r090859okeey2fy",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1knxy419r0b085929bsf1dw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1knxy429r0c0859jh2j2e18",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1knxy479r0g085914buiem3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1knxy489r0i08591u4woj4l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1knxy4a9r0j0859reaam29v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl49ycko14mx0919ego5o41m",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5aygws2u4j0897xe915sq2",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl49xun614m2091997ynd7sh",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315872608-ccb-301-1.jpg",
          "1613315872601-ccb-301-2.jpg"
        ]
      },
      {
        "sku": "303",
        "description": [
          {
            "id": "ckl4a08fz14nu0919p9jwe8lr",
            "title": "Eksen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54ds8aeiq7s08404rba4gdk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54ds8aiiq7u0840e0suuo8c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ds8aliq7v0840zhdktpyz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ds8aziq7z0840tssq1xun",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54ds8b2iq8108402odlsuyk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ds8b5iq820840dl7aoiap",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ds8bfiq860840dyq4ra1n",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54ds8bhiq880840ip2ltil9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ds8bjiq890840kl9j4px3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4a0wxj14op091980yv4ine",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5coa572udk08976eui2kov",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4a08fp14nt0919ecken3zr",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315887897-ccb-303-1.jpg",
          "1613315887948-ccb-303-4.jpg"
        ]
      },
      {
        "sku": "304",
        "description": [
          {
            "id": "ckl4a306b14pm0919e3s0u8l5",
            "title": "Kare Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39x7a6kgybi0840bdxa6j9c",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39x7a6tgybm0840m1upyumu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x7a6vgybn0840i2drk76s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39x7a76gybr08404loz74k0",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39x7a79gybt08406j7atsak",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x7a7bgybu08407gkgrg22",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39x7a7igyby0840s0j512vt",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39x7a7kgyc00840mi0hsrfi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x7a7mgyc10840qnze79ie",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4a3k3t14qi09199j1jn7z8",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5b36132u59089714so2ukz",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4a306714pl0919yfk66pqf",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315903058-ccb-304-1.jpg",
          "1613315903053-ccb-304-2.jpg"
        ]
      },
      {
        "sku": "305",
        "description": [
          {
            "id": "ckl4a5qd714rg0919wsn4po5g",
            "title": "Üçgen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6g8nriqinja0840hkh9juhw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl6g8nrivinjc0840quhs17wd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6g8nriyinjd08400luz39dd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6g8nrj7injh084001944g0i",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl6g8nrj9injj0840li7xnerk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6g8nrjbinjk0840dyj33eet",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6g8nrjninjo08401lcro4m6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl6g8nrjqinjq0840eb9arydd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6g8nrjsinjr0840r4iz4j6t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4a6dfv14sd0919l8z815u6",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5b3lti2u5j0897vzeb2grm",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4a5qcx14rf0919qtup5lvt",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315979110-ccb-305-1.jpg",
          "1613315979334-ccb-305-2.jpg",
          "1613315979200-ccb-305-5.jpg",
          "1613315979260-ccb-305-8.jpg",
          "1613315979253-ccb-305-6.jpg",
          "1613315979258-ccb-305-7.jpg"
        ]
      },
      {
        "sku": "306",
        "description": [
          {
            "id": "ckl4a9ul314to0919i5axv6lm",
            "title": "İnce Künye Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl549vr9ri8gt084041t0oxx7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl549vr9ui8gv08408fc8831b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549vr9vi8gw08400w0svg3b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl549vra2i8h008400nut85cr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl549vra4i8h208404mfxnmsz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549vra6i8h30840o4zrjw0p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl549vradi8h70840bj8r88no",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl549vrafi8h908407hz3vt93",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549vrahi8ha0840fje7uizo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4abgqr14up0919mh2gacok",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5b3wb72u5z0897ec0pjw7o",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4a9ukw14tn091982575l5n",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613315999509-ccb-306-1.jpg"
        ]
      },
      {
        "sku": "307",
        "description": [
          {
            "id": "ckl4adgjy14v90919i74n5kkt",
            "title": "Top Top Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1kooacv9w6w0859vx2qepof",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1kooacx9w6y08594vslkd4m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kooacz9w6z0859kq5uheoi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kooad69w730859e99hklq5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1kooad89w75085929xa1s1u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kooada9w760859rslgju7u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kooadg9w7a0859c6fasqg6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1kooadi9w7c0859ap98yn4i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kooadk9w7d0859n4ogufnp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ae07i14w80919jrc827rm",
            "price": 23,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5b4o3u2u6a0897agly7rb6",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4adgju14v8091961stbf05",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613316136559-ccb307.jpg",
          "1613316136565-ccb-307-2.jpg"
        ]
      },
      {
        "sku": "313",
        "description": [
          {
            "id": "ckl4afwl814wr0919y65tabkc",
            "title": "Yalın Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya7k7uwxozi0859hmk4y9wz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya7k7uyxozk0859o7iq7ojs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7k7v0xozl08592606tqa5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7k7v5xozp08598t3p8teu",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya7k7v7xozr08591ofk48n7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7k7v8xozs08592qyt4y2i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7k7vexozw0859bn2nejxk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya7k7vfxozy08596ez1hacq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7k7vgxozz0859x1vd774w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm5b4yan2u6k08974i47657q",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmhrk87i4v36089727dfocq7",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4afwkz14wq0919wd1gzoes",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619183399525-ccb-313-1.jpg",
          "1613316152297-ccb-313-2.jpg"
        ]
      },
      {
        "sku": "347",
        "description": [
          {
            "id": "ckl4aiq4614yj09190q1xoins",
            "title": "Mini Çiçekli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6kepz91v3g90840f6godjj6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl6kepz98v3gb084033f53wfe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kepz9bv3gc0840i1us8qep",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6kepz9ov3gg0840hlf4o0ze",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl6kepz9rv3gi08408a2a1pqs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kepz9tv3gj084095wkrbur",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6kepza2v3gn08408c3xgc97",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl6kepza4v3gp0840cfnao4j8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kepza5v3gq0840nn9am57p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4aja6114zm0919w2fckxxe",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5b57bz2u6u0897hc3jshqq",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4aiq4014yi0919urp08wq5",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619183604173-ccb-347-L.jpg",
          "1613316176265-ccb-347-2.jpg"
        ]
      },
      {
        "sku": "349",
        "description": [
          {
            "id": "ckl4akxy9150m0919n8kdgzld",
            "title": "Mini Kare Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56tnsyqnlr20840v6mfercs",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56tnsytnlr40840jxdrwz4y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tnsyvnlr50840ubvdsi6x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tnsz3nlr90840p2n7p070",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56tnsz4nlrb0840bti0c5mo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tnsz5nlrc0840ppqvgkem",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tnszanlrg0840u6o7z0vx",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56tnszcnlri084086cct58g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tnszdnlrj084096d1z8h2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4alokf152i0919846jgl4t",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5b5m6z2u740897kia5ftvx",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4akxy0150l0919po1t0gdf",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1656922937938-CCB-349.jpg",
          "1656922959028-CCB-349-1.jpg",
          "1619184049065-ccb-349-L.jpg",
          "1613316201010-ccb-349-2.jpg",
          "1613316201003-ccb-349-3.jpg",
          "1613316201029-ccb-349-4.jpg"
        ]
      },
      {
        "sku": "350",
        "description": [
          {
            "id": "ckl4an2ir15380919fy5l5fgo",
            "title": "Pul Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56x0w1dnvsn0840ga885z74",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56x0w1hnvsp0840aylrs8to",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56x0w1invsq0840qiz3dlpb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56x0w1pnvsu08408exjq6rs",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56x0w1qnvsw08407vd5sy08",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56x0w1rnvsx0840241pppz9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56x0w1wnvt10840qafmt2dm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56x0w1ynvt30840wg9un5ov",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56x0w1znvt40840sg4y2h7n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl8vpc9tt8afx0840cfjpz26q",
            "price": 22,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8vpchk08agi0840dkeurhwd",
            "price": 62,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4an2ij153709198wffzre2",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317081755-ccb-350-1.jpg",
          "1613317081795-ccb-350-2.jpg"
        ]
      },
      {
        "sku": "351",
        "description": [
          {
            "id": "ckl4aosqz15510919kdb9scxf",
            "title": "Elips Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ktpkplaz360859nss1ixls",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1ktpkpnaz380859mwndq6a5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktpkpoaz390859b8ru2g4z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ktpkpuaz3d0859pyiwq5iu",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1ktpkpvaz3f0859dusnxqsb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktpkpwaz3g08594ni9r4u4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ktpkq2az3k0859lmwwcx4i",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1ktpkq3az3m0859o8qekae7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktpkq4az3n0859n2pnsnhj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4apd5415650919q9xhg95l",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5b63t82u7p0897uby29sez",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4aosqt15500919j98hgfy1",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317099147-ccb-351-1.jpg",
          "1613317099180-ccb-351-2.jpg"
        ]
      },
      {
        "sku": "352",
        "description": [
          {
            "id": "ckl4aq8al156o0919h86eu3jw",
            "title": "Ters Üçgen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1kr6vi5agp7085953iwm9z6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1kr6vi8agp90859uo304t45",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kr6viaagpa0859p8cslt80",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kr6vigagpe0859ovyj070g",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1kr6viiagpg0859jltslhv6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kr6vijagph08595alhpl2u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kr6vipagpl0859nc4bs1v2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1kr6viragpn0859bk2c1uoe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kr6visagpo0859r48l3v6p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4aro9k15830919n3o22m0m",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5b6fge2u7z08978hjgnfl3",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4aq8ag156n0919xybykj2z",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1647231915341-IMG_3037.png",
          "1613317119466-ccb-352-4.jpg",
          "1645022707212-352-grey.png"
        ]
      },
      {
        "sku": "376",
        "description": [
          {
            "id": "ckl4at82p158o09192kt5gcm5",
            "title": "Brick Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabw3cremto0859njx2jv11",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabw3csemtq0859h5svml6y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabw3ctemtr0859rqbzos9r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabw3czemtv085933tufbbh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabw3d0emtx0859fboim231",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabw3d1emty0859jo4b90o8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabw3d6emu20859kh68qync",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabw3d8emu40859w1v4heo3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabw3d9emu50859xy9w79mt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4atrlm159w091915c51cbh",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5d4m3n2ulz08973wfej1j4",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4at82i158n0919q3vp6yp4",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317141530-ccb-376-.jpg",
          "1613317141972-ccb-376-2.jpg"
        ]
      },
      {
        "sku": "377",
        "description": [
          {
            "id": "ckl4avk3715bu0919v5oid0nf",
            "title": "Mini Daire Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1m3px1cg9jp0859uy3elrd8",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1m3px1eg9jr08593p8rlufj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m3px1gg9js08596y77aa9z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m3px1lg9jw0859wajafdoe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1m3px1mg9jy0859k8zw49r4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m3px1ng9jz0859w8s91p8w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m3px1sg9k30859jzdc4dev",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1m3px1tg9k50859t2utttej",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m3px1ug9k60859ajnjd5c6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4aw3pd15d30919navyz4ds",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5d58v02umj0897nrz6krrd",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4avk3315bt0919zz8maumg",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619184299762-ccb-377-L.jpg",
          "1613317161522-ccb-377-2.jpg"
        ]
      },
      {
        "sku": "381",
        "description": [
          {
            "id": "ckl4axmyg15dm091953rcr60b",
            "title": "Groovy Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1krf8dwaipp0859oj03eb9y",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1krf8dxaipr085919ojgave",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1krf8dzaips0859ltdmc56g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1krf8e3aipw0859ppv0okhs",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1krf8e5aipy0859200f0gg2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1krf8e6aipz0859pj6n4iab",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1krf8ebaiq30859f2qd1nzy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1krf8edaiq50859z4cdbnsi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1krf8eeaiq60859nfo4iuw4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ayafj15fi0919w4rkj8ia",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5d5unq2un308971cmgoydx",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4axmy615dl0919j4x67j9s",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317592068-ccb-381-3.jpg",
          "1633290854422-5.png",
          "1613317592092-ccb-381-4.jpg"
        ]
      },
      {
        "sku": "382",
        "description": [
          {
            "id": "ckl4b11pp15g10919nlhy6i24",
            "title": "Chic Attack Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56h2hk5mg840840ykas3rcm",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56h2hk9mg860840725vlm0c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56h2hkcmg870840adymigpk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56h2hkpmg8b0840s38nzbrj",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56h2hksmg8d0840k8phm3zt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56h2hkumg8e0840wlhwgy9h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56h2hl3mg8i0840gbahcx4f",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56h2hl5mg8k0840o8i6hoh1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56h2hl6mg8l0840r2veozmh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4b1jm715hz0919ylonk7uu",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5d7d0u2unn0897skket94i",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4b11pe15g00919w3no07dr",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1656922818631-CCB-382.jpg",
          "1613317624159-ccb-382-1.jpg",
          "1633290873698-6.png",
          "1613317624167-ccb-382-4.jpg",
          "1613317624153-ccb-382-2.jpg"
        ]
      },
      {
        "sku": "383",
        "description": [
          {
            "id": "ckl4b2dox15ii0919icdgmp5c",
            "title": "Sarmaşık Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl547wm78i0dc084003cawon2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl547wm7di0de0840me1776wa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl547wm7fi0df084035gri65h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl547wm7pi0dj0840qrmo5xa9",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl547wm7ui0dl0840izk62s9l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl547wm7yi0dm0840ze6rj2ss",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl547wm8ei0dq0840oiutcobw",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl547wm8ii0ds0840qrcbw1pq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl547wm8li0dt0840lche34oj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4b30eo15jv09194snfnwwl",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5dtd2i2upm089751k0yxlo",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4b2dot15ih0919ht3o4ti7",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317694562-ccb-383-1.jpg",
          "1613317694606-ccb-383-2.jpg"
        ]
      },
      {
        "sku": "384",
        "description": [
          {
            "id": "ckl4b416h15l409198o3vjfe4",
            "title": "Labut Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya7kc9rxpb70859ubik5get",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya7kc9txpb90859tf85zh1v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7kc9uxpba0859g2ednm6c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7kc9zxpbe0859lrqhzbgd",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya7kca1xpbg0859swmpcc7i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7kca2xpbh08599m5diuu2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7kca7xpbl0859pbc1kdwj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya7kca8xpbn0859nm7adfbu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7kca9xpbo0859pxnssjyq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4b4t8a15mh091992tcry5g",
            "price": 105,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5ektyj2uut0897t7p0l1mq",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4b416815l30919zubwujff",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317709030-ccb-384-1.jpg",
          "1613317709039-ccb-384-2.jpg",
          "1613317709049-ccb-384-3.jpg"
        ]
      },
      {
        "sku": "385",
        "description": [
          {
            "id": "ckl4b7vvd15n10919qhraa3r2",
            "title": "Mini Disk Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1kmi8jl9fue0859rp61glyb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1kmi8jo9fug085917uyupmu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kmi8jp9fuh08597gwm12aj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kmi8jw9ful0859vsvr3bbx",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1kmi8jx9fun08596wmwfunr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kmi8jz9fuo0859zhgsqkjx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kmi8k49fus08593h7ur1zb",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1kmi8k69fuu0859byneqtxx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kmi8k79fuv08594ccvw4cu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4b8dhg15of0919e2cdboit",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5el21a2uv30897cc6tks2i",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4b7vv115n00919o4x7i8go",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646733129977-2.png"
        ]
      },
      {
        "sku": "386",
        "description": [
          {
            "id": "ckl4b9m2v15oz09198rr5vx6m",
            "title": "Yaprak Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56ttt71nmhi08408movlaq4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56ttt74nmhk0840ubbp2oae",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56ttt75nmhl084051vq0frx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56ttt7dnmhp0840f38f2i2d",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56ttt7fnmhr08400l639u1j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56ttt7gnmhs0840r9rg46x7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56ttt7mnmhw0840knta8b04",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56ttt7nnmhy0840ykrlef6d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56ttt7onmhz0840lub0gwhm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4bairu15qe0919opkpf68c",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5elckc2uvd0897eynti755",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4b9m2n15oy09191iw10xce",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1656922620562-CCB-386.jpg",
          "1613317743087-ccb-386-1.jpg"
        ]
      },
      {
        "sku": "387",
        "description": [
          {
            "id": "ckl4bc2bs15qx09199o4avoee",
            "title": "Zincir Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabwfxfenr90859wke3z0q4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabwfxhenrb0859hk4vbtgt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwfxjenrc0859ju6ot6p8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabwfxpenrg08596ib2p71q",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabwfxrenri0859j2ubilgx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwfxsenrj0859t9an0j3k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabwfxxenrn0859cyrgog3c",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabwfxzenrp0859om8u4b1l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwfy0enrq0859ztnbwhda",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4bd62x15sk09196jbcmkqr",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5elw9i2uwa0897ywa7aymc",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4bc2bm15qw0919avzoljy8",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317768096-ccb-387-1.jpg"
        ]
      },
      {
        "sku": "389",
        "description": [
          {
            "id": "ckl4be1ip15tb0919rtunppgv",
            "title": "Yan Künye Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ku3awjb21z08598hl72hhc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1ku3awlb2210859mxezwj8p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ku3awnb2220859io350ju4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ku3awsb2260859ck4dk4tc",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1ku3awub2280859mwzv5632",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ku3awvb22908597vmjih8v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ku3ax0b22d0859jjgh8y48",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1ku3ax1b22f0859bit1vbna",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ku3ax2b22g0859pdkw9de5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4bej7d15uu0919xrx5l5lq",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5en58h2uwk0897dq9ojjjo",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4be1ij15ta0919ir82g9h0",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317831205-ccb-389-1.jpg"
        ]
      },
      {
        "sku": "421",
        "description": [
          {
            "id": "ckl4bmuh315vt0919pr8afbnz",
            "title": "Dürbün Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xca21h0x40840gxde6ver",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39xca29h0x608405db7x0xu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xca2ch0x708409neibfl0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xca2ph0xb0840ofvejs1f",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xca2th0xd0840wfn5h4v5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xca2wh0xe0840kx5mxx0f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xca38h0xi0840axdd7kks",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xca3ch0xk0840wpklgygn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xca3eh0xl0840jthgmaid",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlnfedvsvms0959t89wev3c",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlnfq2esvnh0959b75etqag",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4bmugv15vs0919a2chtcla",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317847195-ccb-421-1.jpg",
          "1613317847227-ccb-421-2.jpg"
        ]
      },
      {
        "sku": "457",
        "description": [
          {
            "id": "ckl4boo0n15xu09194arya7a4",
            "title": "Çapraz Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyhy6qi13eo0859lql2nte0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyhy6ql13eq0859ibdito72",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyhy6qn13er0859sk5g6xyu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyhy6qu13eu0859ex9qre92",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyhy6qx13ew08592ulsu7q9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyhy6qy13ex08594bri6fmg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyhy6r413f00859wxxitbmh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyhy6r713f20859w4rni99i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyhy6ra13f30859534lpld0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4bp6c715zd0919h6lml5jc",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5eo9s22ux40897mzqmjdhb",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4boo0015xt0919hywtvd2y",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317862564-ccb-457-1.jpg"
        ]
      },
      {
        "sku": "460",
        "description": [
          {
            "id": "ckl4bq60a15zw09197baqw6hg",
            "title": "Diken Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabwknteo2b0859cmm2zgzj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabwknweo2d0859hsipjvzx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwknxeo2e0859q25em3ev",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabwko3eo2i08594y9benm6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabwko5eo2k0859zpp8nq1m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwko6eo2l0859pgdj2z5n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabwkoceo2p0859986j5fmt",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabwkoeeo2r0859h6zaptnj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwkofeo2s0859b4wwf2ug",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4bqna6161g0919t0d2iwsi",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5eov4j2uxe0897zs4la6ap",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4bq60315zv09190tnd77h1",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317888539-ccb-460-1.jpg",
          "1613317888571-ccb-460-2.jpg"
        ]
      },
      {
        "sku": "469",
        "description": [
          {
            "id": "ckl4brmie161z0919535obocg",
            "title": "Su yolu Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6kmdx61vxc908404w1fj6by",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl6kmdx66vxcb0840f3iy0se5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kmdx69vxcc0840muvmtloh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6kmdx6mvxcg084079h94r4a",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl6kmdx6qvxci0840hfsfvxcz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kmdx6tvxcj0840ql5az5kx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6kmdx76vxcn0840v94yg1nc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl6kmdx7avxcp0840y93uxafm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kmdx7evxcq0840iwxo1zir",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4bs0or163k0919dbgdtjum",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckybf06zu0f9208594evwcr7j",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4brmi7161y0919fxfl0rih",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317932818-ccb-469-1.jpg",
          "1613317932853-ccb-469-2.jpg"
        ]
      },
      {
        "sku": "471",
        "description": [
          {
            "id": "ckl4bsuaa164f0919qp9m0tzk",
            "title": "Mix Zincir Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyi8t22169u0859yyu4vzr0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyi8t25169w085967ja9ukd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyi8t27169x0859it7z727x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyi8t2f16a00859xrofcmit",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyi8t2h16a20859h9f9uzzm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyi8t2j16a30859cy6d2aa5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyi8t2r16a60859ayj54wuf",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyi8t2t16a80859aadu78n2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyi8t2v16a908595ar5thhz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4btk9y16610919pzg7ifov",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5epd992uxy0897jqvl18jc",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4bsua6164e0919w63nm4jk",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317950369-ccb-471-1.jpg"
        ]
      },
      {
        "sku": "497",
        "description": [
          {
            "id": "ckl4buqsa167r0919ynsek9jn",
            "title": "Lego Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabwnroeoaf0859f0l8xatd",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabwnrqeoah0859urxzvus3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwnrseoai0859r9qgvum7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabwnrxeoam0859a3a6gkvi",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabwnrzeoao08595u8w40w0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwns0eoap0859zqwx2den",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabwns6eoat0859bv45ze5m",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabwns7eoav0859eo839w6b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwns9eoaw0859cfn9dolj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4bx24g169f0919exbfrmpn",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5eppwm2uy80897r6zt00ay",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4buqs1167q0919olxf1jq8",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317969860-ccb-497-1.jpg",
          "1613317969864-ccb-497-2.jpg"
        ]
      },
      {
        "sku": "500",
        "description": [
          {
            "id": "ckl4bxyma169y0919kzog34am",
            "title": "Bant Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyir1cx1a4l0859l6082daa",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyir1d01a4n0859anur7u2l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyir1d11a4o0859m81m4jxo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyir1d71a4r0859yf4e5v0m",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyir1d91a4t08599fgarrx4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyir1db1a4u0859h5my0kli",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyir1dh1a4x0859tu7debem",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyir1dk1a4z08597v5fwyap",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyir1dl1a500859kni1da3w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4bz1sq16bu0919azco3j5f",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5epy1c2uyi0897wcyrqndl",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4bxym2169x0919bs9rzu2u",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613317987754-ccb-500-1.jpg",
          "1613317987791-ccb-500-2.jpg"
        ]
      },
      {
        "sku": "504",
        "description": [
          {
            "id": "ckl4c0meo16cf0919bgquqidm",
            "title": "Köprü Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wdmj9p6n9f0840y87zeqaa",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wdmj9u6n9h0840tr0m2u64",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdmj9y6n9i0840kqozmv3p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdmjag6n9m08400dkm0raa",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5wdmjal6n9o0840yslfeisj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdmjao6n9p0840kxfpqmde",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdmjb26n9t084043yojujx",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5wdmjb56n9v0840tvw4hjwk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdmjb76n9w0840d1d3pqvr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4c10zj16e20919i1wgisix",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5eqowv2uys0897c61y8n4a",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4c0mei16ce0919nus18zd2",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318009540-ccb-504-1.jpg",
          "1613318009449-ccb-504-2.jpg",
          "1613318009543-ccb-504-3.jpg",
          "1613318009453-ccb-504-4.jpg"
        ]
      },
      {
        "sku": "509",
        "description": [
          {
            "id": "ckl4c1wds16el0919fgchufa5",
            "title": "Duvar Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabwqvceohy0859gp1e259b",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabwqveeoi008590lm3uiib",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwqvfeoi10859vl3vllpl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabwqvkeoi50859xmmvi4kk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabwqvmeoi70859udowv4ha",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwqvneoi80859lxmmt4j6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabwqvseoic08595ckh6iwa",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabwqvueoie0859m0m0f08u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabwqvveoif0859gfd8wvbr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4c29ah16gb0919f6ynoxo6",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5er3982uz20897jnla662x",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4c1wdm16ek0919jlxc6lhj",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318030624-ccb-509-1.jpg",
          "1633290889228-7.png"
        ]
      },
      {
        "sku": "511",
        "description": [
          {
            "id": "ckl4c3e0q16gu0919r3uzgz95",
            "title": "Kalp Atışı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ns04yxpwsr0859p1zkl4vp",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1ns04z0pwst085988j20fz1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ns04z2pwsu0859fnfo7apb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ns04z9pwsy085980zidbek",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1ns04zbpwt00859ido4nifa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ns04zcpwt10859fhjo72go",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ns04zipwt508593n0nenhs",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1ns04zkpwt70859eq0sw0ou",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ns04zlpwt808593cjfqxr9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4c414w16is09196ko041vd",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm5erf9g2uzc0897u3epnxxx",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4c3e0g16gt09192x6pahon",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318050847-ccb-511-1.jpg",
          "1613318050900-ccb-511-2.jpg"
        ]
      },
      {
        "sku": "941",
        "description": [
          {
            "id": "ckl4c50t216jc0919a7dh0mpu",
            "title": "İki Renkli Prizma Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac1zkdet840859wphvv4h4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac1zkfet860859jfuj0wvf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1zkget870859txaqk4vj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac1zklet8b0859nvwr85xo",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac1zknet8d0859z6lnwh38",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1zkoet8e0859wmdg7kl8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac1zkvet8i0859yulhh2hl",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac1zkwet8k0859xax7ikgd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1zkyet8l0859oi98fesi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4c5is016l709193iz6q1n8",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6itz6u2wnr0897y1rxnpcf",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4c50sx16jb0919ig9gei6k",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318077608-ccb-941-1.jpg",
          "1633291017715-9.png",
          "1613318077614-ccb-941-2.jpg"
        ]
      },
      {
        "sku": "515",
        "description": [
          {
            "id": "ckl4c6ds416ly0919dw5nz3lj",
            "title": "Kaplumbağa Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyjb7i31e500859n1d3dow1",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyjb7i71e520859o1isnvt5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyjb7ia1e530859m7yarr26",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyjb7ij1e560859s1pij520",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyjb7in1e5808598n3l040v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyjb7ip1e590859y53blyfc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyjb7ix1e5c0859vmglzddv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyjb7j01e5e085999t7b2ki",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyjb7j21e5f0859l76ow2li",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4c6v6d16ng0919gg9ry5uh",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6iuvvu2wo20897ybva37wa",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4c6drx16lx0919er6scyov",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318099254-ccb-515-1.jpg"
        ]
      },
      {
        "sku": "518",
        "description": [
          {
            "id": "ckl4c7mxt16nz09190bsen7jo",
            "title": "Papyon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl53zzbj9hgxg0840237zmpsb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl53zzbjehgxi0840v7jr4zzj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zzbjhhgxj0840d7toj1fy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl53zzbjqhgxn0840eoo68k7p",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl53zzbjshgxp0840y7aly30j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zzbjthgxq0840h2ad4an9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl53zzbk0hgxu0840bo2x7ats",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl53zzbk2hgxw0840xmhonke9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zzbk4hgxx0840t5yo5tt8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4c86mj16p90919ftup6y4k",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6iv40t2woc0897celr1x1w",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4c7mxj16ny0919t7kjy8jv",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318116621-ccb-518-1.jpg"
        ]
      },
      {
        "sku": "525",
        "description": [
          {
            "id": "ckl4c9k6616qj09196ks1v8dt",
            "title": "Pop Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xcziwh1660840cxl7i1zs",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39xczj2h1680840r8ry4h6e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xczj6h16908405lw3qx83",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xczjoh16d0840lao203j4",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xczjsh16f0840o953a56i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xczjvh16g0840v8ua721o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xczk6h16k0840swvraqy9",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xczkah16m0840c56yw3xq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xczkch16n0840e6d9e8wr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ca3ek16ru0919743gbgq1",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ivb852wom08979eo0dbew",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4c9k5z16qi0919xd8c1jbi",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318134935-ccb-525-1.jpg",
          "1613318135082-ccb-525-2.jpg"
        ]
      },
      {
        "sku": "574",
        "description": [
          {
            "id": "ckl4cc5n916sd0919cdwc1eiv",
            "title": " Superimposable Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6kmaafivwfa0840zvjyozaa",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl6kmaafmvwfc0840kz1pcgbq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kmaafpvwfd0840kzipwifu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6kmaafyvwfh08400mdnt7hp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl6kmaag0vwfj0840lait4bki",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kmaag1vwfk08406b8d7pwl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6kmaag9vwfo0840xbtf9jzx",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl6kmaagcvwfq084057q1vhtz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6kmaagdvwfr0840rzkorqjv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ccnmj16tp0919rwlgly1a",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckybfvzjh0kbk0859ysibzvf4",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4cc5mt16sc0919h6p1osi0",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318153265-ccb-574-1.jpg",
          "1613318153272-ccb-574-2.jpg"
        ]
      },
      {
        "sku": "575",
        "description": [
          {
            "id": "ckl4cdp0q16u909193o6rt7rm",
            "title": "Serial Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1g54yp5qtqv0859tjekmoa4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1g54yp7qtqx0859unz5bbt5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g54yp9qtqy0859g6qcm04g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g54ypfqtr208598dwmyz59",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1g54ypgqtr40859p4l8ecq4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g54yphqtr508593b36jmed",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g54ypnqtr90859joo6g4at",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1g54ypoqtrb0859ca7dgmed",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g54ypqqtrc0859rmirxtwo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4cg9uw16wm0919lvjlfz62",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ivwgr2wp60897dq2q2akb",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4cdp0j16u80919z5e8tewh",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318171674-ccb-575-1.jpg",
          "1613318171713-ccb-575-2.jpg"
        ]
      },
      {
        "sku": "582",
        "description": [
          {
            "id": "ckl4ch5yx16x509196c8hxvlp",
            "title": "Bomba Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54efg4yivdy0840119x8k2j",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54efg56ive008405ztxus30",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54efg5aive10840gy19yh6a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54efg5nive50840bsakztac",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54efg5qive70840btveoaaf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54efg5rive80840uy5go6cr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54efg60ivec0840bpn29bah",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54efg62ivee0840wr1ez3ox",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54efg64ivef0840wyfeb4pa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4chmit16yj0919j9jsu6c2",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6j6f6t2wph0897fsfbm9vk",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4ch5yr16x40919dzz1jmql",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318266136-ccb-582-1.jpg",
          "1613318266144-ccb-582-2.jpg"
        ]
      },
      {
        "sku": "583",
        "description": [
          {
            "id": "ckl4cjap3177j0919elyqkqxn",
            "title": "Sade Bomba Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku3yn608ggvc085982v7og3p",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku3yn60aggve08593q8q253t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yn60cggvf085933kuo5d4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku3yn60iggvi0859fhaw11ux",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku3yn60kggvk0859i5872470",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yn60mggvl0859cpg4k44f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku3yn60sggvo08597w6i0au3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku3yn60uggvq0859ked5m4en",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yn60vggvr0859i81wgupw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ckc9k178y0919d1bop0s8",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6j6ofk2wpr089781p4y7pb",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4cjaov177i09192wafgfv5",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318414859-ccb-583-1.jpg",
          "1613318414863-ccb-583-2.jpg"
        ]
      },
      {
        "sku": "584",
        "description": [
          {
            "id": "ckl4cl6rb179h0919nwrn2fj9",
            "title": "Mix Bomba Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku3yodd4ghdh0859ycsszp8u",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku3yodd7ghdj085930wnhfhp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yodd9ghdk0859m2duv0bd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku3yoddfghdn0859xz0yo3ac",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku3yoddhghdp08593dv918w9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yoddjghdq0859fsr0tcdw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku3yoddoghdt0859vjld7ls8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku3yoddqghdv0859rkeg6fa7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yoddsghdw0859r7k4salj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4clpyp17b00919n3borx14",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6j780h2wq108970f95msq4",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4cl6r4179g0919vqi7p00z",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318471799-ccb-584-1.jpg",
          "1613318471804-ccb-584-2.jpg"
        ]
      },
      {
        "sku": "594",
        "description": [
          {
            "id": "ckl4cseuw17ct0919y5nik1ng",
            "title": "XL Künye Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabyugzepal0859ikqrue6g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabyuh2epan0859faw208r8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyuh4epao0859jdybemv1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabyuhdepas0859irty079s",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabyuhfepau0859uskcidju",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyuhhepav0859kwxrce6g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabyuhpepaz0859gnu29mf3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabyuhrepb10859bhrdcpxl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyuhtepb2085984a4j0cj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4csu5017ea0919g2ef6fnx",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6j7hqw2wqb0897e8fus2wp",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4cseun17cs0919fe1qmj2p",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318488772-ccb-594-2.jpg",
          "1613318488767-ccb-594-1.jpg"
        ]
      },
      {
        "sku": "650",
        "description": [
          {
            "id": "ckl4cyp5t17g40919wo4lyu50",
            "title": "Unisex Lozenge Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54exqlnizoo0840qj2z2lhp",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54exqlsizoq0840gwo28bjk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54exqlwizor0840pubjupx6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54exqm5izov0840gfko115u",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54exqm7izox0840my6e0z0h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54exqm8izoy0840kemua9gj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54exqmeizp20840drr937bl",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54exqmfizp40840t16qvasl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54exqmhizp50840mqjtto5k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4cz4fy17hm0919zzl0epj3",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6j7tvt2wql0897ncy1d7z7",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4cyp5g17g30919it095cj0",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318512046-ccb-650-1.jpg",
          "1613318512040-ccb-650-2.jpg"
        ]
      },
      {
        "sku": "658",
        "description": [
          {
            "id": "ckl4d0n7217i609199lq8w4ir",
            "title": "City Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabyxw9eply0859iyf5gq4b",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabyxwbepm00859torwyf11",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyxwcepm108599yeyx9u6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabyxwhepm50859ocn02pi7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabyxwjepm70859vgf3uh9y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyxwkepm808599zpclt6j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabyxwpepmc0859j4b14n65",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabyxwqepme0859gh8boom7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyxwsepmf08599gwmpb4j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4d14hk17jq0919wj3e7rrn",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6jwru32wrs08970qfuou9t",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4d0n6t17i50919rp9hymlt",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318531605-ccb-658-1.jpg",
          "1633290991461-8.png",
          "1613318531609-ccb-658-2.jpg",
          "1613318531612-ccb-658-3.jpg",
          "1613318531614-ccb-658-4.jpg"
        ]
      },
      {
        "sku": "662",
        "description": [
          {
            "id": "ckl4d33xv17ka0919p47og19w",
            "title": "İç İçe Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabyzcaepps0859osstqxiz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabyzcceppu085991xfsbx3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyzcdeppv0859f1jd1l2f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabyzckeppz0859ocrbv7cn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabyzcmepq10859dbh6bn54",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyzcoepq20859nb0m9bmv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabyzctepq60859dtxojgrc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabyzcvepq80859galdfgts",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyzcwepq90859rzuit04n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4d3fy117lu09195puvn86q",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6jwzgh2ws4089754v0tj74",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4d33xr17k909195lhpyfcw",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318552544-ccb-662-1.jpg",
          "1613318552539-ccb-662-2.jpg",
          "1613318552551-ccb-662-3.jpg",
          "1613318552554-ccb-662-4.jpg"
        ]
      },
      {
        "sku": "696",
        "description": [
          {
            "id": "ckl4d49te17md0919eeztff6k",
            "title": "Denizci Düğümü Daire Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xjjfwh3h508400cufs9mt",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39xjjg1h3h70840d7usbljq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xjjg5h3h80840l7pfvavf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xjjgih3hc0840aa7lwjpi",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xjjgmh3he0840sh10hogr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xjjgoh3hf0840yrd6wc6l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xjjgzh3hj0840rrlb0wsb",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xjjh3h3hl0840p35bqhz1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xjjh5h3hm08400ul2pi5k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4d4rwn17ny0919jpap87qh",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6jxctv2wsf0897km7p3czg",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4d49t817mc091922zsjvgr",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1613318573226-ccb-696-1.jpg",
          "1613318573270-ccb-696-2.jpg"
        ]
      },
      {
        "sku": "697",
        "description": [
          {
            "id": "ckl4d5m0f17ol0919jy7ns7ap",
            "title": "Denizci Düğümü Üçgen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1m0o9zzfll90859fowm5rzo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1m0oa01fllb0859szkho8cn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0oa02fllc0859016filk1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m0oa08fllg0859zmhrklcy",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1m0oa09flli08597p0ccugs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0oa0afllj085909hc82of",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m0oa0fflln0859avga21f1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1m0oa0hfllp08595s8gna20",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0oa0ifllq0859bqoabkhd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4d61rv17q70919vmzmp8r1",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6jxi5m2wsp08975dnhdile",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4d5m0717ok09191zhxhc32",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1613318593196-ccb-697-1.jpg",
          "1613318593237-ccb-697-2.jpg"
        ]
      },
      {
        "sku": "698",
        "description": [
          {
            "id": "ckl4d6qcy17qq0919g8nsh3f1",
            "title": "Denizci Düğümü Kanca Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl347tkp9nioq0840mcv4rflg",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl347tkphnios0840vef13g8v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl347tkpkniot0840utldb2lx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl347tkpxniox0840236kvppm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl347tkq0nioz0840wryh5vf5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl347tkq2nip00840pdsgvlyj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl347tkq8nip4084036gzjx7k",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl347tkqanip608402bgz50kq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl347tkqbnip708406h3kjj5w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4d75yf17se0919v1d1hlkt",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6jyfg22wt00897jo0y4kcg",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4d6qcr17qp09195a8rhqki",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1613318611468-ccb-698-1.jpg",
          "1613318611508-ccb-698-2.jpg"
        ]
      },
      {
        "sku": "720",
        "description": [
          {
            "id": "ckl4d8q0b17u40919empqdv9l",
            "title": "Kalın Kalp Atışı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1kpaj9qa25x0859t18tj4ur",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1kpaj9ta25z0859m6ns7gmn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kpaj9ua2600859t7eq9mwd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kpaja1a2640859y2y8uzsb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1kpaja3a266085924ccfmwr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kpaja4a2670859x6qmlk97",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kpajaaa26b0859isibh25y",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1kpajaca26d0859blljou30",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kpajada26e085957jt4fh0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4d947617vs0919wdtrdql1",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6jyngd2wta08977o4thzvz",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4d8q0617u30919lmma99ce",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318632931-ccb-720-1.jpg",
          "1613318632936-ccb-720-2.jpg"
        ]
      },
      {
        "sku": "722",
        "description": [
          {
            "id": "ckl4d9uq817wb0919pp7v4612",
            "title": "Journey Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabz4kjeq4c0859sdgnwdhb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabz4kleq4e0859dkmzw18x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabz4kneq4f0859nrkw1waa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabz4l0eq4j0859011dz48q",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabz4l1eq4l0859wzr7xdl4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabz4l3eq4m0859zez8vsy8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabz4l8eq4q08597bh4tgpq",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabz4laeq4s08593ztv40k2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabz4lbeq4t0859qtm4bo0r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4dac5n17y00919xgaok0fx",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6mgbdx2wwl0897u101n220",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4d9uq217wa0919h9040tz8",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318651494-ccb-722-1.jpg",
          "1613318651530-ccb-722-2.jpg"
        ]
      },
      {
        "sku": "724",
        "description": [
          {
            "id": "ckl4dcvi217yk0919xj9uk360",
            "title": "Şampiyon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1klx52l99zr0859x3x7vi40",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1klx52n99zt0859syl6fl1u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1klx52p99zu0859lvwogsqp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1klx52v99zy0859g7zrcdbb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1klx52w9a0008599ph4h0zv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1klx52x9a010859b0c5ghdy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1klx5339a050859h5tg5tl9",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1klx5349a070859pq0fda7e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1klx5359a080859r5eshj3b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4de11e180a091944r5p47l",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6mgkdf2wwv0897lf840asw",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4dcvhs17yj09195iwspo7s",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318726484-ccb-724-1.jpg",
          "1613318726518-ccb-724-2.jpg"
        ]
      },
      {
        "sku": "748",
        "description": [
          {
            "id": "ckl4dnbks18630919c1vhvygp",
            "title": "Hedge Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wdmvz46ndo08405h4ziu9i",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wdmvzb6ndq0840v04maebr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdmvzh6ndr08405hdzs4my",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdmvzz6ndv0840hykx9a9d",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5wdmw026ndx0840udqlj8ga",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdmw056ndy08402c8r9lix",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdmw0g6ne208409v43g91i",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5wdmw0j6ne40840upj1kgf1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdmw0k6ne5084093du60tx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4docly188009191e8rlc3m",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6mj3502wxx08977ae6dt9e",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4dnbki186209190hvw4f0p",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318777981-ccb-748-1.jpg",
          "1613318778023-ccb-748-2.jpg"
        ]
      },
      {
        "sku": "749",
        "description": [
          {
            "id": "ckl4dt6m9189h0919komiyux1",
            "title": "Kaos Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzk02eokllyy0859yrwzi0vu",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzk02eomllz00859bfmt2pqk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzk02eonllz108595qdlzp0h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzk02eotllz50859w1lz4cmh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzk02eovllz708597ampz7mu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzk02eowllz80859lxdtlffc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzk02ep1llzc08594c9h7c84",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzk02ep3llze0859cc4uhs2u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzk02ep4llzf0859of6myulu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4dtogi18ar0919oni002hw",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6os3e82x0g0897p3gffqrp",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4dt6m1189g0919ghxaq5hd",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318809127-ccb-749-1.jpg",
          "1613318809134-ccb-749-2.jpg"
        ]
      },
      {
        "sku": "787",
        "description": [
          {
            "id": "ckl4dwhnk18bc09191bubk48z",
            "title": "Senkron Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku41mn9nh1o60859tfpyjctr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku41mn9qh1o808593c8nmzkf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41mn9th1o90859zrhs9shs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku41mna0h1oc0859xsf4za95",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku41mna2h1oe0859hph6smle",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41mna4h1of0859klqz6uhg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku41mnaah1oi0859dz2m0tpa",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku41mnach1ok08592drovex6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41mnadh1ol0859yqcagfbp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4dwvfm18cm091995khpdk5",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6osw8s2x0q08972jkae2uc",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4dwhnc18bb09196qbpzqjo",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318823323-ccb-787-1.jpg",
          "1613318823455-ccb-787-2.jpg"
        ]
      },
      {
        "sku": "793",
        "description": [
          {
            "id": "ckl4dy4nl18d50919lt4gxifz",
            "title": "Spot Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac09b4eqp108598lk75v9h",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac09b6eqp30859k0zyk1xs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac09b7eqp408598k12nj1k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac09bceqp808596r2kaixq",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac09bdeqpa0859gzh8dz7g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac09bfeqpb0859gb3l0r9m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac09bkeqpf0859l1ch6fjv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac09bmeqph0859jgvnz5g8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac09boeqpi08597gmouw3l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4dymte18en0919htb1mxxv",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ot4pg2x100897vikmrqg2",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4dy4nf18d40919nl4i4ct4",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613318839122-ccb-793-3.jpg",
          "1613318839112-ccb-793-4.jpg"
        ]
      },
      {
        "sku": "799",
        "description": [
          {
            "id": "ckl4dzh0418f60919wrobc9ry",
            "title": "Bone Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku41ocsrh20308596ku25fq6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku41ocsvh2050859u9ijp2rw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41ocsxh2060859jlmokmsc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku41oct6h20908592sk6mfju",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku41oct9h20b0859onoupez6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41octbh20c0859r48o4ohz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku41octjh20f0859e7syd3tl",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku41octmh20h08590nzmni7p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41octoh20i0859ixoqp70o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4e5lfn18hx09199xlcvkap",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6otb9t2x1a08977qfqdms9",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4dzgzz18f50919z6ne01gp",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613319063813-ccb-799-1.jpg",
          "1613319063859-ccb-799-2.jpg"
        ]
      },
      {
        "sku": "808",
        "description": [
          {
            "id": "ckl4e6avl18ig091996wyeb4a",
            "title": "Be in Luck Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac0adqeqsn08595w3ni8vo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac0adseqsp0859lct4s19j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0adueqsq0859ymf9ng33",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac0adzeqsu0859vomrbewh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac0ae0eqsw08592rw65f2b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0ae1eqsx0859sw59ajii",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac0ae7eqt10859zde6ka33",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac0ae8eqt30859ptzvcvcy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0ae9eqt40859q1lp57fu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4e6p3518jt091916t63rk2",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6othsf2x1k0897r3zacj5u",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4e6ava18if0919k49ug5yt",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613319079547-ccb-808-1.jpg",
          "1613319079554-ccb-808-2.jpg"
        ]
      },
      {
        "sku": "810",
        "description": [
          {
            "id": "ckl4eg9kf18lb0919489c99gu",
            "title": "Rengarenk Oval Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl57tyddbpl1w0840fzmg9sb3",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl57tyddfpl1y08405lq3ljo1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl57tyddgpl1z08409c4inq0j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl57tyddopl230840qtrjthbm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl57tyddppl250840mevl11kk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl57tyddrpl260840xvd9gdjh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl57tyddwpl2a0840368nhpnu",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl57tyddypl2c084003ibelhh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl57tyddzpl2d0840t3fehfvj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ego9g18mp0919pihdr6ff",
            "price": 62,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ots832x1u08972e9mgzj0",
            "price": 22,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4eg9k418la09191qdcf11a",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1657004919557-IMG_5260.jpg",
          "1657004919546-IMG_5265.jpg",
          "1646734017104-4.png",
          "1613319092827-ccb-810-5.jpg",
          "1613319092786-ccb-810-1.jpg",
          "1613319092781-ccb-810-2.jpg",
          "1613319092790-ccb-810-3.jpg",
          "1613319092823-ccb-810-4.jpg"
        ]
      },
      {
        "sku": "838",
        "description": [
          {
            "id": "ckl4ehery18n80919ewz74f04",
            "title": "Derin Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl53zo3b9hfar0840ye2mhho1",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl53zo3behfat0840ovv4gp6c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zo3bhhfau0840se6lqb6p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl53zo3bshfay0840e12dpy5n",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl53zo3bvhfb00840ubvd3woz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zo3bxhfb10840acdjam1a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl53zo3c9hfb50840sdbegngp",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl53zo3cdhfb70840xyf504mt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zo3cghfb80840qt9hwscs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ehvru18on0919dyi8z74i",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6otyo42x240897tv7ux4xy",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4ehero18n70919kz0n1rk6",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613319120293-ccb-838-1.jpg",
          "1613319120313-ccb-838-2.jpg",
          "1645024111998-838.png"
        ]
      },
      {
        "sku": "847",
        "description": [
          {
            "id": "ckl4eioq518pd0919nwmhf87h",
            "title": "Double Speed Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac0ei6er3o0859sai0k9gb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac0ei8er3q085903g6zw46",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0ei9er3r0859zoyhjod7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac0eieer3v0859wmp55gbn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac0eiger3x0859l6cfu9f2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0eiher3y0859k1s166bg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac0eimer420859vc2n8ocs",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac0einer440859snbxvqf8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0eioer4508595bekfc5b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ekcnt18r709194uvcqpy9",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ou4zm2x2e08973pe2sooa",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4eioq118pc0919jup3wk44",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613319136801-ccb-847-1.jpg",
          "1613319136809-ccb-847-2.jpg"
        ]
      },
      {
        "sku": "852",
        "description": [
          {
            "id": "ckl4encjr18rx0919zoub2wgj",
            "title": "Lazer Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1m0xum6fp780859h04cw0rs",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1m0xumafp7a0859g6iym0w7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0xumcfp7b085986fh9q6c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m0xumkfp7f0859b83wexo3",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1m0xumnfp7h08592tzfth5x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0xumofp7i0859i2enqx3a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m0xumvfp7m0859xqib4z5e",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1m0xumxfp7o08595lz8c6dg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0xumyfp7p0859udowe97e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4enyut18u009198g92oim5",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6oudox2x2o0897mk8y14ne",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4encji18rw0919u1uvlch2",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619185100346-ccb-852-L.jpg",
          "1613319154228-ccb-852-4.jpg"
        ]
      },
      {
        "sku": "853",
        "description": [
          {
            "id": "ckl4eosxy18ul0919iz9jbc86",
            "title": "Kutu Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku41tjvah2x50859jy9kqcxn",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku41tjvdh2x70859qussypqk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41tjvfh2x80859vwrnrv9g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku41tjvmh2xb08597e9tz2xr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku41tjvoh2xd0859k06mp1qt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41tjvqh2xe0859a3cwx2qk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku41tjvwh2xh0859qzv57ytc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku41tjvzh2xj0859b5c8plbr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41tjw0h2xk0859a399wuzm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlkps7esrbe0959rp3h17jv",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlkpzucsrcb0959go30skiy",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4eosxr18uk09198j8bgmns",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619185408243-ccb-853-L.jpg",
          "1613319181786-ccb-853-2.jpg"
        ]
      },
      {
        "sku": "857",
        "description": [
          {
            "id": "ckl4es8ry18yq0919su4dyqma",
            "title": "Dik Lazer Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzpoxnk71zyf0859zqn9jxix",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzpoxnk91zyh08591b8giym8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpoxnkb1zyi0859cqi6b6eq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpoxnkh1zym0859who35dga",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzpoxnkj1zyo0859zmoz6e0a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpoxnkk1zyp08591uc7koz0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpoxnkr1zyt0859ux1b9e7d",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzpoxnks1zyv0859mha5x45j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpoxnku1zyw0859ocos1w9w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4et17p190a09197sidbbs2",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ovoof2x3i0897740xil7d",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4es8rp18yp0919nmd0e3xm",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619185674545-ccb-857-L.jpg",
          "1613319217210-ccb-857-2.jpg",
          "1645024187111-857.png"
        ]
      },
      {
        "sku": "860",
        "description": [
          {
            "id": "ckl4ewbev190t0919wuab6ftt",
            "title": "Vals Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzr3ubvv6ia60859qsdm1pu6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzr3ubvx6ia80859sitvaojh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3ubvz6ia90859q1bdc5uw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzr3ubw56iad08590hyx4qem",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzr3ubw66iaf0859l71bwr4v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3ubw86iag08597y8yk9vz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzr3ubwd6iak0859m2sny6ec",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzr3ubwf6iam0859hnlize7l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3ubwg6ian0859uoup72m5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4ex08l192e0919den7hjtx",
            "price": 105,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ovwmh2x3s0897w99nei5s",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4ewbej190s091934ivote0",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619186306356-ccb-860-L.jpg",
          "1613319244479-ccb-860-2.jpg"
        ]
      },
      {
        "sku": "861",
        "description": [
          {
            "id": "ckl4exsyn192x0919nl8ct7pq",
            "title": "Bariyer Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54e8uolitse08406l59o1zt",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54e8uopitsg08402q9derg4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e8uotitsh0840zqdhmfu4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e8up2itsl0840q1u977fm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54e8up4itsn0840qkeebrdf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e8up6itso08405noid8va",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e8upeitss084079p782qm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54e8uphitsu0840i0irl9qv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e8uplitsw0840y6y2107s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4eyckz194j0919ij03c72p",
            "price": 117,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ow3032x420897pcv39pyz",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4exsyf192w0919bkkl98ez",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613319261261-ccb-861-1.jpg",
          "1613319261257-ccb-861-2.jpg",
          "1613319261302-ccb-861-3.jpg",
          "1613319261307-ccb-861-4.jpg"
        ]
      },
      {
        "sku": "866",
        "description": [
          {
            "id": "ckl4ezjp119530919pd7jrisd",
            "title": "Ritim Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8vr00bk8jh408409lyi8lfe",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl8vr00bq8jh60840xxb9fyha",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vr00bs8jh70840sph4ihxb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8vr00c18jhb0840t7uax1kp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl8vr00c48jhd084057qhqdnh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vr00c68jhe08400sppksan",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8vr00cf8jhi0840hulmn3u0",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl8vr00ci8jhk084075e0mrgo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vr00ck8jhl0840q8ba6av4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4f03wn196q09199zwlhavw",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6r763t2x7u08975mmvljoj",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4ezjov19520919i29rbsmp",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1613319279597-ccb-866-1.jpg",
          "1613319279646-ccb-866-2.jpg"
        ]
      },
      {
        "sku": "877",
        "description": [
          {
            "id": "ckl4f14mz19790919p0bbtcpz",
            "title": "Angel Wings Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1kth1qiax4k0859b3xp2mo3",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1kth1qjax4m0859g2nicqef",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kth1qkax4n0859seh2ii5b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kth1qqax4r0859f9dvcwf3",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1kth1qsax4t08597qp26c1u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kth1qtax4u0859ydam33ba",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kth1qyax4y085993ikwjtv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1kth1qzax5008593jurqw34",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kth1r1ax510859x2d0x30f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4f1oed198x0919jesisku2",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6r8j4a2x8408974ueg3uwk",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4f14mr19780919otdbtiiw",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1621488586739-ccb-877-L.png",
          "1613319323319-CCB-877 yan.jpg"
        ]
      },
      {
        "sku": "879",
        "description": [
          {
            "id": "ckl4f2s35199g0919dfms22iu",
            "title": "Rond Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku421ckhh4av0859rhkmchkb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku421ckmh4ay0859t8u761zc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku421ckqh4az0859ibbdd6fn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku421ckyh4b20859h56xmeak",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku421cl1h4b40859jtamd2b1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku421cl4h4b508590pvc6193",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku421clbh4b80859miykmu6y",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku421cleh4ba08591cmdgssc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku421clhh4bb085950uiz6kq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl4f3gl419b50919bus1e1ov",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6r8po52x8e0897ciszmj8n",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl4f2s2z199f0919m4hiz35u",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619186568945-ccb-879-L.jpg",
          "1613319579515-ccb-879-2.jpg"
        ]
      },
      {
        "sku": "353",
        "description": [
          {
            "id": "ckl54twer1arv0919orybbod3",
            "title": "Taş Detaylı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56h3u64mggy0840ypivrzqk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56h3u68mgh008404movqamo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56h3u6amgh10840qmava3em",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56h3u6jmgh50840d9oka7tj",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56h3u6lmgh70840gfk7pgah",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56h3u6omgh80840qs2i71v7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56h3u6wmghc0840crtu1xcj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56h3u6ymghe0840m8euam17",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56h3u70mghf0840w6x6goyd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl54udq31atl0919j9vo3ymk",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6raacl2x9808974gcwm16w",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl54twec1aru091929r3snn3",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1656922866885-CBS-353.jpg",
          "1632743354748-353.png",
          "1613319600183-ccb-353-2.jpg"
        ]
      },
      {
        "sku": "354",
        "description": [
          {
            "id": "ckl54vboe1auc0919xmfupad1",
            "title": "Taşlı Kare Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya875iextuw0859n0jgewqk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya875igxtuy0859bn1f8uuv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya875iixtuz08594mvawujh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya875iqxtv30859f5szdlwn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya875iwxtv5085955lmxvdf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya875ixxtv6085928m9aszh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya875j3xtva0859txj8491m",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya875j4xtvc08594zplk0ej",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya875j5xtvd0859pm95ww2i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl54z68f1aw40919ydhbmy4x",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rbb2t2xa40897xh1mv0ib",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl54vbo91aub0919di054dj6",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613319620502-ccb-354-1.jpg",
          "1613319620550-ccb-354-5.jpg",
          "1619190471600-ccb-354-L.jpg",
          "1613319620513-ccb-354-4.jpg",
          "1613319620541-ccb-354-7.jpg",
          "1613319620525-ccb-354-3.jpg",
          "1613319620546-ccb-354-6.jpg"
        ]
      },
      {
        "sku": "355",
        "description": [
          {
            "id": "ckl55az111az90919c4n80uj1",
            "title": "Taşlı Ok Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54dswowiqfh0840vgnwgljd",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54dswp2iqfj0840zgnxgtd5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dswp4iqfk0840prw1xudd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dswpeiqfo0840lawixrj0",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54dswpgiqfq0840swyodb7n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dswphiqfr08401543108i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dswpniqfv08406dwit8rs",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54dswppiqfx084097nemu1d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dswpqiqfy08404lwspwfd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl55be8r1b0h0919z848oddp",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rbi7d2xae0897qpbv7jfi",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl55az0q1az8091918d3bewx",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619190209626-CCB-355-L.jpg",
          "1613319653304-CCB-355.jpg",
          "1613319653308-ccb-355-2.jpg",
          "1613319653297-ccb-355-1.jpg"
        ]
      },
      {
        "sku": "356",
        "description": [
          {
            "id": "ckl55cber1b1r0919pn6iyhcs",
            "title": "Taşlı Üçgen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac9v9iez6l08598fefh9dk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac9v9kez6n0859rwy7pprl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac9v9lez6o0859fysg8ssf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac9v9pez6s08590u4zc3mi",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac9v9qez6u08595l9ncxx7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac9v9rez6v0859q17xl4bi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac9v9wez6z08594yvcp3by",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac9v9xez7108591l4pi39d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac9v9yez720859xd9ri50a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl55cq0y1b3009194j6a9ri3",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rbth92xao08976wve23c3",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl55cbek1b1q09193yx0lhi4",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619190776510-CCB-356-L.jpg",
          "1613319683624-CCB-356 yan.jpg",
          "1613319683569-ccb-356-1.jpg",
          "1613319683612-ccb-356-2.jpg"
        ]
      },
      {
        "sku": "357",
        "description": [
          {
            "id": "ckl55dxzc1b3l0919juztbgat",
            "title": "Taç Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3nmz815tu3c084007dfq5e1",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3nmz81btu3e0840pwvmutxi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmz81ftu3f08406y3d7gwa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmz81qtu3j0840yi4937sy",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3nmz81ttu3l084073sd5gb5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmz81wtu3m0840gp6ws54e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmz829tu3q0840hoskeggr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3nmz82btu3s08404csmh3bn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmz82dtu3t0840vi7gfp7d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl55f4tr1b540919rhnw7bts",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rbzf92xay0897hvwyoyqj",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl55dxyx1b3k0919lpzt4mst",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619191257620-CCB-357-L.jpg",
          "1613319733469-CCB-357 yan.jpg",
          "1613319733437-ccb-357-3.jpg",
          "1613319733424-ccb-357-2.jpg",
          "1613319733456-ccb-357-1.jpg"
        ]
      },
      {
        "sku": "358",
        "description": [
          {
            "id": "ckl55l2aj00120819pm8qdnjb",
            "title": "Taşlı Piano Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya85p40xtf408594w35th55",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya85p43xtf60859dexb1efb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya85p45xtf708594jte2nq9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya85p4cxtfb0859j0cxo0qt",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya85p4extfd0859i5v3crjx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya85p4gxtfe0859gfp40ow2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya85p4nxtfi0859nvq55ws5",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya85p4oxtfk0859mwqgco5c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya85p4qxtfl08595ds9di9m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl55lmi7002p0819ie6sdtos",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rc8jw2xb808973ak3d9i8",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl55l28d001108194ezy2amw",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613319804787-ccb-358-3.jpg",
          "1613319804728-ccb-358-2.jpg",
          "1619191514947-ccb-358-L.jpg"
        ]
      },
      {
        "sku": "359",
        "description": [
          {
            "id": "ckl5d0tg2077m0819v2q201ee",
            "title": "Taşlı Sütun Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac9z8aezhn0859t3rvx0o1",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac9z8cezhp0859o3z5mpv2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac9z8dezhq0859jhr4m99d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac9z8iezhu0859jux3f7i7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac9z8kezhw0859qbs52drm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac9z8lezhx0859w6hgvc41",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac9z8rezi10859on1chcqi",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac9z8sezi30859scr8b730",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac9z8uezi40859ucd8bkz7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5d1gz407a40819vut3hkrv",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rcrto2xbi0897ij7yrbkl",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5d0tfx077l0819y2rhidtg",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619191560982-ccb-359-L.jpg",
          "1613319839256-ccb-359-1.jpg"
        ]
      },
      {
        "sku": "360",
        "description": [
          {
            "id": "ckl5d2hqs07aq0819ganlrikd",
            "title": "Taşlı Merdiven Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3nmv12wtt5d0840rvuc87xj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3nmv131tt5f0840jbhae8gr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmv134tt5g084006juzqx4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmv13ltt5k0840qoik8i6k",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3nmv13ptt5m0840uiva54fp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmv13stt5n0840wcjn5aa8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmv144tt5r084040ilhlan",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3nmv147tt5t0840w4gosjwt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmv149tt5u0840x1lz2a9t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5d3w1i07c50819nlrmksx3",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6repzc2xbs0897ipsq9bvd",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5d2hqj07ap0819cdu5u190",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613319957009-ccb-360-1.jpg",
          "1613319956910-ccb-360-2.jpg",
          "1613319957003-ccb-360-4.jpg",
          "1613319957014-ccb-360-5.jpg",
          "1613319956997-ccb-360-3.jpg"
        ]
      },
      {
        "sku": "375",
        "description": [
          {
            "id": "ckl5drmv007e40819zqom351f",
            "title": "Işık Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54efswuivkx0840bpeojdjb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54efswzivkz08409l8irker",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54efsx1ivl00840xgvwx02v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54efsxeivl408407cazdqxf",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54efsxhivl60840ibq7uqvi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54efsxjivl70840pbuxd3ab",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54efsxrivlb0840afuid2ad",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54efsxtivld08409ymqwucg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54efsxvivle0840u99g5cnd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5ds24v07fk0819cb7gj3i8",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rewx42xc2089710hya33n",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5drmum07e308194i4pjodw",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1654852886372-IMG_4548.jpg",
          "1654852895030-IMG_4551.jpg",
          "1654852885452-IMG_4546.jpg",
          "1654852885492-IMG_4547.jpg",
          "1619192165959-ccb-375-L.jpg",
          "1613321126781-ccb-375-2.jpg"
        ]
      },
      {
        "sku": "378",
        "description": [
          {
            "id": "ckl5dt3d107g608195oeiqqgw",
            "title": "Taşlı Mini Daire Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56hfrmpmhju0840duw900fk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56hfrmsmhjw0840yrfp0tdo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56hfrmumhjx0840d770g72d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56hfrn3mhk108408soyfg8b",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56hfrn5mhk30840a82ja6gg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56hfrn7mhk4084089fk91gk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56hfrngmhk8084071g9miw5",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56hfrnjmhka08408qsze5hw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56hfrnlmhkb0840x3hbqr8w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5dtns107hn0819wofnm7is",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rf4d12xcc0897m7t7p6v2",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5dt3cq07g50819ck8vhun7",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1656923024435-IMG_5134.jpg",
          "1656923424143-IMG_5267.jpg",
          "1619191960597-ccb-378-L.jpg",
          "1613321202805-ccb-378-2.jpg"
        ]
      },
      {
        "sku": "379",
        "description": [
          {
            "id": "ckl5dusi907i90819abb09f8a",
            "title": "Çiçekli Taşlı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl57ttvh5pke00840ndo1eyxp",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl57ttvh9pke20840v2eqkn0u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl57ttvhbpke30840j2uqlme7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl57ttvhipke70840eg3mgth9",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl57ttvhkpke90840eer4xwr9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl57ttvhlpkea0840ehq4xp7o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl57ttvhrpkee08402ikvwnj0",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl57ttvhspkeg08402nukznf8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl57ttvhtpkeh0840pm9zzsu2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5fet5w07mn08195m99f4ty",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rf9kh2xcm0897ghva059i",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5dusi107i80819kg4ydl7x",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619192476762-ccb-379-L.jpg",
          "1613321270287-ccb-379-2.jpg"
        ]
      },
      {
        "sku": "380",
        "description": [
          {
            "id": "ckl5fgf2207na08191gnsmox0",
            "title": "Mini Kare Taşlı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3nn1oamtuno0840ftt4cjqu",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3nn1oaptunq0840guxrjzgt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nn1oaqtunr08402rcw71pr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nn1oawtunv08405o8awevb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3nn1oaxtunx0840hdmu4fcf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nn1oaytuny0840wqgb0mj4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nn1ob9tuo20840iwiubfgw",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3nn1obctuo408406cr85vr6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nn1obftuo50840utrve0dk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5fgv0707or0819k7eqmp88",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rfgsr2xcw089706xsgj3o",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5fgf1p07n90819r4072d0a",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619192343994-ccb-380-L.jpg",
          "1613323948351-ccb-380-4.jpg",
          "1613323948348-ccb-380-1.jpg",
          "1613323948341-ccb-380-2.jpg"
        ]
      },
      {
        "sku": "388",
        "description": [
          {
            "id": "ckl5fjar907pc081905lacimt",
            "title": "Taşlı Zincir Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxaca8fbf03v08590ml00hj5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxaca8fdf03x08599sg11f3w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaca8fef03y0859ko8ccfgo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxaca8fkf0420859ngtzqw6o",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxaca8flf0440859p8yk0zxw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaca8fmf0450859h2szgccn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxaca8ftf04908598pa2d34f",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxaca8fvf04b0859gqfzc1e1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaca8fwf04c0859sx7m776x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5flbm807r20819cppi8es6",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rfnxw2xd608979wl8ur8g",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5fjaqy07pb0819kiynmln1",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619192666333-ccb-388-L.jpg"
        ]
      },
      {
        "sku": "498",
        "description": [
          {
            "id": "ckl5fmwbg07rp08198mj7bmaq",
            "title": "Güneş Işığı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8vqzp498jcj0840ksjulzgv",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl8vqzp4d8jcl0840xcbfdl4f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vqzp4g8jcm0840a2r68ve9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8vqzp4o8jcq0840qwh0ylx9",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl8vqzp4q8jcs0840h476o5hb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vqzp4r8jct08409ju86eib",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8vqzp4x8jcx0840d4n1o7aw",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl8vqzp4y8jcz0840zpifhu9e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vqzp4z8jd00840oaqku36d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5fncf707t70819tmgthdsm",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rfunn2xdg089797jfyws5",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5fmwb407ro08192bv7wvca",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613324247100-ccb-498-1.jpg",
          "1633779060683-498.png",
          "1613324247085-ccb-498-2.jpg"
        ]
      },
      {
        "sku": "499",
        "description": [
          {
            "id": "ckl5fq8ll07u608197gamezmx",
            "title": "Vintage Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56tpyzgnm1n0840bz6647b7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56tpyznnm1p0840w46u0iwz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tpyzonm1q0840gnc12ok2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tpyzvnm1u0840sbd6rvu5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56tpyzxnm1w0840zf7wuqf5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tpyzynm1x0840g1znb66o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tpz05nm210840t7ewn61n",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56tpz06nm230840euz2fjja",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tpz07nm2408406zh1i7o3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5fs50w07vr0819y8arrmt1",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rgdrm2xdq0897u114xrb0",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5fq8lc07u50819f3ru5izc",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619192851589-CCB-499-L.jpg",
          "1633779087271-499.png",
          "1613324385937-CCB-499.jpg",
          "1613324385823-ccb-499-1.jpg"
        ]
      },
      {
        "sku": "502",
        "description": [
          {
            "id": "ckl5g7f4407z00819lwm5tw1g",
            "title": "Taşlı Papyon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckty3nyfkyrdz0859wvpo4d78",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckty3nyfnyre10859ekypkcda",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty3nyfpyre20859m9x8itkk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckty3nyfvyre50859d6fk7luy",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckty3nyfxyre70859nza77xtp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty3nyfzyre80859eaxhxiue",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckty3nyg4yreb0859tnfpxmzs",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckty3nyg7yred0859uokgm7yf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty3nyg9yree0859b99d6lg0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5g7t4t080l0819zqu9gkv8",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rh6e92xe00897mzzx384v",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5g7f3f07yz0819o8uranbd",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619192952741-ccb-502-L.jpg"
        ]
      },
      {
        "sku": "508",
        "description": [
          {
            "id": "ckl5g921c0818081980bpli92",
            "title": "Taşlı Yaprak Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1m0akk8fhq80859ycc86c37",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1m0akkcfhqa0859ruh5wpvo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0akkdfhqb0859n1z1tie7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m0akkkfhqf0859it9ckpqe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1m0akklfhqh0859kxic2jcc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0akkmfhqi0859kt6edowb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m0akksfhqm0859dc8jv0qv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1m0akkufhqo0859dqncfq5u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0akkvfhqp0859oc6zp3pd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5g9j7y082x0819gyn1ejhi",
            "price": 155,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rhdzl2xea0897d464piip",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5g921008170819zfv2ykjc",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619193289524-ccb-508-L.jpg",
          "1613325293066-ccb-508-4.jpg"
        ]
      },
      {
        "sku": "512",
        "description": [
          {
            "id": "ckl5gfi850853081934a3mlma",
            "title": "Prizma Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz5ncznk9a8a0859jehy0cki",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz5ncznl9a8c0859iykwnllu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5ncznn9a8d0859z3z2jqqi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5nczns9a8h0859jrzbyocr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckz5ncznu9a8j0859umx3ztvj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5ncznv9a8k0859jrl51y7f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5nczo19a8o0859vlmb0nax",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckz5nczo39a8q08592jv3vuar",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nczo49a8r08599bwlnds3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5gg4cu086z081918fbf908",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rhkfx2xek0897ghnfchaz",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5gfi7r08520819327iutcm",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613325596630-ccb-512-1.jpg",
          "1613325596622-ccb-512-2.jpg"
        ]
      },
      {
        "sku": "514",
        "description": [
          {
            "id": "ckl5ghxjn087m0819ndlw5s3m",
            "title": "Parti Bilekliği"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzr3dc156doc0859rcaiqz7i",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzr3dc186doe0859j6t1r8z2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3dc196dof0859vm0l6noc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzr3dc1g6doj0859kmgbln12",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzr3dc1i6dol0859fvnz9k98",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3dc1j6dom08593juvol62",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzr3dc1q6doq08597ia9wll8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzr3dc1s6dos0859flmlsa3e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3dc1u6dot08591u5gwgxq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5gih5j089a08198f3hhvxp",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rhqlu2xeu0897jaej00co",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5ghxjd087l08196ij2z2v4",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619193125427-CCB-514-L.jpg",
          "1613325728139-CCB-514 byz.yan.jpg",
          "1613325728873-CCB-514.jpg",
          "1613325728744-CCB-514 yan.jpg",
          "1613325728085-ccb-514-1.jpg"
        ]
      },
      {
        "sku": "516",
        "description": [
          {
            "id": "ckl5gozst08bk081951vfvdfk",
            "title": "Kozmopolit Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxacah7kf0oq08599lg6wrd3",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxacah7lf0os08597vj8t3cl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacah7nf0ot0859q3fitfle",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxacah7tf0ox0859pmzydzsv",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxacah7vf0oz0859f5964yhv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacah7wf0p008593qenajnq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxacah88f0p50859ah517jct",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxacah8af0p70859ky4h422n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacah8bf0p808597oqm4mhg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5gph0j08d90819zu8zr2gi",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rhwfn2xf40897iog9htx7",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5gozsg08bj08190u198ydi",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613325825901-ccb-516-1.jpg",
          "1613325825907-ccb-516-2.jpg"
        ]
      },
      {
        "sku": "517",
        "description": [
          {
            "id": "ckl5gvfvi08e10819925liw2b",
            "title": "Düğüm Noktası Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckty7hwbtzf7l0859parxhlew",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckty7hwbwzf7n0859rks6go52",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty7hwc0zf7o0859ubbfyuhr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckty7hwc7zf7r0859u1pz2dxw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckty7hwc9zf7t0859wvnn2gwo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty7hwcbzf7u0859lj9axrj1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckty7hwcizf7x0859mt0l525c",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckty7hwcmzf7z0859nz6ztqvh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty7hwctzf820859r7shd6jm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5gvs7i08fr08193tqdrnve",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ri35v2xfe0897a3imvkrp",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5gvfv608e00819r58k58j2",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613326326793-ccb-517-1.jpg"
        ]
      },
      {
        "sku": "524",
        "description": [
          {
            "id": "ckl5gxsjx08ge08199scxtlzx",
            "title": "Taşlı Merdiven"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54c7ks1iigl0840sl700iiq",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54c7ks7iign0840wyw4jluc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c7ks9iigo0840ioicbiii",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54c7ksiiigs08403px5ikfx",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54c7ksjiigu0840scfdy6rq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c7ksliigv08403s4zeswl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54c7ksuiigz0840yg1lxewb",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54c7kswiih1084018kd1qp0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c7ksyiih20840jx7088ol",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5gz1xe08i50819cuo6sa7t",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rqp0g2xgb0897bghjb0kh",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5gxsj608gd0819qxr5p1id",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613326419550-ccb-524-3.jpg",
          "1613326419576-ccb-524-4.jpg"
        ]
      },
      {
        "sku": "569",
        "description": [
          {
            "id": "ckl5h1d8z08it0819d5ff4yoe",
            "title": "Moon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxacbedjf13208590xi3hp5o",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxacbedlf13408592amluknk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacbedmf13508590xy9a1ky",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxacbedrf1390859y3796aya",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxacbedtf13b0859fas2gki9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacbeduf13c08594c9i9r56",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxacbedzf13g0859yfxm8fdc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxacbee0f13i0859tovdci1x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacbee1f13j0859yruuras8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5h2yhu08k20819nldhqgfl",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cku2lix1qcccy08599cpelvjv",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5h1d8r08is0819nodus5h9",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613326566299-ccb-569-1.jpg",
          "1613326566347-CCB-569.jpg",
          "1613326566337-CCB-569 yan.jpg"
        ]
      },
      {
        "sku": "570",
        "description": [
          {
            "id": "ckl5h3vjo08ko08191sqbraxg",
            "title": "Moon Walk Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyfyc4h0qpx0859n9lq3zct",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyfyc4k0qpz08593k75o583",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyfyc4m0qq008592u5w6unt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyfyc4t0qq30859mc870pv1",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyfyc4v0qq50859t7dfr92l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyfyc4y0qq608593r69r9gw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyfyc550qq90859nzknkv8v",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyfyc570qqb085921papbbr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyfyc590qqc085905w9opx0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5h4ew108lx08191514uion",
            "price": 115,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5h3vjj08kn08194ebuyw9t",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613326749901-ccb-570-1.jpg"
        ]
      },
      {
        "sku": "622",
        "description": [
          {
            "id": "ckl5h5il608mk0819cuykmcmm",
            "title": "Path Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0q96im3lrnm08598ydij1j5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0q96im5lrno0859mrfeuh1u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q96im7lrnp0859lxocz8i5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0q96imflrnt0859t3yc5lqz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0q96imglrnv0859ujr3gr2k",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q96imilrnw0859git92agd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0q96implro00859566eixsm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0q96imrlro20859934jda6u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q96imtlro30859tfr2fyof",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5h60rn08nw08194pzuf9fb",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rtx1v2xh50897ceohwxsb",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5h5ikz08mj08192kev5qss",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647234979785-IMG_9973.jpg",
          "1647234979777-IMG_9974.jpg",
          "1613326815981-ccb-622-1.jpg",
          "1613326815987-ccb-622-2.jpg"
        ]
      },
      {
        "sku": "654",
        "description": [
          {
            "id": "ckl5h6yy408oh08191je9pv42",
            "title": "Crystal Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54djhstio410840v0hwl3bj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54djht0io43084069msu0rz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54djht4io44084040c3l42j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54djhtgio480840v9xk19d5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54djhtiio4a0840b2mhje60",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54djhtlio4b0840rnvy62n7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54djhu1io4g084090e47c44",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54djhu8io4i0840cnbmg52z",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54djhucio4j084038lq89m8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5h7r4708pu08197a20or2r",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ru2vr2xhf08974ukon1ug",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5h6yxy08og0819b4tpts1u",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613326891963-ccb-654-5.jpg",
          "1613326891966-ccb-654-6.jpg",
          "1613326891957-ccb-654-1.jpg",
          "1613326891982-ccb-654-2.jpg",
          "1613326891989-ccb-654-3.jpg",
          "1613326891994-ccb-654-4.jpg"
        ]
      },
      {
        "sku": "728",
        "description": [
          {
            "id": "ckl5hb1rr08ql0819u8mnp49m",
            "title": "Taşlı Soul Zincir Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz5n4ba2975x08594ue7fqvk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz5n4ba5975z0859488fqycp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5n4ba697600859j877eb6o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5n4baf97640859f7kdi0mc",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckz5n4bah97660859x4rzzkjm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5n4baj97670859u70igat7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5n4bar976b0859p4r77w1g",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckz5n4bat976d0859vdora6vv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5n4bav976e08592o5a49ha",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5hbcsd08s1081972y1slqe",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ru9br2xhp0897d0e31i2c",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5hb1rh08qk0819hl7q0pp2",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613327094171-ccb-728-1.jpg",
          "1613327094212-ccb-728-2.jpg"
        ]
      },
      {
        "sku": "761",
        "description": [
          {
            "id": "ckl5hwikw08tv0819213mefwh",
            "title": "İnce Dragon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzk07hj4lnbb0859can2bokq",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzk07hj6lnbd0859fh700r79",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzk07hj8lnbe08599glfn7ff",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzk07hjflnbi0859c29orau0",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzk07hjilnbk0859u7o1x2gl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzk07hjjlnbl0859d1bwhp2v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzk07hjslnbp0859b8wcquao",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzk07hjulnbr08598mqg6e71",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzk07hjwlnbs0859x68fnpp7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5i28vi097h0819k4k0o7kd",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rufpp2xhz0897t00p1y9w",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5hwikm08tu0819rrymmwpj",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613327140152-ccb-761-1.jpg",
          "1613327140201-ccb-761-2.jpg"
        ]
      },
      {
        "sku": "780",
        "description": [
          {
            "id": "ckl5i5sxn099n0819rvcm8y2p",
            "title": "Kalın Dragon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyhj8px0zwi0859dotqqa7j",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyhj8q00zwk0859l99dfksp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyhj8q20zwl0859j1vgwx7y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyhj8qa0zwo0859wftfsq4y",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyhj8qd0zwq0859nyvq3pr5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyhj8qf0zwr0859lboqoahz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyhj8qm0zwu0859wrsfjttj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyhj8qo0zww0859rox92m91",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyhj8qq0zwx08594qxx1ewa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5i7cud09b20819mea5dvfv",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rum5p2xi90897drsd29b5",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5i5sxd099m08199eih313e",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613328442129-ccb-780-1.jpg",
          "1613328442137-ccb-780-2.jpg"
        ]
      },
      {
        "sku": "781",
        "description": [
          {
            "id": "ckl5i90wq09bo0819cnnebvxh",
            "title": "Tetris Taşlı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl548d7xli1t40840i5cqao2o",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl548d7xui1t60840i4ka4ncq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548d7xyi1t708407eqjo1d3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl548d7yhi1tb0840273k760a",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl548d7ymi1td0840213h36i0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548d7yqi1te0840hi87gvph",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl548d7z2i1ti08405bf3jq6o",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl548d7z6i1tk084045dovqa6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548d7z9i1tl08402c3f8p76",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5i9hjf09d30819w6r84242",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rutpd2xij08972hv5azj8",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5i90wi09bn0819bkr33l1o",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613328634045-ccb-781-1.jpg",
          "1613328634097-ccb-781-2.jpg",
          "1613328634114-ccb-781-3.jpg",
          "1613328634119-ccb-781-4.jpg"
        ]
      },
      {
        "sku": "785",
        "description": [
          {
            "id": "ckl5ibg1t09et0819y4gwlub2",
            "title": "Spotlight Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0hynfshpi7408591swkdcjs",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0hynfskpi760859noijo18x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hynfsmpi770859rl1kt68m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hynfstpi7b08598o75300h",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0hynfsvpi7d0859gkb090p6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hynfswpi7e0859gbd84h97",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hynft2pi7i08599o6il3lz",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0hynft4pi7k085969u8a6er",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hynft5pi7l0859cwf891hl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5ic65609g90819f57fbg99",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ruzuc2xit08971aqtdz5f",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5ibg1l09es08194nuirt0y",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1646733564788-2.png",
          "1613328780572-ccb-785-4.jpg",
          "1613328780502-ccb-785-1.jpg",
          "1613328780529-ccb-785-2.jpg"
        ]
      },
      {
        "sku": "798",
        "description": [
          {
            "id": "ckl5idsvy09gv0819y264vxgv",
            "title": "Taşlı Kilit Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxacbnt5f1sv0859t4j3tiej",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxacbnt7f1sx08591rf71pup",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacbnt8f1sy0859h18zrusw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxacbntdf1t20859ki4o1e55",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxacbntef1t408597u8yz6fj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacbntff1t50859ca6bj7is",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxacbntkf1t908592e2frgw8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxacbntmf1tb0859l2aq287e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacbntnf1tc0859mrax9qbs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5ie5om09id08191h4e19vu",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rv6uw2xj30897lhf5ywpr",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5idsvr09gu0819wm0pt069",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613328883712-ccb-798-1.jpg",
          "1613328883727-ccb-798-2.jpg"
        ]
      },
      {
        "sku": "823",
        "description": [
          {
            "id": "ckl5if48v09iz0819zkaia7ia",
            "title": "Hix Dragon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl76btdtvfhq50840wrsrmavx",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl76btdu4fhq70840e7kuccxe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl76btdu8fhq80840fgzcqph9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl76btdulfhqc0840f8crubyd",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl76btduofhqe08407w9kmk9j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl76btduqfhqf08408oowoxmx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl76btdv1fhqj0840wuvcc37z",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl76btdv5fhql0840tvemzulk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl76btdv7fhqm0840vgkts1en",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5ifmca09ki0819iwr4mfti",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rvfpo2xjd0897hrgslmku",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5if48k09iy0819fd1p341s",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613328946515-ccb-823-1.jpg",
          "1613328946508-ccb-823-2.jpg"
        ]
      },
      {
        "sku": "839",
        "description": [
          {
            "id": "ckl5ikqmr09le0819qlyu48u2",
            "title": "Dikdörtgen Turkuaz Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzpowxim1zsj0859s8pqe1xs",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzpowxip1zsl0859dauno02d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpowxis1zsm08595qx1zadk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpowxj11zsq0859z85t3mr7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzpowxj41zss0859cdrubkab",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpowxj61zst0859atib7exc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpowxje1zsx08595ie7d3vf",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzpowxjh1zsz08592wkgpq8m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpowxji1zt00859b6bkk7p1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5il68709mx08196ijyn8kp",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rvn0m2xjn0897kzeukcpx",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5ikqmj09ld0819y4tpk9k7",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613329210449-ccb-839-1.jpg",
          "1613329210442-ccb-839-2.jpg",
          "1645024150947-839.png"
        ]
      },
      {
        "sku": "840",
        "description": [
          {
            "id": "ckl5im98509nj0819e8cc9fyk",
            "title": "Mercan Noktalı Kare Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyi9djj16gp0859anvo573j",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyi9djl16gr0859uwvl6hgc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyi9djn16gs0859g90yx6zp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyi9djt16gv0859b360koty",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyi9dju16gx0859zyuel8ww",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyi9djw16gy0859vvqlmsfg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyi9dk216h108590ekl3fi3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyi9dk416h30859eoa1qs0w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyi9dk616h40859gahoklac",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5imkzw09p40819yd8kpquf",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rvzvn2xjx08977ifeazpf",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5im97x09ni081960dwrzd2",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1613329286983-ccb-840-1.jpg",
          "1613329286990-ccb-840-2.jpg",
          "1613329286995-ccb-840-3.jpg",
          "1613329286999-ccb-840-4.jpg"
        ]
      },
      {
        "sku": "851",
        "description": [
          {
            "id": "ckl5jb1cv09qp0819kyswuzc6",
            "title": "Abaküs Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzes69xo4b220859awf2fxyo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzes69xq4b240859w4l0abwr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzes69xr4b250859wzttj14r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzes69xw4b290859fbg31iym",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzes69xy4b2b08596uqfrqeq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzes69xz4b2c0859zeshd80p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzes69y54b2g0859bycvrloe",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzes69y64b2i08598phvhult",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzes69y84b2j0859iuck1569",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5jbfyd09sb0819ll7h0upu",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6rwvp22xk80897sxn86duc",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5jb1co09qo0819aycj0r59",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619203587672-CCB-851-1.jpg",
          "1613330426044-CCB-851 yan.jpg"
        ]
      },
      {
        "sku": "878",
        "description": [
          {
            "id": "ckl5jcr0s09sy0819nscr77zc",
            "title": "Taşlı Turtle Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyirul81ac60859la3i88mj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyirulb1ac808590vdgd2d0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyirulc1ac9085980ekmnw5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyiruli1acc0859nc4udvjf",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyirulk1ace08599mjjljsd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyirull1acf0859tqpd9izv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyirulr1aci0859zc2ek8h6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyirult1ack0859rp7hwoaw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyirulu1acl0859ke5cijm3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5jd78109ul0819ve8ookvf",
            "price": 117,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s3fd22xkj0897l2ecz0aj",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5jcr0g09sx0819t9f3j83a",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619203836881-ccb-878-L.jpg",
          "1613330519552-ccb-878-2.jpg",
          "1613330519541-ccb-878-3.jpg",
          "1613330519546-ccb-878-4.jpg"
        ]
      },
      {
        "sku": "890",
        "description": [
          {
            "id": "ckl5jeopb09v70819nsctpra3",
            "title": "Midye Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyj57681cn70859ble9l5gh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyj576a1cn90859bo49w7qd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyj576b1cna0859s86uh9ts",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyj576g1cnd08599ky2qlt5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyj576i1cnf0859aoc885b3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyj576j1cng0859prubg3vc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyj576o1cnj08598ri9q7gr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyj576p1cnl0859u2fc0t4h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyj576r1cnm0859naw2z6al",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5jf4rd09wu0819igdaofqp",
            "price": 117,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s4qc42xkt0897k0tdund0",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5jeoou09v60819uitv5wst",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619179639703-CCB-890-1.jpg",
          "1613330606179-CCB-890 yan.jpg"
        ]
      },
      {
        "sku": "904",
        "description": [
          {
            "id": "ckl5jw8ct0a220819ky6j5r67",
            "title": "Roof Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3nn20kkturj0840ogk1tl5h",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3nn20knturl08402kl2zuje",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nn20kqturm0840oalrp60g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nn20l1turq0840nndpd7c6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3nn20l4turs0840llsk51a9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nn20l6turt084019e9uoxz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nn20ldturx0840d8w84ecd",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3nn20lfturz0840folzjouk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nn20lgtus008403lngr0w2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlnbia7svgs0959tbj1kxrd",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlnbtxdsvhh0959hqzzzrb3",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5jw8cf0a210819mjk5fmim",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619187103140-CCB-904-L.jpg"
        ]
      },
      {
        "sku": "905",
        "description": [
          {
            "id": "ckl5jxlev0a490819ekysabrk",
            "title": "İkiz Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xbj2zgzo70840tfudhnjn",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39xbj33gzo90840owg60hqm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xbj35gzoa0840in6hbd7g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xbj3jgzoe0840um012h5n",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xbj3mgzog08404p0d2pyb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xbj3ogzoh0840g00n34b8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xbj3xgzol08403ld66u4s",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xbj40gzon0840t77u031s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xbj41gzoo0840ti7ccziq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5jxyfo0a5y0819jq8gondt",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6r9y332x8y0897ee5wqik1",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5jxleo0a48081984zablzq",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619187402359-CCB-905-L.jpg",
          "1613331494675-CCB-905 yan.jpg"
        ]
      },
      {
        "sku": "651",
        "description": [
          {
            "id": "ckl5k27mm0a700819046garjw",
            "title": "Frape Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqto7d8omz70859b0rqhzso",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqto7daomz90859z0t2s0if",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto7dbomza0859btv8ewwt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqto7dgomzd0859qajtsl3x",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqto7djomzf0859zo4pdxco",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto7dkomzg0859n9u7e2vb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqto7dpomzj0859kbpb15oy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqto7dqomzl0859d5tge78d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto7dromzm0859j1ml55ti",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5k2pqq0a8r0819w8xpoa98",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s4ys42xl90897kivpqsxk",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5k27mc0a6z0819ijtk8vlq",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619204018692-cbm-516-L.jpg",
          "1613331639314-cbm-516-2.jpg"
        ]
      },
      {
        "sku": "585",
        "description": [
          {
            "id": "ckl5k4it90a9i0819fs6sz745",
            "title": "Mineli Sütun Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl546s93qhwp00840a347o4q0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl546s93whwp20840nboaxayq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl546s93zhwp30840qiq6mlfi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl546s94dhwp70840efefkr93",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl546s94ghwp90840yne9z6ji",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl546s94ihwpa0840w02hyen0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl546s94rhwpe08401h6ywxq6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl546s94uhwpg08400kl6t0re",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl546s94xhwph08408o6omtmo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl31bnikls3x40840by02n2w9",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl31boaxus40n0840oh0y4txl",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5k4it30a9h0819zohxroe4",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619204240637-cbm-585-L.jpg",
          "1613331806487-2.jpg"
        ]
      },
      {
        "sku": "586",
        "description": [
          {
            "id": "ckl5k78p00acj0819ib1wn4pr",
            "title": "Mineli Piano Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39x69zpgxvn0840d9cwhcc7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39x69zvgxvp0840640o6hwa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x69zzgxvq0840jtghvkgk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39x6a0dgxvu0840m9svaner",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39x6a0ggxvw0840dero6btv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x6a0igxvx0840prinxmqy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39x6a0vgxw10840vz1ql04v",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39x6a0ygxw308403v3dl5hg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x6a10gxw408403vlzfnuv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5k7pxq0adr0819n9fof5o4",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s5in52xlt0897op8qe2qp",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5k78os0aci0819x499xw2t",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613331929761-3.jpg",
          "1613331929770-2.jpg",
          "1613331929775-1.jpg"
        ]
      },
      {
        "sku": "587",
        "description": [
          {
            "id": "ckl5k8xa90af20819xhbznfpr",
            "title": "Mineli Taç Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1g4y8tqqspc0859pwk3qsa9",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1g4y8tuqspe08595n96tacv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g4y8twqspf08596zyjtjel",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g4y8u4qspj0859w3b3nztd",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1g4y8u6qspl08594lj4hafz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g4y8u7qspm0859do0g7jnq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g4y8udqspq0859t6fz9ir2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1g4y8ufqsps085930n95rev",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g4y8uhqspt0859okh3ho93",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3093cgnmasq0840s3d75kps",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3094r56mbev0840rr3xzp61",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5k8xa10af10819uuhpxr6e",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613332009207-1.jpg",
          "1613332009186-2.jpg"
        ]
      },
      {
        "sku": "588",
        "description": [
          {
            "id": "ckl5ka7u50ah00819d7ob05iy",
            "title": "Mineli Kare Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54ej73ciwfh084051nhmby2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54ej73iiwfj08406qp7xnid",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ej73miwfk0840bhwnpltw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ej73ziwfo08406eea3uva",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54ej742iwfq0840pnxaa7aq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ej744iwfr0840q4m44zdp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ej74diwfv08408gw14ebm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54ej74giwfx0840z6juo3ar",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ej74iiwfy0840am40h6dl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5kap2y0aie081947118lfh",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s5yoz2xmz08971q7wv234",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5ka7tw0agz0819ve06d4st",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613332088371-3.jpg",
          "1613332088386-2.jpg",
          "1613332088391-1.jpg"
        ]
      },
      {
        "sku": "589",
        "description": [
          {
            "id": "ckl5kbsuz0ajf08196sxvmkqw",
            "title": "Mineli Üçgen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqtnw0homdq0859p0wg264u",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqtnw0momds0859ja3boi7h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqtnw0oomdt0859c81l1xmh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqtnw0tomdw0859nonipuj5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqtnw0uomdy0859wj6e6dlp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqtnw0womdz0859q3x3cc96",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqtnw10ome208591b4kvgx6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqtnw12ome40859sa1v3w4q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqtnw13ome50859epur6vut",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5kcd0q0al30819q0hc9mon",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s662x2xn908976swzlmt5",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5kbsut0aje0819e96horgi",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613332146043-2.jpg",
          "1613332146018-1.jpg"
        ]
      },
      {
        "sku": "590",
        "description": [
          {
            "id": "ckl5kddj40alq08191pi2y3i7",
            "title": "Mineli Merdiven Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wd85r16k3008404p5azvcf",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wd85rc6k320840r9m04j2b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wd85rg6k330840qx0mio8c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wd85rt6k370840vj8ml7yx",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5wd85rx6k390840x9fi8g1w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wd85rz6k3a0840qams388k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wd85sa6k3e0840lh92buze",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5wd85sd6k3g0840q7vfx83h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wd85sf6k3h0840fzov6i5d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5kdu3e0an30819hdvpp3mm",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s6exa2xnj0897pf98sw3x",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5kddix0alp0819fbru4ios",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613332230906-2.jpg",
          "1613332230933-1.jpg",
          "1613332230947-3.jpg"
        ]
      },
      {
        "sku": "591",
        "description": [
          {
            "id": "ckl5kilak0api0819uo8gjt2t",
            "title": "Mineli Groovy Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqtnzsyomkk0859e811r8fp",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqtnzt0omkm08590b7i4qvl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqtnzt2omkn0859djk6hx2h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqtnzt6omkq0859hc8thfql",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqtnzt8omks0859o7toozrd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqtnzt9omkt0859uyjwlz36",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqtnzteomkw085998ggl55a",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqtnztfomky0859ytpy1gbw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqtnzthomkz0859lx97gw3v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm6s6ns12xnt0897k1vu81i5",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmjrd39p65fm0897gmz8r8vt",
            "price": 165,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5kila90aph0819unm1qdfy",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613332299415-1.jpg",
          "1613332299405-2.jpg"
        ]
      },
      {
        "sku": "592",
        "description": [
          {
            "id": "ckl5kkqj70asw0819imjjs6sr",
            "title": "Mineli Ters Üçgen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz8i5mf2n0il0859hqqoux3m",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz8i5mf4n0in0859tfbp749o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8i5mf6n0io0859a3fo96gi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz8i5mfbn0is08590e047sx3",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckz8i5mfdn0iu0859k54p30rw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8i5mfen0iv0859v8a1cari",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz8i5mfkn0iz08597855xgk2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckz8i5mfmn0j108593jayw075",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8i5mfnn0j20859zrlttciw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5kl6wq0aua0819hanc8hgx",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s7ndc2xo30897rpkxiaux",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5kkqiy0asv0819qo2tabui",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613332579506-1.jpg",
          "1613332579514-2.jpg"
        ]
      },
      {
        "sku": "593",
        "description": [
          {
            "id": "ckl5kmepp0auv0819o6v5cq3d",
            "title": "Mineli Ok Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56tsqmvnmdh084038rm6zq5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56tsqmznmdj084088hsd2al",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tsqn1nmdk0840ab2es52u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tsqnanmdo0840i7s3n828",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56tsqnbnmdq0840m9f8de6n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tsqnfnmdr08406bf7nwcx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tsqnlnmdv0840inuwabrm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56tsqnnnmdx0840c9nksjmr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tsqnonmdy08405cynmly0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5kmsrv0awa0819p5wq1363",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s7x772xod0897mcgwg8d2",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5kmepe0auu08199gvy192c",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613332655993-1.jpg",
          "1613332655974-2.jpg"
        ]
      },
      {
        "sku": "639",
        "description": [
          {
            "id": "ckl5lezos0bhl08193gnwpuac",
            "title": "Round Up Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqto5lwomv70859o9yk1gc7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqto5lyomv90859cy0yfyk4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto5lzomva0859szsv5off",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqto5m4omvd0859n43v8fxg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqto5m6omvf0859hsro1jwg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto5m7omvg0859y5hfr3q4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqto5mcomvj0859ld874iqy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqto5meomvl08591g83myvy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto5mfomvm0859pp379hra",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5lfjei0bj40819vt00n0wl",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s8pl52xon0897m2a0wcb5",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5lezoi0bhk0819kxxi4agi",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613333897766-cbm-639-1.jpg",
          "1613333897805-cbm-639-2.jpg"
        ]
      },
      {
        "sku": "680",
        "description": [
          {
            "id": "ckl5lhnv50bjw0819x6lln4xx",
            "title": "Gökyüzü Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqto9d6on2r0859y6c4cbsy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqto9d9on2t0859ndbq264t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto9daon2u085999bn5vqf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqto9dfon2x0859jcplny09",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqto9dhon2z0859k9t4iync",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto9dion300859pq1zpysr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqto9dnon3308599350aavt",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqto9dpon350859ehn1fl7y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqto9dqon360859ew4d0h26",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5lieik0bld0819i39j12ff",
            "price": 189,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s8z1g2xox0897kjpodnow",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5lhnut0bjv0819zxh6gh2u",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613334068046-1.jpg",
          "1613334068056-2.jpg"
        ]
      },
      {
        "sku": "681",
        "description": [
          {
            "id": "ckl5ljwii0bly0819v8ux218g",
            "title": "Flair Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl548k78fi2dd0840mz10yrwf",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl548k78mi2df08400o9yb12e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548k78qi2dg08401qlzjts0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl548k795i2dk0840qquy6yzk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl548k799i2dm08406knit5r5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548k79di2dn0840hnqiu6oy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl548k79si2dr0840ffe63bh9",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl548k79xi2dt0840w4w8u95u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548k7a1i2du0840ov4uf4kg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5lly6v0bnk08193jak9sgm",
            "price": 199,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s975x2xp70897uj4ahvdx",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5ljwi60blx0819iidzz9ny",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613334185314-1.jpg",
          "1613334185307-2.jpg"
        ]
      },
      {
        "sku": "682",
        "description": [
          {
            "id": "ckl5lq2jg0bpr08196iwbtlin",
            "title": "Okyanus Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56tqztknm5o0840007ukiqj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56tqztnnm5q08403hjs7r98",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tqztpnm5r0840267imked",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tqztwnm5v0840ig0aozxe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56tqztynm5x0840vcuco9gu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tqztznm5y0840lm4fkhv3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tqzu4nm6208406xc0ifpc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56tqzu6nm640840a7f64rp7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tqzu7nm6508404qpnoudh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5lqod60bra0819cfrx6bxj",
            "price": 199,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s9hya2xph0897z99806zv",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5lq2j30bpq0819tv26g2zs",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613334461627-1.jpg",
          "1613334461632-2.jpg"
        ]
      },
      {
        "sku": "683",
        "description": [
          {
            "id": "ckl5lshtn0bsk0819gttd5e1x",
            "title": "Japon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz5nohto9ekl0859748wbtgw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz5nohtq9ekn0859yvc0cp38",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nohts9eko0859bh7etlgp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5nohtx9eks0859q36ewqri",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckz5nohtz9eku08599g33t192",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nohu19ekv0859wpgh8l2a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5nohu89ekz0859hbgyojkp",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckz5nohua9el1085981gt2bwk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nohub9el20859p57o40tv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5lsxmg0bu50819kceey3i1",
            "price": 189,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6s9qw12xpr08973o61a854",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5lshte0bsj08192jap68rf",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613334584753-1.jpg",
          "1613334584778-2.jpg",
          "1613334584784-3.jpg",
          "1613334584821-4.jpg"
        ]
      },
      {
        "sku": "684",
        "description": [
          {
            "id": "ckl5ludjd0bup0819qn0u1k0j",
            "title": "Çok Renkli Play Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ktkc33axzg0859iasv351v",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1ktkc35axzi0859khir8jng",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktkc36axzj0859y7siu10n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ktkc3caxzn08593h3dpie9",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1ktkc3daxzp08598bzdbc7a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktkc3faxzq0859kyhsurvk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ktkc3laxzu0859aomm6x1j",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1ktkc3maxzw08598s1o5r1g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktkc3paxzx0859sib49gmb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5m0buz0byx0819a0bjbw9a",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6saatb2xq108974fqdkxb1",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5ludj10buo0819xmufkfp0",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613334695156-1.jpg",
          "1613334695188-2.jpg"
        ]
      },
      {
        "sku": "685",
        "description": [
          {
            "id": "ckl5m2ivx0bzq081934dnw14e",
            "title": "Mist Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8vjmp267ikp0840te6jjuym",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl8vjmp2a7ikr0840jvccf6vq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vjmp2c7iks084019spfrs0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8vjmp2l7ikw0840bx7ikcxe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl8vjmp2n7iky0840xn9dz3aw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vjmp2p7ikz0840bzhk5g7a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8vjmp2x7il30840pffngahn",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl8vjmp2z7il50840w53ofree",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vjmp317il60840fitf2c2c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5m31nh0c1c0819chbv6sx3",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6saqb22xqb0897wvnz1626",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5m2ivo0bzp0819aj8deckf",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1652799015143-IMG_4033.jpg",
          "1652799015457-IMG_4034.jpg"
        ]
      },
      {
        "sku": "695",
        "description": [
          {
            "id": "ckl5m4l0p0c2008199p19497v",
            "title": "Panache Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckty6dcd7z5ic08591q00l9w5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckty6dcdaz5ie0859n4a3b295",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty6dcddz5if08598rveo5je",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckty6dcdkz5ii0859ofwwhduw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckty6dcdnz5ik0859vvktljxn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty6dcdpz5il0859p5tu734x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckty6dcdwz5io0859vjot0sir",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckty6dcdyz5iq08599y1p379s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckty6dce0z5ir08591kbudaqy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5m52wo0c3p0819073e1hwe",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sb3102xql0897rlclxfsl",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5m4l0h0c1z0819h4nuy5a0",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613335171145-1.jpg",
          "1613335171150-2.jpg"
        ]
      },
      {
        "sku": "723",
        "description": [
          {
            "id": "ckl5m78vu0c4d0819kpyrr5b9",
            "title": "Mineli Vintage Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54elbgpiwt70840iq3skcbm",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54elbguiwt90840o6vx53ft",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54elbgxiwta0840xwfaxodg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54elbh9iwte0840ryyuisj7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54elbhbiwtg0840egp8ag9m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54elbhdiwth08405apd0v6w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54elbhmiwtl0840tv58ct2c",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54elbhpiwtn0840hwn8oka4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54elbhtiwto0840pupart5l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5m7rvc0c610819frnaj67b",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sbhut2xqv0897zp0n4tth",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5m78vg0c4c0819u0wrz2il",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1647238603548-IMG_0003.png",
          "1613335255039-4.jpg"
        ]
      },
      {
        "sku": "729",
        "description": [
          {
            "id": "ckl5mnm2m0caq0819cjhmwh85",
            "title": "Jealousy Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0q7nwypln2b08594wx2c66e",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0q7nwysln2d0859hu5gfy5t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q7nwytln2e0859ykgwf2al",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0q7nwz0ln2i08596iba35xb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0q7nwz2ln2k0859i9vua8w5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q7nwz3ln2l08591mgniga7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0q7nwz9ln2p0859u1nzln70",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0q7nwzaln2r0859q3490q2x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q7nwzcln2s08599ehdm6wa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5mo3tp0cch0819eengkj7d",
            "price": 200,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sbte12xr50897rlh7utzx",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5mnm2e0cap081932eu6kq2",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1647232432251-CCB-729.png",
          "1647232435077-CCB-729 YAN.jpg"
        ]
      },
      {
        "sku": "730",
        "description": [
          {
            "id": "ckl5mplal0cd30819kkqyvrkn",
            "title": "Cheesecake Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54eah8uiud80840e60tmgvn",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54eah8yiuda0840mcw1k6rp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eah90iudb08404jeu7fhm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54eah9iiudf084045kzg3m7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54eah9miudh08409q4l24t5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eah9piudi0840h77yziqe",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54eaha0iudm08404wftwogc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54eaha2iudo0840ec2ia58l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eaha4iudp0840tph50v0a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5mqn4p0ceu0819c90sit6v",
            "price": 207,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sc7522xrf0897bno87bsp",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5mplad0cd20819zol62v7x",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613336151606-1.jpg",
          "1613336151611-2.jpg",
          "1613336152817-CBM-730 sarı..jpg",
          "1613336152823-CBM-730 sarı.jpg",
          "1613336153343-CBM-730.jpg",
          "1613336153350-CBM-730 yan.jpg"
        ]
      },
      {
        "sku": "731",
        "description": [
          {
            "id": "ckl5mt1mt0cfi08191bmanm69",
            "title": "Misty Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ktmbrqayje08596gk6r5i0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1ktmbrsayjg085998d1h9g5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktmbrtayjh08591cgqtl13",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ktmbrzayjl0859hg4te1uc",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1ktmbs0ayjn085972h41wss",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktmbs1ayjo0859k9ub41bp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ktmbs7ayjs0859crzr2jb0",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1ktmbs9ayju0859u4szksxg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ktmbsaayjv0859fn9i9zrh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5mtjyx0cha0819flpm837u",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6scf3l2xrp0897481e2mya",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5mt1ml0cfh0819ijtod0iu",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646733167563-3.png",
          "1613336309221-2.jpg"
        ]
      },
      {
        "sku": "732",
        "description": [
          {
            "id": "ckl5n0kfj0ci40819442lj0lm",
            "title": "Regatta Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqu48l4oqdj08593pvlpnc6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqu48l7oqdl08597znl18tw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu48l8oqdm0859x5j02p1a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu48ldoqdp0859vinqyp39",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqu48lfoqdr08594dn9gmmg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu48lgoqds0859iqr41bsx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu48lkoqdv0859vhrn9k35",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqu48lmoqdx0859de21egrz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu48lnoqdy0859c3yjecjj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5n15mt0cjd081926nziq4n",
            "price": 200,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6scyln2xrz0897vaqoysnx",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5n0kf40ci30819cb2gcng9",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613336469471-1.jpg",
          "1613336469476-2.jpg"
        ]
      },
      {
        "sku": "764",
        "description": [
          {
            "id": "ckl5n4buo0cls08194xmkreih",
            "title": "Üçgen Taşlı Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0joqlt6y2bp0859pvchmlt7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0joqltby2br085977309ngd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0joqltdy2bs0859j0zslf9j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0joqltjy2bw085947scnkiu",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0joqltky2by0859n5luz4gs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0joqltmy2bz0859ancobxiy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0joqltry2c30859z1lo7j7e",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0joqltsy2c50859vdgrgif7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0joqltty2c6085970v323ov",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl8vrh40i8lnt08402xerr2w2",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8vrhec18low0840wmpkhhsi",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5n4buj0clr0819mls9h249",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613336837228-cbm-764-1.jpg",
          "1613336837265-cbm-764-2.jpg"
        ]
      },
      {
        "sku": "765",
        "description": [
          {
            "id": "ckl5n6fpt0cnp0819wd6bku7o",
            "title": "Wild Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyg70670t3j0859qofjiyhe",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyg706a0t3l0859im8klhbe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyg706b0t3m08590fdz2ysw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyg706i0t3p0859cdndxkr9",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyg706k0t3r0859dp33l68t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyg706m0t3s0859qgckaro2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyg706s0t3v0859byw7rd8a",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyg706v0t3x08597ou71287",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyg706x0t3y08599fw3ue5e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl8vrg6td8lix0840u4rh71s2",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8vrgg9z8llc084051a8mrcv",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5n6fpj0cno0819c340q9b4",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613336944536-1.jpg",
          "1613336944529-2.jpg"
        ]
      },
      {
        "sku": "778",
        "description": [
          {
            "id": "ckl5n8jzg0cpp0819i4irx9va",
            "title": "Spot Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54e072vis1p0840ow5chgw7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54e072yis1r0840roetwll5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e0730is1s0840ztg2zxjk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e073fis1w0840as3injck",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54e073his1y0840h62anpye",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e073jis1z08403s48k1q5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e073pis230840i6yah9gz",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54e073qis250840pecg1p2b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e073sis260840lz9c6v3w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl31fi3ifsqgd0840mkx024ww",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl31fikkssqk908405psz7dj1",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5n8jz90cpo0819wzfnbo55",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613337048446-cbm-778-1.jpg",
          "1613337048465-cbm-778-2.jpg"
        ]
      },
      {
        "sku": "779",
        "description": [
          {
            "id": "ckl5nbmel0crp08190ghumj8g",
            "title": "Be in Luck Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39x39mrgwtl0840cz5p91nd",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39x39mxgwtn0840wkyxm8a3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x39nbgwtq084065fvfba1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39x39ntgwtu0840zuiktcss",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39x39nygwtw0840quojarg7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x39o2gwtx0840opqsmhbu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39x39ovgwu10840xj63lkwu",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39x39pbgwu30840t8ulgo7q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x39pigwu408403m2ep4rh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5nduep0ct60819czkkdb82",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sonit2xu00897bki15ybz",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5nbmee0cro0819uhgu5i68",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613337133274-cbm-779-1.jpg",
          "1613337133297-cbm-779-2.jpg"
        ]
      },
      {
        "sku": "783",
        "description": [
          {
            "id": "ckl5nfa4t0cts0819d8y7ssai",
            "title": "Mineli Dragon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyh4vgo0xuu0859zuqy9314",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyh4vgu0xuw0859zcb1u837",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyh4vgw0xux0859wck7vlgz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyh4vh30xv00859qumv4jwb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyh4vh60xv208597dwtqrug",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyh4vh80xv30859aa7wvbnb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyh4vhe0xv60859w6vyck51",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyh4vhg0xv80859cmvy762m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyh4vhi0xv908598wbo3ukr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5nfs3m0cv708197i77dsk6",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sovu52xua0897fpawpgzp",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5nfa4n0ctr0819aujlts2w",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619204386448-cbm-783-L.jpg",
          "1613337355503-2.jpg"
        ]
      },
      {
        "sku": "784",
        "description": [
          {
            "id": "ckl5nhai20cvu081942jqqiam",
            "title": "Tetris Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54dtpstiqrk0840lpttdiz6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54dtpsxiqrm08402kyrr0nq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dtpt0iqrn0840auucho2p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dtptfiqrr08404gdemiav",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54dtptkiqrt08409ywldawh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dtptoiqru0840min304zh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dtpu6iqry0840t05vtgkz",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54dtpubiqs00840nd6q7vy2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dtpueiqs10840xtjjat5r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5nijin0cx90819wggjczax",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sp3ir2xuk0897n42kdqkl",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5nhahx0cvt0819vvmhjk9g",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613337428420-cbm-784-1.jpg",
          "1613337428443-cbm-784-2.jpg",
          "1646745085147-784-2.png",
          "1646745085438-784.png",
          "1645023931061-784.png"
        ]
      },
      {
        "sku": "786",
        "description": [
          {
            "id": "ckl5nka4l0cxz0819wqvd691v",
            "title": "Mineli Dörtgen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyho5zd11500859apzbwmkf",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyho5zf11520859knaxpnov",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyho5zh115308595xdna5bq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyho5zm11560859vt98m878",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyho5zo11580859v3yrtjl3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyho5zq115908599qrhltl9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyho5zw115c08594uf07y8j",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyho5zy115e0859d4ky9n8q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyho5zz115f0859b8s7zacg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5nn0kb0czk081904rxqp7q",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sperr2xuu08972n3pk1m6",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5nka4f0cxy0819u6o5dxmb",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613337577450-cbm-786-1.jpg",
          "1613337577469-cbm-786-2.jpg"
        ]
      },
      {
        "sku": "788",
        "description": [
          {
            "id": "ckl5nwn570d0n08190x4ittgy",
            "title": "Tetris Taşlı ve Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqu6roaorv60859ijirkhpk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqu6rocorv80859o1317odv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6roeorv90859844ej8ci",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu6roiorvc0859vl4ernmj",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqu6rokorve0859js3gvygt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6rolorvf08596ivgljeq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu6roqorvi0859ydcxjf7t",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqu6rororvk08596qmnk3le",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6rotorvl08593namd9em",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5nx7lj0d2408194cf39bls",
            "price": 200,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6spml32xva0897dm2cgsap",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5nwn4t0d0m08191nob7nyx",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613338156489-cbm-788-1.jpg",
          "1613338156513-cbm-788-2.jpg"
        ]
      },
      {
        "sku": "789",
        "description": [
          {
            "id": "ckl5nyvor0d2q0819s7no6eny",
            "title": "Follow-Up Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqu6ne0orr8085934qwuw5m",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqu6ne2orra0859ryspxkas",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6ne3orrb0859crpz63bk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu6ne8orre0859xjfoff4c",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqu6ne9orrg0859x8vzjj42",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6neaorrh0859p8cdie0n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu6neeorrk0859xn27mria",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqu6negorrm0859ictu6gl0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6nehorrn0859rtvwpdak",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5nznm60d480819dwg9ubn9",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6spw4k2xvk0897rnunh6vk",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5nyvoi0d2p0819lp8r80kb",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613338242070-cbm-789-1.jpg",
          "1613338242102-cbm-789-2.jpg"
        ]
      },
      {
        "sku": "790",
        "description": [
          {
            "id": "ckl5okhpu0d5h0819bz1p3mms",
            "title": "Follow-Up Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqu6kpgornt0859pa5o7hlc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqu6kpiornv0859n2j3bszg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6kpjornw0859y0vu5o8e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu6kpoornz085986yvyv6q",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqu6kpporo10859upme9hpe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6kpqoro20859dvx45ro1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu6kpvoro50859ux2tc6sm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqu6kpworo70859p1qau33z",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu6kpxoro80859a222hhoa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5olyo90d750819y3fppovl",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sq4fb2xvu08971jbkx0yj",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5okhph0d5g0819nfzflkdx",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613339233373-cbm-790-1.jpg",
          "1613339233416-cbm-790-2.jpg"
        ]
      },
      {
        "sku": "791",
        "description": [
          {
            "id": "ckl5outv30d8y0819zd60nfjl",
            "title": "Parça Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzpoudvd1z8d0859xf1v8hvx",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzpoudvh1z8f0859ava2ukla",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpoudvi1z8g085901u4wib2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpoudvs1z8k08597vuez6oe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzpoudvu1z8m0859kwaluebv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpoudvw1z8n0859h4flcgbr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpoudw31z8r0859dw9q1ot7",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzpoudw51z8t0859y1428c3u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpoudw71z8u0859kuo1p2rg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5owiel0daj0819y4mwp0er",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sqa722xw40897bhfgjepn",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5outun0d8x0819eq1nyvvi",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613339723774-1.jpg",
          "1613339723781-2.jpg",
          "1645024036784-791.png"
        ]
      },
      {
        "sku": "792",
        "description": [
          {
            "id": "ckl5oynbh0db40819d437cjqv",
            "title": "Puantiye Mineli Bracelet"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya8791axu610859cjwjt64g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya8791dxu6308595jddidar",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya8791fxu640859vizgfwx2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya8791lxu680859y0p20xcg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya8791nxu6a0859jd6x1l5t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya8791pxu6b0859hcsyc2x4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya8791wxu6f0859bnezq8jr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya8791yxu6h0859j2m92c8e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya8791zxu6i0859676u4joj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5ozm710dcp0819xhgfwmst",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sqh982xwe0897avjrbdic",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5oynb60db30819vgynl0q6",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613339895032-1.jpg",
          "1613339895043-2.jpg",
          "1613339895039-3.jpg",
          "1613339895047-4.jpg"
        ]
      },
      {
        "sku": "795",
        "description": [
          {
            "id": "ckl5p246i0dda0819zzb65x4f",
            "title": "Home Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xej81h1pp0840p2u20xam",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39xej88h1pr08401q3xlpf1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xej8bh1ps084068abcee7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xej8lh1pw08407ns04x5r",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xej8oh1py084019uuj9al",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xej8ph1pz08401asmryx8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xej8vh1q308408n4jxew5",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xej8xh1q508405omidrpe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xej8yh1q608408mtgf46r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5p3f520dew0819gyb51ctw",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sqt6o2xwo0897hznjj1kl",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5p246b0dd90819eestxyj8",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646733590077-3.png",
          "1613340073793-2.jpg"
        ]
      },
      {
        "sku": "816",
        "description": [
          {
            "id": "ckl5p4t180dfg0819xvvfqres",
            "title": "Follow Up Tetris Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1kt18y3asw30859i6u76yxl",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1kt18y9asw50859cr47ykce",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kt18yaasw60859vqh9csaa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kt18ygaswa0859pig1hlht",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1kt18yhaswc0859f6f9zewr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kt18yiaswd08591ugpdrml",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1kt18ynaswh0859rc8bjyuo",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1kt18yoaswj0859tyrho8iu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1kt18ypaswk0859xt4rgq6f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5p5k6k0dh308192h7u1xa1",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6sr92h2xwy08975e3iyb01",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5p4t0z0dff081969lip6y3",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613340206601-cbm-816-1.jpg",
          "1613340206607-cbm-816-2.jpg"
        ]
      },
      {
        "sku": "822",
        "description": [
          {
            "id": "ckl5p6q6n0dhn0819vv1ygyuq",
            "title": "Dragon Simetri Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl548nfedi2zq08402tozp3ey",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl548nfehi2zs0840p79agkzh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548nfeki2zt0840fqscfl1o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl548nfesi2zx08404bpn1mm8",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl548nfeui2zz0840hua0hgcy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548nfevi3000840ebeo8f7h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl548nff1i304084031c6ec05",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl548nff3i3060840ne071vh3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl548nff4i3070840ufw0c9ox",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5p7eek0djb0819zvppuo0a",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6srnel2xx8089728qzixog",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5p6q6f0dhm08195r6net3a",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613340303720-cbm-822-1.jpg",
          "1613340303726-cbm-822-2.jpg"
        ]
      },
      {
        "sku": "824",
        "description": [
          {
            "id": "ckl5p8lmv0djv0819lmnzzka6",
            "title": "Dragon Asimetri Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyivvmy1b540859w40fs5j0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cktyivvn21b560859iaiaqafo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyivvn41b570859cyv7ak3b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyivvnb1b5a08596ccni6t8",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cktyivvne1b5c0859rdg1ziqu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyivvng1b5d0859pmyz68i0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cktyivvnn1b5g08598uw021y3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cktyivvnp1b5i0859hkhhajcf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyivvnr1b5j0859xqjz1imk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5p97fb0dlk0819916ib8q1",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6srunz2xxi0897y4e41nan",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5p8lmp0dju0819hzxua2qu",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613340389210-cbm-824-1.jpg",
          "1613340389203-cbm-824-2.jpg"
        ]
      },
      {
        "sku": "825",
        "description": [
          {
            "id": "ckl5pay3u0dm40819n1ze4fbu",
            "title": "Dragon Asimetri Taşlı ve Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwqu5kbwoqzk0859phjhun90",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwqu5kbzoqzm0859gwt2gw01",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu5kc0oqzn0859od8k0p9q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu5kc5oqzq0859ubyq2b5i",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwqu5kc7oqzs0859n7idv9bp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu5kc9oqzt08597xk8h4ej",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqu5kceoqzw0859zwct4o7h",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwqu5kcfoqzy0859z1hp0v7h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqu5kchoqzz0859ubqpt69d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5pbmip0dnu081950xdohb0",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ss16q2xxs0897tsxghx0j",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5pay3l0dm30819mx26m3jx",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613340481095-cbm-825-1.jpg",
          "1613340481113-cbm-825-4.jpg",
          "1613340481103-cbm-825-2.jpg",
          "1613340481106-cbm-825-3.jpg"
        ]
      },
      {
        "sku": "828",
        "description": [
          {
            "id": "ckl5pdi5c0dog0819xve0ielc",
            "title": "Mercan Kaos Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwquf1wzounb0859uixew538",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwquf1x2ound08590e47k6s8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquf1x3oune08592gucdtic",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwquf1x8ounh08594rfhw3fz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwquf1xaounj0859kntz0q2n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquf1xcounk08598byfxcxr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwquf1xgounn08591c712zob",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwquf1xiounp08599hcermav",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquf1xjounq08596ggy6p5j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5pe95s0dq908196cg56r8n",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ss88q2xy20897sz32lb2c",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5pdi530dof0819nbg6adr5",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613340597261-cbm-828-1.jpg",
          "1613340597250-cbm-828-2.jpg"
        ]
      },
      {
        "sku": "841",
        "description": [
          {
            "id": "ckl5phg0f0dqw0819ior855pm",
            "title": "Köşegen Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1bsrmjs8ykv0859ibhs4ov9",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1bsrmjv8ykx0859ro7tubtz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1bsrmjw8yky0859kxix8nif",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1bsrmk38yl20859w4zr208l",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1bsrmk58yl40859mm7kos2y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1bsrmk68yl50859oa4wxtq7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1bsrmkj8yl90859pgmwx0mw",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1bsrmkl8ylb0859jx7tof5g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1bsrmkm8ylc0859yyere75h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckv9epwl0ay3j08597h8it4ok",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckv9eqnkpayco0859y8ush0hb",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5phg080dqv0819tpvds4fp",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1648537663870-411.png",
          "1613340716633-cbm-841-2.jpg"
        ]
      },
      {
        "sku": "842",
        "description": [
          {
            "id": "ckl5pmlbh0dsp0819soa4tvgc",
            "title": "Dörtgen Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3nmxhzottmq0840pvidnb0n",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3nmxhzsttms08408xqez5c1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmxhzvttmt0840n3hvlde7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmxi09ttmx0840l4agiqqc",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3nmxi0dttmz084032eeybrc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmxi0fttn008401hu9ru1i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmxi0pttn40840p9thmkes",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3nmxi0rttn6084093ni5ukp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmxi0wttn70840z1d8tsr5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5pnjb90dty0819a7s2pzcu",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6szlsg2y500897wos8o6bk",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5pmlba0dso0819i3v8e7y9",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613341052109-cbm-842-1.jpg",
          "1613341052117-cbm-842-2.jpg"
        ]
      },
      {
        "sku": "843",
        "description": [
          {
            "id": "ckl5ppaf40duh08199cil91xn",
            "title": "Dörtgen Üç Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwquevd9ouck0859sp92c4dw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwquevdcoucm085949fclbkl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquevdeoucn0859kformyd2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwquevdkoucq0859tlh88b3r",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwquevdloucs0859ur099btv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquevdnouct0859mrwp7jdy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwquevdroucw0859zfd22ls2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwquevdtoucy0859muw9xpgt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquevduoucz0859rh2cotap",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5pq2hm0dvs0819z30o224r",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t0l552y5a08974xm99vt3",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5ppaes0dug0819o4y5wtbx",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613341143988-cbm-843-1.jpg",
          "1613341143993-cbm-843-2.jpg"
        ]
      },
      {
        "sku": "845",
        "description": [
          {
            "id": "ckl5ps6u30dwb0819rce28yuv",
            "title": "Dörtgen Mix Mineli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwquetq7ou97085941nkumvo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwquetq9ou9908591o69sjm7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquetqbou9a0859lbd1q8w4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwquetqfou9d0859wj0b5l6w",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwquetqhou9f08593i0scjjr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquetqiou9g08594doaw383",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwquetqmou9j0859zmutr3n6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwquetqoou9l08591hq5hfhu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquetqpou9m08592hyo5pvp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5pswmd0dxm0819iyudz0ok",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t0tl32y5k0897349jf7ky",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5ps6tv0dwa0819cnov1oom",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613341311065-cbm-845-1.jpg",
          "1613341311071-cbm-845-2.jpg"
        ]
      },
      {
        "sku": "846",
        "description": [
          {
            "id": "ckl5pu6mx0dy70819jgz7xewj",
            "title": "Dikdörtgen Mineli Ok Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwquere2ou5h0859jrewysv2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckwquere4ou5j0859sgtuzy60",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquere6ou5k08591kvemhk5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwquerecou5n0859o15f9v2u",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckwquereeou5p0859xve3hj9k",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwqueregou5q0859eso5ru3l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckwqueremou5t0859saqlsa07",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckwquerepou5v0859m89ciz0e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwquereqou5w0859vuqb7zko",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlngts8svob0959o78yg6oa",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmb6uvwvo4e09592h75l3p9",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5pu6mr0dy60819ype3d4lz",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613341394892-cbm-846-1.jpg",
          "1613341394899-cbm-846-2.jpg"
        ]
      },
      {
        "sku": "867",
        "description": [
          {
            "id": "ckl5pwx7y0e0z0819jm0juxvp",
            "title": "Yarım Mineli Ritim Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1m0g889fj5l0859v37wggs9",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1m0g88bfj5n0859i1vigsu9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0g88cfj5o0859nno72hu5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m0g88hfj5s0859nt5l53nl",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1m0g88jfj5u0859t4n9g44a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0g88kfj5v08593t53apf4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1m0g88pfj5z0859upv5wya8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1m0g88rfj610859x7de05qs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1m0g88sfj620859n9w1hoai",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5pxez00e2d0819wkwcrkd4",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t22ny2y6408974m4u52py",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5pwx7o0e0y08196hskk5o8",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613341549540-cbm-867-1.jpg",
          "1613341549546-cbm-867-2.jpg"
        ]
      },
      {
        "sku": "868",
        "description": [
          {
            "id": "ckl5pyotb0e2x0819i7vy125w",
            "title": "Mineli Ritim Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya6vdduxfu008593bwbs8g4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya6vddwxfu2085937xut60w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6vddyxfu308599q6x3k27",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6vde5xfu708597aylukqs",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya6vde7xfu90859yvgt48wj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6vde9xfua0859gzhltqst",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6vdefxfue0859cp12uhos",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya6vdehxfug085955dt42id",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6vdeixfuh0859y4v4fbge",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5pzhbh0e4b0819zhtqfxky",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t2b902y6f0897x9vtvvky",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5pyot30e2w0819n83edkte",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613341604443-cbm-868-1.jpg",
          "1613341604449-cbm-868-2.jpg"
        ]
      },
      {
        "sku": "870",
        "description": [
          {
            "id": "ckl5q0o3i0e4w08192bho1c1g",
            "title": "Mineli Sınır Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya6yrb2xh0q08590s4jcblk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya6yrb4xh0s08598g70h13w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6yrb5xh0t0859psggfn7a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6yrbcxh0x085948mw9uyw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya6yrbdxh0z08591xzhu42l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6yrbfxh100859h9o24zrk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6yrblxh140859u4ag4t3t",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya6yrbmxh160859ra1ikfx4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6yrboxh170859hfc4l92o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5q19q60e6d08190kt2d1bu",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t2h0w2y6p08978apv2sx5",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5q0o3b0e4v0819e3xg069r",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613341702105-cbm-870-1.jpg",
          "1613341702098-cbm-870-2.jpg"
        ]
      },
      {
        "sku": "871",
        "description": [
          {
            "id": "ckl5q3rz90e750819vg7ev6i1",
            "title": "Mineli Parantez Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1g6r5d6r0hq0859mzr2cmiy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1g6r5d8r0hs08591cq984tp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g6r5d9r0ht0859slepwnqw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g6r5der0hx0859pk4hlul4",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1g6r5dgr0hz0859y8f6r2vx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g6r5dhr0i00859zn7w9oc7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g6r5dor0i508597uvva0qh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1g6r5dqr0i70859ginze4vu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g6r5drr0i80859cn3cz2z9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5q4g6d0e8l0819nl79nsq2",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t2nw82y6z0897olmszpul",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5q3rz00e740819yw7pi0ay",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1613341808127-cbm-871-1.jpg",
          "1613341808136-cbm-871-2.jpg"
        ]
      },
      {
        "sku": "891",
        "description": [
          {
            "id": "ckl5q5unc0e960819a2lwzlvu",
            "title": "Mineli Örgü Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl53zuhmchg9d0840wien3ntr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl53zuhmkhg9f084033cg3uqg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zuhmohg9g08403emp9oxa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl53zuhn5hg9k08408cmk4neb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl53zuhn9hg9m0840fngjdhqv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zuhnchg9n0840ytfyfytj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl53zuhnnhg9r0840taa6ooir",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl53zuhnqhg9t0840y3clf2uw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zuhnthg9u08400fao2bzy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5q6jh30ean08197m5juxhh",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t2t9i2y790897u7k0803h",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5q5un30e950819d1o17lmk",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619204584225-CBM-891-L.jpg",
          "1613341931297-CBM-891 yan.jpg"
        ]
      },
      {
        "sku": "893",
        "description": [
          {
            "id": "ckl5q84f70eb80819qpyuyq7a",
            "title": "Sarmal Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl13p5cvluiqo08596t5c7nlw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl13p5cvouiqq0859um81dbkf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl13p5cvquiqr0859xq3m8vc9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl13p5cvxuiqv08592yonzsvr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl13p5cvzuiqx0859nla73f2f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl13p5cw0uiqy0859c0sijxqz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl13p5cw6uir208594unn8ajk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl13p5cw8uir40859gk4bslz8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl13p5cw9uir5085979myxb4w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5q8tpn0ecs0819xubdfljq",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t31iw2y7j0897e5pjhyn3",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5q84ex0eb70819kipmkl1j",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619204840240-CBM-893-1.jpg",
          "1613342039315-CBM-893 yan.jpg"
        ]
      },
      {
        "sku": "895",
        "description": [
          {
            "id": "ckl5qae3z0edd0819uwx15u26",
            "title": "Damalı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz5ngo9c9bvt085954tysfwx",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz5ngo9e9bvv0859pfdj1a1l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5ngo9g9bvw0859l3kbxjux",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5ngo9m9bw008590av4ap8g",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckz5ngo9o9bw20859cwchwgf5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5ngo9p9bw30859cxy1kcan",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5ngo9w9bw70859suykao2z",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckz5ngo9x9bw90859ocl9xkwo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5ngo9y9bwa0859bj53rn88",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5qb3ul0eex08195s5nriwm",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t3s3r2y7t08972pcjfcgy",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5qae3r0edc0819vsd8oaoy",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619205118305-CBM-895-1.jpg",
          "1613342154331-CBM-895 yan.jpg"
        ]
      },
      {
        "sku": "894",
        "description": [
          {
            "id": "ckl5qceln0efi081972u3tf6r",
            "title": "Mineli Kelebek Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xika7h3550840lmz292dc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39xikabh3570840oi9st881",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xikaeh3580840mw8n1u79",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xikath35c0840ssthbnrj",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xikavh35e0840tti2dny8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xikaxh35f0840d9clpz2k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xikb8h35j0840vnh1hcpm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xikbbh35l08408up658jl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xikbdh35m0840i5wpidot",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5qdp8y0eh20819prm94yyk",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t4aen2y830897fa9r5g2d",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5qcelf0efh0819i8l1lfqx",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619205346501-CBM-894-1.jpg",
          "1613342256994-CBM-894 yan.jpg",
          "1645024221223-894.png"
        ]
      },
      {
        "sku": "896",
        "description": [
          {
            "id": "ckl5qfgze0ehn0819270npx6u",
            "title": "Sıralı Taşlı Damalı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku3yxa9bgl670859cmaih262",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku3yxa9dgl690859d2l3bxgr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yxa9fgl6a0859dqrzzqja",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku3yxa9mgl6d0859kb6fj139",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku3yxa9ogl6f0859b537b1oa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yxa9qgl6g0859dun56kte",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku3yxa9xgl6j0859noe2yv2z",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku3yxaa0gl6l0859445rlwy7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3yxaa2gl6m0859hy2wbyah",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5qgg450ej90819p0knmwnh",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t4iaw2y8d0897sa4be4vo",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5qfgz50ehm0819ye5ddf5l",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619205538256-CBM-896-1.jpg"
        ]
      },
      {
        "sku": "863",
        "description": [
          {
            "id": "ckl5t0o850en70819we4kxsw0",
            "title": "Mineli Kelepçe Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxaccvdmf36u0859mezp1u3w",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxaccvdof36w0859nngfuqft",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaccvdpf36x0859ele0529x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5t1hzm0eot0819p5oqh6yc",
            "price": 89,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t52gp2y8t0897ykaj5v48",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5t0o7o0en60819b9ij0aej",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1613342578567-cbm-863-1.jpg",
          "1613342578575-cbm-863-2.jpg",
          "1613342578579-cbm-863-3.jpg",
          "1613342578581-cbm-863-4.jpg"
        ]
      },
      {
        "sku": "406",
        "description": [
          {
            "id": "ckl5t41ko0ep90819r0f8v8kx",
            "title": "Kelepçe Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5480m6oi12o0840z9p7lgc3",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl5480m6ti12q0840htgutii4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5480m6wi12r08401polg1si",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5t745y0esa0819yqhfpeis",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t5kch2y9408971uen26ti",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5t41kc0ep80819pn5pd0ga",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1613346864925-ccb-406-1.jpg"
        ]
      },
      {
        "sku": "437",
        "description": [
          {
            "id": "ckl5t65i80eqa0819iw76kwid",
            "title": "Gümüş Düz Çizgi Unisex Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku42jf3th7na0859m9xmuluu",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku42jf3vh7nc0859zidlwk2a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42jf3xh7nd0859n8whohyj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5t78yy0esk0819rxjf8cuu",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t5xyx2y9e08973yxbofcx",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5t65i00eq90819cqgc1x3k",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1613346975548-ccb-437-1.jpg"
        ]
      },
      {
        "sku": "438",
        "description": [
          {
            "id": "ckl5t8cti0et00819nqfqhlhm",
            "title": "Gümüş Noktalı Unisex Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku42ndpnh82f0859e1yqewv3",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku42ndpph82h08599mkb5n2p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42ndprh82i0859xp384tnh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5t927i0eup0819s89mvgzl",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t66712y9o0897sfe1a8rq",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5t8ctd0esz0819o9951g9t",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1613347113136-ccb-438-1.jpg"
        ]
      },
      {
        "sku": "439",
        "description": [
          {
            "id": "ckl5t9wee0ev50819esxnw44p",
            "title": "Gümüş Zigzag Unisex Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku42nvl4h86i085986cigxw1",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku42nvl7h86k08599xhz8rux",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42nvl9h86l08592l14ld8n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5tb69c0eyp0819gh13lrb8",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t6cew2y9y08977bw8cd1a",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5t9we90ev40819038hu159",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1613347238253-ccb-439-1.jpg",
          "1613347238262-ccb-439-2.jpg",
          "1613347238266-ccb-439-3.jpg"
        ]
      },
      {
        "sku": "440",
        "description": [
          {
            "id": "ckl5tc0pi0ez50819s6kncdqe",
            "title": "Gümüş Üçgenli Unisex Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku42odrrh89e0859o6579lot",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku42odrth89g0859ervgzxjs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42odruh89h0859utldqnqn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5tcm6o0f0w0819qgi0lcnu",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t6j862ya80897k9kgnonl",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5tc0pd0ez40819b9pniq72",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1613347289104-ccb-440-1.jpg"
        ]
      },
      {
        "sku": "441",
        "description": [
          {
            "id": "ckl5tdkym0f1c0819mifprvm7",
            "title": "Gümüş Çizgili Unisex Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku42ordch8cq0859rh4ek69x",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku42ordeh8cs0859zfgeidpy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42ordgh8ct08593iegp1jv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl5tef3m0f2k0819mwoozw17",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t6pz82yai0897w7ijrpsv",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5tdkyd0f1b0819xtipv059",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1613347357567-ccb-441-1.jpg",
          "1613347357592-ccb-441-2.jpg",
          "1613347357575-ccb-441-3.jpg"
        ]
      },
      {
        "sku": "687",
        "description": [
          {
            "id": "ckl5tfwd10f31081997jduvmk",
            "title": "Line Kelepçe Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz8itbt8n6kz0859j1kswoao",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckz8itbtan6l1085913eax1s9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8itbtbn6l20859d9kynuwe",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlnhvwmsvq40959kx1enrgi",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlni5ejsvqt0959teelg1hj",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl5tfwcu0f30081932c0chub",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1613347451899-ccb-687-1.jpg",
          "1613347451916-ccb-687-3.jpg",
          "1613347451912-ccb-687-2.jpg"
        ]
      },
      {
        "sku": "451",
        "description": [
          {
            "id": "ckl64txxa0foa0819wyfz4lfi",
            "title": "Pul Pul Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56gwhwlmf7e0840tibx9vcb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56gwhwpmf7g0840uq1ol3rs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56gwhwtmf7h0840z6nir7zn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmjqhgtwve609591vpwj15g",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmjr60qwvib0959oilqm0us",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl64txwu0fo90819jz4nq8wf",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1656922502068-CNS-451.jpg",
          "1613366150957-ccn-451.jpg",
          "1613366150965-ccn-451-1.jpg"
        ]
      },
      {
        "sku": "396",
        "description": [
          {
            "id": "ckl64zcuk0fqc0819ysx72t2e",
            "title": "Özgür Ruh Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54f8u7nj1cu0840whf8vy2o",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54f8u7rj1cw0840klrun3kd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8u7uj1cx0840pr7fpi2i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54f8u87j1d108406nztdvxn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54f8u89j1d30840ilqjnije",
                "price": 24,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8u8bj1d408406339tq5p",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54f8u8kj1d80840x1g3d31v",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54f8u8mj1da0840qu71hwze",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8u8oj1db08402oxafhao",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54f8u8zj1df0840huep7a6b",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54f8u91j1dh0840gcdxli9b",
                "price": 8,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8u95j1di0840yt2nmqwk",
                "price": 3,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54f8u9kj1dm0840cu5bhdtg",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl54f8u9pj1do08407216tclr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8u9rj1dp0840649lpjnx",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl64zxrk0frn0819yhtl8uxc",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6t8ocu2yby0897qq2mo73u",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl64zcuc0fqb081948gu7mts",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1640088155296-IMG_3083.jpg",
          "1640088164180-IMG_3084.jpg",
          "1613366756212-ccn-396-1.jpg"
        ]
      },
      {
        "sku": "398",
        "description": [
          {
            "id": "ckl65424d0ft40819842v6zn9",
            "title": "Elektrik Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3wuu4uzahr8084063agvu89",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3wuu4v4ahra0840473kbdpi",
                "price": 20,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wuu4v6ahrb0840yv7hpdzt",
                "price": 7,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3wuu4vhahrf0840p56tv7yo",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3wuu4vkahrh08408zefnn0v",
                "price": 56,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wuu4vmahri0840s6c5uyt1",
                "price": 20,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3wuu4vuahrm0840d1dftfda",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl3wuu4vwahro0840zad8kdt6",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wuu4vyahrp0840pe2ur735",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3wuu4w4ahrt0840779m5c32",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl3wuu4w6ahrv0840pi4cbod5",
                "price": 34,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wuu4w7ahrw08400ybyi1ap",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3wuu4weahs00840zwymhlk8",
            "size": {
              "id": "ckr29cpxvakbh09594nr6tkqz",
              "sku": "XXL"
            },
            "prices": [
              {
                "id": "cl3wuu4wgahs208405ijs67ac",
                "price": 90,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wuu4wiahs30840f92wfwym",
                "price": 32,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3wuu4wsahs70840nrlqz0v6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3wuu4wuahs908400z2ktx4c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wuu4wwahsa0840bgsetgag",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nhye0go2o40859jvibinzm",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nhylhwo2px0859qyuw8as9",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65423t0ft30819mxldnwak",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613367045065-ccn-398-1.jpg",
          "1613367045057-ccn-398-2.jpg"
        ]
      },
      {
        "sku": "400",
        "description": [
          {
            "id": "ckl65d6pd0fx00819jqtwxjpq",
            "title": "Badem Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya6woolxgau0859tbbddqib",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya6woonxgaw0859s98mwc74",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6woooxgax0859wy55rc55",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6woouxgb10859kr31bhbt",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya6woowxgb30859ifh6apl2",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6woozxgb50859k7jjcstn",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6wopmxgb90859wlmztyeo",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckya6woppxgbb0859tmjse0ln",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6woprxgbc0859m2wzo5nq",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6wopxxgbg0859a03b95uh",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckya6wopyxgbi0859j9t5g7gk",
                "price": 64,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6woq0xgbj08597fq4fn30",
                "price": 23,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1niwiw7oa890859hih74ehi",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nixh3foafv0859clr74dnz",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65d6p30fwz0819ysigv0z2",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613367462227-ccn-400-1.jpg",
          "1633291209594-10.png"
        ]
      },
      {
        "sku": "402",
        "description": [
          {
            "id": "ckl65ffa50fz10819excykxsz",
            "title": "Jungle Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i7bmzdsjgv0859sdbafwrj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i7bmzfsjgx0859jhfxc8p2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i7bmzisjgy085968af6fxh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i7bmzosjh2085925x12z15",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0i7bmzpsjh408591n8hjmjb",
                "price": 26,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i7bmzrsjh50859mxi0o089",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i7bmzwsjh90859z6aprrth",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0i7bmzysjhb0859bzcxuy3v",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i7bmzzsjhc0859fk5ps29w",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i7bn04sjhg08594nly8c6i",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0i7bn06sjhi08597gt6fd6r",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i7bn07sjhj0859869xmda6",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl65fwq80g0i08197d641jhz",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6vypvu2ygr0897isbm9ev9",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65ff9z0fz00819doesx0ub",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613367564509-ccn-402-1.jpg",
          "1613367564530-ccn-402-2.jpg"
        ]
      },
      {
        "sku": "405",
        "description": [
          {
            "id": "ckl65h3ll0g120819xysgu2jo",
            "title": "Pul Pul Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0s62vhhub5v0859cm4xjpl4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0s62vhmub5x0859c04dpjfa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0s62vhnub5y0859faqgtq6p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0s62vhuub62085967dw86uz",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0s62vhwub6408597ln559i6",
                "price": 12,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0s62vhyub650859r35qt0t8",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmk8i52wxza0959shghy4ix",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmk9vlawybb095902p07g91",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65h3lb0g1108194yebi0f4",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613367680413-ccn-405-1.jpg"
        ]
      },
      {
        "sku": "409",
        "description": [
          {
            "id": "ckl65ipgc0g3308197qp0vsvv",
            "title": "Pipe Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl84klc8j5jo008408zq92bz4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl84klc8n5jo208408ynyr0hb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84klc8q5jo30840fblyfyql",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl84klc905jo70840agqdrahk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl84klc935jo90840t5ualqrk",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84klc955joa0840phbfb61b",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl84klc9f5joe0840wfchvhdf",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl84klc9k5jog0840i8884llu",
                "price": 39,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84klc9m5joh0840kgrkmb14",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl84klc9y5jol0840qgazrqtm",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl84klca05jon0840z0gmdvzy",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84klca25joo0840031myh5d",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl84klca95jos0840y3kpjcm5",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl84klcab5jou0840k5k07mt7",
                "price": 34,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84klcac5jov0840a2j3q8jw",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl84klcah5joz0840pdcr28t1",
            "size": {
              "id": "ckr29cpxvakbh09594nr6tkqz",
              "sku": "XXL"
            },
            "prices": [
              {
                "id": "cl84klcaj5jp10840z78jezpa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84klcak5jp20840khl1xrqq",
                "price": 30,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmkq89fx1i80959v1f05hui",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmkr3nwx1q00959osbovfcn",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65ipg50g320819y9j0losv",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "5582507-1656922069761-img-5089.webp",
          "1613367750378-ccn-409-1.jpg"
        ]
      },
      {
        "sku": "410",
        "description": [
          {
            "id": "ckl65ki330g560819f5s1d5ej",
            "title": "Taş Detaylı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56gpscumefk0840v5pjue5l",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl56gpscymefm08407j8yn6gk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56gpsd1mefn0840o3dsxxy5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56gpsdimefr0840aqlymwqx",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56gpsdnmeft0840s106ulr3",
                "price": 20,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56gpsdrmefu08402fdfzqk0",
                "price": 7,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56gpse4mefy0840qotzkxr9",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl56gpse7meg008405ntmpwyy",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56gpse9meg10840k5oh1urv",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56gpselmeg508400amsqnu3",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl56gpseomeg708400yt9tlab",
                "price": 12,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56gpsermeg80840b87aotxz",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nfl0bxnkkj0859f3we6scy",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nflsh6nkp70859dw03ply1",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65ki2v0g540819uobse0dr",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1656922204892-IMG_5091.jpg",
          "1613367828089-ccn-410-1.jpg"
        ]
      },
      {
        "sku": "420",
        "description": [
          {
            "id": "ckl65m74w0g790819o1gxrrxh",
            "title": "Art Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5etumul6g00859owbqlw2m",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5etumxl6g20859l0x5786x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5etun0l6g30859pvjxfj2t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl31abrnfrvo308402a767pna",
            "price": 78,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl31acthrrvrt08405ewwqtzc",
            "price": 218,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65m74l0g780819kwji3jrk",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613367895336-ccn-420-1.jpg"
        ]
      },
      {
        "sku": "422",
        "description": [
          {
            "id": "ckl65ntoe0g9c0819cae6ct6t",
            "title": "Kontrast Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j2jed0h0cc0840f2udi3d6",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7j2jed7h0ce084051e2bm7v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2jedah0cf084080jhnm1z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmkwfsjx2vt0959u1wfo8pb",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmkwruyx2xz0959meip1t74",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65nto70g9b0819lzz5x4sr",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613367984425-ccn-422-1.jpg"
        ]
      },
      {
        "sku": "467",
        "description": [
          {
            "id": "ckl65pc8m0gbf0819m4p8dqwi",
            "title": "Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77qwxrgjg1f0840sjzbi2tv",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77qwxrljg1h0840gch96o88",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77qwxrnjg1i0840xv09dfn2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77qwxrxjg1m08406c5sji3r",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77qwxs1jg1o0840fnr6kygw",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77qwxs4jg1p0840bcnozg0a",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77qwxsfjg1t0840llp3r2zs",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77qwxshjg1v0840ztbssujf",
                "price": 92,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77qwxsjjg1w0840plmrrczl",
                "price": 33,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77qwxssjg200840gapzrciv",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl77qwxsvjg22084076w76p9z",
                "price": 109,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77qwxswjg230840h7cw0y2o",
                "price": 39,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77qwxt4jg27084066qddxwh",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl77qwxt6jg290840f1awwu7p",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77qwxt7jg2a0840hwyofd01",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77qwxtejg2e0840z4t9lp4n",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl77qwxtgjg2g0840pvm6ml9o",
                "price": 64,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77qwxthjg2h0840e7jetnyf",
                "price": 23,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl65ps3k0gd00819h0sksuvt",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6w7a4e2yif089739b653j4",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65pc8f0gbe08192xfctshc",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613368067773-ccn-467-1.jpg",
          "1613368067760-ccn-467-2.jpg",
          "1633291232910-11.png",
          "1613368067769-ccn-467-3.jpg"
        ]
      },
      {
        "sku": "470",
        "description": [
          {
            "id": "ckl65qssm0gdj0819m3ckdea7",
            "title": "Mix Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3bebkulon2a08404k15sizw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3bebkuton2c0840w209tglm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3bebkuwon2d0840xl19fjg7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3bebkv9on2h0840pfuz7ftd",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3bebkvdon2j0840aqns1kuj",
                "price": 26,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3bebkvfon2k0840zg1duec8",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3bebkvvon2o0840jxmyvdmn",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3bebkvzon2q084054det7y8",
                "price": 64,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3bebkw1on2r0840n6ww1a3z",
                "price": 23,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3bebkwdon2v0840mabv6f8w",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl3bebkwgon2x0840cg3gxl1x",
                "price": 6,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3bebkwjon2y0840g4ole6xc",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3bebkwvon320840nt7ca33p",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl3bebkwyon340840v81q4s9j",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3bebkx1on350840354jvl75",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3bebkxhon3a0840f2pkddvb",
            "size": {
              "id": "ckr3n5p6o5afp085987g3w3l8",
              "sku": "XXXL"
            },
            "prices": [
              {
                "id": "cl3bebkxkon3c0840g9grwudk",
                "price": 280,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3bebkxmon3d0840jxq73oxm",
                "price": 100,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nld7gnoub60859qnr6higx",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nle0yooufm0859ybft9wwo",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65qssa0gdi0819tdtwtj70",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613368135935-ccn-470-1.jpg"
        ]
      },
      {
        "sku": "494",
        "description": [
          {
            "id": "ckl65sk0s0gfm08191uubzouc",
            "title": "Kleopatra Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz5nfgbi9bbq0859kd1pou46",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz5nfgbk9bbs0859rstr6dvz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nfgbl9bbt0859c0wfyxb4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5nfgbr9bbx0859waquugkq",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckz5nfgbs9bbz0859m4yl7klz",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nfgbu9bc008597jukofpa",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nfsdlgnlyk0859ud6as16t",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nfsoq2nm0r0859poko3v0d",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65sk0g0gfl0819q58m8giu",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613368196534-ccn-494-1.jpg",
          "1633291250965-12.png"
        ]
      },
      {
        "sku": "496",
        "description": [
          {
            "id": "ckl65v6s50ghr0819vvosiy3r",
            "title": "Ataç Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxadolxifawu0859rmal4xqw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxadolxlfaww08597oz0gw6z",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxadolxmfawx0859swxmi8jd",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxadolxtfax10859kx5fe7x9",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckxadolxvfax30859chif1r56",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxadolxwfax40859jyee23nd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nhmr3to0nx0859gp65r9kj",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nhn4bxo0qa0859518hmb2c",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65v6rz0ghq0819iotd7zuv",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613368326512-ccn-496-1.jpg"
        ]
      },
      {
        "sku": "503",
        "description": [
          {
            "id": "ckl65yh8b0gk20819s55nnplu",
            "title": "Mix Kleopatra Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1xh3oppo8lh0840hmfaol14",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1xh3opwo8lj08404mir90iy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1xh3opzo8lk0840tbscwu2q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl65z0cb0glr0819wzzngjsk",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm9g8s3538qb0897pe2t9snq",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl65yh830gk10819texfadz2",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613368500803-ccn-503-1.jpg",
          "1649848312776-39.png"
        ]
      },
      {
        "sku": "507",
        "description": [
          {
            "id": "ckl66170i0gpg08195rt7wj75",
            "title": "Sonsuzluk Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1arnxf962zl085974u3199d",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1arnxfd62zn0859g11o6v4h",
                "price": 8,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arnxfe62zo0859e6b3vvls",
                "price": 3,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1arnxfk62zs0859z68as1sp",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1arnxfn62zu0859mtrub4n6",
                "price": 33,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arnxfo62zv0859zfx8cwed",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1arnxft62zz08595vgdsmma",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl1arnxfv63010859kud6m2yc",
                "price": 100,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arnxfw63020859ows091wr",
                "price": 36,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1arnxg2630608591mitevna",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl1arnxg7630808595o49h71w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arnxg863090859qttsmp46",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1arnxge630d0859k3nj3m1b",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl1arnxgf630f0859cv8qtfxh",
                "price": 16,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arnxgh630g0859hhitxs7e",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1arnxgm630k0859abkwog5w",
            "size": {
              "id": "ckr29cpxvakbh09594nr6tkqz",
              "sku": "XXL"
            },
            "prices": [
              {
                "id": "cl1arnxgn630m0859lrcuiwv8",
                "price": 117,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arnxgo630n085995vwqwpa",
                "price": 42,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1arnxgv630r0859lb3pu3c0",
            "size": {
              "id": "ckr3n5p6o5afp085987g3w3l8",
              "sku": "XXXL"
            },
            "prices": [
              {
                "id": "cl1arnxgx630t0859lqb8l5p2",
                "price": 131,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arnxgy630u0859k9qtht4x",
                "price": 47,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nihv7ao67j0859723hlz3p",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nii536o6a50859srsgtq2e",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66170a0gpf0819o4ctv41l",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613368613243-ccn-507-1.jpg",
          "1633291722608-13.png"
        ]
      },
      {
        "sku": "510",
        "description": [
          {
            "id": "ckl662ogt0gro0819udai805z",
            "title": "Damla Parçalı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5eyfnsl77t0859qxspxu7x",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5eyfnvl77v0859g85ol2rz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5eyfnxl77w0859aixn88h0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmpm222xmev0959ylu5uahx",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmpml0wxmg60959qq4pm6ud",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl662ogm0grn0819a0gojn10",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613368688750-ccn-510.jpg"
        ]
      },
      {
        "sku": "520",
        "description": [
          {
            "id": "ckl664g8p0gty08199km08z4g",
            "title": "Burgulu İçten Geçmeli Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0axiiuuzrnn0859ckq60kkp",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0axiiuzzrnp08591rjk6sip",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axiiv0zrnq0859ng7n930j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axiiv7zrnu0859dxuz7cw7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0axiiv9zrnw0859bs13g9hc",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axiivazrnx0859oldcxbyg",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axiivhzro108592puswald",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0axiivjzro30859hp40cb4s",
                "price": 84,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axiivkzro40859ib46fuo3",
                "price": 30,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axiivqzro80859ay4aqbhf",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl0axiivszroa0859jr9z69e6",
                "price": 101,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axiivuzrob0859p8wv3w3t",
                "price": 36,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axiiw0zrof0859vhsbcvtf",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0axiiw2zroh0859dpkq6gy7",
                "price": 6,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axiiw3zroi0859byx18lkd",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axiiw9zrom0859eqffr4cr",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl0axiiwbzroo08592grs470d",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axiiwczrop0859m8wz8fgz",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axiiwizrot0859tg8nxbvj",
            "size": {
              "id": "ckr29cpxvakbh09594nr6tkqz",
              "sku": "XXL"
            },
            "prices": [
              {
                "id": "cl0axiiwkzrov0859hvsrht5g",
                "price": 115,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axiiwlzrow08598gb4k5us",
                "price": 41,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axiiwrzrp00859aiw620p2",
            "size": {
              "id": "ckr3n5p6o5afp085987g3w3l8",
              "sku": "XXXL"
            },
            "prices": [
              {
                "id": "cl0axiiwtzrp208594e2oboxc",
                "price": 135,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axiiwuzrp30859qi6clcdg",
                "price": 48,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nljiuwovkj0859cmyx84gx",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nllkmiow0708599mwivnbl",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl664g8k0gtx081956u41zjt",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631517091674-IMG_4818.jpg",
          "1631517092234-IMG_4819.jpg",
          "1613368768178-ccn-520-1.jpg"
        ]
      },
      {
        "sku": "521",
        "description": [
          {
            "id": "ckl6667v90gvr081971g0t41p",
            "title": "Arma Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0axkebszskl0859h35gwhc0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0axkebwzskn0859hy06qx03",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axkebxzsko0859q8yi2q0c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axkecezskt08593r942f9a",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0axkechzskv0859i0cwum0p",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axkeckzskw0859dreundpg",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axked0zsl10859723hnq5c",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0axked4zsl408595so44afr",
                "price": 84,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axkedazsl50859s0jh4jk2",
                "price": 30,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axkedozsla08596puvo1qs",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl0axkedszslc0859e8d4jujc",
                "price": 101,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axkedvzsld0859zlkhp5at",
                "price": 36,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axkeeazslh0859dkffplxu",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0axkeedzslj085970e17hob",
                "price": 6,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axkeefzslk08597eaciog1",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axkeelzslo08596c9z65q6",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl0axkeemzslq0859pa37q9jr",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axkeenzslr085942anfqsr",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axkeetzslv0859vqg1dqez",
            "size": {
              "id": "ckr29cpxvakbh09594nr6tkqz",
              "sku": "XXL"
            },
            "prices": [
              {
                "id": "cl0axkeeuzslx085937w51tl5",
                "price": 115,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axkeewzsly0859il3ptmca",
                "price": 41,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0axkef2zsm208598jik7pyv",
            "size": {
              "id": "ckr3n5p6o5afp085987g3w3l8",
              "sku": "XXXL"
            },
            "prices": [
              {
                "id": "cl0axkef3zsm40859palo4d98",
                "price": 135,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0axkef5zsm5085934ds6ac5",
                "price": 48,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nmblhmozhb08595hm0hfzm",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nmbuxnoziq085901i075nj",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6667uw0gvq0819o0iakre3",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613368846569-CCN-521.jpg",
          "1613368846576-CCN-521 yan.jpg"
        ]
      },
      {
        "sku": "522",
        "description": [
          {
            "id": "ckl66bppw0gxp0819fqan5jfy",
            "title": "Unisex Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wdkrbx6mq508401115n9g5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wdkrc26mq70840cl28hqv4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdkrc46mq80840zj5twfej",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdkrcd6mqc0840n7bx50ol",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5wdkrcf6mqe08408nrn8jp4",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdkrch6mqf0840y0mmzqvm",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdkrco6mqj0840f2wd3zyk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5wdkrcq6mql0840och9qf9s",
                "price": 84,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdkrcs6mqm08404ovhkkex",
                "price": 30,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdkrd26mqq0840ks28fkp6",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl5wdkrd56mqs0840o720hjm0",
                "price": 101,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdkrd86mqt0840jkywbw2k",
                "price": 36,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdkrdj6mqx08400ar4ir8v",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl5wdkrdm6mqz0840959nsrhb",
                "price": 6,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdkrdp6mr00840m6fihcqz",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdkrdx6mr40840h8ss0k9s",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl5wdkrdz6mr608406xq3o3nj",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdkre06mr70840nqfb0cq8",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdkre66mrb0840js7g1uz6",
            "size": {
              "id": "ckr29cpxvakbh09594nr6tkqz",
              "sku": "XXL"
            },
            "prices": [
              {
                "id": "cl5wdkre86mrd0840jfhb5ija",
                "price": 115,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdkre96mre0840alf2rucc",
                "price": 41,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nmjts7p0xh0859bj5v58vv",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nmjwtyp0yt0859yjq35yfy",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66bppm0gxo08196b3gevrm",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631516984509-IMG_4832.jpg",
          "1631516994246-IMG_4833.jpg",
          "1613369111260-ccn-522-1.jpg"
        ]
      },
      {
        "sku": "532",
        "description": [
          {
            "id": "ckl66e5z80gzk0819vh15g4ni",
            "title": "Yeni Badem Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl124je7bowmf0859krz8nq9g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl124je7fowmh0859gsv5tkrm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl124je7howmi0859ga6mw21t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl124je7oowmm0859tgz6j2nl",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl124je7powmo0859mqbjmdux",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl124je7qowmp0859diu6nry7",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl124je7wowmt0859wi2axggj",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl124je7yowmv0859qu8013c5",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl124je7zowmw0859mrmuqh2s",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl124je84own008591laqd07i",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl124je86own208592xpn6g7r",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl124je87own308599k03zlrl",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl66epo80h0w0819f2zk4fo6",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6wcr602ys60897j2q4yj0g",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66e5z10gzj0819tkoewrf5",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613369206203-ccn-532-1.jpg",
          "1647952746866-1.png"
        ]
      },
      {
        "sku": "555",
        "description": [
          {
            "id": "ckl66hjsu0h1g0819w4ewcs2g",
            "title": "Linkage Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1xebr59nqa708405866c18h",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1xebr5hnqa90840chfduzwm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1xebr5jnqaa08401zcxna1w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1xebr5unqae0840lwjafzx3",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1xebr5xnqag0840fe772rgd",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1xebr5ynqah0840h8dc9dng",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1xebr64nqal08401hlykfg4",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl1xebr66nqan0840x83dpb6o",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1xebr67nqao08408g8xp47w",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1xebr6fnqas084023mfsc6p",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl1xebr6hnqau084061u3urjn",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1xebr6jnqav08401l0uz4dn",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1xebr6qnqaz0840buhuk5is",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1xebr6snqb10840yjnmkd4r",
                "price": 56,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1xebr6tnqb20840tyzbux2z",
                "price": 20,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nnypvmp9zl08597lfh1bvw",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nnyxjrpa1e0859ckrpda1z",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66hjsn0h1f08194u5xwjti",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613369346350-ccn-555-1.jpg",
          "1613369346356-ccn-555-2.jpg"
        ]
      },
      {
        "sku": "611",
        "description": [
          {
            "id": "ckl66obvy0h3i0819x99866zz",
            "title": "Daire Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl32v9uxo04c20840b6kdb406",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl32v9uxx04c40840if9i1lr4",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl32v9uy204c50840l2tygqjg",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl32v9uyi04c90840l5gn18il",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl32v9uyl04cb0840ism6exhx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl32v9uyn04cc0840he7nnygx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmpr0wexmuk0959g1o0sk09",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmprgslxmvj0959ssyy51gg",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66obvo0h3h08195w9vjmqx",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613369676686-ccn-611-1.jpg",
          "1613369676693-ccn-611-2.jpg"
        ]
      },
      {
        "sku": "615",
        "description": [
          {
            "id": "ckl66rj4b0h5q08199dy81irc",
            "title": "Yaz Kolyesi"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0thnugl05pa0859pd38kgng",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0thnugn05pc0859uu5kk2rl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0thnugp05pd0859xwcyll5f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0thnugv05ph0859irrff2ik",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0thnugx05pj08595kj9ihgt",
                "price": 8,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0thnugy05pk0859vje8yyxs",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nns9nvp8jo0859p1etiteo",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nnscbtp8kg0859y0xy2zlf",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66rj3y0h5p08194rnfru0x",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1613369792584-ccn-615-1.jpg"
        ]
      },
      {
        "sku": "620",
        "description": [
          {
            "id": "ckl66thg30h9908190tm78sae",
            "title": " Damla Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54f3a8yj0dl0840uj9obsmr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54f3a9cj0dn0840cfxmq8n3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f3a9gj0do0840odx2ed8t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nnwn27p9jc0859ozyop4aw",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nnwqzip9ko0859gpr8shtx",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66thfw0h98081925a9ppm5",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1613369930400-ccn-620-1.jpg",
          "1613369930406-ccn-620-2.jpg"
        ]
      },
      {
        "sku": "623",
        "description": [
          {
            "id": "ckl66w0ad0hbj0819z9p1ibek",
            "title": "Renkli Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i0dt5kq1al08591coelysy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i0dt5nq1an0859lt3r989g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0dt5oq1ao08596myh6ymg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1lzkdi8fbxj0859o9qtybcu",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1lzkqfpfbzk0859hnr670hj",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66w0a20hbi0819h06ma7ka",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1613370058726-ccn-623-1.jpg"
        ]
      },
      {
        "sku": "625",
        "description": [
          {
            "id": "ckl66y93b0hf40819649l9pmi",
            "title": "Plaj Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1q56smz0p1x07598hlruqoy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1q56sn40p1z0759hl7638au",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1q56sn60p2007599ovsdtpb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1q56sne0p240759m4p2o8nw",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl1q56snh0p260759ghmla0vy",
                "price": 8,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1q56snj0p270759g8yyownm",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1lznrrcfchp0859q0qkfj45",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1lznygjfciv0859aacbv1yq",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl66y9330hf30819j82kkiw5",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1613370164644-ccn-625-1.jpg"
        ]
      },
      {
        "sku": "626",
        "description": [
          {
            "id": "ckl670u9s0hh50819krjluyo4",
            "title": "Çizgili Badem Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckyo4qcbfyz3e0859kwnro2z7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckyo4qcbhyz3g0859em0lzc9s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4qcbjyz3h0859y0m9ci94",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4qcbpyz3l0859l0xxx5z5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckyo4qcbqyz3n0859npv3ggnf",
                "price": 19,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4qcbryz3o08595oizr5wx",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4qcbyyz3s0859rbdfty3i",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckyo4qcc0yz3u0859n9c5pu66",
                "price": 10,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4qcc1yz3v08591ati3fmo",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4qcc7yz3z0859gzetcc09",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckyo4qcc9yz41085956jwkw22",
                "price": 39,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4qccayz420859fboodug9",
                "price": 23,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1ngw3uknuso08590l4orjca",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1ngwgstnuuw0859hujph6wf",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl670u9i0hh40819opikj678",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613370237525-ccn-626-1.jpg",
          "1613370237506-ccn-626-2.jpg"
        ]
      },
      {
        "sku": "627",
        "description": [
          {
            "id": "ckl673x6x0hj90819zx9498lh",
            "title": "50ler Kolyesi"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54ftpntj4gq0840vh0eu0u7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54ftpnxj4gs0840bf2zxr86",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ftpo0j4gt0840u4w51njb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ftpojj4gx08407wzgrmjg",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54ftponj4gz0840c2qxvcnw",
                "price": 8,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ftpoqj4h00840s6djseqr",
                "price": 3,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl678k2c0hmp08195lugkiye",
            "price": 247,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6wedl02yug0897izcm09yb",
            "price": 88,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl673x6p0hj80819evqbv3xd",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613370410996-ccn-627-1.jpg"
        ]
      },
      {
        "sku": "633",
        "description": [
          {
            "id": "ckl6778me0hkl0819b1zfa24e",
            "title": "Antik Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcompwcxy970859cde5rae0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzcompwfxy990859x5139y9j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcompwgxy9a0859j4vh86w6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmpyv7jxnq20959efskdsms",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmpz1a7xnqh0959y93c0zld",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6778m80hkk0819ehkvft0j",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613370481455-ccn-633-1.jpg",
          "1633291342392-15.png"
        ]
      },
      {
        "sku": "636",
        "description": [
          {
            "id": "ckl67caso0hnf08190jtd1176",
            "title": "Top Mineli Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4e1z1rv9n1q0840plcfcb3r",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl4e1z1s09n1s0840yzc5fnmp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4e1z1s29n1t084068hqecqz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4e1z1sk9n1y08402uh2aiyy",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl4e1z1sm9n200840xzyjbzlu",
                "price": 16,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4e1z1so9n2108409apo3e24",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrho567yczpd0859kb0ubjdj",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrho6yppczxz0859bi572xi2",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67casg0hne0819a163pygr",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1613370692143-ccn-636-1.jpg"
        ]
      },
      {
        "sku": "640",
        "description": [
          {
            "id": "ckl67hmxd0htr0819e38p9quw",
            "title": "Zincir Hikayesi"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i1uyjhqdzh0859k0tea90c",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i1uyjjqdzj085925z6r66v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i1uyjkqdzk0859fh6ohsz8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i1uyjqqdzo0859nc769ayw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0i1uyjsqdzq0859e1covent",
                "price": 35,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i1uyjtqdzr0859akiip6bj",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i1uyk4qdzx085912bab6xa",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0i1uyk6qdzz0859jrcjh8gb",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i1uyk7qe00085908hwq8pv",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl67i2cl0hve0819gje69bs8",
            "price": 155,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6wezpk2yva0897va8e9ptl",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67hmx70htq0819jspx34e1",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1613371062892-ccn-640-1.jpg",
          "1613371062897-ccn-640-2.jpg"
        ]
      },
      {
        "sku": "641",
        "description": [
          {
            "id": "ckl67m13g0hvy0819w903raw4",
            "title": "Art Work Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcomy3txyc108599ft8pca8",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzcomy3vxyc30859dvzlpzvo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcomy3wxyc40859e6y1am0f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl67mn0a0hxm0819gvx8roui",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ydqet2ywl0897qmkws4fy",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67m1380hvx0819it6a3tbd",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613371256086-ccn-641-1.jpg"
        ]
      },
      {
        "sku": "652",
        "description": [
          {
            "id": "ckl67nnua0hy40819xajraa9j",
            "title": "Yarı Değerli Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcon56nxyej0859zirbjrw7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzcon56qxyel0859jyc41dfr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcon56rxyem0859dz3fcazu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl67o3pc0hzs0819shbi8i0g",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ydxsi2ywv0897q1nzt50r",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67nnu30hy30819c0mjddgl",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613371349255-ccn-652-1.jpg"
        ]
      },
      {
        "sku": "653",
        "description": [
          {
            "id": "ckl67p48p0i0b08190crmn1hg",
            "title": "Yarı Değerli Taşlı Detaylı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzconbvvxyh60859yo3uunv4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzconbvxxyh80859pb0h6es5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzconbvzxyh90859a2euc6g0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl67pldu0i200819ti9u45n4",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ye3jo2yx50897dt3g0u47",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67p48i0i0a08190utlgk7t",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613371415561-ccn-653-1.jpg"
        ]
      },
      {
        "sku": "655",
        "description": [
          {
            "id": "ckl67qt1k0i2i0819crsbn700",
            "title": "Kalın Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77n5keviyub08406wrzd78b",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77n5kf0iyud0840gw8a633l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77n5kf2iyue0840jshbj3hn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77n5kfbiyui0840d77v7sge",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl77n5kfeiyuk0840zoohp5zz",
                "price": 15,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77n5kfgiyul08404i5rzubz",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl67rbg10i4808197bjesfwe",
            "price": 247,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6ye9f62yxf0897ie6d0zhg",
            "price": 88,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67qt1c0i2h0819yxdmnyg5",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613371493235-ccn-655-1.jpg"
        ]
      },
      {
        "sku": "656",
        "description": [
          {
            "id": "ckl67s6z10i4r0819xafy5yn9",
            "title": "Bomba Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0thkm6803z6085996cn3rtg",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0thkm6a03z80859wl27i3cw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0thkm6b03z90859mzv1me4w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0thkm6h03zd0859k5v5e1ub",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0thkm6i03zf0859eph7i8vr",
                "price": 26,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0thkm6k03zg0859a7iq31xb",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0thkm6r03zk0859e70jwooa",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0thkm6t03zm0859w9r3p8t2",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0thkm6u03zn0859uvxiaucq",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0thkm6z03zr0859w6xiz5y5",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl0thkm7103zt0859tu94pmkg",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0thkm7203zu0859zmcjf33f",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl67sn070i6j0819ap379ksw",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6yeh562yxp0897kn4lcma2",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67s6yu0i4q08191hd1xkc2",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613371558295-ccn-656-1.jpg"
        ]
      },
      {
        "sku": "659",
        "description": [
          {
            "id": "ckl67tyt70i740819p71jp1hr",
            "title": "Turkuaz Nokta Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54fp3c7j3ys0840n2m25n5d",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54fp3cbj3yu08400v7lftka",
                "price": 26,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fp3cdj3yv08405hrb1jx6",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54fp3cnj3yz08400qvlazg0",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54fp3cpj3z10840t5vlf1oh",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fp3cqj3z20840ddgjjsu9",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54fp3cxj3z60840ejuv23jn",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54fp3czj3z808409ad4ihyp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fp3d1j3z90840f1uj8xcg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54fp3d9j3zd0840cf4mglsz",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl54fp3daj3zf0840nrcegley",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fp3dcj3zg0840w9cdtrp3",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl67udf70i8c0819ejq0esdl",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6yeown2yxz08973864w6jf",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67tysz0i73081923f393ms",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1613371628931-ccn-659-1.jpg"
        ]
      },
      {
        "sku": "664",
        "description": [
          {
            "id": "ckl67wmgn0i910819rg5sio16",
            "title": "Akış Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7hmnkr7d137084073c0gycx",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7hmnkrdd13908403rmb4di6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7hmnkrfd13a08406yaackq5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7hmnkrnd13e0840oq5ezpqg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7hmnkrpd13g0840t40qankg",
                "price": 26,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7hmnkrrd13h084010gkx6mg",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7hmnkrxd13l0840o2mgx2v0",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7hmnkrzd13n0840jz8zbvww",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7hmnks0d13o0840x9apzdi9",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7hmnks9d13s0840s8adokoj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7hmnksbd13u08408c4jcw07",
                "price": 142,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7hmnksdd13v0840aba15hxj",
                "price": 51,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7hmnkskd13z0840tz4bqbob",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7hmnksmd14108409oe0n8bu",
                "price": 165,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7hmnkspd1420840hrbh97m6",
                "price": 59,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl67x6n00iab0819athantsu",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6yeybg2yyw08974l4oehtb",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl67wmgf0i9008197tz6gica",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613371714135-ccn-664-1.jpg"
        ]
      },
      {
        "sku": "666",
        "description": [
          {
            "id": "ckl69ujul0ifz081916n569lr",
            "title": "Ok Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5fovxnlahe0859eq7hqhkz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5fovxrlahg08592q5na04y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5fovxulahh0859kmtmbpz5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl69uxeg0ih908193gx4vqz8",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6yf6p92yz60897r71poxdy",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl69uju60ify0819llzaqb3o",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613375008133-1.jpg"
        ]
      },
      {
        "sku": "678",
        "description": [
          {
            "id": "ckl69wx7v0ihr0819wc8hlxmw",
            "title": "Damla Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl793zzpco0d908404092le72",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl793zzpko0db0840stzgyxvn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl793zzpoo0dc08401qr60ehg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl793zzq2o0dg0840tnkfj24j",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl793zzq5o0di0840baei6y38",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl793zzqco0dj0840rr8h35kh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmngi81xdx00959v2fdqm5d",
            "price": 88,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmngu9hxdzk0959dbro3bpc",
            "price": 246,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl69wx7j0ihq08191g5nukt7",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613375107178-ccn-678-1.jpg",
          "1613375107186-ccn-678-2.jpg"
        ]
      },
      {
        "sku": "688",
        "description": [
          {
            "id": "ckl6aanhz0ilg08195hw3b7on",
            "title": "Unchain My Heart Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl83785q81r1w0840dvvtdff7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl83785qf1r1y08400fa02x2z",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl83785qh1r1z0840pbyuvdr8",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl83785qs1r2308400hy4xg9p",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl83785qu1r250840vcvs5z3a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl83785qw1r260840ks6k6ge4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl83785r31r2a0840av448vr9",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl83785r51r2c0840xe1o4ouh",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl83785r61r2d08406r9bj8ai",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl83785rd1r2h0840dfjxnk6f",
            "size": {
              "id": "ckr3n5p6o5afp085987g3w3l8",
              "sku": "XXXL"
            },
            "prices": [
              {
                "id": "cl83785rf1r2j0840759wsdw9",
                "price": 280,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl83785rg1r2k08409lfcve6e",
                "price": 100,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl83785rm1r2o08401z4dsima",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl83785ro1r2q0840vh0itelr",
                "price": 165,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl83785rq1r2r0840wndpno87",
                "price": 59,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6ac9mj0imu08198wepkhav",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6yfk6l2yzq0897f4xio4vg",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6aanhl0ilf081922eflbqp",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613375770248-ccn-688-1.jpg",
          "1613375770255-ccn-688-2.jpg"
        ]
      },
      {
        "sku": "692",
        "description": [
          {
            "id": "ckl6agnzh0ind08194fh5r214",
            "title": "Sonsuzluk Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5qmx4zwtf4308406i26ta1b",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5qmx504tf45084018e9ctaj",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5qmx508tf460840dvzlau59",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5qmx50mtf4a08409op2ydft",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5qmx50qtf4c0840s7sm1abl",
                "price": 64,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5qmx50ttf4d0840w3qohpnz",
                "price": 23,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5qmx514tf4h0840kxn79erv",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl5qmx517tf4j08402ajiqf8o",
                "price": 8,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5qmx519tf4k0840ugzs7ra0",
                "price": 3,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5qmx51htf4o0840pwz2z6tb",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl5qmx51jtf4q0840ffi0gcrb",
                "price": 47,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5qmx51ltf4r0840v1bl7zj5",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5qmx51stf4v0840x97g4da3",
            "size": {
              "id": "ckr29cpxvakbh09594nr6tkqz",
              "sku": "XXL"
            },
            "prices": [
              {
                "id": "cl5qmx51vtf4x0840r0yul0o5",
                "price": 98,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5qmx51wtf4y08402rd3k3nd",
                "price": 35,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5qmx525tf520840nxnolduj",
            "size": {
              "id": "ckr3n5p6o5afp085987g3w3l8",
              "sku": "XXXL"
            },
            "prices": [
              {
                "id": "cl5qmx527tf540840t4myykbe",
                "price": 280,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5qmx528tf550840muhqfmwy",
                "price": 100,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5qmx52etf590840ywjrhmt6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5qmx52gtf5b08404hnji6yf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5qmx52htf5c0840o4on2ihx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6ahksy0iq90819nc68yu8m",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6yfx882z000897i1i21k92",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6agnz70inc0819nab9753r",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613376096777-ccn-692-1.jpg",
          "1613376096768-ccn-692-2.jpg"
        ]
      },
      {
        "sku": "693",
        "description": [
          {
            "id": "ckl6alb6t0ird08192usrj4yy",
            "title": "Dantel Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl2elgf73wivf0840jcidlw5h",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl2elgf78wivh0840u79t8j91",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2elgf7awivi0840edyf0ejr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2elgf7mwivm08404216jrzf",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl2elgf7qwivo0840ocm7z56z",
                "price": 26,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2elgf7twivp0840ahu9idah",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2elgf88wivt08404hzqibnk",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl2elgf8cwivv0840rskjg0mz",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2elgf8ewivw0840ytm5o2s4",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2elgf8pwiw00840pjqf310c",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl2elgf8swiw20840lx6bdbgk",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2elgf8vwiw308400fbdsqu8",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2elgf97wiw708408djkvwwy",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl2elgf99wiw908400zl1p2lv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2elgf9bwiwa08405ez89f76",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6am27f0isu0819hjanxld4",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6yznss2z0n08974ktumkfh",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6alb6h0irc0819smsy6d8m",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613376223675-ccn-693-1.jpg",
          "1613376223680-ccn-693-2.jpg"
        ]
      },
      {
        "sku": "694",
        "description": [
          {
            "id": "ckl6b77360iua0819i8c48l3o",
            "title": "Querida Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j2ktnlh0pb0840vmutjpu5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j2ktnrh0pd0840rkrv5hrn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ktnvh0pe0840xcygdwxb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j2ktobh0pi0840h9mdfr4q",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j2ktoeh0pk0840xqx2at5v",
                "price": 22,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ktohh0pl084031gh2n7n",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j2ktorh0pp0840z08hq2ut",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j2ktouh0pr0840c6py43jm",
                "price": 50,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ktoxh0ps08409qfw28yp",
                "price": 18,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j2ktp9h0pw0840quw0xwk1",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7j2ktpch0py0840uhv16kb7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ktpeh0pz08400w8vl4xm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j2ktplh0q308406gtmzcm9",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7j2ktpnh0q50840xl6spxc8",
                "price": 11,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ktpoh0q6084067ffvnlh",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j2ktpvh0qa08406bnb6wak",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7j2ktpxh0qc08407l6kfpkv",
                "price": 34,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ktpyh0qd0840de5li2qp",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6b7nxe0ivp0819meokewr0",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6yzu332z0x0897qa7xpqk7",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6b772o0iu90819uj1bfhay",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613377348066-ccn-694-1.jpg",
          "1613377348096-ccn-694-2.jpg"
        ]
      },
      {
        "sku": "709",
        "description": [
          {
            "id": "ckl6b90ry0ixj0819eozw0xyl",
            "title": "Mini Düz Bomba Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl84kkp4u5jdk084059n27q0l",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl84kkp525jdm0840893d5cpw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84kkp555jdn0840vc4xve4n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl84kkp5g5jdr0840mkbgp4ie",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl84kkp5i5jdt0840b5az1goe",
                "price": 26,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84kkp5k5jdu0840cfk80obq",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl84kkp5r5jdy0840ktrinm44",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl84kkp5t5je00840gomo341y",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84kkp5u5je108402ddkhqqg",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl84kkp605je50840c6uddu00",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl84kkp625je708407jz042qu",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl84kkp635je808401hglfzqr",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6b9rh40iyz0819b4zo15g4",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6z03k92z170897ql3ox3dv",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6b90rr0ixi08190net11qz",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613377378307-ccn-709-1.jpg"
        ]
      },
      {
        "sku": "713",
        "description": [
          {
            "id": "ckl6big8i0j0k08195se6692i",
            "title": "Candelabra Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5fwet6lc4n0859er7y4cyl",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5fwetblc4p08594xha9eku",
                "price": 12,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5fwetdlc4q08599t8jw167",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5fwetmlc4t0859yuwtk8hu",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cku5fwetplc4v0859k8ldqkgg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5fwetrlc4w0859rf1fgivo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5fweu0lc4z0859tx7axc5g",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cku5fweu3lc510859zcgmb8fh",
                "price": 30,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5fweu5lc520859res78pma",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmre3l8xqx90959i79s5fa5",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmrehfcxqxo0959j15ewjdv",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6big850j0j08199oqstk1z",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613377489774-ccn-713-1.jpg",
          "1613377489783-ccn-713-2.jpg"
        ]
      },
      {
        "sku": "726",
        "description": [
          {
            "id": "ckl6bof0d0j3e0819n189mdv7",
            "title": "Yoğun Halka Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56tuu52nmmv084071nv15qg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl56tuu55nmmx0840q609yj1y",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tuu56nmmy0840n6ms740l",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tuu5bnmn20840hhlhejxt",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl56tuu5dnmn40840shqtwajr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tuu5enmn508408gwo4bx8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl56tuu5jnmn9084047ol5a6y",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl56tuu5lnmnb08403kuvefyb",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56tuu5mnmnc0840gpvac5kh",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6bow540j4w0819okir6cw4",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6z0hq02z1r08973kfltfhd",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6bof080j3d0819cgrcyuvp",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613378109662-ccn-726-1.jpg"
        ]
      },
      {
        "sku": "736",
        "description": [
          {
            "id": "ckl6breey0j5i08192aqlwy41",
            "title": "Bağ Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya6wqg3xgfy0859wf143m0u",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya6wqg5xgg00859pjsznemu",
                "price": 6,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6wqg7xgg108594otb8h3i",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6wqgdxgg50859dczyiep9",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya6wqgexgg70859bb0pvrqu",
                "price": 84,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6wqggxgg80859yuvem05e",
                "price": 30,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6wqglxggc0859cfkk9hok",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckya6wqgnxgge0859dc3ua7bs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6wqgoxggf0859tg3h88op",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya6wqguxggj0859pci8ei76",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckya6wqgvxggl0859wb943qfv",
                "price": 40,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya6wqgxxggm08592zafiqfq",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1no0vwbpajw0859ke2n4467",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1no0yqipakx0859x8h0b9b3",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6breem0j5h0819jpc30dro",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613378222251-ccn-736-1.jpg",
          "1613378222241-ccn-736-2.jpg",
          "1613378222286-ccn-736-3.jpg"
        ]
      },
      {
        "sku": "762",
        "description": [
          {
            "id": "ckl6hkul20k880819zyaj4b4f",
            "title": "Karışık Damla Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i0ekemq1lu0859psy2ujik",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i0ekeoq1lw0859jpseogdj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0ekepq1lx085939u4tt96",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmo3k1axgfc0959mtso41qs",
            "price": 99,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmo3qxqxgg809597irg2t5p",
            "price": 277,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6hkukn0k870819ttbi6hnq",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1613387896748-ccn-762-1.jpg",
          "1613387896773-ccn-762-2.jpg"
        ]
      },
      {
        "sku": "777",
        "description": [
          {
            "id": "ckl6hobgp0kae0819uyf638lj",
            "title": "Flair Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i08natpzob08594ds3kx3g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i08nawpzod08593tbqqw9x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i08naxpzoe0859m386k2pa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6hpd4r0kc108190dm2apvq",
            "price": 434,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6z3w402z2l08976xqnamqg",
            "price": 155,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6hobge0kad0819kjoyqtj9",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1613388153842-ccn-777-1.jpg",
          "1613388153849-ccn-777-2.jpg"
        ]
      },
      {
        "sku": "897",
        "description": [
          {
            "id": "ckl6htbff0kdb0819kq4j22ox",
            "title": "Sonsuz Şans Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzr3l4cx6fqh0859cy6lpmbe",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzr3l4cz6fqj0859qo3exsa2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3l4d06fqk0859u36pb1ez",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzr3l4d66fqo08596wp3jjyn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzr3l4d76fqq0859s3gpr5la",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3l4d96fqr0859klkudkoe",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzr3l4de6fqv0859n6a97kl1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzr3l4df6fqx0859p5fvdf0k",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3l4dh6fqy0859gz9gzazr",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzr3l4dm6fr20859c94qpxyv",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckzr3l4do6fr40859jryky8cp",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3l4dp6fr50859ilx2l7fm",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzr3l4dv6fr90859ewhv4twn",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckzr3l4dw6frb08597q6edy5v",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzr3l4dy6frc0859mfkmgyo7",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky6gdgfyoc9j0859d1xbrazi",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky6gfa19occc0859c3xnodha",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6htbf60kda08194dqugfji",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613388331001-CCN-897-M.jpg",
          "1613388331112-CCN-897-M yan.jpg"
        ]
      },
      {
        "sku": "901",
        "description": [
          {
            "id": "ckl6hw5170kfw0819eugl7nhw",
            "title": "Silindir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxaftz2tfnwt08597rffdqr0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxaftz2wfnwv0859vqu8ox7c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaftz2yfnww0859ksogzvkc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxaftz33fnx00859w223n2he",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxaftz35fnx20859g9qhvjat",
                "price": 33,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaftz36fnx30859dsxw9kli",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxaftz3bfnx70859eluavchh",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckxaftz3dfnx90859g71zz57v",
                "price": 16,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaftz3efnxa085967aneovn",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxaftz3jfnxe0859xxuqu1qm",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckxaftz3lfnxg085924jhel3b",
                "price": 44,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaftz3mfnxh0859y7hcwfxx",
                "price": 16,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nn238yp45x0859p5m4n1p5",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nn26vnp4700859uti3ijxj",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6hw50z0kfv0819flmcnfar",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613388477232-CCN-901.jpg",
          "1613388477240-CCN-901 yan.jpg"
        ]
      },
      {
        "sku": "902",
        "description": [
          {
            "id": "ckl6hz2vd0ki60819xkb2bs8d",
            "title": "Tutku Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wdkaz36mkk08407cqcy1vg",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wdkaz76mkm084090xn4br6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdkaz96mkn08408v1n0fqa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1noe2fvpdow0859utam1dah",
            "price": 106,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1noe5rtpdpg0859lqoy6gcl",
            "price": 297,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6hz2v60ki50819ih5ugg7c",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613388638310-CCN-902.jpg",
          "1613388638405-CCN-902 yan.jpg"
        ]
      },
      {
        "sku": "903",
        "description": [
          {
            "id": "ckl6i1ik70kkl0819e1605kao",
            "title": "Açılı Silindir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxafv4rgfo6j0859i3d0bsjh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxafv4rifo6l0859k1wpfagn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxafv4rkfo6m0859wskkj69g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxafv4rqfo6q0859qlymdpc7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxafv4rrfo6s0859tmkb9sus",
                "price": 33,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxafv4rsfo6t0859hmac2mrg",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxafv4ryfo6x085909b1vmzh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxafv4s0fo6z0859samctkvn",
                "price": 72,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxafv4s1fo700859tq1m9ix6",
                "price": 26,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxafv4s6fo7408596edi2ql9",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckxafv4s8fo760859bl2qf5fr",
                "price": 16,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxafv4s9fo770859tg05ms4e",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxafv4sffo7b085924wndbe2",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckxafv4shfo7d0859xog8k88d",
                "price": 44,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxafv4sifo7e0859le96oz9z",
                "price": 16,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nmtrc2p2yb0859y2web7a9",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nmu91zp31y085970y4rxje",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6i1ijw0kkk081988v2b8ez",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613388783790-CCN-903.jpg",
          "1613388783770-CCN-903 yan.jpg"
        ]
      },
      {
        "sku": "908",
        "description": [
          {
            "id": "ckl6i3whm0kmu08196mce3jjb",
            "title": "Duru Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckyo4f3duyxmq08595l0zr134",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckyo4f3dwyxms0859j415nj9p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4f3dyyxmt0859q90ua9ez",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4f3e4yxmx08598ns6fw66",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckyo4f3e5yxmz0859fcdqvvud",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4f3e6yxn008594vhfrnu1",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4f3edyxn40859h7q2fxo1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckyo4f3eeyxn608594ceqvhz5",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4f3egyxn70859e90oss86",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4f3elyxnb085996rflvpk",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckyo4f3enyxnd0859nsuuswr2",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4f3eoyxne085978s4emzu",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4f3euyxni0859kfsvn0hn",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckyo4f3ewyxnk0859m4b30awy",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4f3exyxnl0859n7ey8d5v",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky6hgznuoe8q08598rbfsy8w",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky6hh603oe920859zfhdz5wo",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6i3whe0kmt081924tpfwzj",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613388879236-CCN-908.jpg",
          "1613388879230-CCN-908 yan.jpg"
        ]
      },
      {
        "sku": "800",
        "description": [
          {
            "id": "ckl6i7wb50kpe08195an3pe4n",
            "title": "Aralıklı Dokulu Bomba Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3spv7u13ipc0840thog3nrc",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3spv7u63ipe0840kj0mshgm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3spv7u93ipf0840ask65ik8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3spv7un3ipj0840ct5zpp5n",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3spv7ur3ipl08404hru2fqa",
                "price": 22,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3spv7uu3ipm0840uu6r7qxx",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3spv7v43ipq0840o9zp2nbi",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl3spv7v73ips0840l3k9mjkl",
                "price": 10,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3spv7v93ipt08406nr43rdj",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3spv7vj3ipx08404qiucc6y",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl3spv7vl3ipz08400klq5qs6",
                "price": 30,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3spv7vn3iq00840hba9i7ts",
                "price": 11,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6i8eu70kr60819ekoj3osq",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm6z6jsv2z4a0897hk32nir4",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6i7wax0kpd0819pw15zdyr",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1613388966975-ccn-800-1.jpg",
          "1613388966981-ccn-800-2.jpg",
          "1613388967018-ccn-800-3.jpg"
        ]
      },
      {
        "sku": "801",
        "description": [
          {
            "id": "ckl6ib25t0krt0819tk1t5q49",
            "title": "Dokulu Bomba Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxagabscfqh00859b3zrpl4d",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxagabsefqh20859k7k83tsy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagabsffqh30859c8hz6mcu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagabslfqh70859f2pjjr6x",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxagabsmfqh90859hr0vppl4",
                "price": 56,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagabsnfqha08590r34n3c4",
                "price": 20,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagabssfqhe0859yj6as107",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckxagabsufqhg0859fmairnuj",
                "price": 20,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagabsvfqhh0859iqlufhip",
                "price": 7,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmoc7f2xh5q09591pczhi3s",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmocm72xh7t0959uys0p7k9",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6ib25l0krs08195rn81e49",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613389160168-ccn-801-1.jpg",
          "1613389160176-ccn-801-2.jpg"
        ]
      },
      {
        "sku": "802",
        "description": [
          {
            "id": "ckl6io0p60ku40819uxqe58ed",
            "title": "Pürüzsüz Bomba Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i0hourq2ds0859qmp2b9zk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i0houuq2du08590ill5tca",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0houwq2dv08592y8rwrtv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i0hov3q2dz0859xvu1v1md",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0i0hov5q2e10859xs2a9dx2",
                "price": 56,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0hov7q2e20859la934zov",
                "price": 20,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i0hoveq2e60859uur35w3b",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0i0hovgq2e80859ygr6h8bw",
                "price": 20,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0hoviq2e908596gc6kkhl",
                "price": 7,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmoeobqxhi1095993qyvwcl",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmoez2exhjm09597ll60e9y",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6io0ou0ku30819o9w3tgyq",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1613389773340-ccn-802-3.jpg",
          "1613389773350-ccn-802-4.jpg",
          "1613389773313-ccn-802-1.jpg",
          "1613389773346-ccn-802-2.jpg"
        ]
      },
      {
        "sku": "814",
        "description": [
          {
            "id": "ckl6itiie0kvx0819kfzubz9f",
            "title": "Mineli Akış Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54ftemhj4db08407ks829ap",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54ftemkj4dd0840uzstec8y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ftemmj4de0840300u15gt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ftemwj4di0840h1r84ywm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54ften0j4dk0840iuz5n75s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ften3j4dl0840vog01e6f",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ftengj4dp0840968rzqy6",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54ftenkj4dr08405fxfww2p",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ftenmj4ds0840862pyyso",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6itwpc0kx70819fr3qzie1",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7z25ky30o70897ivvjd7lv",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6itii10kvw0819fbg4xo6g",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1613389986852-ccn-814-1.jpg",
          "1613389986859-ccn-814-2.jpg"
        ]
      },
      {
        "sku": "849",
        "description": [
          {
            "id": "ckl6ixqt60kzd0819on13l30g",
            "title": "Ateş Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcooavjxysp08594g8q5l6b",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzcooavlxysr0859105rh8eq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcooavnxyss0859sg9n9xc1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm7z2ghw30oh0897ajdjq29k",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmood9wxioj09596ml531n2",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6ixqsx0kzc0819sn5iwxhj",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613390235375-ccn-849-1.jpg",
          "1613390235382-ccn-849-2.jpg",
          "1613390235385-ccn-849-3.jpg",
          "1613390235390-ccn-849-4.jpg"
        ]
      },
      {
        "sku": "850",
        "description": [
          {
            "id": "ckl6j6cn00l2g0819ry3s0br8",
            "title": "Taşlı Disk Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5g9haalf7w0859bvpe4qx1",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5g9haclf7y0859zxvpk5fq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5g9haelf7z0859t4zcfs0m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6j7rh80l3t0819a7xrtd5j",
            "price": 173,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7z5cli30or08974d3eecdh",
            "price": 62,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6j6cmp0l2f0819wrlx4vj8",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613390674369-CCN-850.jpg",
          "1613390674375-CCN-850 yan.jpg"
        ]
      },
      {
        "sku": "854",
        "description": [
          {
            "id": "ckl6jisq20l6n0819s39nigwt",
            "title": "Hayal Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxagihnifrjb0859wpl9uivb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxagihnlfrjd0859ruoc3ixa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagihnmfrje0859cnjxxsbn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagihnsfrji0859185mbeqf",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckxagihnufrjk085979ubth55",
                "price": 34,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagihnvfrjl0859rtddpfeo",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl20azls33pqu0840jhnkxjsi",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl20azuac3psc0840l9b4ffiv",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6jispq0l6m0819cqtye3vx",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613391112422-ccn-854-1.jpg",
          "1613391112438-ccn-854-4.jpg",
          "1613391112429-ccn-854-3.jpg",
          "1613391112432-ccn-854-2.jpg"
        ]
      },
      {
        "sku": "819",
        "description": [
          {
            "id": "ckl6jsln80l8y0819hbd3tbzp",
            "title": "Kurşun Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxagk9eufru90859l7c7isr2",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxagk9ewfrub0859sr37mb6r",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagk9eyfruc0859lvz4hru4",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagk9f5frug0859e5xkcxr8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxagk9f7frui0859cwx3pts1",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagk9f9fruj0859x7yzsno3",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagk9fgfrun0859zegqpi0u",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "ckxagk9fhfrup0859kq1df7ol",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagk9fjfruq0859e02av3aj",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagk9fofruu0859jjlbgnew",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckxagk9fpfruw08595kg5sg3v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagk9fqfrux085999gich1e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagk9fvfrv10859diueghua",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckxagk9fxfrv30859nvvvcs38",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagk9fyfrv40859hvig4sa5",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmormsaxj2f0959mjc6f6wu",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmorwajxj3z0959zw9tvyau",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6jsln10l8x08197qkfyxxv",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613391684137-ccp-819-1.jpg",
          "1613391684113-ccp-819-2.jpg"
        ]
      },
      {
        "sku": "448",
        "description": [
          {
            "id": "ckl6jwsbg0lb20819wa32s0bv",
            "title": "Çakıl Taşı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckyo86mclzh1408592udhuui6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckyo86mcnzh160859e77329c0",
                "price": 10,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo86mcozh170859owi6tjl2",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo86mcuzh1b0859rammt3rq",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckyo86mcwzh1d0859b7uix0v5",
                "price": 33,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo86mcxzh1e0859if6acrk7",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo86md2zh1i0859gn6qap2x",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "ckyo86md4zh1k085902moxxb6",
                "price": 44,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo86md5zh1l0859k3lk43hx",
                "price": 16,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo86mdazh1p085953gbry8a",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckyo86mdbzh1r0859bmigls5k",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo86mdczh1s0859w5hr3eee",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo86mdhzh1w0859hlwe24py",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckyo86mdjzh1y0859lak1toqz",
                "price": 20,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo86mdkzh1z0859dhi9ljby",
                "price": 7,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6jxepy0lch08192g06m8cx",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7z6owv30pl0897xrfzfyrb",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6jwsb80lb108197e759tsn",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613391898726-ccn-448-1.jpg",
          "1613391898753-ccn-448-2.jpg"
        ]
      },
      {
        "sku": "450",
        "description": [
          {
            "id": "ckl6jz15y0ld108195au0y7sb",
            "title": "Zincir Akışı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckyo7j81kze9c0859v8fqrqpa",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckyo7j81mze9e0859v0z87dsd",
                "price": 10,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo7j81oze9f0859ui7xoa8q",
                "price": 3,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo7j81tze9j0859a0c2f7pb",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckyo7j81vze9l0859huajzmt9",
                "price": 30,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo7j81wze9m0859av0zjwc4",
                "price": 11,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo7j821ze9q0859kblgk7cp",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckyo7j822ze9s08598tswjfn0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo7j823ze9t08590l84xbxl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo7j829ze9x08595e5ba7hj",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckyo7j82aze9z0859ddo3hi9c",
                "price": 20,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo7j82czea008598yolyhav",
                "price": 7,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6jzkps0leh0819ytwvyshh",
            "price": 180,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7z6xzn30pv0897uqsw1hv1",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6jz15t0ld00819it8lkywp",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613392005775-ccn-450-1.jpg",
          "1613392005784-ccn-450-2.jpg"
        ]
      },
      {
        "sku": "459",
        "description": [
          {
            "id": "ckl6k1ygc0lfs0819d2gi1ebf",
            "title": "Geometri Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxagrgw5ftlm08590edldd00",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxagrgw8ftlo08594unqmpfp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagrgw9ftlp08594ysin0nw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagrgwfftlt0859ic6kvm1m",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxagrgwhftlv0859mi1uze9g",
                "price": 30,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagrgwiftlw08594ivv8wtr",
                "price": 11,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxagrgwnftm00859q5ffl9fx",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckxagrgwpftm20859rhqtn4cz",
                "price": 15,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxagrgwqftm30859tesuscmq",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6k2kur0lhe0819m2rsy8r7",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7z74kc30q50897akfi1j4r",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6k1yg10lfr0819761c5wbj",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613392165187-ccn-459-1.jpg",
          "1613392165218-ccn-459-2.jpg"
        ]
      },
      {
        "sku": "461",
        "description": [
          {
            "id": "ckl6k470d0lhx0819l99e4ujz",
            "title": "Kuş Tüyü Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8ecwx6wxxu008408zss5gk3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl8ecwx75xxu208403b2bq01d",
                "price": 20,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8ecwx7exxu30840ywl34rbx",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8ecwx7pxxu70840t5kdjfjz",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl8ecwx7sxxu908403jldwqxe",
                "price": 30,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8ecwx7txxua08402qlho30w",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8ecwx7zxxue0840yentdgod",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl8ecwx82xxug0840bc2fyqfs",
                "price": 10,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8ecwx83xxuh084039k1t71a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8ecwx8axxul0840ksi2vv4n",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl8ecwx8cxxun0840jhdqi9tk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8ecwx8dxxuo0840jl83jmgn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6k4ydu0ljg081983ys2tpy",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6k47050lhw0819fw2km20i",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613392254559-ccn-461-1.jpg",
          "1613392254567-ccn-461-2.jpg",
          "1613392254571-ccn-461-3.jpg",
          "1613392254585-ccn-461-5.jpg",
          "1613392254592-ccn-461-4.jpg",
          "1613392254613-ccn-461-6.jpg"
        ]
      },
      {
        "sku": "817",
        "description": [
          {
            "id": "ckl6lfslp0m3e0819o83s8e3y",
            "title": "Boyutlu Daire Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54ff1uyj2f00840kbor7l9j",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54ff1v2j2f208400s2ji3qw",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ff1v4j2f308403avvazlk",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ff1vcj2f708408hq16ng2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54ff1vdj2f90840gri9fv0m",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ff1vfj2fa084051jf6bvw",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ff1vlj2fe08406g3ty4ty",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl54ff1vmj2fg084064c4hlx9",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ff1vnj2fh08404xj5j01f",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ff1vtj2fl0840d0kowajh",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54ff1vuj2fn0840r0aakwzw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ff1vwj2fo0840s8md661z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ff1w1j2fs0840c49i2iah",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl54ff1w3j2fu0840cstwrgh5",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ff1w4j2fv0840waxmjkya",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmov8vgxjg30959xto2a4r3",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmovjwcxjhb09597y0kaljd",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6lfslj0m3d0819wl0pd4fr",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613394435390-1602067818312-CCP-817.jpg",
          "1613394464217-CCP-817.yan.jpg"
        ]
      },
      {
        "sku": "818",
        "description": [
          {
            "id": "ckl6lkkyf0m8i0819kp6zcffd",
            "title": "Boyutlu Geometrik Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xucluh6hw0840gy65zrqt",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xuclzh6hy0840y4d35816",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xucm3h6hz08409wsifsah",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xucmhh6i30840nmd6rd7l",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xucmjh6i508407da3ef5t",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xucmlh6i60840r3yq8zj5",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xucnhh6ib0840i5r0uo8z",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl39xucnnh6if0840fdwjvfpi",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xucnrh6ig0840corpro6r",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xuco1h6ik0840o50liowg",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl39xuco4h6im0840qhazykq0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xuco6h6in08409zrhs27q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xucoeh6ir0840cgh7m3m6",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl39xucogh6it08404u6l0pjo",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xucoih6iu08405shf64eg",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmowqxrxjlz0959d7zrukwl",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmox2vyxjmy0959ht13q457",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6lkky90m8h08195u12syvq",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1613394714975-CCN-818.jpg",
          "1613394714981-CCN-818_yan.jpg"
        ]
      },
      {
        "sku": "362",
        "description": [
          {
            "id": "ckl6oyc5q0mox0819w86e5t75",
            "title": "Trio Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i5szr7s8wc0859xlhvy1vw",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i5szr9s8we0859l8ud62ku",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i5szras8wf0859m1llrc0s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6ozaf50mq50819bjiaw3o2",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7za9p430t708976qy86efh",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6oyc5b0mow0819c2t4a1fx",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1613400310412-cce-362-1.jpg"
        ]
      },
      {
        "sku": "390",
        "description": [
          {
            "id": "ckl6p0b5s0mqn0819inuy4sz7",
            "title": "Taş Detaylı Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i5t7rqs8zd0859bj8zaboc",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i5t7rss8zf0859lgli7sl0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i5t7rts8zg0859arohy1jn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6p0r4n0mrw0819rngcd0lm",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zan4o30u90897kou0c1sn",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6p0b5j0mqm081940jibc5o",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1613400481268-cce-390-1.jpg"
        ]
      },
      {
        "sku": "391",
        "description": [
          {
            "id": "ckl6p24vj0msf08196yns4lpi",
            "title": "Merdiven Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i5tnw0s94q0859ymtoci4v",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i5tnw3s94s0859svo6uwsc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i5tnw4s94t0859ciuzcptv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6p2l4f0mu10819ae06h5io",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7ze38p30uv0897rblwtpao",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6p24vb0mse0819ssi8ucg6",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1613400562765-cce-391-1.jpg"
        ]
      },
      {
        "sku": "392",
        "description": [
          {
            "id": "ckl6p7pz90mvg0819u6rm3oim",
            "title": "Üçgen Basamak Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i62vo0sabu08595zu1020a",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i62vo3sabw0859kryurqze",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i62vo4sabx0859mnsffk30",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6p854j0mwz0819sld8wsdx",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zegxg30v50897b9lvsal6",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6p7pyy0mvf0819nbnyn4dh",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1613400671618-cce-392-1.jpg"
        ]
      },
      {
        "sku": "551",
        "description": [
          {
            "id": "ckl6piyqs0myz0819gpfwctsv",
            "title": "Alt Alta Kare Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i64c0wsaov0859txdqn6il",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i64c0ysaox0859nycv1ioi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i64c0zsaoy08592cj842fm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlgflwksjhs09590zbslmpi",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlgfytzsjj40959t0uy1n3h",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6piyqk0myy0819qrh2qr7q",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1613401326422-cce-551-1.jpg"
        ]
      },
      {
        "sku": "752",
        "description": [
          {
            "id": "ckl6plyqt0n0w0819mnvd35xr",
            "title": "Tek Damla Akışı Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i653vpsaxp08594wghdni0",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i653vusaxr0859wwp0t104",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i653vwsaxs08592njwbrdo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6pmscc0n2b0819savpev2k",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zhvzf30vy0897ebrc8bil",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6plyql0n0v08191lnwyyzu",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1613401468211-cce-752-1.jpg"
        ]
      },
      {
        "sku": "753",
        "description": [
          {
            "id": "ckl6pnuua0n2z0819147cn35n",
            "title": "Triloji Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xvsumh7dg0840lsrez8jg",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl39xvsuxh7di0840knhgmekb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xvsv0h7dj084029oohqxd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6poa7p0n4d0819js7hfyiy",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zi7j530w80897yftcn6l8",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6pnuu30n2y0819jyw1r45d",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1613401585035-cce-753-1.jpg"
        ]
      },
      {
        "sku": "755",
        "description": [
          {
            "id": "ckl6ps8l40n4x0819e030dbdw",
            "title": "İp Detaylı Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2lm4bplmi085915n6u3vn",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2lm4dplmk0859d4f5ojcn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2lm4eplml0859pmdmhnnf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6psuzy0n6c0819r61l3mw9",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zifzy30wi08972idrcai8",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6ps8ku0n4w0819qlbh70n1",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1618368271158-CCE-755.jpg",
          "1613401783796-cce-755-1.jpg"
        ]
      },
      {
        "sku": "756",
        "description": [
          {
            "id": "ckl6pxo0g0n6u0819h4125im1",
            "title": "Kleopatra Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1xf5lcenw8v0840v81m1b9s",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1xf5lcjnw8x0840rui7vtu3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1xf5lclnw8y0840uj2uxfek",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6pz0xp0n8a0819gvraznwg",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zinsz30ws0897488aegsc",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6pxo080n6t0819m16a5ru1",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1613402046221-cce-756-1.jpg",
          "1649845047456-1.png"
        ]
      },
      {
        "sku": "757",
        "description": [
          {
            "id": "ckl6q7hkm0n9x0819wquadbyo",
            "title": "Köşeli Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54dxlzhirez0840lk6d87o6",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl54dxlzlirf10840m1u0q3hc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dxlzpirf20840zrev7lf2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6q86ij0nd50819fgfioa75",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7ziwmw30x2089708l9kkak",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6q7hkf0n9w08195dc0y12f",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1613402246960-cce-757-1.jpg"
        ]
      },
      {
        "sku": "758",
        "description": [
          {
            "id": "ckl6q9zfi0ne60819ju6ooeva",
            "title": "Köşeli Uzun Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2lq5dpls50859opu5edun",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2lq5fpls70859ev6nk1f9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2lq5gpls80859ibliqgmh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6qanhj0ng30819da5jod7x",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zj56r30xc0897wfbltpmu",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6q9zfa0ne50819ul4as9r8",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1613402625061-cce-758-1.jpg"
        ]
      },
      {
        "sku": "759",
        "description": [
          {
            "id": "ckl6qbyvj0ngw0819k312q996",
            "title": "Pops Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0ktz0ek1hgf0859mrwp3cpw",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0ktz0em1hgh0859mmwmsezx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0ktz0ep1hgi0859nd8ylks1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm7zjegs30xn089791ixdhzf",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckoq4f9iu1mlu0759vol3759t",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6qbyvf0ngv0819nbss9to0",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1613402702905-cce-759-1.jpeg"
        ]
      },
      {
        "sku": "834",
        "description": [
          {
            "id": "ckl6qeyzo0njw08192z11jhdf",
            "title": "Kalp Atışı Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0q7lqwmlmpo0859q47nnuqg",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0q7lqwplmpq0859wwt26myx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q7lqwqlmpr08593gmvd4em",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0q7lqwwlmpv08595bpgfum7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0q7lqwylmpx08598ph4eum1",
                "price": 47,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q7lqwzlmpy0859ummih025",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6qft4n0nlg0819ynuz6b1b",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zjjmp30xx0897qe967cgp",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6qeyzf0njv0819h03uo4vt",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1647232300026-IMG_6581.jpg",
          "1613402822967-cce-834-2.jpg",
          "1613402822978-cce-834-3.jpg",
          "1613403627969-cce-834-4.jpg",
          "1613403627964-cce-834-5.jpg",
          "1613403627973-cce-834-6.jpg"
        ]
      },
      {
        "sku": "492",
        "description": [
          {
            "id": "ckl6qxqpn0noz0819nzxj1j3v",
            "title": "Mineli Basamak Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9mttjgdb90859o2qbeu6b",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9mttngdbb0859hpyk6x61",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9mttpgdbc0859c1cvol37",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6qydzd0nql0819ahj0ntge",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zjwmg30y70897d9cvt4co",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6qxqpg0noy08198ygxbjpr",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1613403717335-cem-492-1.jpg",
          "1648626463505-1.png",
          "1613403717308-cem-492-2.jpg"
        ]
      },
      {
        "sku": "550",
        "description": [
          {
            "id": "ckl6qzm510nrf0819jyjbqut9",
            "title": "Mineli Üçgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9n69pgdee08591ysnzg81",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9n69sgdeg0859mjqw8u8n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9n69tgdeh08591bwewcgu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6r0ehx0nt80819bwq7jj52",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zk5gm30yh08977knj5df4",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6qzm4v0nre0819x9zmrp4i",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1613403810699-cem-550-1.jpg",
          "1648626481700-2.png"
        ]
      },
      {
        "sku": "553",
        "description": [
          {
            "id": "ckl6r1jl70ntr0819zut6rgl9",
            "title": "Mineli Nokta Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxacr2i8f5fy0859lg6m02wq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxacr2iaf5g00859a83kmd5w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacr2ibf5g108590akvw6vz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6r2vzo0nve0819rt39g964",
            "price": 40,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zkz4m30ys08973vs6v6m3",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6r1jl10ntq0819jhokl218",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1613403902958-cem-553-1.jpg",
          "1613403902965-cem-553-2.jpg"
        ]
      },
      {
        "sku": "554",
        "description": [
          {
            "id": "ckl6r8wpd0nwj0819m3dhocnr",
            "title": "Mineli İçiçe Üçgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr1ul7dphni0859cx33vhod",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr1ul7fphnk0859yayscyfz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr1ul7hphnl0859mhutww02",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlgcgvxsjbl0959xjigz02g",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlgcpsksjc00959zjou3agn",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6r8wp20nwi08191kjmld64",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1613404204051-cem-554-1.jpg",
          "1613404204044-cem-554-2.jpg"
        ]
      },
      {
        "sku": "567",
        "description": [
          {
            "id": "ckl6rau730nyq0819qgtpswpw",
            "title": "Mineli Altıgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9nthlgdl80859ukb4ldp8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9nthpgdla08593bmijow5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9nthrgdlb0859tgjry4rc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6rbazr0o0f08191ym3qo9d",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zlesm30zc089741mi892t",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6rau6w0nyp0819u8693ecr",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1613404331848-cem-567-1.jpg",
          "1648626511716-3.png"
        ]
      },
      {
        "sku": "603",
        "description": [
          {
            "id": "ckl6rcwlv0o0v0819v0smxlbn",
            "title": "Mineli Üçgen Uzun Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr1uodyphrd0859qw04seoc",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr1uoe0phrf08596rjok8sp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr1uoe1phrg0859h3zsfyx0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6rdf8s0o2l08190crypprv",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zlp8g30zm08970swql7fc",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6rcwlp0o0u0819etjd85vm",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1613404418046-cem-603-1.jpg",
          "1613404418054-cem-603-2.jpg"
        ]
      },
      {
        "sku": "771",
        "description": [
          {
            "id": "ckl6rlbdc0o400819o3rbe0fn",
            "title": "Renkli Köşeli Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr1upokphtc0859693z1ojk",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr1uponphte0859gmyauoqa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr1upoophtf0859u9yp3rif",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6rlzw40o5r0819p46hb2oc",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zlw8h30zw089704oy8elk",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6rlbd20o3z0819eiqlq0jm",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1613404520933-cem-771-1.jpg"
        ]
      },
      {
        "sku": "820",
        "description": [
          {
            "id": "ckl6rnhol0o680819iozpa67o",
            "title": "Mineli Aristokrat Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr1w4yhpi480859pxkyvyx8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr1w4yjpi4a0859puekf39g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr1w4ylpi4b0859qwh0596o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6ro7p20o7g0819pxmmaclb",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zm76u3106089790aanmkq",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6rnhoa0o670819n5qe77nz",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1613404923912-cem-820-1.jpg",
          "1613404923918-cem-820-2.jpg",
          "1613404923923-CEM-820.jpg"
        ]
      },
      {
        "sku": "449",
        "description": [
          {
            "id": "ckl6s0hr70oad0819wmv91rkk",
            "title": "Arası İpli Üçgen Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3bfxkpotz0859jjffc1bv",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3bfxmpou10859v7haogmb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3bfxopou208593tbowqak",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqljtdnqsq6e0959wfy9js6q",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqljtp27sq7q095946v1fo7c",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6s0hqr0oac08199m6y8rfw",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1613405525517-ccr-449-1.jpg",
          "1613405525504-ccr-449-2.jpg"
        ]
      },
      {
        "sku": "478",
        "description": [
          {
            "id": "ckl6s2s280oc40819in3syqqx",
            "title": "Taşlı ve İpli İnce Çubuk Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i6gnbfsdof0859ig1ji297",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl0i6gnbhsdoh0859g69noa61",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6gnbjsdoi0859atp76e56",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqljur4msqa00959c4pb8hit",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqljuxoisqai09594y5tp7ka",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6s2s220oc30819357odmzo",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1613405626193-ccr-478-1.jpg",
          "1613405626198-ccr-478-2.jpg"
        ]
      },
      {
        "sku": "481",
        "description": [
          {
            "id": "ckl6s58e60odw0819z8h1xecz",
            "title": "Yuvarlak Taşlı ve İpli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1agy8gb3sbz0859sz26136m",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl1agy8gi3sc10859jbo9hp6z",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1agy8gj3sc20859zrtt2wyy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqljw255sqct0959x9fpuwyy",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqljwcuzsqdi09593zc89ef2",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6s58e00odv08193q17fd27",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1648457354793-IMG_1126.jpg",
          "1648457354607-IMG_1125.jpg",
          "1613405721705-ccr-481-1.jpg",
          "1613405721712-ccr-481-2.jpg"
        ]
      },
      {
        "sku": "485",
        "description": [
          {
            "id": "ckl6s6k7h0ofq08192x59oykz",
            "title": "İpli Kalın Çubuk Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz8hkfg0mw8t0859wdws8q37",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckz8hkfg4mw8v085960perwrj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8hkfg5mw8w08594z7xzxb2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqljxucesqfp0959lx0j9csw",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqljy3qxsqgb0959caayz0px",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6s6k7a0ofp0819x828o3cg",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1613405825000-ccr-485-1.jpg",
          "1613405825008-ccr-485-2.jpg"
        ]
      },
      {
        "sku": "739",
        "description": [
          {
            "id": "ckl6sb2ed0ohm0819k5bioxvw",
            "title": "At Nalı Taşlı Şans Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3x5gci2bgkt0840a6riju6b",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl3x5gci7bgkv0840ehla7q9c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3x5gci9bgkw0840538f25k7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6sbi4d0oiz08197gosdujj",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zrbbe31270897zf9o36z3",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6sb2e20ohl0819vzyxg9p4",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1613405912842-ccr-739-1.jpg",
          "1613405912848-ccr-739-2.jpg"
        ]
      },
      {
        "sku": "836",
        "description": [
          {
            "id": "ckl6sea0i0oje081997le3fc5",
            "title": "Kalp Atışı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0q7foywlmf80859elm7519t",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl0q7foyylmfa0859hp9s11h8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0q7foz0lmfb08591rywmhe5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6st7dh0old0819cbrgcx05",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zrhtg312h089774e07zio",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6sea0a0ojd0819or1qrjs9",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1647232051645-IMG_6519.png",
          "1647231975113-IMG_6518.jpg"
        ]
      },
      {
        "sku": "561",
        "description": [
          {
            "id": "ckl6svlit0omw0819mcq3moy6",
            "title": "Mineli Nokta Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3k14zpr100859l3jl2f6t",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3k153pr120859azs6pu7v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3k155pr130859d4c93p9m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6sw3w30oob0819cnzgji2u",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zrot1312r0897q7v077kl",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6svlim0omv08195f2a7e4t",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613406962082-crm-561-1.jpg",
          "1613406962116-crm-561-2.jpg"
        ]
      },
      {
        "sku": "546",
        "description": [
          {
            "id": "ckl6sznh10oos0819a0v8k63b",
            "title": "Mineli Zırh Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3js7bpqyt0859ftci4u7h",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3js7dpqyv0859ina7447u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3js7fpqyw08594xjsqcig",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlj6f1esp9o0959milwaf0v",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqm6ckbnuxb709591hwmb4m5",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6szngq0oor0819dbpuwwan",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613407165618-crm-546-1.jpg",
          "1613407165624-crm-546-2.jpg",
          "1630324085640-IMG_3937.jpg",
          "1630324106266-IMG_3938.jpg"
        ]
      },
      {
        "sku": "547",
        "description": [
          {
            "id": "ckl6t2ias0oqq0819g6fswdut",
            "title": "Mineli Bronz Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3jgkhpqwq08593cl3aupe",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3jgkjpqws0859hbmflryj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3jgkkpqwt0859zdjkv2ns",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlj935lspbt09595nr4sw6f",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlj9wfmspcx0959c3lsbsvo",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6t2iai0oqp08197ath4trb",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613407291273-crm-547-1.jpg",
          "1613407291282-crm-547-2.jpg"
        ]
      },
      {
        "sku": "548",
        "description": [
          {
            "id": "ckl6t4zv90osr0819gircy7g6",
            "title": "Gökkuşağı Mineli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3jd03pquu0859oef7wuko",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3jd05pquw08593b29iv2y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3jd07pqux0859j2cxzy8p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqljd4azspfg0959dltj66w3",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqljdjd0spgw0959c3x1fje8",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6t4zuw0osq0819ge31rfpn",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613407393198-crm-548-1.jpg",
          "1613407393205-crm-548-2.jpg",
          "1613407393208-crm-548-3.jpg",
          "1613407393212-crm-548-4.jpg"
        ]
      },
      {
        "sku": "562",
        "description": [
          {
            "id": "ckl6tb5ri0ouw0819menhyajt",
            "title": "Mineli Altıgen Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3j9gapqsx08597ezw4jv6",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3j9gcpqsz0859lremj6bx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3j9gdpqt00859owc16ay6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6tc9ls0owf08193j6rff28",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zshmd313v0897u0am4b3l",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6tb5r80ouv0819b4voi7ee",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1630324155223-IMG_3939.jpg",
          "1630324156152-IMG_3940.jpg",
          "1613407556370-crm-562-1.jpg",
          "1613407556376-crm-562-2.jpg",
          "1630326633287-IMG_3935.jpg",
          "1630326633554-IMG_3936.jpg"
        ]
      },
      {
        "sku": "484",
        "description": [
          {
            "id": "ckl6te75l0ox20819asm1w65g",
            "title": "Mineli İnce Çubuk Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3j66opqr10859k1sy02o3",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3j66qpqr30859oujpagwm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3j66rpqr408592uo7hzz2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqljh9e3spn309598sbf5579",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqljhpaxspoe0959pecn8w8n",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6te75e0ox10819ndvzoac2",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613407851161-crm-484-1.jpg",
          "1613407851155-crm-484-2.jpg"
        ]
      },
      {
        "sku": "563",
        "description": [
          {
            "id": "ckl6tj4mx0oz60819nszy7qyb",
            "title": "Taşlı ve Mineli Dikdörtgen Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3j35spqp40859wrc6a1px",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3j35upqp608595on42l5q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3j35vpqp70859fal37d3m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl25uzlr7xqm108400k30lwdn",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl25v062xxqpv0840u7gf3jkx",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6tj4mn0oz50819e0jfhvpx",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613408031465-crm-563-1.jpg",
          "1613408031473-crm-563-2.jpg"
        ]
      },
      {
        "sku": "564",
        "description": [
          {
            "id": "ckl6tm7ag0p190819p7ng69ws",
            "title": "Dikdörtgen Mineli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3j0bppqn708593gwitei5",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3j0brpqn90859dlwnxmoz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3j0bspqna0859ghlascn0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6tmysl0p2v0819ye5klkae",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zt573314q08972pvbuvya",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6tm7a80p180819mj16teqj",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613408181081-crm-564-1.jpg",
          "1613408181103-crm-564-2.jpg",
          "1630326585472-IMG_3933.jpg",
          "1630326585629-IMG_3934.jpg"
        ]
      },
      {
        "sku": "598",
        "description": [
          {
            "id": "ckl6tpivf0p3e0819tbs6fnhr",
            "title": "Taşlı ve Mineli Altıgen Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3ix8opqla0859gz2oupyd",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3ix8qpqlc0859jsbhs7im",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3ix8rpqld0859fbvz13hi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6tpyo60p510819ycf85hd9",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7ztb8l31500897kr0yu1st",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6tpiv80p3d0819uigyf4nj",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613408357282-crm-598-1.jpg",
          "1613408357287-crm-598-2.jpg"
        ]
      },
      {
        "sku": "607",
        "description": [
          {
            "id": "ckl6ttozf0p5k0819tvftzigl",
            "title": "Taşlı ve Mineli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3iv6bpqjv08599b9ivfzs",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3iv6dpqjx0859xy101y86",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3iv6fpqjy0859rrvz0ura",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6tu4sw0p780819m98a07o9",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zto8p315b0897v640xbrn",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6ttoz70p5j0819zb3r9t5p",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613408499544-crm-607-1.jpg",
          "1613408499552-crm-607-2.jpg"
        ]
      },
      {
        "sku": "608",
        "description": [
          {
            "id": "ckl6tvat10p7p0819ou5e1l49",
            "title": "Yuvarlak Mineli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz8hcfo5muny0859xtgms9pj",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckz8hcfo7muo00859u62j5h0a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8hcfo9muo10859n6jzzwz0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6tvqsv0p9e0819xpsq66yj",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zu425315m08978jmsmf7c",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6tvasv0p7o0819yate7ved",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1613408649694-crm-608-1.jpg",
          "1613408649702-crm-608-2.jpg"
        ]
      },
      {
        "sku": "669",
        "description": [
          {
            "id": "ckl6txrlr0p9w08191yrrgv6i",
            "title": "Sade Oval Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3dcbopphc0859n8gprvwb",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3dcbqpphe0859ay2j6vge",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3dcbrpphf0859c24w4sry",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6tyasx0pbm08193by0lass",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zu9qe315w0897at79dq2o",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6txrlj0p9v08193c1dynib",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1613408762932-ccr-669-1.jpg",
          "1613408762940-ccr-669-2.jpg",
          "1613408762944-ccr-669-3.jpg"
        ]
      },
      {
        "sku": "670",
        "description": [
          {
            "id": "ckl6u1iu40pc508193ntthuu1",
            "title": "Oval Taşlı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3x5gwadbgnh08407jvfnqnw",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl3x5gwaibgnj08400j1zcoyp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3x5gwambgnk0840eowhqg9s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6u2aij0pdv0819p3virpe1",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zug7o316608974a138mv6",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6u1its0pc408197jerax4v",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1613408868779-ccr-670-1.jpg",
          "1613408868789-ccr-670-2.jpg",
          "1613408868797-ccr-670-3.jpg"
        ]
      },
      {
        "sku": "671",
        "description": [
          {
            "id": "ckl6u3vwa0pec0819epm2znfu",
            "title": "Kare Unisex Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3d96zppdh0859x7kuoaxy",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3d971ppdj0859hmp63s9h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3d972ppdk0859zv0j1mxx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6u4l9h0pfk08199by75k2b",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zuoal316g08975dt3s8us",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6u3vvz0peb0819871z4ew1",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1613409031215-ccr-671-1.jpg",
          "1633290654119-1.png",
          "1613409031186-ccr-671-2.jpg"
        ]
      },
      {
        "sku": "745",
        "description": [
          {
            "id": "ckl6u6oyr0pg10819rvfy9esq",
            "title": "At Nalı Şans Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3d7l3ppbl0859lb31ib7c",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3d7l6ppbn0859u37uti5g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3d7l8ppbo0859up27xtme",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6u73ao0pha08198uvjaqq7",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zuwpg317c0897b1ohdyzl",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6u6oym0pg00819je9q39a3",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1613409172177-ccr-745-1.jpg",
          "1633291071734-2.png"
        ]
      },
      {
        "sku": "672",
        "description": [
          {
            "id": "ckl6u82u00phq0819tv0ucn6i",
            "title": "Sade Kare Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54a01dci93i0840dlvioone",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl54a01dhi93k0840euafuyeq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54a01dki93l0840l4rvdv8g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6u8kvy0pj008195o2x0ehp",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zvj2b317m0897bk29numf",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6u82tr0php0819s47x5hyv",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1618358285297-CCR-672 YAN.jpg",
          "1618358275942-CCR-672.jpg",
          "1613409253626-ccr-672-1.jpg",
          "1613409253631-ccr-672-2.jpg",
          "1613409253635-ccr-672-3.jpg"
        ]
      },
      {
        "sku": "673",
        "description": [
          {
            "id": "ckl6uatch0pkc08197um5wsq3",
            "title": "Kalkan Unisex Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz8gwn6xmr790859b4ygej53",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckz8gwn71mr7b085990e9p11b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8gwn73mr7c0859dehpt3ty",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6ubli00plt081980c9qyyq",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zvsq9317w0897dba80e6g",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6uatc90pkb081909tk2q5m",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1613409375913-ccr-673-1.jpg",
          "1633291053446-3.png",
          "1613409375895-ccr-673-2.jpg",
          "1613409375949-ccr-673-3.jpg"
        ]
      },
      {
        "sku": "674",
        "description": [
          {
            "id": "ckl6ucvz60pma08193gh6c8e7",
            "title": "Taşlı Kare Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3x5hf8fbgpy08405s1rteey",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl3x5hf8mbgq008409zxosx2x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3x5hf8sbgq10840jb3oenu9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6udvt40pnm0819xh1etp05",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zvzzg31860897g2pem4a0",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6ucvyt0pm9081926r5n3lc",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1613409460702-ccr-674-1.jpg",
          "1613409460696-ccr-674-2.jpg",
          "1613409460684-ccr-674-3.jpg"
        ]
      },
      {
        "sku": "675",
        "description": [
          {
            "id": "ckl6ufo1o0po408199vpn19u6",
            "title": "Daire Unisex Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz8hju9gmw420859dqg20pub",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckz8hju9imw440859yh66lv22",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8hju9kmw4508591xzz4xhy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl6ug3ue0pph08193dr3ymcc",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm7zw6x7318g0897qqtc4qoy",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl6ufo1f0po30819zlvod9cm",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1613409587697-ccr-675-1.jpg",
          "1633291042061-4.png",
          "1613409587703-ccr-675-2.jpg"
        ]
      },
      {
        "sku": "635",
        "description": [
          {
            "id": "ckl70ixys0rhe0819e22bgcwn",
            "title": "Elips Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3mmzyprl008597s282ayg",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3mn00prl2085908zyl54p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3mn02prl308598uis372w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl70ji3d0rj108192bgdhnjx",
            "price": 79,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83kmko31eu0897ltt42u53",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl70ixy60rhd0819byo8a28m",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1613419802984-cch-635-1.jpg",
          "1613419802974-cch-635-2.jpg"
        ]
      },
      {
        "sku": "649",
        "description": [
          {
            "id": "ckl70kzfy0rjj0819t1o21ggn",
            "title": "Cross Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3mlgeprj30859mqxb0n33",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3mlgfprj50859ck1acwn6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3mlghprj608590064vsnj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl70nrao0ro708198cdzpnm5",
            "price": 79,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83lkrd31fg0897raz4miqf",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl70kzfq0rji0819ssgmmyth",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1613419918204-cch-649-1.jpg",
          "1613419918198-cch-649-2.jpg"
        ]
      },
      {
        "sku": "803",
        "description": [
          {
            "id": "ckl70paj00ron0819746ydnsw",
            "title": "Drop Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3mjwbprh60859034fvguh",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3mjwdprh80859ciepp3wx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3mjwfprh9085952fk0g5h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlhzmwnsnki0959kuwi8541",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlhzuqssnl6095964an3rja",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl70pais0rom0819s9yxtui3",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1613420134434-cch-803-1.jpg",
          "1613420134427-cch-803-2.jpg"
        ]
      },
      {
        "sku": "647",
        "description": [
          {
            "id": "ckl70shwi0rsr0819cybrjbww",
            "title": "Pul Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3mi23prf70859ts6fsjsq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3mi25prf908591fjmj8o5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3mi26prfa0859t0wyrfu6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl70t5mn0ruh0819802fhys2",
            "price": 79,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83lyap31g00897n5uy489t",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl70shwb0rsq0819h6chuh7f",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1613420280271-cch-647-1.jpg",
          "1613420280277-cch-647-2.jpg"
        ]
      },
      {
        "sku": "648",
        "description": [
          {
            "id": "ckl70v2mp0ruz0819vp3mre1w",
            "title": "Switch Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3mfv2prdb0859ea0so5d1",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3mfv4prdd0859y8gkmppo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3mfv5prde0859dupnbgu8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckl70vmmj0rwq0819gojw8ah1",
            "price": 79,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83m52s31ga0897tcmfj3a0",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl70v2mi0ruy08190gdx2wsi",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1613420359740-cch-648-1.jpg",
          "1613420359748-cch-648-2.jpg"
        ]
      },
      {
        "sku": "827",
        "description": [
          {
            "id": "ckl70x1hp0rx808196n8y8dp5",
            "title": "Mini Renkli Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1xeswdunug20840yhc069x3",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1xeswdznug40840zvzu5dyo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1xeswe1nug50840n62p5ysy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm83mexj31gk0897xqhcks9b",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqli0zr6snpd0959nl480fwb",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl70x1hg0rx708190hzecvb4",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1613420463556-cch-827-1.jpg",
          "1613420463564-cch-827-2.jpg",
          "1613420463571-cch-827-4.jpg",
          "1613420463567-cch-827-3.jpg"
        ]
      },
      {
        "sku": "833",
        "description": [
          {
            "id": "ckl70z7td0rz00819w32pqtq3",
            "title": "Boncuklu Pul Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3mcfqpr9i0859piqslrwn",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3mcfspr9k08599xa5gaxr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3mcftpr9l0859dsgujiav",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqli2366snre0959fflrc3gp",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqli2fx1snsg0959s7pg2y25",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckl70z7t40ryz0819hjgpwskm",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1613420604135-cch-833-1.jpg",
          "1613420604129-cch-833-2.jpg"
        ]
      },
      {
        "sku": "701",
        "description": [
          {
            "id": "ckm2cyhev2gmb08971qojw121",
            "title": "Yarım Mineli Büyük Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxacnc3wf4dl0859kywk5rp4",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxacnc3yf4dn0859tbl443d3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacnc3zf4do0859kdzjypme",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2e2ol72h0d08971cb7ivci",
            "price": 33,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83tfit31k70897qco71jcl",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2cyhda2gma0897qj16duw4",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1615315127581-ccl-701-L-1.jpg",
          "1615315127612-ccl-701-L-2.jpg"
        ]
      },
      {
        "sku": "702",
        "description": [
          {
            "id": "ckm2d0gaj2gnb0897w0qp83py",
            "title": "Rengarenk Mineli Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxacnno3f4hr0859l2bbv94v",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxacnno5f4ht0859730ky429",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacnno6f4hu0859snknlc2b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2e22il2gzt08973x6z3v4f",
            "price": 35,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83tkt431kh0897bdthsbe2",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2d0ga52gna08977ez4h6xa",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1615315211695-ccl-702-1.jpg",
          "1615315211701-ccl-702-2.jpg"
        ]
      },
      {
        "sku": "703",
        "description": [
          {
            "id": "ckm2d2fz12god0897rk0zmg3m",
            "title": "Yarım Taşlı Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxacp1gvf4qw0859mvm2q0i3",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxacp1gyf4qy08592i29acg3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxacp1gzf4qz08598fg2u97s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2edsv42h790897r3amv9sd",
            "price": 34,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83u4bk31l60897pyzulpn2",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2d2fyr2goc0897xb5s5nc2",
        "category": {
          "id": "ckku4pxfu0iu80919zgl05fr1",
          "sku": "CCL"
        },
        "photos": [
          "1615315296198-ccl-703-1.jpg",
          "1615315296214-ccl-703-2.jpg"
        ]
      },
      {
        "sku": "704",
        "description": [
          {
            "id": "ckm2dn5yz2gpu0897gtkiepeu",
            "title": "Yarım Mineli Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabd4mwefdt0859wg8xy0pv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxabd4myefdv08598aa2xmvb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabd4n0efdw0859oet8rvns",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2e3xgn2h2q0897al55svt5",
            "price": 23,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83u9vx31lg0897q0uvvnbp",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2dn5yo2gpt089781bvpapk",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1615315391065-ccl-704-1.jpg",
          "1615315391058-ccl-704-2.jpg"
        ]
      },
      {
        "sku": "782",
        "description": [
          {
            "id": "ckm2dozqw2gqv0897mlmdt33z",
            "title": " Dalgalı Mineli Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i701w6shsv0859dtxqhur9",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i701w9shsx0859b8i9hmqc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i701wbshsy0859fwwiauw3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2e0b9f2gz2089783pr0qo1",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83vdor31lq08973nlldp8m",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2dozqp2gqu08972poiie0k",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1615316366930-cpm-782-1.jpg",
          "1615316366968-cpm-782-2.jpg"
        ]
      },
      {
        "sku": "830",
        "description": [
          {
            "id": "ckm2dquvu2grv0897qm6hv1a3",
            "title": " Mineli Fil Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i6xht5sh7i0859tw89987l",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i6xht7sh7k08594c6b16fd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6xht9sh7l0859kv0pv11h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2dzrxt2gyi0897i9s5logk",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83vj7r31m00897el3zge2j",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2dquvk2gru0897a1lxtdt9",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1615316448326-cpm-830-1.jpg",
          "1615316448314-cpm-830-2.jpg"
        ]
      },
      {
        "sku": "831",
        "description": [
          {
            "id": "ckm2ds55e2gsw0897128g2dhb",
            "title": " Mineli Aslan Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i6ywrushim0859yj6u7p6k",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i6ywrwshio085904oyz02r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6ywrxship0859q5vrcy1b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2dz4vf2gxy0897ig3n2was",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83vpy831ma0897ah6toybc",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2ds5532gsv0897ttu3zkqa",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1615316535457-cpm-831-1.jpg",
          "1615316535451-cpm-831-2.jpg"
        ]
      },
      {
        "sku": "858",
        "description": [
          {
            "id": "ckm2dt4ue2gtw0897g818fe2c",
            "title": "Çizgi Mineli Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i726sqsi890859r6d5e8j2",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i726sssi8b08594z4z7as6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i726susi8c0859zbl6xr5r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2dynja2gxe0897myvcu4pf",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83vv6831mk08973dmvi2y9",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2dt4ua2gtv0897yuuclc7y",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1615316590273-cpm-858-1.jpg",
          "1615316590295-cpm-858-2.jpg",
          "1615316590305-cpm-858-4.jpg",
          "1615316590300-cpm-858-3.jpg"
        ]
      },
      {
        "sku": "910",
        "description": [
          {
            "id": "ckm2dwrnx2gve0897ptdnnhek",
            "title": "Zambak Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3s5kqps7j0859ohkbobtb",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3s5ksps7l085987aguucl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3s5ktps7m0859jfmz1udu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2dy9972gwu0897klsqrp02",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83wamw31mu08975cn5er65",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2dwrnj2gvd08975zy7n0ci",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1615316664989-CPM-910.jpg",
          "1615316664981-CPM-910 açık.jpg"
        ]
      },
      {
        "sku": "872",
        "description": [
          {
            "id": "ckm2eooy12ha00897y6v785qn",
            "title": "Fatıma'nın Eli Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl40eitxygusg0840znlsze5w",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl40eity5gusi0840zloap6qi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl40eity8gusj0840jikb7269",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqli8jlxsnzf0959l6zftmh9",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqli8rjpso070959u79zq29d",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2eooxu2h9z0897s45da7cn",
        "category": {
          "id": "cl40ei1d3gup70840s1vs67hw",
          "sku": "CPS"
        },
        "photos": [
          "1615317999581-CCP-872.jpg"
        ]
      },
      {
        "sku": "873",
        "description": [
          {
            "id": "ckm2esavm2hca0897rxqii506",
            "title": "Taşlı Güneş Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl40eix3yguuh0840byw5rdyv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl40eix44guuj08406c5pi4a7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl40eix48guuk084058j8hn9b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqliag9yso3m09591b6h4tou",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqliax97so5o0959u1a1n6dd",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2esavd2hc90897y9pgbk8j",
        "category": {
          "id": "cl40ei1d3gup70840s1vs67hw",
          "sku": "CPS"
        },
        "photos": [
          "1615318237384-CCP-873.jpg"
        ]
      },
      {
        "sku": "874",
        "description": [
          {
            "id": "ckm2euso82he80897nucvup8p",
            "title": "Taşlı Ay Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl40ej02zguwi0840kz8zl67z",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl40ej032guwk0840bhx0cie9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl40ej034guwl0840uf5io2si",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlij9bjsoju0959ogsw9j30",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqliki34solj0959xi0i0kng",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2euso12he70897vyqqmt4i",
        "category": {
          "id": "cl40ei1d3gup70840s1vs67hw",
          "sku": "CPS"
        },
        "photos": [
          "1615318326779-CCP-874.jpg"
        ]
      },
      {
        "sku": "885",
        "description": [
          {
            "id": "ckm2f34zw2hha0897174mlwtt",
            "title": "Blue Eye Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39y0zeph8sl08400xylwuk1",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl39y0zeth8sn08405u3nt4uo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39y0zewh8so0840erz3f5hp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2f4btc2hj80897lgkqnma1",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83xu4x31nz089763uvr113",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2f34zh2hh90897gtg9tmet",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1615318687455-CPM-885 syh..jpg",
          "1615318685836-CPM-885 brd..jpg",
          "1615318687458-CPM-885 mavi.jpg",
          "1615318685845-CPM-885 byz..jpg"
        ]
      },
      {
        "sku": "875",
        "description": [
          {
            "id": "ckm2f6egm2hjo0897qv5jhvzk",
            "title": "Kare Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0ktl9nz1g1b0859bt1br9ne",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0ktl9o11g1d0859s7egjjfo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0ktl9o31g1e0859sriroxt8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2f8jnz2hlf0897zgixibis",
            "price": 35,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83y6hj31om08977t7kzm1e",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2f6egg2hjn0897t98gkg34",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1618614248269-0002.jpg"
        ]
      },
      {
        "sku": "876",
        "description": [
          {
            "id": "ckm2ff6m32hnd0897hdpa10ia",
            "title": "Kesişim Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0ktmlxo1g990859gv6chy2o",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0ktmlxr1g9b08599zf52a8d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0ktmlxt1g9c08595vd02dw9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm83ydbj31ow0897lkrjf0bf",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckphcdrltgl6k0959cyc211ga",
            "price": 34,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2ff6lv2hnc0897ovyw42z4",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1644366872849-ccp-876-01.jpg"
        ]
      },
      {
        "sku": "886",
        "description": [
          {
            "id": "ckm2fjgwk2hpl08978urtv0op",
            "title": "Lotus Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54fmcpxj3qf0840hi1hnori",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl54fmcq3j3qh08404udildmk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fmcq7j3qi0840krofipmr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2fl8962hrk0897a5fk86je",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83yidf31p60897uohyc48d",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2fjgwa2hpk0897swqr085o",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1615319430241-CPM-886.jpg"
        ]
      },
      {
        "sku": "919",
        "description": [
          {
            "id": "ckm2fo90c2hrz0897d8hniq8w",
            "title": "Kurukafa Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3v80rpt5208591w4grqsj",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3v80upt540859k3q78e7v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3v80vpt5508598k7sd3yv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckm2fpcnq2hta08978zy9blva",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckm83yphn31pg0897io9e8oa1",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckm2fo9032hry0897ny7c71j0",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1615319627043-CPM-919 KRMZ.jpg",
          "1615319627053-CPM-919 SYH.jpg"
        ]
      },
      {
        "sku": "862",
        "description": [
          {
            "id": "ckmbv7e1i3ksg0897s38sy8cb",
            "title": "Maya Bileklik "
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac0nruerts0859e5iw39bb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac0nrxertu0859r20viefu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0nryertv0859tp5ryxcs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac0ns3ertz0859f2fj2jat",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac0ns5eru1085900gp3dwd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0ns6eru20859uf6sm0qf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac0nsgeru60859q6e4f02e",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac0nsieru808592905ukde",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0nsjeru90859hk97kwoi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmbvfxkp3kuv08974887tark",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmbvhd653kv70897yhlhrh1n",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbv7e173ksf0897xf965xjs",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619187809823-CCB-862-1.jpg",
          "1615889898715-CCB-862 yan.jpg"
        ]
      },
      {
        "sku": "883",
        "description": [
          {
            "id": "ckmbvqxzq3l1b0897o5megxvq",
            "title": "Assemble Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac0p9merxe0859ecp0poqh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac0p9perxg0859ardor0ly",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0p9qerxh0859m6ub93ck",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac0p9verxl0859m5zp0onx",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac0p9werxn0859qnxautxs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0p9xerxo08595sm51otp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac0pa2erxs0859z9x97rwr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac0pa4erxu085988fi6uou",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac0pa5erxv0859f4x4az0y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlnats0svfg095963z5i9ju",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlnb2n2svg509591bslg1ui",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbvqxzi3l1a0897m8nr0173",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619188015859-CCB-883-l.jpg",
          "1615890933805-CCB-883 yan.jpg"
        ]
      },
      {
        "sku": "913",
        "description": [
          {
            "id": "ckmbwcby23lbm0897eeg85juf",
            "title": "Wicker Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz5ndlen9ak90859gwkf3cwz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz5ndler9akb0859g0qq8091",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5ndleu9akc0859tm99cbc3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5ndlf49akg0859fcncv8s5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckz5ndlf69aki0859x0ak3xij",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5ndlf89akj08594t374w9g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5ndlfh9akn0859o237s17i",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckz5ndlfk9akp085924sfrduh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5ndlfm9akq0859inf7w7gc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmbweegj3ldh0897lukk1doc",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmbwf6yv3le608975r1h5c21",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbwcbxw3lbl0897aslobt8r",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619188232718-CCB-913-1.jpg",
          "1615891901646-CCB-913 yan.jpg"
        ]
      },
      {
        "sku": "914",
        "description": [
          {
            "id": "ckmbwkhgk3lfg08975rpd1zk5",
            "title": "Mini wicker bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz8iu51gn6uu08590miejnqd",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz8iu51jn6uw085980vgzkt1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8iu51kn6ux08592ohmz7uc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz8iu51sn6v10859o2ruf8fh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckz8iu51un6v308593s98wras",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8iu51vn6v408590q8o65g6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz8iu523n6v80859eznudagy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckz8iu525n6va08597rku4jm3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz8iu527n6vb0859gtckk26f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmbwnvq33li00897hamn8y5m",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmbyjvqt3lro08975yfqq9dz",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbwkhgb3lff0897vu36jeuv",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619188439004-CCB-914-1.jpg",
          "1615892280091-CCB-914 yan.jpg"
        ]
      },
      {
        "sku": "917",
        "description": [
          {
            "id": "ckmbyllp73lsy0897m6brr3oi",
            "title": "U turn bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzpp0ql620ec0859u9az6tja",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzpp0ql820ee0859sbakybhd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp0ql920ef0859dr1b3iod",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpp0qlg20ej0859wu4r0y6u",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzpp0qli20el085967vwhnej",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp0qlj20em08596292s6lz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpp0qlp20eq08599gh0lq1i",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzpp0qlr20es0859v2j4ib7e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp0qlt20et0859xp28aeh3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlncmj1svig0959wmzlxqq0",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlnctwqsviq0959qpgyue93",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbylloy3lsx0897qbwp24s6",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619188791851-CCB-917-L.jpg",
          "1615895720313-CCB-917 yan.jpg",
          "1645024333293-917.png"
        ]
      },
      {
        "sku": "918",
        "description": [
          {
            "id": "ckmbypagp3lwk0897nz7emyhm",
            "title": "Tangled bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54c6weziibq0840ynf98bp6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54c6wf5iibs0840hsxmtu6n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c6wf7iibt0840n949j6oz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54c6wfgiibx0840hpvyx7ag",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54c6wfiiibz0840k5fqknp9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c6wfjiic00840i31guvcy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54c6wfpiic4084097khs1yh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54c6wfqiic60840ejafrmkq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c6wfriic70840xkpznut1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmbyqg5q3lz20897e2z29ctn",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmbyqo7x3lzc089783ayxw5p",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbypagi3lwj089723t52hbf",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619188944946-CCB-918-L.jpg",
          "1615895876547-CCB-918 yan.jpg"
        ]
      },
      {
        "sku": "927",
        "description": [
          {
            "id": "ckmbz6ke73m1b0897c0b41mds",
            "title": "Dalgalı bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl549wrq4i8ns0840rzl8x1hj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl549wrq8i8nu0840vjuww79l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549wrqai8nv08401nnjhlvn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl549wrqji8nz08406w4ok1zh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl549wrqli8o10840l3n4li83",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549wrqni8o20840l1n4w9kp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl549wrqui8o60840g3cyduev",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl549wrqvi8o808402aqyk6sl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549wrqxi8o90840nqvpzl2g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmbz77j13m2u0897oop7dqzb",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmbz7oal3m340897jqyy3ku9",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbz6kdy3m1a08978tni24x3",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619189846003-CCB-927-L.jpg",
          "1615896693182-CCB-927 yan.jpg"
        ]
      },
      {
        "sku": "513",
        "description": [
          {
            "id": "ckmbzg2gi3mad08971k5sij7e",
            "title": "Düz Prizma Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3nmxupzttr508402o7w9pvy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3nmxuq2ttr708404sgro305",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmxuq4ttr80840gluu9vwc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmxuqbttrc084039bdjyqc",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3nmxuqdttre0840yt9180ts",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmxuqettrf0840eucaz00b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmxuqkttrj0840lcjk8fac",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3nmxuqlttrl0840f71q3yfb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmxuqnttrm0840f791ou9n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmbzh66j3mdh0897mgizkn95",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmbzhdgh3mdy08975qwfujug",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbzg2gd3mac0897nsyp15mj",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619189743559-CCB-513-1.jpg",
          "1615897139465-CCB-513 yan.jpg"
        ]
      },
      {
        "sku": "835",
        "description": [
          {
            "id": "ckmbzk1fj3mfb0897aeou3j7x",
            "title": "Chopstick Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl489j147wbmo0840c5nq0p64",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl489j14cwbmq0840jssmhgw7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl489j14fwbmr0840yzl6kpx2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl489j14qwbmv0840et4p2i8g",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl489j14twbmx0840nv8zc5g4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl489j14vwbmy0840izv1w5vj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl489j15qwbn208403kdq9yx2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl489j15vwbn408400abwecxd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl489j15xwbn508408av8tipb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmbzkj9r3mh30897tpjkohwb",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmbzm4uz3mi20897etd1w3f5",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbzk1fb3mfa0897t20x8z24",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619179937354-CCB-835-1.jpg",
          "1615897313921-CCB-835 yan.jpg",
          "1654853736800-IMG_4533.jpg",
          "1654853736649-IMG_4535.jpg",
          "1654853735464-IMG_4527.jpg",
          "1654853736693-IMG_4528.jpg",
          "1654854290416-IMG_4525.jpg",
          "1654854290407-IMG_4526.jpg",
          "1654854296703-IMG_4529.jpg",
          "1654854296638-IMG_4530.jpg",
          "1654854296842-IMG_4531.jpg",
          "1654854296890-IMG_4532.jpg",
          "1654854299134-IMG_4536.jpg",
          "1654854299265-IMG_4537.jpg"
        ]
      },
      {
        "sku": "881",
        "description": [
          {
            "id": "ckmbznrzr3mj608971vuew36b",
            "title": "Taşlı Spot Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j3jdabh4vs0840srbtjr0s",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j3jdaih4vu0840l8u8rkze",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j3jdalh4vv084093ngql48",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j3jdawh4vz08408gudb37x",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j3jdayh4w10840p8zb3gqf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j3jdb0h4w20840u60n8e9e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j3jdb9h4w60840hegnpm7v",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j3jdbch4w80840ry8ctker",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j3jdbfh4w908408h9dzpzy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmbzq3d33mn108977mbykj6u",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmbzracb3mnp089751nho42u",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbznrzm3mj50897s7bspqk6",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1619205976091-CCB-881-L.jpg",
          "1615897483273-CCB-881 yan.jpg"
        ]
      },
      {
        "sku": "869",
        "description": [
          {
            "id": "ckmbzswfy3mp708971ncxj54p",
            "title": "Kovan Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xhrioh2tm08402sb8b5hs",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39xhrirh2to0840wj238ded",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xhriuh2tp08401fag3kjy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xhrj7h2tt0840wimm6mcb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xhrjah2tv084071s0utas",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xhrjdh2tw0840wtx2w2xg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xhrjqh2u00840gnvdo59s",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xhrjth2u2084094ziqgrg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xhrjvh2u30840ct6e9tel",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl31ehb3ksif10840i5dxkmo4",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl31ehf54sig30840kvgk3sod",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmbzswfr3mp608971q9g9g7h",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619206177068-CBM-869-1.jpg",
          "1615897731563-CBM-869 yan.jpg"
        ]
      },
      {
        "sku": "925",
        "description": [
          {
            "id": "ckmc0607c3n3f0897jgc0aiif",
            "title": "Skeç Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku41g898h0c80859pfvoq05d",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku41g89ch0ca0859ceclipr6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41g89eh0cb0859mtb2vs4u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku41g89lh0ce0859zp87c60o",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku41g89ph0cg0859sgborjh1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41g89rh0ch08595k0r1cvm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku41g89yh0ck0859di9skw3i",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku41g8a0h0cm0859vwbxy85y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku41g8a2h0cn0859f477h7u1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc07vnx3n6k0897w1fxmvdc",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc096603n7z0897293nw87h",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc060763n3e0897wgep3ril",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619206486157-CBM-925-1.jpg",
          "1615898350214-CBM-925 yan.jpg"
        ]
      },
      {
        "sku": "926",
        "description": [
          {
            "id": "ckmc0cm7y3n8t0897z7dsnnl4",
            "title": "Düğümlü Skeç Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1g6qaner06s0859j7r69hlw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1g6qangr06u0859a51y0wg1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g6qanir06v08594zdsmy5b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g6qannr06z0859wedadj63",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1g6qanpr0710859lrw80lk9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g6qanqr07208595cdng4oj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g6qanvr07608598mhxcjql",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1g6qanwr078085902qecsfj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g6qanyr07908596uayjrtf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc0dxvu3nbw0897n1xzfz82",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc0eowf3nc60897d8fhlr1r",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc0cm7s3n8s08972hqqbtrq",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619206580862-CBM-926-1.jpg",
          "1615898569818-CBM-926 yan.jpg"
        ]
      },
      {
        "sku": "581",
        "description": [
          {
            "id": "ckmc0gypj3ncy0897p14vvwmp",
            "title": "Düğüm Kelepçe Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57esi0k4yd0859hnty0ygd",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku57esi4k4yf0859ocjucwzg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57esi6k4yg08593an28xo9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku57esidk4yj0859h7xbmpkq",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku57esifk4yl08591p9sokac",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57esihk4ym0859f6y81062",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku57esimk4yp0859uyabhuk4",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku57esiok4yr08590z354jup",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57esiqk4ys0859n1sb9v8e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc0il1v3ng60897h6wbguk4",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc0je413ngg0897h6qevwty",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc0gypc3ncx0897mbpzhhju",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1619207242005-CCB-581-1.jpg",
          "1615898805226-CCB-581 yan.jpg"
        ]
      },
      {
        "sku": "837",
        "description": [
          {
            "id": "ckmc0rph73nm90897of63wdws",
            "title": "Doğal Taş Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5s61pkjwuf60840s7qczpgd",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5s61pkrwuf80840vhqv6mkz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5s61pkvwuf908400zmko554",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5s61pl8wufd08400sz1p97w",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl5s61plawuff0840qrnkgu91",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5s61plcwufg0840rknyer2e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc0t05p3nq40897j99ltnq5",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc0t7yv3nqe0897z105ti4x",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc0rph03nm808977gsns4do",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1615899263588-1.jpg",
          "1615899263602-CCN-837.jpg"
        ]
      },
      {
        "sku": "898",
        "description": [
          {
            "id": "ckmc0z98g3ns90897je8j9d77",
            "title": "Desenli Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckyo4bsvkywvn0859n3tybbpc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckyo4bsvnywvp08598uvnsah0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4bsvpywvq0859dn8s0wky",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4bsvxywvu0859lz6hzuqh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckyo4bsvzywvw0859ip0c1hen",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4bsw0ywvx0859o4iitrpk",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4bsw5yww10859m8jkhdsk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckyo4bsw7yww30859yqsj904l",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4bsw8yww40859zixwiacq",
                "price": 72,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4bswdyww8085959ephuz6",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckyo4bswfywwa0859zsy9l9zc",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4bswgywwb0859vxl797u9",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckyo4bswlywwf0859ofc4zkb6",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckyo4bswsywwh0859e7qsxo3c",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyo4bswtywwi0859kf5okq6u",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky6gmxj2ocw70859ic0rxul3",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky6go3avoczl0859b8qliedy",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc0z9893ns80897d6nboxt1",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1615899726092-CCN-898-M.jpg",
          "1615899726084-CCN-898 yan.jpg"
        ]
      },
      {
        "sku": "906",
        "description": [
          {
            "id": "ckmc1ll1n3o0i0897lh37ibvk",
            "title": "Sekiz Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7uggbalaj7n0840ztbfhknb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7uggbavaj7p0840d6v6v1pw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7uggbayaj7q0840mj6gzix5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7uggbbbaj7u0840lg6bnqwr",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7uggbbfaj7w08408znfs13b",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7uggbbhaj7x0840m78qkt1z",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7uggbbraj8108406whaqrjr",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7uggbbuaj830840c7m237b1",
                "price": 17,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7uggbbxaj8408409g78rjjk",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7uggbcbaj880840d9eay1tr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7uggbcfaj8a08401fewdls8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7uggbciaj8b08401ou6p93x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nhwnmko2bh0859qsy84yoq",
            "price": 88,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nhwuxho2cv0859uz17y2pr",
            "price": 246,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc1ll173o0h089727nsyh3s",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1615900727223-CCN-906-.jpg",
          "1615900727229-CCN-906-yan.jpg"
        ]
      },
      {
        "sku": "907",
        "description": [
          {
            "id": "ckmc20vuc3o830897cldevpfo",
            "title": "Hayalet Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54fil5lj2wx0840omwud9ez",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54fil5rj2wz0840uh9b1rt0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fil5uj2x00840q2km7xqh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54fil64j2x40840wjmeq6yb",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54fil67j2x60840hmsbybft",
                "price": 15,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fil69j2x70840k9d3l0zx",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc21yyc3oa408970qobmwg9",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc226ms3oae089781c4td40",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc20vu53o820897wtzrpamw",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1615901251525-CCN-907-.jpg",
          "1615901251531-CCN-907-yan.jpg"
        ]
      },
      {
        "sku": "920",
        "description": [
          {
            "id": "ckmc2ma393ogm0897c3lf3ccp",
            "title": "Mineli Sun Kissed Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i0987jpzx50859e24lzzfc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i0987lpzx708597jh37w2y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0987mpzx80859usorcqal",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i0987rpzxc0859nytsnako",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0i0987tpzxe0859nmb7d9nj",
                "price": 15,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0987upzxf0859rt8pbebc",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc2nmww3oj70897zf4bmypa",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc2pw6w3okf0897cvefls84",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc2ma2x3ogl089728ivesbh",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1615902457571-CCN-920.jpg",
          "1615902457591-CCN-920 yan.jpg"
        ]
      },
      {
        "sku": "921",
        "description": [
          {
            "id": "ckmc2rj813olt0897qg3ileh4",
            "title": "Mineli Boyut Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i09lrpq01j0859bb4fdeaf",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i09lrrq01l0859ldq6ut3l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i09lrsq01m0859uj6yml01",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i09lryq01q08596tsqd5oj",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0i09lrzq01s08593bf7sc12",
                "price": 15,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i09ls0q01t0859vl1piyf3",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc2txyc3op10897za6ipgds",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc2ulr93opl089724qgi79w",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc2rj7q3ols08977yqb1r68",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1615902705238-CCN-921.jpg",
          "1615902705232-CCN-921 yan.jpg"
        ]
      },
      {
        "sku": "922",
        "description": [
          {
            "id": "ckmc2yp3h3orp0897and0ooxs",
            "title": "Taşlı Boyut Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxah0k5mfwwm085958q42tzw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxah0k5ofwwo0859igrto2p4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxah0k5pfwwp0859go6ke8f9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxah0k5vfwwt0859fm1d0eht",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckxah0k5xfwwv08591gqnj9vm",
                "price": 15,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxah0k5yfwww0859yzy8wu9d",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc2zw8o3ou10897pykq7wu1",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc30htg3oue08978yawx648",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc2yp393oro0897ejjku5pr",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1615902959718-CCN-922.jpg",
          "1615902959724-CCN-922 yan.jpg"
        ]
      },
      {
        "sku": "923",
        "description": [
          {
            "id": "ckmc3cj6t3ox80897bhfig0zs",
            "title": "Mineli pul pul kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i09vggq05f0859p3o7oq97",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i09vgiq05h0859yyovbh0w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i09vgjq05i0859m1pgl4un",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i09vgpq05m0859fuitbgcw",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0i09vgqq05o0859hipb7637",
                "price": 15,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i09vgrq05p0859nfe2dc72",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmp3xxjxk640959obc3usbb",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmp4bxvxk7v0959rdy7ekdz",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc3cj6l3ox708976olovvar",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1615903585078-CCN-923.jpg",
          "1615903585069-CCN-923 yan.jpg"
        ]
      },
      {
        "sku": "859",
        "description": [
          {
            "id": "ckmc3mi3y3p2a08975h1mlfb4",
            "title": "Işık Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl2wdqy0t66iy0840oedegbz0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl2wdqy0z66j00840zlnoct2o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2wdqy1166j10840m48e1yxp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2wdqy1f66j50840k0den0qh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl2wdqy1j66j70840h9yelv9m",
                "price": 17,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2wdqy1m66j80840cj7jy7mz",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc3nwe13p5n0897aes6an12",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc3p6kz3p5x0897vmr8vswo",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc3mi3l3p290897e9n5jbc8",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1615904132841-CCE-859-S.jpg",
          "1649845423169-37.png",
          "1615904132833-CCE-859-M.jpg",
          "1649845423406-38.png"
        ]
      },
      {
        "sku": "928",
        "description": [
          {
            "id": "ckmc3vj653p9x0897f16z89rg",
            "title": "Mineli Baklava Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr1w36bpi2808592968cemo",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr1w36dpi2a08597r72rvlw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr1w36fpi2b0859lvmphc3m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckmc3ym4p3pdd089744p871ai",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckmc3yyt33pdu0897uts8alwr",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc3vj5w3p9w0897ientmhxe",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1615904406790-CEM-928 sarı.jpg",
          "1615904406804-CEM-928 mavi.jpg"
        ]
      },
      {
        "sku": "929",
        "description": [
          {
            "id": "ckmc43chi3pf008972teg335i",
            "title": "Mineli Daire küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7epc61h4jmi0840zlnkljgh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7epc61s4jmk0840ue1v0407",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7epc61w4jml0840iyx2i80u",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7epc6254jmp0840iok80sr8",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7epc6274jmr08401qi6ow9e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7epc6284jms084039s69slw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cksstwj97nth60859gi3ma2eq",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cksstwye8nto508597n88v41v",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckmc43ch53pez0897j6xb8m54",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1615904887364-CEM-929 SYH.jpg",
          "1648626532924-4.png",
          "1615904887373-CEM-929 TRNC.jpg"
        ]
      },
      {
        "sku": "900",
        "description": [
          {
            "id": "ckngnm07832hl08932neq203n",
            "title": "Parça Desenli Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54flfgnj3gq08406ehzw09c",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54flfgsj3gs0840w56c63yt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54flfgvj3gt0840i37mr10e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54flfh6j3gx08404i6i2tgm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54flfhbj3gz0840s662ibn6",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54flfhdj3h00840b2xtdehh",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54flfhsj3h408402s3m9jko",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54flfhvj3h60840l8x8dh3t",
                "price": 48,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54flfhyj3h70840vwbvda49",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54flfiaj3hb0840izw1uumo",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54flficj3hd0840ew351e1p",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54flfiej3he08408yci9kwp",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54flfilj3hi0840ym42fwyo",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl54flfinj3hk0840kk7kc9d8",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54flfipj3hl0840wtjub2iz",
                "price": 13,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky6h00i7odo70859qpbegzo1",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky6h094godom0859e3ce2ssp",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngnm06v32hk0893tjkk06ny",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1618356180863-CCN-900.jpg",
          "1618356180872-CCN-900 YAN.jpg"
        ]
      },
      {
        "sku": "937",
        "description": [
          {
            "id": "ckngnqhvo32mr0893dvuvd4y9",
            "title": "At Nalı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1yw9w1qv6gk0840cwl86a7c",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1yw9w1zv6gm08404xe5vjw9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1yw9w24v6gn0840n0bkvcgd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1yw9w2hv6gr0840ui01qa9y",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1yw9w2lv6gt0840rsbk428a",
                "price": 56,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1yw9w2mv6gu084061zyr426",
                "price": 20,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmp6i0oxkgp0959509olent",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmp6x2gxki00959n1mtlz25",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngnqhuu32mq08934mw5g1np",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1618356525766-CCN-937.jpg",
          "1618356530696-CCN-937 YAN.jpg"
        ]
      },
      {
        "sku": "938",
        "description": [
          {
            "id": "ckngnvl1532sg0893dyz4lmvc",
            "title": "Çizgili At Nalı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54fennfj2880840tbklz9pr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54fennkj28a0840laljsrcf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fennoj28b0840i81mfpa1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54feno3j28f08408efe4om5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54feno7j28h0840vokgxzrd",
                "price": 35,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54feno9j28i0840e05krxaw",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54fenoij28m084054z39nze",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54fenolj28o08402seh857c",
                "price": 20,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fenomj28p0840t92z1v4y",
                "price": 7,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmp83j2xkom0959rkp4xaow",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmp8jikxkrb09596nyzxc7c",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngnvl0532sf08937rkbzu73",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1618356759997-CCN-938.jpg",
          "1618356770706-CCN-938 YAN.jpg"
        ]
      },
      {
        "sku": "950",
        "description": [
          {
            "id": "ckngo0qrx32vs0893pglo86lx",
            "title": "The roof uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7k6z0tyjxq30840lwsywos8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7k6z0u6jxq50840tky9c4j1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7k6z0uajxq60840ouq3lhhi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngo20lt32xz0893z1yd6nsn",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngo3ia132ya089321x8xqbj",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngo0qr732vr0893wygq73yk",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1618356985251-CPM-950.jpg"
        ]
      },
      {
        "sku": "972",
        "description": [
          {
            "id": "ckngo89hr32z80893k4i9uo5m",
            "title": "Güneş uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54fmmqnj3sn0840lf1b0y5w",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl54fmmqsj3sp0840dkxmk3f2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fmmquj3sq0840otwxv7uk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngo9oxl334d0893j2c64cdg",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngo9yux334n0893qso1biby",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngo89hi32z708934hn2e3to",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1618357338036-CPM-972 MAVİ.jpg",
          "1618357338065-CPM-972.jpg"
        ]
      },
      {
        "sku": "952",
        "description": [
          {
            "id": "ckngocg3b336b0893wvraid2k",
            "title": "The eye uç"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3uwespsz808591139jz3i",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3uweupsza08590bvtbfk6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3uwevpszb0859a4tg45i6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngoe54u338v0893gr380oi5",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngoelqy339s0893bo0det80",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngocg2o336a0893eeu4cpxr",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1618357568185-CPM-952.jpg"
        ]
      },
      {
        "sku": "943",
        "description": [
          {
            "id": "ckngoi1fg33az0893q5nz7p70",
            "title": "Puantiye mineli kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3s92vps9h0859bowp0czt",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3s92xps9j0859k2dsvza9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3s92yps9k0859nd1fllg0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngolnam33e90893boxykk08",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngolxfq33ej0893wsu9kmjj",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngoi1f533ay0893lfw0360j",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1618357794791-CLM-943.jpg",
          "1618357802631-CLM-943 AÇIK.jpg"
        ]
      },
      {
        "sku": "955",
        "description": [
          {
            "id": "ckngp5jqu33lh089304xnnhhb",
            "title": "Baton Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3i2p3pq91085900wozztf",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3i2p5pq930859cjrb5pat",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3i2p7pq9408599y6r9n67",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngp80lv33pd0893zhbj8hd7",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngp8af633pn0893gn9u1vdh",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngp5jni33lg0893ohmi3dxq",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1618358461587-CRM-955 YAN.jpg",
          "1618358452906-CRM-955.jpg",
          "1630324407769-IMG_3951.jpg",
          "1630324407776-IMG_3952.jpg"
        ]
      },
      {
        "sku": "954",
        "description": [
          {
            "id": "ckngpo70833rj0893ifvdg6v5",
            "title": "Tune Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3idj3pqb00859um0y7eau",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3idj5pqb20859flxoff9c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3idj6pqb30859nqhwc55n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngpqmja33tv0893xkkv1t9y",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngprhtr33uf0893ouskbrt7",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngpo6zd33ri0893xu93kewx",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1618359778452-CRM-954 YAN.jpg",
          "1618359695444-CRM-954.jpg",
          "1630324447718-IMG_3944.jpg",
          "1630324447864-IMG_3943.jpg"
        ]
      },
      {
        "sku": "953",
        "description": [
          {
            "id": "ckngpyzdu33w008931zhxpk6r",
            "title": "Mineli ve Taşlı Yuvarlak Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i2xdb4qmdr0859qt34qazm",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl0i2xdb7qmdt0859bcw09gm4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i2xdb8qmdu0859dugpfez4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngq0ujp33yw08930o3o3e5v",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngq160b33z60893jyh0vp9w",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngpyzcu33vz0893s0vjhxyl",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1630324516589-IMG_3945.jpg",
          "1630324516584-IMG_3946.jpg",
          "1618360331281-CRM-953 YAN.jpg",
          "1618360303549-CRM-953.jpg",
          "1630324516315-IMG_3941.jpg",
          "1630324516013-IMG_3942.jpg"
        ]
      },
      {
        "sku": "958",
        "description": [
          {
            "id": "ckngq4kcw341108939jfa3m1m",
            "title": "Saturn Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3hq8rpq2r0859u4jpnz2x",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3hq8tpq2t0859kzu7q9ek",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3hq8vpq2u08592nni2qrd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngq5w1a342v08938bppvkpn",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngq664234350893ocxgfznm",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngq4ka83410089393fgthou",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1630324222014-IMG_3953.jpg",
          "1630324222025-IMG_3954.jpg",
          "1618360529811-CRM-958 YAN.jpg",
          "1618360529786-CRM-958.jpg"
        ]
      },
      {
        "sku": "957",
        "description": [
          {
            "id": "ckngqa1la344l0893goycr9ml",
            "title": "Göz Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3hv5xpq4v0859845z7jrk",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3hv5zpq4x0859chz1686p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3hv60pq4y0859vdqw2p03",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngqxkli347e08930gbjrlk9",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngqxvw7347o0893t0ce3swo",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngqa1j7344k0893mu2l67cq",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1630324262249-IMG_3949.jpg",
          "1630324262828-IMG_3950.jpg",
          "1618360843495-CRM-957 YAN.jpg",
          "1618360843507-CRM-957.jpg"
        ]
      },
      {
        "sku": "956",
        "description": [
          {
            "id": "ckngr1eaj34c20893w6ul3cza",
            "title": "Sultan Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3hy97pq6z0859ewbs2kan",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwr3hy99pq710859lt6x27nd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3hy9apq720859glje0cv9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngr2mi334dr0893273ocny2",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngr2xyl34e10893o832xz4i",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngr1e9t34c108934n7pbds6",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1630324340323-IMG_3947.jpg",
          "1630324340474-IMG_3948.jpg",
          "1618362089092-CRM-956 YAN.jpg",
          "1618362089041-CRM-956.jpg"
        ]
      },
      {
        "sku": "966",
        "description": [
          {
            "id": "ckngrt6ij34h40893r04rlvrv",
            "title": "Taşlı Prizma Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0hyotyypisu08597zfh5qfw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0hyotz0pisw0859lw8uxb4r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hyotz2pisx08593u0nggsf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hyotz8pit10859j9jbyp5h",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0hyotzapit30859u82s6pog",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hyotzcpit408595riudgv8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hyotzhpit80859chhfbc5q",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0hyotzjpita0859udd8wi2f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hyotzkpitb0859uxq7drlv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngt23vh34nb0893h4gvjmi6",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngt2b9c34nm08935ijr8xmd",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngrt6g334h30893ypibtte4",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1646733629984-4.png",
          "1618363397422-CCB-966 YAM.jpg"
        ]
      },
      {
        "sku": "959",
        "description": [
          {
            "id": "ckngt3zq734p50893fwhgh1ew",
            "title": "Trail Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3nmzmcutu7o08402rqpncqm",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3nmzmcytu7q08402vw9465w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmzmd2tu7r0840rz4jd4no",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmzmdftu7v0840zgox7ixd",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3nmzmdhtu7x0840l2p385wc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmzmdjtu7y0840pqtig5ti",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmzmdrtu820840koyn53x0",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3nmzmdttu840840g8jy7m9b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmzmdutu850840pi60dqcs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngt6etx34rd0893hivkilbg",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngt6kck34rn0893tyirp7io",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngt3zpw34p40893cf819ftn",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619207141239-CCB-959-L.jpg",
          "1618365585161-CCB-959 YAN.jpg"
        ]
      },
      {
        "sku": "965",
        "description": [
          {
            "id": "ckngt8a4a34sg08937u9nz9i7",
            "title": "Asimetrik Üçgen Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3nmvfuttt980840p3vci4rt",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3nmvfv0tt9a0840glnxc9ez",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmvfv5tt9b08402u15sfpg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmvfvltt9f0840i46osdwk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3nmvfvqtt9h0840owzbze58",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmvfvttt9i0840bpbjvbvr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3nmvfw4tt9m084020khrv32",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3nmvfw6tt9o0840yewuv7cq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3nmvfw8tt9p08404kyc6mgb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngt9pvz34u70893ib44lo67",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngt9xh234uh0893hhgr2bgw",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngt8a4334sf0893udabidcj",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619207381328-CCB-965-L.jpg",
          "1618365787175-CCB-965 YAN.jpg",
          "1645024363487-965.png"
        ]
      },
      {
        "sku": "960",
        "description": [
          {
            "id": "ckngtc06534va0893a6ghagdg",
            "title": "Dot Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54c5ki0ii4c0840wjauszea",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54c5kiaii4e0840hni7303e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c5kipii4f0840q77ozrwo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54c5kj9ii4j0840y8rj17f4",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54c5kjbii4l0840vtuji0hs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c5kjeii4m0840symry1jn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54c5kjnii4q084028tmz5bj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54c5kjpii4s0840zb8h9lz2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c5kjqii4t0840rzcqjv7k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngtd01v34xg08938afdittp",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngtd68034xq0893mpvidz52",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngtc05w34v90893d6pjuo60",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619207716122-CCB-960-L.jpg",
          "1618365922622-CCB-960 YAN.jpg"
        ]
      },
      {
        "sku": "968",
        "description": [
          {
            "id": "ckngtel4h34yj0893bxa7wm83",
            "title": "Backwards Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac25oqetqe0859v0bnspuf",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac25osetqg0859xz0mmyuk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac25otetqh0859rvfkc9vv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac25p0etql0859mhsk39uf",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac25p2etqn0859rjszna51",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac25p3etqo0859wnoj559l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac25p9etqs08590sddjrxe",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac25paetqu0859935qvc50",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac25pbetqv0859l1y45a39",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngtfn1a350e0893aqvj7cru",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngtfwin350y0893rddrqsh1",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngtel4734yi0893klh5dfig",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619208061748-CCB-968-L.jpg",
          "1618366083333-CCB-968 YAN.jpg"
        ]
      },
      {
        "sku": "969",
        "description": [
          {
            "id": "ckngtipnx351w0893me85d8la",
            "title": "Sword Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku42hadmh7bf08597ajv011g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku42hadqh7bh0859izdg6tyr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42hadsh7bi0859khom5fvw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku42hadyh7bl0859a8xm6jeu",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku42hae0h7bn0859exqbocvo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42hae2h7bo08591q6vjfxy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku42hae9h7br0859d1znjoiv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku42haebh7bt0859toiwfo82",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42haedh7bu0859p51vqqhg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngtjv8f353t0893q3o3rtu3",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngtk2d335430893iquboa0n",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngtipns351v0893dk3uuknh",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619208362658-CCB-969-L.jpg",
          "1618366275002-CCB-969 YAN.jpg"
        ]
      },
      {
        "sku": "964",
        "description": [
          {
            "id": "ckngtljr9354w08936r4a5qzp",
            "title": "Şerit Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku42c4nah6l50859lu6rybho",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku42c4ndh6l70859oi37xlwl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42c4nfh6l80859xpxz3xa0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku42c4nlh6lb0859mc6bz7jo",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku42c4nnh6ld0859ee7g3vo0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42c4nph6le0859kkffpncn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku42c4nvh6lh0859vgjbjjus",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku42c4nxh6lj0859k1499qy0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku42c4nzh6lk085909z8fyho",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngtmnsy356x0893np4c0bb6",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngtmqtn35770893763ldah2",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngtljr3354v0893x4an8mns",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619208561170-CCB-964-L.jpg",
          "1618366403771-CCB-964 YAN.jpg"
        ]
      },
      {
        "sku": "963",
        "description": [
          {
            "id": "ckngtoje135800893m5n8e5ed",
            "title": "Düğümlü Şerit Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5b2fp8knq70859dsd4k9ak",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5b2fpcknq90859dxmw9xex",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b2fpeknqa08591hby9967",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b2fpmknqd0859b41lbsh1",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5b2fppknqf08597ip2aa8l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b2fprknqg08591tg7p6u0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b2fpzknqj0859dpdwuce1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5b2fq2knql0859bf1dutnp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b2fq3knqm085964nhioa3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngtpd8m35a10893x7m40eik",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngtpgtg35ab0893igx4ajpg",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngtojda357z0893y0uixegw",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619209408815-CCB-963-L.jpg",
          "1618366539038-CCB-963 YAN.jpg"
        ]
      },
      {
        "sku": "945",
        "description": [
          {
            "id": "ckngtr8hu35b70893qyrgu97l",
            "title": "Labirent Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54dtab2iqln08402bp5etnk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54dtab9iqlp084050rm4nr5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dtabdiqlq0840x80hijik",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dtaboiqlu08402645nfq0",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54dtabriqlw0840enrm78fp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dtabviqlx0840vmnuwebx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dtac9iqm10840vshhp3kk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54dtaceiqm30840bsx8nepg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dtachiqm40840xasijhaw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngtsdva35ds0893tcw185s3",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngtsjil35e20893afdcx0bg",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngtr8hq35b608937tu7qz14",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619209549462-CBM-945-L.jpg",
          "1618366649318-CBM-945 YAN.jpg"
        ]
      },
      {
        "sku": "970",
        "description": [
          {
            "id": "ckngtttfo35ev08936lr73h3u",
            "title": "Forwards Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57hj11k5ch08593knvsw3f",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku57hj14k5cj0859lrj3b1ei",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57hj15k5ck0859pl69pqjd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku57hj1bk5cn08593x5zxnlm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku57hj1dk5cp0859duzc9s8u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57hj1fk5cq08591hvqbxaa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku57hj1lk5ct0859ftoefbhl",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku57hj1nk5cv0859g3aqvngx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57hj1ok5cw0859rhv3sh1c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngtwuxi35h00893z88u6p1w",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngtx1i135ha0893tw6w5dya",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngtttfe35eu089332k8c5a2",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619209662695-CCB-970-L.jpg",
          "1618366793818-CCB-970 YAN.jpg"
        ]
      },
      {
        "sku": "931",
        "description": [
          {
            "id": "ckngtzigg35i40893d743vrji",
            "title": "Halat bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac1vqgesx70859r226mjm3",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac1vqjesx90859n5ni1tn9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1vqlesxa08594ayat626",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac1vqsesxe085969r2uok8",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac1vquesxg0859fat4okwu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1vqvesxh0859h5f18837",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac1vr2esxl08597qu9fpvv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac1vr3esxn08595b2hvtu1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1vr5esxo085983pmtcfu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckngu0co835kb08933k84btc9",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngu0hxv35kl089317egasxb",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngtzig935i30893gs7q9c4v",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619209776269-CCB-931-L.jpg",
          "1618367036392-CCB-931 YAN.jpg"
        ]
      },
      {
        "sku": "933",
        "description": [
          {
            "id": "ckngu2qb135lr0893tq5dqkf2",
            "title": "Reaction bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54c4th9ii0f0840o7apsis2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54c4thdii0h0840vdo7kc9m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c4thfii0i0840iqxu5ws8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54c4thoii0m0840k15lf8gy",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54c4thsii0o08401feu2v4k",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c4thwii0p0840or64j0dh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54c4ti9ii0t0840be4bsccs",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54c4tieii0v08404y9kkl6q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54c4tihii0w0840x7g4i1q0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlnehi4svld0959uw3worow",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlnerc3svm20959xcsk7usm",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngu2qaw35lq08936ek21tdo",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619209974038-CCB-933-L.jpg",
          "1618367179686-CCB-933 YAN.jpg"
        ]
      },
      {
        "sku": "932",
        "description": [
          {
            "id": "ckngu5n3735p20893z6buenuv",
            "title": "Zincir bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac1x20et0u08596n14s5yy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac1x22et0w0859faidqwsz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1x24et0x0859l7wz126r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac1x2cet1108591q6xdx2s",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac1x2eet130859n5hdote9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1x2fet140859bdfqtgz3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac1x2met1808590avn4cwx",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac1x2oet1a08591zmki2r0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac1x2pet1b08599214gwur",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlndff4svjk09599ogus0zp",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlne502svko09591fqwpvap",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngu5n2z35p1089322pwxxx3",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1619210140867-CCB-932-L.jpg",
          "1618367336232-CCB-932 YAN.jpg"
        ]
      },
      {
        "sku": "949",
        "description": [
          {
            "id": "ckngu8kw935t0089373wrdjxu",
            "title": "Evil eye küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr1x0lapibh0859jad8w8qr",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr1x0mdpibj0859v4kiphll",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr1x0mmpibk08596ntevcmq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlh15z2sl940959r6mvg0w2",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlh1hvqsl9s09599bve69on",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngu8kw635sz0893u7vy2js1",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1618367467147-CEM-949.jpg"
        ]
      },
      {
        "sku": "962",
        "description": [
          {
            "id": "cknguc1gz35we0893qpje1cri",
            "title": "Mineli Lotus Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54fecscj2520840xw907518",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl54fecsjj2540840oqfdjbxl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fecsnj2550840ij0mezb5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlhkpqmsm6709590z8g7v1s",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlhla4msm820959wd2kxa50",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknguc1gu35wd08933b7n4cjj",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1618367594320-CEM962.jpg"
        ]
      },
      {
        "sku": "939",
        "description": [
          {
            "id": "cknguen4t361o0893lrkqel50",
            "title": "Kurukafa Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xwuclh7ly0840eorscuvn",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl39xwucrh7m00840dzr9jamn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xwucvh7m10840ekl4ogag",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlgu2gtskvq0959rwbgs594",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlgubi7skwo0959a65ru2i6",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknguen4l361n0893ccn9cgvb",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1618367776530-CEM-939.jpg"
        ]
      },
      {
        "sku": "936",
        "description": [
          {
            "id": "cknguir1r365q0893gqqf5ujo",
            "title": "Dragon küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr1vz02phxm0859wg4sh7k4",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr1vz04phxo0859524ser1o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr1vz06phxp0859p7ckhei5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlgrrykskr609591ldgzde1",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlgryzmsks109592iqk7wfs",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknguir1m365p089326nlwosx",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1618367943431-CEM-936 KIRMIZI.jpg",
          "1618367944021-CEM-936 SİYAH.jpg"
        ]
      },
      {
        "sku": "935",
        "description": [
          {
            "id": "ckngum33c36ae0893iblnz4ls",
            "title": "Taşlı Dragon Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i661gvsb930859my17wxj8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i661gysb95085939vl2pz4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i661gzsb960859f9wtd8hv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlhtwu7smy60959sw4kt065",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlhu3kysmzz0959eigdyl03",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngum33836ad0893zd69r4vs",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1618368106307-CCE-935.jpg"
        ]
      },
      {
        "sku": "951",
        "description": [
          {
            "id": "ckngus2mu36en0893k63q9u9w",
            "title": "Creamy Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabdmsneflc08599t503s3k",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxabdmsqefle08595z0gdgnj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabdmsseflf0859bmgdhv4u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cknguszsl36g60893uwuex4zz",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckngut3ap36gh089315k9ftrg",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngus2mp36em089324ymx34l",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1618368350978-CCE-951.jpg"
        ]
      },
      {
        "sku": "940",
        "description": [
          {
            "id": "cknguuyff36hd0893mf0odv66",
            "title": "Gate Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr1wzsjpi9k0859o3omtgzo",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr1wzslpi9m0859rg3xji9i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr1wzsnpi9n08593aqiom3i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cknguvpqv36iy0893za4rpvui",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cknguvyvr36ji0893ovwnqqt3",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknguuyfb36hc08930f3mwkw5",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1618368502775-CEM-940.jpg"
        ]
      },
      {
        "sku": "971",
        "description": [
          {
            "id": "ckngux29936jy08933ik1neqy",
            "title": "Casper Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2m6e2pm4w0859dv4o0nfl",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2m6e4pm4y0859cn3hehum",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2m6e5pm4z0859yxzjamqc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cknguyd2e36ll0893ozicn6n7",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cknguz5sc36lv0893tvijzijs",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckngux28y36jx08935t4e5wbd",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1618368633040-CCE-971 -1-.jpg"
        ]
      },
      {
        "sku": "887",
        "description": [
          {
            "id": "cknjbqu2e0cnb099317l2mcmh",
            "title": "Retro Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl549i3ggi7h60840wrrerbfj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl549i3gni7h808406xirtoxj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549i3gri7h908404pvsh0n3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl549i3h6i7hd0840fl6cgcot",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl549i3h8i7hf0840htbywt9j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549i3hbi7hg0840r3je43ks",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl549i3hji7hk0840ym0065t6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl549i3hmi7hm0840842bwwsg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl549i3hoi7hn084026hrraq3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cknjbrzrp0cow099371jchi7l",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cknjbsiuj0cp80993sg7tl7gq",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknjbqu200cna0993bhcr3xhs",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1619210305517-CBM-887-1.jpg",
          "1618517837190-CBM-887 yan.jpg"
        ]
      },
      {
        "sku": "665",
        "description": [
          {
            "id": "cknlu1rsb0pe20993hhkm2vev",
            "title": "İki Renkli Ok Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i0i1tiq2kj0859ekaclnjr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i0i1tlq2kl0859z8x5oaa0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0i1tmq2km0859v4lce2t8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nfatj2ngo70859tnbt81gb",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nfb9y1ngrp0859xw4cxk6y",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknlu1rs50pe109935ix0s0sv",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1618669346498-ccn-665-1.jpg"
        ]
      },
      {
        "sku": "912",
        "description": [
          {
            "id": "cknm0r3g50svd0993nodxvi8r",
            "title": "Merger Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcoot4rxyz70859pldpp0js",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckzcoot4txyz908593sck4luu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcoot4vxyza0859iocf2eg4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nnn26kp7g708593xyi94tz",
            "price": 106,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nnn58fp7gu0859kk721zpw",
            "price": 297,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknm0r3fz0svc0993ynthcaes",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1618680715276-CCN-912.jpg",
          "1618680715283-CCN-912 yan.jpg"
        ]
      },
      {
        "sku": "751",
        "description": [
          {
            "id": "cknm0ud2c0swh0993q1bj60k7",
            "title": "Damla Akışı Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i64j7ssasq0859f1te2bho",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i64j7usass0859ru6seupk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i64j7vsast0859wb4qpof6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cknm13owz0t5d09931hxedbhh",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cknm14io30t5n09939p88xytq",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknm0ud200swg0993gvk59ltp",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1618680844506-CCE-751.jpg",
          "1618680844385-CCE-751-1.jpg"
        ]
      },
      {
        "sku": "924",
        "description": [
          {
            "id": "cknm0wh2t0sy50993u4vq7zbk",
            "title": "Love Song Geometrik Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5iko55lswi0859lscze4l0",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5iko5alswl085991gz8jzs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5iko5clswm0859173q5p3u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmpbjazxlab0959mbkakgcj",
            "price": 99,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmpcl3bxlhm0959ax074lf8",
            "price": 277,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknm0wh2n0sy40993lzf5284b",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1618680982995-CCN-924.jpg",
          "1618680983002-CCN-924 yan.jpg"
        ]
      },
      {
        "sku": "930",
        "description": [
          {
            "id": "cknm0y6ct0szq0993bh5quoic",
            "title": "Encounter Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcop1g9xz2208592ogqvsno",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckzcop1gbxz240859eoy717zx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcop1gcxz250859s0jjl6j3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nnogyip7qf0859m8z3d33a",
            "price": 91,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nnokg1p7r70859d2inany4",
            "price": 255,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknm0y6co0szp099339f8au6b",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1618681078804-CCN-930.jpg",
          "1618681078820-CCN-930 yan.jpg"
        ]
      },
      {
        "sku": "976",
        "description": [
          {
            "id": "cknpwllow19zg09939whkdx5r",
            "title": "Hayalet Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2m9khpm710859l9niz6lh",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2m9kjpm730859s8sjzke0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2m9kkpm740859rmpvc39p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cknpwo44k1a180993gqefvf5j",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cknpwob961a1i09932eowwsdg",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cknpwllo819zf0993j4aafkw9",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1618915516953-CCE-976.jpg"
        ]
      },
      {
        "sku": "486",
        "description": [
          {
            "id": "ckntdfqs7065008917e7srsy7",
            "title": "Mineli Kalın Çubuk Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1agx2ys3s3w0859emi8qorq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1agx2yw3s3y08590w92mvkg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1agx2yy3s3z0859122bphxg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqljq3fvsq0p0959phf940sw",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqljqg79sq1e09599vhxs8hj",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckntdfqrx064z0891oggqjvoj",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1648457298026-IMG_1124.jpg",
          "1648457297867-IMG_1123.jpg",
          "1619125272655-crm-486-1.jpg",
          "1619125272666-crm-486-2.jpg"
        ]
      },
      {
        "sku": "092",
        "description": [
          {
            "id": "ckot4k00y3g7q07594yxzly72",
            "title": "Geniş Künye Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5495ngoi61m08402kkv29wh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5495ngsi61o0840pzg2s9ay",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5495ngvi61p0840ossrxbjf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5495nh7i61t0840efz50k3h",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5495nhbi61v0840p0gjc9hq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5495nhfi61w0840z2t9zzdj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5495nhui62008401o77walr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5495nhzi62208409h8idkzw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5495ni3i6230840o1ym60yq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckot4ln553gdu0759k5dwqa6a",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckot4wbqk3gms0759iqp31h5e",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckot4k00s3g7p0759rzzyxf9x",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646824874611-1629956619660.png",
          "1646824873873-1621287146741-CCB-092.jpg"
        ]
      },
      {
        "sku": "462",
        "description": [
          {
            "id": "ckphayksmg9dw0959hckm04r1",
            "title": "Bone Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xxzulh7v60840xtp2et1x",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl39xxzuph7v8084094kyquq0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xxzurh7v90840r1tt4s1j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckphaz7umg9i30959t04pevod",
            "price": 34,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckphb4iqega7q0959ppp07bym",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckphayksjg9dv0959krggwdi0",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1644366605211-1622749107920-CCP-462.JPG"
        ]
      },
      {
        "sku": "488",
        "description": [
          {
            "id": "ckphbuzafgg230959xeqp0wpt",
            "title": "Lades Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xytwgh8640840vjsm2y91",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl39xytwlh8660840ikw8edxa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xytwqh86708402ro4h7gw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckphbvhs8gg620959ab15v2z6",
            "price": 34,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckphbw5tlggau0959cwa53tv2",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckphbuzacgg220959e65gnvf2",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1644366346759-ccp-488.jpg"
        ]
      },
      {
        "sku": "628",
        "description": [
          {
            "id": "ckphbz3a4gh5s09594nc24lfx",
            "title": "Artuk Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0kt97kg1edi0859dhojzdx1",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0kt97ki1edk0859q6b85tdz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0kt97kj1edl08593tfmtlrv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckphc0134ghel0959wb2e5e0k",
            "price": 34,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckphc04vkghg70959i44ri10p",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckphbz3a1gh5r0959xem986wy",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1644366758173-1622750803237-CCP-628.jpg"
        ]
      },
      {
        "sku": "630",
        "description": [
          {
            "id": "ckphc2y84gi6l09591gj699ln",
            "title": "Üçgen Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xzkykh8gi0840xceejihy",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl39xzkyrh8gk0840m45k54qm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xzkywh8gl0840njeb4qvk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckphc3ox5gift0959r5jfp7ec",
            "price": 34,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckphc3vz9gihw0959vi1aich7",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckphc2y82gi6k0959zlp4fofu",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1644366932161-1622750970580-CCP-630.jpg"
        ]
      },
      {
        "sku": "631",
        "description": [
          {
            "id": "ckphc921egjyc0959mkpnm6w2",
            "title": "Sarmal Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39y0b3gh8nb0840ce3zml78",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl39y0b3jh8nd08403r72trl9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39y0b3lh8ne0840ed21uetl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckphc9s52gk7f0959erxs2j98",
            "price": 34,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckphc9wmjgka20959ad958xx5",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckphc921cgjyb09599ewwmza2",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1644366667341-1622751313788-CCP-631.jpg"
        ]
      },
      {
        "sku": "882",
        "description": [
          {
            "id": "ckphcgiquglmr09599o2upn8e",
            "title": "CCP-882"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0ktooup1gif0859l2l9mcsh",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0ktoous1gih0859vv3rk1tt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0ktoouu1gii0859kximj2cu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckphchblggluk0959x49i5o06",
            "price": 34,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckphcifsngm6e09590syjzbkg",
            "price": 12,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckphcgiqtglmq0959oocs2ca9",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1622751644042-CCP-882   195TL.jpg"
        ]
      },
      {
        "sku": "979",
        "description": [
          {
            "id": "ckphcnb3ugn5v0959umiasia7",
            "title": "İp Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xv59jh741084074p9zlni",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xv59lh7430840t7x7pg84",
                "price": 5,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xv59mh7440840gdofw9yj",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xv59th74808406ovekijl",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xv59uh74a0840g24r3ksu",
                "price": 17,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xv59wh74b08405i7z8ggb",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xv5a5h74f0840nwnx26pp",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl39xv5a7h74h0840fbfilkf7",
                "price": 22,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xv5a9h74i08407aa1e26c",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xv5aih74m08402miefsys",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl39xv5akh74o0840788wb0mx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xv5amh74p08406lq9lyp2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xv5auh74t08408fkxy62d",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl39xv5ayh74v0840xcfw6pnk",
                "price": 11,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xv5b2h74w0840fql5km9a",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckphcr3fbgnqj0959bwf9b3i5",
            "price": 17,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckphcunt9go3z0959gz5js625",
            "price": 6,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckphcnb3rgn5u0959jzgpfhoz",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1644366477869-ccn-979.jpg",
          "1622751853679-IMG_1163.jpg",
          "1622751873913-IMG_1159.jpg",
          "1622751873908-IMG_1160.jpg",
          "1622751909062-IMG_1164.jpg",
          "1622751909111-IMG_1165.jpg"
        ]
      },
      {
        "sku": "967",
        "description": [
          {
            "id": "ckpsv6kwnihut0959nr13eefx",
            "title": "Asimetrik Sword Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1koilmi9uz20859reu2qb60",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1koilmk9uz40859r9aoy59k",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1koilml9uz50859uecn285q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1koilmr9uz90859dv17m46p",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1koilmt9uzb08598dn0lk5o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1koilmu9uzc0859ui07epxs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1koiln09uzg08591d822mw5",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1koiln19uzi0859t6n3dz59",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1koiln29uzj0859yf7ar118",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckpsv7k4gihxj0959rzo85dzu",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckpsvbld1ii7k09598c9p3muq",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckpsv6kwkihus0959kqg2ztjj",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1623509771990-bileklikSablon.png",
          "1623447711824-CCB-967 YAN.jpg"
        ]
      },
      {
        "sku": "1007",
        "description": [
          {
            "id": "ckq744g4s8tfm0959kgbtk1ui",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1qckt0u2hk90759c4tkzcyv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1qckt0y2hkb0759y0v51umx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1qckt102hkc0759t49i2x0x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq7454r98tj30959ygafp6c3",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq7458398tjl0959e7fqpufo",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq744g4m8tfl0959cdq16gxt",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1646728806751-27.png",
          "1624309670347-4.jpg",
          "1624310365539-18.jpg"
        ]
      },
      {
        "sku": "1008",
        "description": [
          {
            "id": "ckq74d9l58u7v095919lxhkn4",
            "title": "Rainbow Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j7armsveuj0859213aocat",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0j7armvveul085963coe1o5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j7armwveum0859mth9hgbp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq74dvra8uaw0959pj6rruja",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq74e2dw8ubw09594rmsdtpk",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq74d9kv8u7u09594b2qol5v",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1624310179098-21.jpg",
          "1624310179078-22.jpg",
          "1624310213868-IMG_0948.jpg"
        ]
      },
      {
        "sku": "1009",
        "description": [
          {
            "id": "ckq74jqbr8v6f0959no8lwulr",
            "title": "Renkli  ip Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1qcj2s22gm60759sxk94hwc",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1qcj2s82gma0759wsou2dzw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1qcj2sa2gmb07596khnb0x1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq74l3c18vaz09595tx8sb0i",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq74lb778vcc09594sfdb5k4",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq74jqbm8v6e0959gtxwcnvo",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1646728826027-28.png",
          "1624310502584-2.jpg",
          "1624310502812-18.jpg"
        ]
      },
      {
        "sku": "1010",
        "description": [
          {
            "id": "ckq74reis8w9f0959e73eiv52",
            "title": "Renkli  İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1pmjq7h17pq0859jjaa7y9n",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1pmjq7m17ps0859jlstaad3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1pmjq7q17pt08596q8nmc7e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1pmjq7z17px08596gy0i6nw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1pmjq8317pz0859yr3r8ntw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1pmjq8417q00859a25ojkxt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1pmjq8o17q4085936mlaxdy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1pmjq8q17q608593rgmf3b1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1pmjq8t17q70859b266fq8s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq74s8my8wea0959lsx3s8rs",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq74sh7c8wfg0959jxtz0gpw",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq74reio8w9e0959k1y2lgo9",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1646728847231-15.png",
          "1624310825287-6.jpg",
          "1624310826372-18.jpg"
        ]
      },
      {
        "sku": "1011",
        "description": [
          {
            "id": "ckq74yhq78x0v095947udb4g0",
            "title": "Renkli  İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1pmk2ip17w30859mbfkq0kh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1pmk2it17w50859mtaeqlq5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1pmk2iv17w60859vb3174wh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1pmk2j417wa0859msbh7oxn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1pmk2j617wc0859su7wt735",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1pmk2j717wd08591zz30kyf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1pmk2jf17wh085907jp5541",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1pmk2jh17wj0859ryazlqx2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1pmk2jj17wk0859d2x3vyhq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq74zk3r8xbc0959laamrtyi",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq74zs9l8xco0959r818dif5",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq74yhq58x0u0959jd2snuhz",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1646728874309-14.png",
          "1624311176970-188.jpg",
          "1624311177704-18.jpg"
        ]
      },
      {
        "sku": "1012",
        "description": [
          {
            "id": "ckq756j1v8ydx0959lgnspfcc",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1qcl8y32hub0759o14a5gam",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1qcl8y62hud0759a1bx8rk2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1qcl8y72hue07594tpvbtpk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq7587co8ymf0959x3m3ir1e",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq758ca98ymz0959jo91p5c7",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq756j1r8ydw0959o8o9v62f",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1646728899834-13.png",
          "1624311537475-182.jpg",
          "1624311540297-18.jpg"
        ]
      },
      {
        "sku": "1013",
        "description": [
          {
            "id": "ckq75d6eg8yyt0959ijedmnir",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl2j3oqo4lnqn08403itx7jcg",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl2j3oqo8lnqp0840i5mxxo9v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2j3oqoalnqq0840wsakstum",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq75gme88zbi0959rbxfwnzv",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq75gtha8zc50959bum45jgp",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq75d6ec8yys0959t5344bnw",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1624311821117-182.jpg",
          "1624311821157-12.jpg",
          "1624311821834-18.jpg"
        ]
      },
      {
        "sku": "1014",
        "description": [
          {
            "id": "ckq75sjdx90ns09591meqqjdh",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl2j3om8vlno00840yegg6az1",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl2j3om8zlno208407ssln9hb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2j3om91lno30840jo8gqp79",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq75t3x490r70959w4uaz0mm",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq75t7jo90rr095931simuf6",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq75sjds90nr09591wfn1gsx",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1624312550657-182.jpg",
          "1624312550652-14.jpg",
          "1624312555522-18.jpg"
        ]
      },
      {
        "sku": "1015",
        "description": [
          {
            "id": "ckq75xlaa918t0959ng21sbxv",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4y7wx2q4jgd0840h4wgsw8r",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4y7wx2y4jgf0840f9al3fbx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y7wx324jgg084064ibzx42",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq75z7v391dw0959uetdympr",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq75zbc691ee09591h20rab5",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq75xla0918s0959prrxlkoy",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1624312822331-182.jpg"
        ]
      },
      {
        "sku": "1016",
        "description": [
          {
            "id": "ckq7650x5929n0959393erlwb",
            "title": "Rainbow Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j7aut0vewm0859qvma5hm2",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0j7aut1vewo0859n5jm0vhz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j7aut3vewp0859pg8m5vb1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq765ue592f10959vcni05k4",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq765yak92fn095971qj9rhu",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq7650x1929m0959du4v3i3u",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1624313160050-ccb1008.jpg",
          "1624313141073-IMG_09548.jpg",
          "1624313141837-IMG_0948.jpg"
        ]
      },
      {
        "sku": "1017",
        "description": [
          {
            "id": "ckq76cpig93br0959vv3f5oii",
            "title": "Rainbow Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j7ax3vveyz08591jgydqmy",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0j7ax3xvez10859tc18mprq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j7ax3yvez20859s0nbbtip",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq76erj193t309594dsaoixc",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq76f5to93vw095987ao362x",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq76cpic93bq0959hdth7nw7",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1624313519771-23.jpg",
          "1624313519752-24.jpg",
          "1624313521036-IMG_0948.jpg"
        ]
      },
      {
        "sku": "1018",
        "description": [
          {
            "id": "ckq76q6kv95zz0959oujwdbnw",
            "title": "Rainbow Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j7azmxvf1108596j6m6lhx",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0j7azmzvf130859frjqttez",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j7azn0vf140859c7tcqqe1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq76qxrm964b0959nx63nwok",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq76r2l896530959ong82uiu",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq76q6kn95zx0959s87o1z4m",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1624314117578-25.jpg",
          "1624314117473-26.jpg",
          "1624314118633-IMG_0948.jpg"
        ]
      },
      {
        "sku": "1019",
        "description": [
          {
            "id": "ckq76t7ey96ea0959iv62uf1d",
            "title": "Rainbow Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j7b200vf3g08599bny7e9s",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0j7b202vf3i08599x1qt24g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j7b203vf3j0859bntzmwk9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckq76ulig96m309599ukfamax",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckq76upzb96ml0959ritn0c4x",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckq76t7ev96e90959yufas8qt",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1624314271755-27.jpg",
          "1624314271716-28.jpg",
          "1624314272625-IMG_0948.jpg"
        ]
      },
      {
        "sku": "915",
        "description": [
          {
            "id": "ckqilstqtbd7j0959wdjub6mg",
            "title": "Mineli Yıldız Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya7j2osxoiu085902qg5c9f",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya7j2ouxoiw08592htxpozu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7j2ovxoix08592az6y7nc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7j2p1xoj10859vmzw2rnl",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya7j2p2xoj30859t0csetbi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7j2p4xoj4085992pv3vsr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7j2paxoj80859ge4thhmj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya7j2pbxoja0859db70d3u4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7j2pcxojb0859hkslks44",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiltn6qbd990959cyj3wmlt",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqilu7npbd9y095977rd0tas",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqilstqobd7i09594jymvqrc",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1633347277703-915.png",
          "1625004461512-915 YAN.jpg"
        ]
      },
      {
        "sku": "977",
        "description": [
          {
            "id": "ckqilyxl8covu09592piv9qaq",
            "title": "Renkli Çizgi Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54ccs7fij2p084021s4o600",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54ccs7kij2r0840firdntzw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ccs7oij2s0840kcyuxh97",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ccs85ij2w0840uiimkps6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54ccs8aij2y0840pu1z2j4a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ccs8eij2z08402fhi39ee",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ccs92ij330840ezb969v3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54ccs95ij3508408f4hbvx4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ccs96ij3608402vvj03t8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqim16f7cozy0959zohxbwsq",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqim1daicp0e0959faqaffcd",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqilyxl6covt0959u7eod8l6",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1633347382049-977.png",
          "1625004845834-CBM-977 YAN.jpg"
        ]
      },
      {
        "sku": "1006",
        "description": [
          {
            "id": "ckqim5iwce0kb0959pbn4bqfw",
            "title": "Karışık renkli bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku3tgh2ifqvg0859tn4g3oeh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku3tgh2pfqvi0859zo6bi40p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3tgh2rfqvj08594x94fswy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku3tgh30fqvm0859flg7s540",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku3tgh33fqvo0859wz2f84ly",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3tgh35fqvp0859gr3ifao4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku3tgh3dfqvs0859s854efoj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku3tgh3gfqvu085977unb0na",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku3tgh3ifqvv0859mhp7wt6t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqim7gq4e0or0959uddt9drp",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqim7rhhe0pe0959dacxshhm",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqim5iw8e0ka0959mn6aejfp",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1625005175109-CBM-1006.jpg",
          "1625005175123-1006 YAN.jpg"
        ]
      },
      {
        "sku": "619",
        "description": [
          {
            "id": "ckqimbfcifc820959yifskrm1",
            "title": "Drop Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabyvf4epe80859pzeb7ks6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxabyvf6epea08598cqhs96o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyvf8epeb08599x8zv9ua",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabyvfdepef0859sbtldv3a",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxabyvfeepeh0859tzotq6xe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyvfgepei0859tup7v19u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxabyvflepem0859txgq4ljn",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxabyvfmepeo085984sweftq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabyvfnepep0859qqrai1o1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqlkermfsqu3095913pkeal8",
            "price": 22,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqlkf2jcsqus0959l6lf7kxg",
            "price": 62,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqimbfcffc810959un4vfpzg",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1625005456162-CCB-619.jpg",
          "1625005456148-619 YAN.jpg"
        ]
      },
      {
        "sku": "946",
        "description": [
          {
            "id": "ckqimnhljfwk40959sy4i7ds5",
            "title": "Simp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0jpaicty6yc0859aco052hx",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0jpaicwy6ye0859urftaj9p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0jpaicyy6yf0859k71rzp2w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0jpaid5y6yj0859vivm9e6k",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0jpaid7y6yl0859lhm03o3l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0jpaid8y6ym0859npqo8adp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0jpaidey6yq0859blmmqas5",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0jpaidgy6ys0859uv8wz5mj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0jpaidhy6yt0859o3tgptuh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqimohkzfwom09597wsx2skx",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqimozbcfwqq0959om00tyqm",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqimnhlgfwk3095956ndyg52",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1646728754486-25.png",
          "1625005759271-CCB-946 YAN.jpg"
        ]
      },
      {
        "sku": "974",
        "description": [
          {
            "id": "ckqineby1g3y40959urneo6af",
            "title": "Karabina bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39x14bxgvzt084090z8qhni",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39x14c3gvzv0840kyaww8jn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x14c6gvzw0840rgop9q7c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39x14cfgw0008409411u3yu",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39x14chgw020840jl49o5v0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x14cjgw030840j5560g2r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39x14cvgw070840s34kse7p",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39x14czgw090840oanvpjk0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39x14d3gw0a0840q6v7gzji",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqinfamgg43b09594ljj811y",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqinfiy7g4450959he7m2boc",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqinebxyg3y30959u421q32c",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1646728776719-26.png",
          "1625007210134-YAN.jpg"
        ]
      },
      {
        "sku": "944",
        "description": [
          {
            "id": "ckqinnjmcg7qs09591s9gh01i",
            "title": "Yaprak Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i0i8wpq2o90859plcw45uj",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i0i8wrq2ob0859c71gndmn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0i8wsq2oc0859ojvkwfxs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nnve7op9aj0859gj2t6ucn",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nnvhjpp9bb0859wiwfn4d8",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqinnjm8g7qr09593pdhvv78",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1628252608221-CCN-944.jpg",
          "1628252608238-CCN-944 2.jpg",
          "1625007695800-CCN-944.jpg",
          "1625007695808-944 YAN.jpg"
        ]
      },
      {
        "sku": "1005",
        "description": [
          {
            "id": "ckqinqg1hgc100959b8j42ouw",
            "title": "Mixed Drops Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcopevzxz5n085996ghsgrc",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckzcopew1xz5p0859ogukz98e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcopew3xz5q08599pc2qvbe",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqmpgq1qxlxa09591npyeawv",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqmph91mxlz70959nwx2bfyr",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqinqg1fgc0z0959g4t4f6mn",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1625007841382-CNM-1005.jpg",
          "1625007841373-1005 YAN.jpg"
        ]
      },
      {
        "sku": "987",
        "description": [
          {
            "id": "ckqinwgc8gsf00959q9qglua5",
            "title": "Taşlı Eşkenar Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i67sipsbrd0859qq3qsl7y",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i67sirsbrf08597eyomfup",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i67sitsbrg0859b4psz67g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqinwz13gshd0959f05xy0m8",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqinx78sgsi50959rdw3ncyh",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqinwgc5gsez0959nxvjuubn",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1625008134179-CCE-987.jpg"
        ]
      },
      {
        "sku": "988",
        "description": [
          {
            "id": "ckqio03ncgsso0959rxvndlvr",
            "title": "Taşlı Dörtgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i67xpisbu308592a1c5wno",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i67xplsbu50859ir30qhoy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i67xpmsbu60859m1fa20q4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqio0mscgsuy0959u75u4npw",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqio0sjmgsvh0959bphicppc",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqio03n9gssn0959s0lrb64b",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1625008284207-CCE-988.jpg"
        ]
      },
      {
        "sku": "990",
        "description": [
          {
            "id": "ckqio2s4qgt3x0959avhhg04a",
            "title": "Yuvarlak Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2syiqpn5t0859wmoxhcmr",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2syispn5v08598md1m4li",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2syiupn5w0859cjwl70xn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqio3cvwgt6509592wtm1vtp",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqio3p53gt7b09599z865bzm",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqio2s4ogt3w0959a96u3ef5",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625008437099-CCE-990.jpg"
        ]
      },
      {
        "sku": "991",
        "description": [
          {
            "id": "ckqio65xjgtcb0959yizmzjcu",
            "title": "Desenli Eşkenar Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2r82opmzc0859u3hv9m8q",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2r82qpmze0859015oqd8b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2r82rpmzf0859ayy4gmn5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqio6onzgtef0959jcytxjjf",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqio6vgxgtf109598ukumajy",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqio65xhgtca0959l7eta0i5",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625008557095-CCE-991.jpg"
        ]
      },
      {
        "sku": "992",
        "description": [
          {
            "id": "ckqiocdcugtri0959ve7u36f2",
            "title": "Şimşek Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2r4mopmx50859e807n8h7",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2r4mqpmx70859y0w29u7v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2r4mrpmx808592stgv37l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiocxi1gttc0959k3jaxyvf",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiod60agtu20959ldbo9989",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiocdcrgtrh0959tqc6m7kv",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625008867764-CCE-992.jpg"
        ]
      },
      {
        "sku": "993",
        "description": [
          {
            "id": "ckqiof9hogu0a0959dda3r4mq",
            "title": "KÜPE"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2r1uopmv50859np0ssc5t",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2r1uqpmv70859739z5dxh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2r1uspmv80859vjqkm1at",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiogdf4gu2z0959i1czwivv",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiogmtegu3j0959km6gam0n",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiof9hlgu090959f6a1lrv6",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625009014586-CCE-993.jpg"
        ]
      },
      {
        "sku": "994",
        "description": [
          {
            "id": "ckqioi44cgu9u0959o6ugl5zb",
            "title": "Lindy Hoop Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabnz14ei0f08599mss5ket",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxabnz16ei0h0859n2z3ltez",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabnz18ei0i0859xwwytspf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqioizqkgud309599sgdvq2h",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiojcoegue7095940m39y1z",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqioi449gu9t0959ftuubosk",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625009152750-CCE-994.jpg"
        ]
      },
      {
        "sku": "995",
        "description": [
          {
            "id": "ckqiol91egull0959a8ch7lcy",
            "title": "KÜPE"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabo6kwei400859x9yi2xij",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxabo6kxei42085923kk06fe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabo6kyei430859qpmg0tyz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqioltl1gunt0959xeb3wv08",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiom1pxguoh0959caqy1txl",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiol91bgulk0959tt07ngzg",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625009284943-CCE-995.jpg"
        ]
      },
      {
        "sku": "996",
        "description": [
          {
            "id": "ckqiopu79guxh0959a0oh17y8",
            "title": "KÜPE"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabodhhei7b0859em6qx4w4",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxabodhiei7d0859uzy7t5g4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabodhkei7e08598o76yepx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqioqig0guzg0959ezv9h3li",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqioqqceguzy0959r6a7wqnj",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiopu76guxg0959v2tj4s0f",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625009504106-CCE-996.jpg"
        ]
      },
      {
        "sku": "997",
        "description": [
          {
            "id": "ckqiosyprgv6l0959n2rfs1n8",
            "title": "Cha-Cha Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2xbv7pnmi0859zz1wbc2i",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2xbv9pnmk08593lpf1lax",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2xbvbpnml0859c8ny1kl6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiov4r6gvcp09597f70qqfd",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiovc27gvdb0959vhl4ytfr",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiosyppgv6k09593obt0hma",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625009640353-CCE-997.jpg"
        ]
      },
      {
        "sku": "998",
        "description": [
          {
            "id": "ckqiox2wsgvl40959n1bm6a6p",
            "title": "Hip-Hoop Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2xak9pnkk0859a09rmqtl",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2xakcpnkm0859wj43wki0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2xakdpnkn0859l6nu1w95",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqioxmwngvnj09597gpyxbg7",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqioxx7pgvop0959602oddkd",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiox2wpgvl30959k286wp4g",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625009844671-CCE-998.jpg"
        ]
      },
      {
        "sku": "999",
        "description": [
          {
            "id": "ckqiozhzfgvvo0959cmrbz16x",
            "title": "Mini Bee Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr2ygrlpnuu0859rxndwglt",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr2ygrmpnuw0859p68hwkzo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr2ygropnux0859acbebpjw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqip04hfgvy50959aizrzvii",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqip0fangvyz0959a22miq35",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiozhzcgvvn09597tmj1gk5",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625009962083-CCE-999.jpg"
        ]
      },
      {
        "sku": "1001",
        "description": [
          {
            "id": "ckqip2kv8gw8j09590n0sc1tx",
            "title": "Bee Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxaboowneibo0859mobz36vj",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxaboowqeibq0859fu365260",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxaboowreibr0859d18a48vm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqip32grgwas0959isupetre",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqip385qgwbc0959jm7hg6uj",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqip2kv6gw8i09590ogfuy4t",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625010093303-CCE-1001.jpg"
        ]
      },
      {
        "sku": "1002",
        "description": [
          {
            "id": "ckqip5k8agwje095956i5gw4g",
            "title": "Şeker Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxabouvxeies085901xxwq1f",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxabouvzeieu0859aqn7ymek",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxabouw1eiev0859rrq76pkh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqip6978gwmk0959kooj1nbn",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqip6vlmgwoi09595o4d4trq",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqip5k88gwjd09597u4z2iew",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1625010232599-CCE-1002.jpg"
        ]
      },
      {
        "sku": "1047",
        "description": [
          {
            "id": "ckqip8vf6gwzv0959aklgshxe",
            "title": "Taşlı Üçgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7k705hijxvu0840sp24gnnq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7k705hnjxvw0840yahsdxzd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7k705hpjxvx08401rrt6msm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqip9dw1gx3m0959mnyj1zkt",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqip9jlcgx4k0959xxwkhbne",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqip8vf3gwzu0959oc5s8027",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1625010379053-CCE-1047.jpg",
          "1625010385769-CCE-1047 YANDAN.jpg"
        ]
      },
      {
        "sku": "1020",
        "description": [
          {
            "id": "ckqipbofcgxcs09598pltad21",
            "title": "Geometrik Elips Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9ow20gdvy0859hl2ad34w",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9ow23gdw00859wk7wp3m7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9ow24gdw10859ellfex5d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqipc5b8gxf30959und7cn1s",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqipcaw8gxfo0959ezaud5vz",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqipbofagxcr0959lcbfu2l3",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625010526941-CEM-1020.jpg",
          "1648626562036-5.png"
        ]
      },
      {
        "sku": "1021",
        "description": [
          {
            "id": "ckqipdiaugxjn0959jsq3jx2s",
            "title": " Geometrik Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9p7w2gdzi08595oetkmi3",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9p7w4gdzk0859gij6z7zz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9p7w6gdzl0859bmovq8r0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqipe361gxlv0959ws10tloa",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqipe93ogxmf0959ttmkfn5a",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqipdiasgxjm0959s8ec5q8r",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625010608191-CEM-1021.jpg",
          "1648626576648-6.png"
        ]
      },
      {
        "sku": "1022",
        "description": [
          {
            "id": "ckqiphycggy320959dnpsq5v6",
            "title": " Geometrik Kare Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9pni8ge32085927axgk3t",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9pniage3408598v584ns4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9pnicge350859yzgswfub",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqipii21gy560959gpi9ipzn",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqipipj8gy5u09590m5wrrb7",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiphyc9gy2z09594f4rzewv",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625010764803-CEM-1022.jpg",
          "1648626596669-7.png"
        ]
      },
      {
        "sku": "1023",
        "description": [
          {
            "id": "ckqipmslqgyho0959ochhykvg",
            "title": "Geometrik Kare Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9q35kge6d0859gdjqtrpm",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9q35nge6f0859epc132vy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9q35oge6g0859zyot7apc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqipnqlvgylb09593sokcq5u",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqipnxbigylx0959wsef3m2h",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqipmslogyhn0959p4nhloc7",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625011040515-CEM-1023.jpg",
          "1648626613321-8.png"
        ]
      },
      {
        "sku": "1024",
        "description": [
          {
            "id": "ckqippwo1gysv0959gcaida61",
            "title": "Geometrik Damla Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9qf8egeaf08591vzfbsu6",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9qf8hgeah0859935gltpn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9qf8jgeai0859hebl51mv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqipqecxgyuz0959raidim4j",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqipqmkrgyvp0959jh7a5crs",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqippwnygysu0959bjh6u4is",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625011193941-CEM-1024.jpg",
          "1648626632634-9.png"
        ]
      },
      {
        "sku": "1025",
        "description": [
          {
            "id": "ckqipsc3kgz2r0959114t81he",
            "title": "Geometrik Altıgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9qt3ogeeo0859r171thoz",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9qt3qgeeq0859etf0qhnz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9qt3sgeer0859mfiu73cg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqipt1pwgz5d0959hrnenf6p",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqipthqygz6f0959fd8d0xsj",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqipsc3hgz2q0959io4k0sg7",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625011299909-CEM-1025.jpg",
          "1648626651412-10.png"
        ]
      },
      {
        "sku": "1026",
        "description": [
          {
            "id": "ckqipwm0ngzhl0959rjg3uin1",
            "title": " Geometrik Altıgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9quedgegw0859nxqzcamk",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9quefgegy0859bp8xj1n1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9quehgegz0859pbfobpl8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqipyut6gznd0959ovurca20",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqipz24ugzoi095926qfp9s1",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqipwm0lgzhk0959rnyyym3j",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625011500255-CEM-1026.jpg",
          "1648626653906-11.png"
        ]
      },
      {
        "sku": "1027",
        "description": [
          {
            "id": "ckqiq0ty7gzvp0959c4z0gnn7",
            "title": "Geometrik Kare Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9rmnpgep60859czvbhnm8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9rmnrgep80859avlgkspg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9rmnsgep90859iwk1goaa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiq205mgzyb095913wv6izh",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiq288ugzz70959tlbp4buy",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiq0ty5gzvo0959io7qlr60",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625011694518-CEM-1027.jpg",
          "1648626671212-12.png"
        ]
      },
      {
        "sku": "1028",
        "description": [
          {
            "id": "ckqiq3p0vh06q0959ntk2mi0w",
            "title": "Geometrik Altıgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9rpnhgeru0859vgf80yfx",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9rpnjgerw0859bllvj12e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9rpnlgerx0859dy55gdnd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiq4c89h09l0959s26qvfr7",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiq4lcuh0b30959nrkribv3",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiq3p0th06p0959l34iapgd",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625011833200-CEM-1028.jpg",
          "1648626688618-14.png"
        ]
      },
      {
        "sku": "1029",
        "description": [
          {
            "id": "ckqiq6y5yh0jn0959to2tuef1",
            "title": "Arctic Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9ruchgeuo0859h7iut2on",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9rucjgeuq0859d3oybpx6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9ruckgeur0859zyf7sn4f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiq993bh0oi0959cancpevr",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiq9gwhh0p10959zhiouoli",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiq6y5vh0jm09596oxb2wtj",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625011973093-CEM-1029.jpg",
          "1648626679365-13.png",
          "1631516280257-IMG_4784.jpg"
        ]
      },
      {
        "sku": "1030",
        "description": [
          {
            "id": "ckqiqcktlh0xp0959yrmsgjh5",
            "title": "Hive Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9rxb7gex20859sr2j63rw",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9rxb9gex40859g4mtd7bg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9rxbbgex50859crxmu034",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiqdvl6h10a09594klm4shu",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiqe4oeh1170959bl8dodrp",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiqcktjh0xo0959xljftk47",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625012231184-CEM-1030.jpg",
          "1648626685507-16.png"
        ]
      },
      {
        "sku": "1031",
        "description": [
          {
            "id": "ckqiqfxefh174095958lp2ot0",
            "title": "Elegant Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54dd4ozimlw08405tewt0sb",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl54dd4p4imly0840tyraf65x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dd4p7imlz0840qp9d9zov",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiqgppqh19i0959dpepvvs0",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiqh1kxh1a30959mejrqw3e",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiqfxech17309590m9pegiu",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625012396200-CEM-1031.jpg",
          "1648626774405-17.png",
          "1631516321568-IMG_4783.jpg"
        ]
      },
      {
        "sku": "1032",
        "description": [
          {
            "id": "ckqiqjtjmh1gt0959scqh145z",
            "title": "Joyful Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9trn6gfct08594stupcws",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9trn9gfcv0859esw3x616",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9trnagfcw0859su7cpsce",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiqlkkrh1jv0959i8vb3c2u",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2uka4ikyi940840i2f13tgm",
            "price": 23,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiqjtjkh1gs0959kmpttlc4",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625012574654-CEM-1032.jpg",
          "1648626789219-18.png"
        ]
      },
      {
        "sku": "1033",
        "description": [
          {
            "id": "ckqiqnhpsh1p80959ww5x73vw",
            "title": "Amalfi Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9u0t2gfgv0859tmsvvtdk",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9u0t5gfgx08593r5sxurj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9u0t7gfgy08593fgrgzco",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiqnzwjh1qw0959lklzptff",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiqo9m5h1rw09598upservl",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiqnhpph1p7095935dgl9r4",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625012743014-CEM-1033.jpg",
          "1648626801037-19.png"
        ]
      },
      {
        "sku": "1034",
        "description": [
          {
            "id": "ckqiqqj08h20f0959fjzfj2qz",
            "title": "Capri Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9uhj9gfnc0859e4ppgmuo",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9uhjbgfne0859ewcrzm43",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9uhjdgfnf0859quu67je4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiqrf0zh23t09596318ezeu",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiqsw3yh27r0959f0aj1y5g",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiqqj05h20e09598yg9ttzy",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625012880280-CEM-1034.jpg",
          "1648626819573-20.png"
        ]
      },
      {
        "sku": "1035",
        "description": [
          {
            "id": "ckqiquq87h2eh0959kt6o70oh",
            "title": "Sardinia Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9uj65gfpg0859xnru6bk4",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9uj67gfpi0859wue0htzh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9uj69gfpj0859wni4v9ah",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiqwaveh2i70959sphhcaim",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiqwik8h2iz0959vr0u6ayd",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiquq85h2eg0959jcr0vtwf",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625013089771-CEM-1035.jpg",
          "1648626822644-21.png",
          "1631516433073-IMG_4780.jpg"
        ]
      },
      {
        "sku": "1036",
        "description": [
          {
            "id": "ckqiqxqsnh2of09592g7cxz9j",
            "title": "Tuscany Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9uwuagfuv0859m3lr1wnt",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9uwucgfux08594dq7odlh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9uwudgfuy0859qbcgr5f4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqiqy7lxh2qd0959mps4fjdr",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqiqydqqh2qw09594m1t9j5r",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqiqxqslh2oe09597rv6mi74",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625013230363-CEM-1036.jpg",
          "1648626839479-22.png"
        ]
      },
      {
        "sku": "1037",
        "description": [
          {
            "id": "ckqir0ihph2xw09596a9irvoz",
            "title": "Geometrik Küme Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9uyc1gfx20859f0s6c624",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9uyc4gfx408591n6w5x9y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9uyc5gfx50859ts3xx6y8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqir12tnh2zz09599rm5o1ea",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqir19elh30h0959qwr2v6gg",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqir0ihnh2xv0959cnw3d3o5",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625013343691-CEM-1037.jpg",
          "1648626842471-23.png"
        ]
      },
      {
        "sku": "1038",
        "description": [
          {
            "id": "ckqir38tqh36n0959kmzbd36f",
            "title": "Ibiza Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9uzwigfzb0859i9r8864m",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9uzwlgfzd0859o6k0roud",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9uzwmgfze0859z91byx9u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqir41snh3940959e4x2jq9m",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqir4b2oh39m09595xy2azlj",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqir38toh36m0959cd0pgbdj",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625013472203-CEM-1038.jpg",
          "1648626845009-24.png"
        ]
      },
      {
        "sku": "1039",
        "description": [
          {
            "id": "ckqir5sglh3f10959yf4i841b",
            "title": "Cadiz Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j3lua7h57i0840ii60ci3d",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7j3luach57k0840s2xozipj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j3luahh57l0840ufpgov4q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqir6bxgh3h709595ggloe7h",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqir6hpkh3hn0959vlvpovsm",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqir5sgjh3f00959azlmppxc",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625013608937-CEM-1039.jpg",
          "1648626863854-25.png",
          "1631516475150-IMG_4790.jpg"
        ]
      },
      {
        "sku": "1040",
        "description": [
          {
            "id": "ckqir8acih3nl0959gw1835e9",
            "title": "Tenerife Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1d9vgwmgg680859inxgmw6e",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1d9vgwogg6a0859giv8578q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1d9vgwxgg6b08592doc9qw4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqir8tynh3ph0959wke5jw75",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqir9110h3px0959u8xtz11a",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqir8acfh3nk0959iv9s4r39",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1625013713144-CEM-1040.jpg",
          "1648626866897-26.png"
        ]
      },
      {
        "sku": "984",
        "description": [
          {
            "id": "ckqirjg3lh4lh0959hxoj9pl0",
            "title": "Kare lazer bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya7k6anxovq0859upr4y7ur",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya7k6apxovs08593yjdmyfr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7k6arxovt0859y2y528m0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7k6axxovx0859i5kxdzhz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya7k6ayxovz085949hnxiuk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7k6b0xow00859hr8hxnfr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7k6b5xow40859di6eydsz",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya7k6b7xow60859cn07adsw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7k6b8xow70859x2vkf0jn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqirkvjvh4oh0959csg63p92",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqirl1luh4ov0959u2owd2cv",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqirjg3ih4lg09593yassugm",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1625014213262-CCB-984.jpg",
          "1625014213255-984 YAN.jpg"
        ]
      },
      {
        "sku": "407",
        "description": [
          {
            "id": "ckqm423p1ugvg0959jp5f20wk",
            "title": "CCB-407"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqm47et8ukvk0959nlheuq0n",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckqm47etlukvm0959p7vt9rk3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqm47etoukvn09591hfm1pk8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckqm47etyukvq09591t8bpdys",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckqm47eu2ukvs0959rgewoim4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqm47eu5ukvt0959arl3np7j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckqm47eufukvw0959yj2iz4yn",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckqm47euiukvy0959fevgxhvf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqm47eulukvz0959lbinzvim",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqm423ovugvf0959mmajhzhv",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1625216529602-juju-logo-beyaz copy.png"
        ]
      },
      {
        "sku": "458",
        "description": [
          {
            "id": "ckqnp8dt2z7k609591tnlkl77",
            "title": "Way Back Home Bilezik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57i72ck5gz08593s7rnnqy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku57i72gk5h10859815ck4yd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57i72jk5h20859b9er56ww",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku57i72qk5h5085992lbda5x",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku57i72tk5h70859y0je49p6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57i72wk5h80859g6mgwpce",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku57i733k5hb0859vyegcukd",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku57i736k5hd0859ikhtvi0n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57i738k5he0859k8e6v837",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzwogj0skuk108597dgek4nx",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzwohvzskuoz0859gba1m0bn",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqnp8dsyz7k50959qar2g72b",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1625312692228-ccb-458-1.jpg"
        ]
      },
      {
        "sku": "5000",
        "description": [
          {
            "id": "ckqozj9jb1v2109594jrp69pc",
            "title": "Test Product"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqozl1r51vfa0959bb2s1mx8",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckqozl1rd1vfc0959ptyx1md4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqozl1rg1vfd0959350kchag",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckqozl1rn1vfg0959xtuho99y",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckqozl1rq1vfi0959y2usukpp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqozl1rs1vfj09596j57pnij",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqozk7y11v930959vwb55x49",
            "price": 111,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqozj9j51v200959g3ms55mk",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1625390464710-placeholder2.jpeg"
        ]
      },
      {
        "sku": "986",
        "description": [
          {
            "id": "ckqp60k5r2nhg0959yll2o3zo",
            "title": "Taşlı Şimşek Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i67delsbkq0859wpp32mp0",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i67deosbks08597wf4qsyy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i67depsbkt08597d9pyk9w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqp6181b2nll0959ac4uwnt8",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqp61lxh2npf09590ve8o8ou",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqp60k5o2nhf09591lol0xl4",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1625401291637-CCE-986.jpg"
        ]
      },
      {
        "sku": "989",
        "description": [
          {
            "id": "ckqp63xvb2o6a0959u0g1j0uv",
            "title": "Taşlı Yuvarlak Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54fuvbnj4qw0840g8rqwwmj",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl54fuvbuj4qy08409am3o8vh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fuvbyj4qz0840i4vp2swg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckqp64wfy2obn0959qf4ebcxc",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckqp653fa2ocz0959c143lu1y",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqp63xv72o690959nhccfe5a",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1625401506313-CCE-989.jpg"
        ]
      },
      {
        "sku": "284",
        "description": [
          {
            "id": "ckqt8sv2tg99c0959zyt5nosk",
            "title": "Boncuklu Afrika Uçlardan Uzun İpli Kolyeler"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqt8tuokg9nj0959kucshts7",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "ckqt8tuorg9nl0959sm6ka72c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqt8tuotg9nm0959f41d279t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqt8sv2qg99b0959alvilptp",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "1120",
        "description": [
          {
            "id": "ckqt93yrrgc6f09593u2e2q1p",
            "title": "Doğal Taşlı Afrika Uçlu Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwoxnhg2isfy0859mdnsanpg",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "ckwoxnhg7isg008594jv6snks",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwoxnhg9isg10859yj3lgo1f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqt93yrogc6e0959g4uu5o65",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1625648272225-logo.png"
        ]
      },
      {
        "sku": "621",
        "description": [
          {
            "id": "ckqt9sz6ogm7d0959fk4gmd43",
            "title": "Doğal Taşlı Afrika Kolye Yazlık"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5imu6ilt940859gob2ut68",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5imu6llt960859gqzg7izz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5imu6nlt970859pmttolgo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye52ukt8ukh0859jmdgycvr",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqt9sz6kgm7c0959t2l90ods",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1625649518793-logo.png"
        ]
      },
      {
        "sku": "578",
        "description": [
          {
            "id": "ckqtaaomegroy0959zs32gbrk",
            "title": "YILDIZLI KELEPCE"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57l59dk5pj0859hs7ttc0d",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku57l59hk5pl0859ivqitvsq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57l59jk5pm085950e4jcyu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtaaomagrox0959vri5yqli",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1625650425085-ccb-578-1.jpg"
        ]
      },
      {
        "sku": "453",
        "description": [
          {
            "id": "ckqtadqybgsky0959m3w614bp",
            "title": "TEL TEL AHMETIN BILEZIGI"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57lty5k5t60859koorkcyq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku57ltybk5t90859q5c065sx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57ltyek5ta0859fzvbnjpe",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye67kqw91fo08592bok31bi",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqtadqy9gskx0959c0heq4sz",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1625653642507-ccb-453-1.jpg"
        ]
      },
      {
        "sku": "474",
        "description": [
          {
            "id": "ckqtan0ydgv6y0959judl2yi1",
            "title": "Fresh Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqtap70ngx2a0959im8amewh",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckqtap70pgx2c09593jzq9ao7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqtap70rgx2d0959mgtku63t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtan0y8gv6x0959eqhij5p8",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1625650967358-logo.png"
        ]
      },
      {
        "sku": "446",
        "description": [
          {
            "id": "ckqtaosoxgwu70959f7bpl2rl",
            "title": "Kurukafalı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57mqqgk5wq08590ej80w1s",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57mqqjk5ws08592ps9hzml",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57mqqnk5wt0859zau0y7sb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtaosovgwu60959y0p7fubr",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1625651061591-ccr-446-1.jpg"
        ]
      },
      {
        "sku": "272",
        "description": [
          {
            "id": "ckqtasmc2gy0u095938efqnpz",
            "title": "Last Day Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye694to91rh0859kyaygcjo",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckye694tr91rj0859k7oubaah",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye694tt91rk0859c8fzqdu1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye69o6291wf08591t23km8b",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqtasmc1gy0t0959rqf56fw3",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "1642150907736-WhatsApp Image 2022-01-14 at 12.01.34.jpeg"
        ]
      },
      {
        "sku": "473",
        "description": [
          {
            "id": "ckqtaw12xgyya09599fom4rjy",
            "title": " Kendinden Kilitli Üzeri Top Top Cuff Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqtaxmdogzaf09598uwikz5o",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqtaxmdsgzah0959b83j4i9a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqtaxmdugzai09599ihh52kk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye5edap8vqk0859ei61hcu7",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqtaw12vgyy90959fikjyjog",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": []
      },
      {
        "sku": "821",
        "description": [
          {
            "id": "ckqtb0l06gzzu0959fgh2i3k2",
            "title": "Doğal Taşlı Bomba Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5cmnpwkyuz085935tu95b8",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5cmnpykyv108597yn4ps67",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cmnq0kyv2085964kx7j14",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5cmnq6kyv50859fx6g2pkv",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5cmnq7kyv70859ch3joq0n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cmnq9kyv80859ffjjtc2h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5cmnqekyvb0859cjl75zl1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5cmnqhkyvd0859wdly3n0u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cmnqjkyve0859hmoip29x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtb0l05gzzt0959cox019za",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": []
      },
      {
        "sku": "340",
        "description": [
          {
            "id": "ckqtbz8x4h9w70959kh7von0i",
            "title": "ARASI KESIK YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye73j4g97z80859ir8bjou4",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckye73j4j97za0859zqvhd2lb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye73j4k97zb08593ygob7l9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye743zb982p0859br5enpx5",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqtbz8x2h9w6095949aunrpq",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1642152325274-WhatsApp Image 2022-01-14 at 12.25.10.jpeg"
        ]
      },
      {
        "sku": "797",
        "description": [
          {
            "id": "ckqtk3w47ijph09599hwuqlvh",
            "title": "Misunderstood Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5csfe0kzha085907lwo9pj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5csfe4kzhc08591ixb99sb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5csfe6kzhd0859ugdvm4ko",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5csfedkzhg0859djgc7wc8",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5csfegkzhi0859n5ajqnvb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5csfeikzhj08593z140b7i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5csfepkzhm08593pf3xr81",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5csferkzho0859eyihuobv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5csfeukzhp0859xm1wydo9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3iom6d9d5p50840y884xyh0",
            "price": 23,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqtk3w42ijpg09595viwxivi",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": []
      },
      {
        "sku": "889",
        "description": [
          {
            "id": "ckqtketvqikxl0959tcwvmnk9",
            "title": "Last Supper Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqtkfh9xil270959qv2li69x",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckqtkfha1il2909595jenpypb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqtkfha3il2a0959tuqhzgwt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3iondhed5tb084095uwdp3a",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqtketvgikxj0959d8urxzpn",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": []
      },
      {
        "sku": "888",
        "description": [
          {
            "id": "ckqtkgo74il820959lc7taw73",
            "title": "Heidi Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5ctogbkzlc0859nriobhl8",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5ctogekzle0859r7ztvi98",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5ctoggkzlf0859hxe9yvp2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckycti2tb4lt80859aih5f8a3",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqtkgo71il8109592laorsj2",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": []
      },
      {
        "sku": "030",
        "description": [
          {
            "id": "ckqtlbu68iow40959bmsdk757",
            "title": "Story Of Us Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqtle3afipnl095965kj68mc",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqtle3aiipnn0959nisjy1da",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqtle3ajipno0959ibk0wipk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtlbu65iow30959nqp5y686",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "556",
        "description": [
          {
            "id": "ckqtlfw9ciq490959awh8qhb8",
            "title": "Driving On The Highway Bilezik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqtlh8twiri00959ujz32bon",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckqtlh8u0iri20959fz0lylcm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqtlh8u1iri30959ugg8d1bh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtlfw9aiq480959u3ennhu2",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "487",
        "description": [
          {
            "id": "ckqtmzzjxj2630959s6i8ity8",
            "title": "Nothing Really Ends Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5d95zml17z0859kyhh9a9a",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5d95zpl1810859rme5v7ig",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5d95zrl1820859cbwbfoir",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtmzzjwj2620959gik6fe6w",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "855",
        "description": [
          {
            "id": "ckqtnc7fij4l20959qwmxy4ps",
            "title": "Kalp Şekilli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5b32h5knxd08590s5ls180",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5b32h9knxf08594blpayep",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b32hbknxg0859jdsxtqjt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b32hjknxj0859yttt0q7n",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5b32hnknxl0859lfwhdzsk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b32hpknxm0859lxs56n6v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b32hwknxp0859z8ewg5i2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5b32hzknxr0859zzor2nbc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b32i1knxs085966az7be8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye5bkpg8vfy0859j8bkvzys",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqtnc7ffj4l10959mvn5rwmc",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": []
      },
      {
        "sku": "0100",
        "description": [
          {
            "id": "ckqtngkzvj8bd0959z8uek1le",
            "title": "Zincirli Boncuklu Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqtnh6ojj8j609599dtdjb7l",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqtnh6onj8j80959u6xcxju7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqtnh6opj8j90959giywc893",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtngkzuj8bc0959z1y9s2nw",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "606",
        "description": [
          {
            "id": "ckqtniko6j8vp0959kx881gm2",
            "title": "Linkage Bilezik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5b3lvtko2m0859uln5xnef",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5b3lvxko2o0859rmbt3uh2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b3lw0ko2p08591r0canxu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b3lw8ko2s0859lvjy3p4r",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5b3lwcko2u08599szd3nl0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b3lweko2v08599nulvozq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b3lwnko2y08597mzd4kuf",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5b3lwqko300859z49shppm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b3lwsko310859kpqzeayq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtniko5j8vo0959t84mf981",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": []
      },
      {
        "sku": "796",
        "description": [
          {
            "id": "ckqtnn97djcnq0959odqfckqz",
            "title": "795İN ARALARI TAŞLI SADESİ (MİNESİZ)"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5cny8dkz0008592qg0lilk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5cny8gkz020859d11xfath",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cny8hkz030859eom18vcx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5cny8nkz060859zq7qgl6c",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5cny8pkz080859btm3427y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cny8qkz090859vc51anm1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5cny8wkz0c0859pe1nb428",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5cny8zkz0e08591yuvqg4m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cny91kz0f0859i41luvub",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtnn97bjcnp0959ay6bmje0",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": []
      },
      {
        "sku": "909",
        "description": [
          {
            "id": "ckqtnxfe1jf0909593w4x2hs7",
            "title": "DRAGON KUPE SILVERI GÜMÜŞ GOLDU BRONZ"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5d6eatl0xk0859ngkchep8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5d6eawl0xm0859fibgimwc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5d6eayl0xn0859abzbza46",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqtnxfdyjf07095923faayza",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": []
      },
      {
        "sku": "403",
        "description": [
          {
            "id": "ckqum07j6l05u0959xbueur7p",
            "title": "Crowded Room Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5iofg9ltjo0859n8kbjkoj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5iofgjltjq0859o0600bwd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5iofgmltjr0859zoy2wkfv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqum07j4l05t0959m381xobo",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "0110",
        "description": [
          {
            "id": "ckqumslfsl3w809590qjcpok6",
            "title": "Around Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye6jhc9945g0859dluzvoaa",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckye6jhcb945i0859vjgw9h9a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye6jhcd945j08599c22utv1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye6k83094cj0859xkx2nifs",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqumslfrl3w70959uzzkua2i",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1642151390521-WhatsApp Image 2022-01-14 at 12.09.38.jpeg"
        ]
      },
      {
        "sku": "865",
        "description": [
          {
            "id": "ckqumvmg3l4mi0959pz0ji0in",
            "title": "Gravity Kolye Ucu "
          }
        ],
        "sizePrices": [
          {
            "id": "cl8bfktbapk2q0840q7bbsyna",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl8bfktbgpk2s0840y6xe8jl3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8bfktbipk2t0840beuclca2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8bfktbrpk2x0840lx9elj5i",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl8bfktbupk2z084064o8hevz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8bfktbwpk300840jjvmtcqg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz70j8elg0g50859qq0q7qif",
            "price": 8,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqumvmg2l4mh0959u0dcdcz8",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1527309-whatsapp-image-2022-09-21-at-12.jpg"
        ]
      },
      {
        "sku": "832",
        "description": [
          {
            "id": "ckqun2f40l5gm0959b41h0nk7",
            "title": "Wolf Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqun390yl5ll0959sr794nsf",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqun3911l5ln0959f0diuasb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqun3912l5lo0959y6zbpwi0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqun2f3zl5gl09591tjfcitl",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": []
      },
      {
        "sku": "706",
        "description": [
          {
            "id": "ckqunn9bcl81t0959fw543ir8",
            "title": "Diana Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqunovrkl8r20959ir33h8c7",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqunovrql8r40959efl6mt5f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqunovrsl8r509591si3s639",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqunn9bal81s0959gnlbri1d",
        "category": {
          "id": "ckku4pxfu0iu80919zgl05fr1",
          "sku": "CCL"
        },
        "photos": []
      },
      {
        "sku": "1049",
        "description": [
          {
            "id": "ckqurbaoqlqze0959vgfbltz7",
            "title": "Disk Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya79odmxlep0859s9tirtlx",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya79odpxler0859dkt9koos",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya79odsxles0859ol0hekh8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckry0ux6iydo40859zrvrmwjm",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckry0w7ubyf3m0859zj94zvtk",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqurbaoolqzd0959a0wxno0j",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1628113639163-CCN-1049.jpg",
          "1628113639218-CCN-1049-2.jpg"
        ]
      },
      {
        "sku": "948",
        "description": [
          {
            "id": "ckqus8jscm4ys0959xt31ez9g",
            "title": "SADE IPTEN YAYLI KILITTEN OLUSAN BILEKLIK"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0qnnefsoarh0859by6x8je3",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0qnnefvoarj0859jicucfj0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0qnnefyoark08592ox05ngg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0qnneg5oaro0859k5ug5gfe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0qnneg7oarq0859v5os9phk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0qnneg8oarr0859le07kv9l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0qnnegfoarv0859mi9hoe3r",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0qnneghoarx0859uzj2pxe8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0qnnegjoary0859kw3b86i3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2udqqjbwwtt0840ag6s8i1p",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqus8js5m4yr0959zvvl1kf0",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1647259276008-0707e41c-e8d7-4561-9188-33231fab74a9.jpg"
        ]
      },
      {
        "sku": "794",
        "description": [
          {
            "id": "ckqusfo92m8re09596vyw2p2f",
            "title": "Just Friends Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5cu535kzpe0859fo657p9y",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5cu538kzpg085926jnt5th",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cu53bkzph08598dem6l6t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5cu53jkzpk0859pm0lr0z8",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5cu53mkzpm08599o55i1i4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cu53pkzpn0859x02um6ns",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5cu53xkzpq085972p1ql49",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5cu540kzps08597lxjg77w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5cu543kzpt0859y8dsxkfy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt40qpqk98go085973oswnnd",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt40qu7j98ho0859ajv9v3w4",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqusfo8xm8rc0959my8phmp2",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": []
      },
      {
        "sku": "667",
        "description": [
          {
            "id": "ckquuebedmgbe09596r6w5z1l",
            "title": "Cindy Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye6fqzx93h808594eyukjix",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckye6fqzz93ha0859wmcgymgc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye6fr0193hb0859mrfqob6m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye6dxqo93390859bdyie1t1",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckquuebebmgbd0959ifo19ki4",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1642151216128-WhatsApp Image 2022-01-14 at 12.06.39.jpeg"
        ]
      },
      {
        "sku": "815",
        "description": [
          {
            "id": "ckquun74mmi1p0959zysytb50",
            "title": "Cherish Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5irc44lu7v0859y5ctd11p",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5irc47lu7x0859fbhr3rrr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5irc4alu7y0859i3879h0y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5irc4hlu81085934wxkd5s",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5irc4jlu830859ozxsxub3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5irc4llu840859ubzn0di3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5irc4rlu870859ezsavjkm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5irc4ulu890859y8pf3b09",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5irc4wlu8a0859errlolar",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckquun74kmi1o0959fv758l95",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "980",
        "description": [
          {
            "id": "ckquy58p0mxms0959sqs9eua9",
            "title": "Two Of Us Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl348kundntxk08403g1brm77",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl348kunintxm0840y977qhrc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl348kunlntxn0840jmdvvngz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl348kunwntxr0840vqsoqmz4",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl348kunyntxt0840bb9d0yl6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl348kunzntxu0840p711wrxf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl348kuo6ntxy0840iojljpeq",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl348kuo8nty00840ofzaluu6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl348kuo9nty108405uiwmq9g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl348kuognty50840fxfcbwmr",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl348kuojnty70840g9bnq6wi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl348kuolnty80840o1i8wx8s",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye54nw28usd0859amjyshce",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckquy58ovmxmr0959uqmkc9vu",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1642148300934-WhatsApp Image 2022-01-14 at 11.17.55.jpeg"
        ]
      },
      {
        "sku": "528",
        "description": [
          {
            "id": "ckquyhi0qn0wf0959ro8ppn0x",
            "title": "Pretty Piano Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckquyi9bjn1la0959c0um30tb",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckquyi9bmn1lc0959z6dvki6s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckquyi9bon1ld0959nnjqf064",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckquyhi0on0we0959d4qyc2gg",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "754",
        "description": [
          {
            "id": "ckquzc11on7yu0959g9n8l9ig",
            "title": "Zen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5d6vqel10p0859is3xzf5e",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5d6vqhl10r0859xsvpclfd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5d6vqjl10s0859l9snvggm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckquzc11mn7yt0959f4um9lnu",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": []
      },
      {
        "sku": "566",
        "description": [
          {
            "id": "ckquze6s2n97a0959bnnxulil",
            "title": "Altıgen Mineli Taşlı Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5d0idul0gb08597ih53gys",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5d0idyl0gd0859i35augdt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5d0ie0l0ge08599fu77ic6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckquze6s0n9790959dg3zifmb",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": []
      },
      {
        "sku": "612",
        "description": [
          {
            "id": "ckquzxj4jnjqm0959cypzo9a8",
            "title": "Get You Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57nh0nk6120859kf648m07",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku57nh0pk61408592gf03pg0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57nh0rk6150859m3322j73",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckquzxj4hnjql0959348dxulc",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": []
      },
      {
        "sku": "601",
        "description": [
          {
            "id": "ckqv0bho5ntlb0959fogwlfuo",
            "title": "Leaving Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57o2k6k6630859fr2ib8yv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku57o2k9k66508592clbqoxe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57o2kbk6660859qd8wwqvo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv0bho4ntla0959zz8z8hbo",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": []
      },
      {
        "sku": "325",
        "description": [
          {
            "id": "ckqv0hcw0nvl80959u0c316rs",
            "title": "Secret Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57oxvjk6ck0859qwti5bzg",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57oxvmk6cm08597fiks2w5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57oxvnk6cn0859mu7z9xh4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz6u2cggeajr0859o79rqikj",
            "price": 24,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqv0hcvynvl70959oscotdfs",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "331",
        "description": [
          {
            "id": "ckqv0ldr2nwd50959xtpniihi",
            "title": "UCGEN YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57pie4k6ge0859kblrgdks",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57pie6k6gg0859b6hy3d7y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57pie8k6gh08597lbjrjj8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv0ldr0nwd40959zbkz1aof",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "336",
        "description": [
          {
            "id": "ckqv0n5r5nws009599widpdm6",
            "title": "Skull"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye63r0d90ou0859xtmqy9mc",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckye63r0f90ow0859la2f61w6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye63r0h90ox0859pbg416nx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye53sja8uoj0859ywepwgua",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqv0n5r3nwrz0959ejnvi3hm",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1642150656419-WhatsApp Image 2022-01-14 at 11.57.24.jpeg"
        ]
      },
      {
        "sku": "334",
        "description": [
          {
            "id": "ckqv0or82nx4209590is88rv2",
            "title": "Kovboy Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57qo6kk6ly0859cd4nnplx",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57qo6nk6m008595ycaxrrx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57qo6ok6m108592i20ha51",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye8qfoh9glk085992gifz7r",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqv0or80nx410959ejpmg51v",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "339",
        "description": [
          {
            "id": "ckqv0qddznxn80959m4ttdmyo",
            "title": "Skull The Hatter Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye61fkz907408595uw6u3t0",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckye61fl290760859nvwsyfsq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye61fl390770859kz33x4hx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye623on90ct0859jxr06rmq",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqv0qddwnxn70959gutk5vt2",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1642150548558-WhatsApp Image 2022-01-14 at 11.55.35.jpeg"
        ]
      },
      {
        "sku": "328",
        "description": [
          {
            "id": "ckqv0s8fvnxzj0959tsvsxnnw",
            "title": "Hide Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57rzowk6u20859gsq0brd4",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57rzoyk6u40859a5l8snyk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57rzp1k6u50859oqc84aro",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz6u41koeb080859nb247q2s",
            "price": 24,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqv0s8funxzi0959slf41zlr",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "315",
        "description": [
          {
            "id": "ckqv0vns4nymh095944e69673",
            "title": "Swift Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57sn8jk6xk0859c6pjw6bh",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57sn8mk6xm08594te9j8ae",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57sn8ok6xn08595c38xggk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv0vns2nymg0959a7rsjvio",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "327",
        "description": [
          {
            "id": "ckqv0yjtfnzci09597g7iq6uh",
            "title": "USTU DUZ YUVARLAK HALKALI HEMDE KOLYE UCU"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57t78ak71k0859n24qc8v4",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57t78dk71m0859qgvi4ea5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57t78ek71n0859vurbrjnn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz6suyeqe03z0859jqjidgca",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqv0yjtcnzch095999fv7zo8",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "326",
        "description": [
          {
            "id": "ckqv11wooo00h0959gfdq3q2n",
            "title": "Touch Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57tutwk75408591cj2qtup",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57tutzk75608594qtre8fk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57tuu7k7570859jtwqokms",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv11wono00g0959mnni3hmn",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "332",
        "description": [
          {
            "id": "ckqv143aqo0ee0959tuaayoi9",
            "title": "Spooky Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57ueb8k78b0859ecu45v0g",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57uebbk78d08598ibrrl3n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57uebdk78e08595dzr2ljl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv143apo0ed095941g9zkkw",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "341",
        "description": [
          {
            "id": "ckqv15jsuo0u20959qi1y7vls",
            "title": "Cut Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57vfdyk7bk0859aklxd4px",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57vfe1k7bm08597oi24dfo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57vfe2k7bn08591g40b8jm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv15jsto0u109597nydixkd",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "322",
        "description": [
          {
            "id": "ckqv174hno17p09595hisf896",
            "title": "Apocalypse Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57w735k7er0859b2u4rswk",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57w738k7et0859uttdpir0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57w73ak7eu0859o8nu4ed2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv174hmo17o0959fuzftcf9",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "345",
        "description": [
          {
            "id": "ckqv19fq1o1mv0959p0dejs34",
            "title": "UCLU YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57xfikk7jd085904yyr7q6",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57xfink7jf0859n604sk8h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57xfipk7jg0859vmh73bre",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye940tz9j3c08590ug0xvv6",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqv19fpzo1mu0959efzsb7ji",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "346",
        "description": [
          {
            "id": "ckqv1bgm9o24s0959mmgsgeng",
            "title": "VIDALI YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5d9ppzl1ab085981wxok7z",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5d9pq3l1ad08598jlbg9rz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5d9pq5l1ae0859b4qbn7iq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz70i73mg0aq0859qdp7jzvn",
            "price": 18,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqv1bgm8o24r0959jgxht8ct",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "367",
        "description": [
          {
            "id": "ckqv1d73oo2kq09594yb6anjd",
            "title": "CIZIK CIZIK GENIS YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqv1dvp7o2tk09590pcd8wd3",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckqv1dvpco2tm0959g3a97pkb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqv1dvpfo2tn0959bwhy9a8b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1d73mo2kp09590oiod7qy",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "482",
        "description": [
          {
            "id": "ckqv1f3qno37i0959c6c6wkly",
            "title": "Chillin Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57y887k7mk0859pf9keob8",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57y88ak7mm08596qg4ae3p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57y88bk7mn0859xzxit3l3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1f3qmo37h0959fvku4lv7",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "320",
        "description": [
          {
            "id": "ckqv1h1ilo3t40959mj9yd4ph",
            "title": "Earth Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57yqsok7p70859a5qv2nx7",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57yqsrk7p90859gtni3m6z",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57yqsuk7pa085990ltaee5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1h1iko3t309593dxp0d70",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "317",
        "description": [
          {
            "id": "ckqv1j31wo4890959j2ojexqb",
            "title": "Dotty Dot Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57z82ck7rs0859ovyonkyi",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57z82ek7ru0859qnnxldh6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57z82gk7rv0859t5lrh0vp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1j31uo48809596vxfrend",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "316",
        "description": [
          {
            "id": "ckqv1l03po4oa0959ssk0we02",
            "title": "4Ever Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku57zrw4k7ur0859ztrrbfcx",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku57zrw6k7ut0859sxkrctt4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku57zrw8k7uu08594h9tqfe7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1l03oo4o90959r1jwfi2b",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "321",
        "description": [
          {
            "id": "ckqv1pepzo5ce09599akvcykr",
            "title": "Through Me Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku580dfwk7y2085943fkfjoj",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku580dfzk7y40859d1hiucht",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku580dg2k7y50859promdi29",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1pepxo5cd0959wsz8hvvf",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "806",
        "description": [
          {
            "id": "ckqv1s2l7o5wc0959i38o00nt",
            "title": "YAPRAK YÜZÜK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku580yoek82c0859sgoqlj8t",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku580yogk82e0859ukc3knhp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku580yoik82f085934dctcex",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1s2l5o5wb09596j0z15ia",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "253",
        "description": [
          {
            "id": "ckqv1vp77o6ji0959ty1rcg4c",
            "title": "KALIN USTU ÇİZGILI ERKEK YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku581jp1k86h0859huuev85o",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku581jp4k86j0859qr6af3ey",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku581jp6k86k0859tu48z75d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1vp75o6jh0959xzh95974",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "807",
        "description": [
          {
            "id": "ckqv1zgugo73x09597s8zjaoa",
            "title": "Heavenly Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5835lck8bn0859uzx4fl84",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5835lfk8bp08590760rces",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5835lhk8bq0859jicvt3l8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv1zgufo73w0959guc6kege",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "364",
        "description": [
          {
            "id": "ckqv20zz9o7f109596uis7z09",
            "title": "Ace Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku586y29k8ol0859n1tvr0ya",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku586y2bk8on0859lu0vpgal",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku586y2dk8oo0859j0dmp0zj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv20zz7o7f009599febxm5p",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "337",
        "description": [
          {
            "id": "ckqv3uf7iodji095968aocmfh",
            "title": "Sirens Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku584mtbk8h70859toikx3uj",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku584mtfk8h90859plhlo0nv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku584mthk8ha085968qg0rv4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqv3uf7godjh095959vv56t4",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "536",
        "description": [
          {
            "id": "ckqw24ffgq9y60959b2niutxi",
            "title": "OVAL MINELI KOLYE UCU"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqw27t7nqad70959jns14trg",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqw27t7rqad90959lltvfg6b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqw27t7tqada09595w08llw4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqw24ffeq9y50959u3me8hjw",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": []
      },
      {
        "sku": "538",
        "description": [
          {
            "id": "ckqw2a1byqbdu0959uz1vij9v",
            "title": "DIKDORTGEN TASLI MINELI KOLYE UCU"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqw2cafyqj4l09598skhfeqj",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqw2cag2qj4n09598rh5i0ge",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqw2cag4qj4o09590l7tr6gs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqw2a1bvqbdt0959bk768ew3",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": []
      },
      {
        "sku": "596",
        "description": [
          {
            "id": "ckqw2dtdjqjde09598qnxnz3t",
            "title": "Dikdörtgen mineli uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cku587htxk8tj0859ajhxe29g",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku587hu0k8tl0859d1fxhvde",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku587hu2k8tm0859yzamuv65",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqw2dtdhqjdd0959g6l75ac8",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": []
      },
      {
        "sku": "537",
        "description": [
          {
            "id": "ckqw2fqu2qkfe095965h07czg",
            "title": "Üçgen taşlı mineli uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58eo21k9l10859opt5o94e",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58eo23k9l30859u6511flp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58eo25k9l408594x92k9o8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqw2fqu1qkfd0959r9ro3rnf",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": []
      },
      {
        "sku": "534",
        "description": [
          {
            "id": "ckqw2llicqsyl0959gq142uy8",
            "title": "Cruising Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58ebdjk9gm085932odfcti",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58ebdnk9go0859t3cxuggc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58ebdpk9gp0859ybcrwm9l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl31drr55sdfl0840u4m3y0c9",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqw2lliaqsyk0959z8o07idc",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": []
      },
      {
        "sku": "0111",
        "description": [
          {
            "id": "ckqw2xvh8qwoe09592vi018wp",
            "title": "Pear Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqw2yah1qwus0959ytrmrpcr",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqw2yah5qwuu09592njwjpxe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqw2yah7qwuv0959at4b06yk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye6vtey96mo08598xpx5wuh",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqw2xvh6qwod0959smnkhlvt",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": []
      },
      {
        "sku": "418",
        "description": [
          {
            "id": "ckqw3f5vhqzsn0959cjuebo88",
            "title": "Venüs Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i6h2a3sdzl08590d5oaux0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i6h2a6sdzn0859ki7la9bs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6h2a8sdzo0859bf9phqxi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i6h2agsdzs0859w044z0du",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0i6h2ahsdzu0859bhp21hrh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6h2ajsdzv0859m9r0t2xl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqw3f5vfqzsm09597nqwwnuo",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": []
      },
      {
        "sku": "530",
        "description": [
          {
            "id": "ckqw406xbr3i20959l8i47i7n",
            "title": "One More Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye4xxpi8twd0859attcd5gm",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckye4xxpl8twf0859zr0trxhs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye4xxpn8twg08592t061ylt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye53cd08umn085986clqzj2",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqw406x7r3i10959ox7o3hge",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1642148705390-WhatsApp Image 2022-01-14 at 11.24.45.jpeg"
        ]
      },
      {
        "sku": "559",
        "description": [
          {
            "id": "ckqw42da1r3yb0959rx7bd96b",
            "title": "Cosmic Dance Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58gu6kka330859qrx1rzel",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58gu6oka35085976bh0prl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58gu6rka360859tljq2woe",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqw42d9zr3ya0959ahoe8ec5",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "529",
        "description": [
          {
            "id": "ckqw44wdlr4qc0959xxiob8d1",
            "title": "Domino Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye5wzqo8zah085928wz55tv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckye5wzqr8zaj0859di03g10l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye5wzqs8zak0859j4uzvbkd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye5xtto8zfw08590pit045c",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqw44wdjr4qb09598zuwrf2y",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1642150341488-WhatsApp Image 2022-01-14 at 11.51.54.jpeg"
        ]
      },
      {
        "sku": "531",
        "description": [
          {
            "id": "ckqw4791vr5hm09590dhw7she",
            "title": "Maria Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye796rj98sm0859in3pbs9w",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckye796rl98so0859vab9yi0a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye796rm98sp0859n69qbm2v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye786hr98my0859xxdh5n7z",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqw4791tr5hl0959glmp28se",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1642152589835-WhatsApp Image 2022-01-14 at 12.29.21.jpeg"
        ]
      },
      {
        "sku": "425",
        "description": [
          {
            "id": "ckqw5sb5erdzs0959tdurjbsp",
            "title": "CARM BÜYÜK KÜPE KOLYE UÇLARI(GÖZ-KAKTÜS-FİL-AY-AYAK) (KUPE CIFTI 275TL)"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58nu1rkazi08594igpnvc9",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58nu1vkazk08592g3wnx3v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58nu1xkazl08591bq171ox",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqw5sb5brdzr0959pg4avztl",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": []
      },
      {
        "sku": "426",
        "description": [
          {
            "id": "ckqw6faofrhg50959edbuybrs",
            "title": "CARM BÜYÜK KÜPE KOLYE UÇLARI(GÖZ-KAKTÜS-FİL-AY-AYAK) (KUPE TEKI 155TL)"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58nfvnkavx0859i7bs57ch",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58nfvqkavz0859jxg3czqx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58nfvskaw00859m3fjw778",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqw6faodrhg40959jzhes7z5",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": []
      },
      {
        "sku": "5960",
        "description": [
          {
            "id": "ckqwcigbxsg1w0959bh00jb0n",
            "title": "DIKDORTGEN INCE MINELI YUZUK "
          }
        ],
        "sizePrices": [
          {
            "id": "ckqwco8a5shh70959zw4ch366",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqwco8aashh90959l2x76cf6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqwco8adshha095983i3kgxx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqwcigbssg1u0959iubxppwo",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": []
      },
      {
        "sku": "419",
        "description": [
          {
            "id": "ckqwdj1etsldt0959dz5h3tsi",
            "title": "Jasper Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i68fwasc290859jr8gqp6n",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0i68fwcsc2b0859munh80ws",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i68fwesc2c0859x3ujxqq8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i68fwjsc2g0859e26qy3fa",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0i68fwlsc2i0859c3vdtc2b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i68fwmsc2j0859naw2aluc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i68fwssc2n0859w6oh491c",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0i68fwusc2p0859sxytwjzv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i68fwvsc2q0859g1f74grw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i68fx0sc2u08594fk58w52",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl0i68fx2sc2w0859u191hjqq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i68fx3sc2x0859pap9nzt0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0i68fx8sc310859n4p2parj",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl0i68fxasc330859mcpgmlx7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i68fxbsc3408592y3pd917",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqwdj1erslds0959z6ut9rew",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": []
      },
      {
        "sku": "1450",
        "description": [
          {
            "id": "ckqxs2ghqvfws0959oul866n8",
            "title": "Tarihi Eser Aya Benziyen Boncuklu Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqxs635qvgs90959gbspppli",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqxs635uvgsb0959eix6jx1x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqxs635wvgsc0959ygf04t3l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxs2ghpvfwr095905ysneuz",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "1090",
        "description": [
          {
            "id": "ckqxsasm1vhid0959ffo53xej",
            "title": "Boncuklu Çok Taşlı Eski Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqxsb81cvhlu09593ctmtyo7",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqxsb81gvhlw0959vpka1a88",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqxsb81ivhlx09595rkd3qdg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxsaslzvhic0959wgm0tch6",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "310",
        "description": [
          {
            "id": "ckqxsihs7vhyt0959shdjc18c",
            "title": "Küçük Boncuklu Zincirli Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye6h3rv93qi08596mqohpm9",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckye6h3rx93qk0859jdiw6byg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye6h3rz93ql0859yxtr57iz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye6hro793v00859f9snno7z",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqxsihs4vhys09594zsjvoau",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1642151279425-WhatsApp Image 2022-01-14 at 12.07.44.jpeg"
        ]
      },
      {
        "sku": "199",
        "description": [
          {
            "id": "ckqxsxlsivkq20959l51836dv",
            "title": "Boncuklu Fil Dişli Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqxsy1fcvktd0959x4rkx3u5",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqxsy1ffvktf0959uq7qbddj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqxsy1fhvktg0959tipqa0aa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxsxlsfvkq10959dhh7pc8k",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "219",
        "description": [
          {
            "id": "ckqxt6wcivlio0959yzzpn2cu",
            "title": "Boncuklu Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye6bcfy92c60859tok89lvq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckye6bcg092c80859kucjm6tr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye6bcg192c90859tvhr2w4d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye6bwup92h90859263el65r",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqxt6wcgvlin0959zccvi7qu",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1642151010963-WhatsApp Image 2022-01-14 at 12.03.15.jpeg"
        ]
      },
      {
        "sku": "215",
        "description": [
          {
            "id": "ckqxt8xnavm1w0959iexlhlnu",
            "title": "Lacivert Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqxt9bivvm6d09597zone46t",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqxt9bixvm6f0959cnzwha8r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqxt9bizvm6g09596k2cgpcf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxt8xn7vm1v09597skm5svp",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "216",
        "description": [
          {
            "id": "ckqxtaql3vmcl0959m14ot47a",
            "title": "Afrika Taşlı Boncuklu Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqxtb0hgvmfd0959nrfsf4a7",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqxtb0hivmff0959lma8tkkf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqxtb0hkvmfg0959dwhvatqa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxtaql2vmck0959n9jzrnzk",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "417",
        "description": [
          {
            "id": "ckqxtxbtlvon20959meira5g3",
            "title": "Doğal Taşlı Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58sa4skbg60859rq61v66z",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58sa4wkbg80859r5img3wg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58sa4ykbg908595tqwjvok",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxtxbtjvon109598un0cax4",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": []
      },
      {
        "sku": "0109",
        "description": [
          {
            "id": "ckqxtzjzivp0k0959nwfg07p2",
            "title": "Metal Top Şeklide Taşlı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i6h6ktse2a0859b2wc1683",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl0i6h6kvse2c0859cwsvpqd0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6h6kxse2d08592f99huag",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxtzjzgvp0j0959mrw5zh08",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": []
      },
      {
        "sku": "973",
        "description": [
          {
            "id": "ckqxudbdevqzc0959bbwu6rj5",
            "title": "Mineli Kaplumbağa Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58tgtmkbl708596mk9vjjn",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58tgtpkbl90859uso491bm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58tgtrkbla0859va7012ie",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz70krmug0qm0859pc5vfwqc",
            "price": 8,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqxudbdcvqzb0959zrnkgnwx",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": []
      },
      {
        "sku": "947",
        "description": [
          {
            "id": "ckqxuq10cvsv209591md1m7zq",
            "title": "Yuvarlak Taşlı Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i68dm9sbyk0859nvs41zen",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i68dmbsbym08593dcufmwh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i68dmcsbyn085996bo4ixm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxuq10avsv10959ppn3dr1a",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": []
      },
      {
        "sku": "542",
        "description": [
          {
            "id": "ckqxvbg2wvvef0959p6xs4p9i",
            "title": "İnce Yuvarlak Mineli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58ugqykbt90859o2evzijc",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58ugr1kbtb0859dh5432ey",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58ugr3kbtc085907kkmian",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxvbg2tvvee0959dpcixtm9",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": []
      },
      {
        "sku": "597",
        "description": [
          {
            "id": "ckqxve1ydvvql095996t0t1sn",
            "title": "İnce Altıgen Mineli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58uwlfkbwf0859jgld9ymr",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58uwlikbwh0859hvw1r96n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58uwllkbwi0859h25zbek9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxve1yavvqk09592cg8p3y4",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": []
      },
      {
        "sku": "544",
        "description": [
          {
            "id": "ckqxvfs05vw050959kot5m481",
            "title": "Ortası Mineli Taşlı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58vfhlkbzw085992dktvgv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58vfhokbzy085928xjikfj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58vfhqkbzz08590bxtqca2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxvfs04vw040959uqu5nd3k",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": []
      },
      {
        "sku": "545",
        "description": [
          {
            "id": "ckqxvgjskvw740959t83fizyk",
            "title": "Ortası Mineli Taşlı Üçgen Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cku58vv4akc2x0859t35y7vop",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku58vv4dkc2z0859pzdb5rez",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku58vv4fkc300859hyfyvhgq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxvgjsjvw730959pygfbnhb",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": []
      },
      {
        "sku": "829",
        "description": [
          {
            "id": "ckqxvrb7qvwyr0959byugb1e6",
            "title": "EN INCE USTU TOP TOP AHMETIN YAPTIGI YUZUKLER"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5dbwvql1fv08590scowbhy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5dbwvul1fx0859jycc72q7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5dbwvxl1fy08595n45ydpe",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5dbww6l1g10859qma0w9ro",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cku5dbww9l1g308594j6thbka",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5dbwwbl1g408599yg9a2hl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye8vrlm9hna0859kjbpjp2u",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqxvrb7pvwyq0959y4ded2s9",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "8290",
        "description": [
          {
            "id": "ckqxw1g2dvxnv0959qs0fyr57",
            "title": "Yuvarlak Üzeri Tırtıklı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqxw2rcgvxyw09591j37ajpj",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckqxw2rcivxyy0959q2v5qdb7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqxw2rcjvxyz0959b8stsivx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye9b4tf9k7u0859uive4qjq",
            "price": 8,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqxw1g2bvxnu0959kgfsl1kd",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "093",
        "description": [
          {
            "id": "ckqxx8fyaw19s095927lwdfs5",
            "title": "Taşlı Şekilli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i6hbusse510859079u2mcy",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl0i6hbuvse530859hqck6975",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6hbuwse540859f3est4n2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye984xw9jvb0859236jqg5p",
            "price": 18,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqxx8fy8w19r0959r3on16k7",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": []
      },
      {
        "sku": "4810",
        "description": [
          {
            "id": "ckqxz616kw8oa0959gffavk2o",
            "title": "895 in kolyesi"
          }
        ],
        "sizePrices": [
          {
            "id": "ckry128awygvw0859imppyh5a",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckry128b0ygvy0859j4t32bo2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckry128b2ygvz08591sotsbde",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqxz616iw8o909590pmrmn9r",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "8190",
        "description": [
          {
            "id": "ckqyw4q2rx2oy0959mg2vndkw",
            "title": "Yuvarlak Delikli Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqyw54flx2th0959fbhl3nw6",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqyw54fqx2tj09598llz59qi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqyw54fsx2tk0959ypyive4d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye7b3d9992r08594dhdh7cy",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqyw4q2qx2ox0959vi35wauz",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": []
      },
      {
        "sku": "428",
        "description": [
          {
            "id": "ckqywrvibx4dw0959gyv7u3xh",
            "title": "Yuvarlak Madeni Para Şeklinde Uçlar"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqywsdprx4in09594jcirce6",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqywsdpvx4ip09593qz4g5dg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqywsdpwx4iq0959gu07c92e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye9g59q9l3a0859ufbwtavi",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqywrviax4dv09598uxxhb23",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": []
      },
      {
        "sku": "429",
        "description": [
          {
            "id": "ckqywwvifx4w309597g6ay0z0",
            "title": "Madeni Para Şeklinde Uçlar"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqywxgbex4za0959bvmkyz41",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqywxgbhx4zc0959fpz80w35",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqywxgbjx4zd0959a2a04aqx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye8sg8e9gvr08597qqcirkr",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqywwviex4w20959epeo38w7",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": []
      },
      {
        "sku": "427",
        "description": [
          {
            "id": "ckqyx1jivx5ci0959ms8ogbuy",
            "title": "Küçük Madeni Para Şeklinde Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqyx49i1x5kr0959u9ds5ome",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqyx49i5x5kt0959uzzh3edv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqyx49i7x5ku0959lzkilt02",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqyx1jiux5ch0959ry7cknzs",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": []
      },
      {
        "sku": "411",
        "description": [
          {
            "id": "ckqyx88gdx5vh0959lklgspjf",
            "title": "Siyah Değişik Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqyxffcix6dy095922c5earc",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckqyxffcox6e009598gz4b0ww",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqyxffcqx6e10959f7prpzco",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqyx88gcx5vg0959upeu3jmn",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": []
      },
      {
        "sku": "056",
        "description": [
          {
            "id": "ckqz0eir7xe2s09595034m36n",
            "title": "Ortası Altıgen Mineli Taşlı Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckr1rgm106w7w09591b9s5vy2",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckr1rgm156w7y0959muw528xy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr1rgm176w7z0959i7lrtbz8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqz0eir5xe2r0959fgusw9px",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": []
      },
      {
        "sku": "035",
        "description": [
          {
            "id": "ckqz5a29axsmq0959q6zpa599",
            "title": "kurukafalı kanatlı yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye5z7y88zpz0859510bu8kx",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckye5z7ya8zq10859jvdoc0ui",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye5z7yc8zq20859ovj24d1q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye5zuli8zvm08595egfsvgn",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqz5a294xsmo0959jgx31yia",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1642150445290-WhatsApp Image 2022-01-14 at 11.53.51.jpeg"
        ]
      },
      {
        "sku": "333",
        "description": [
          {
            "id": "ckqz6elzfxvrk09593ktcfu6g",
            "title": "BEYNI DAMARLI"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5dcjhsl1j00859zxzmnv3a",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5dcjhvl1j20859ye7svvep",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5dcjhwl1j30859vxuslvbr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye546in8uqa085977ufmr31",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqz6elzdxvrj09593o2of1f2",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "036",
        "description": [
          {
            "id": "ckqz7prney0od0959bhtly8fu",
            "title": "Çok Damarlı Kuru Kafalı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckqz7qhmty0w20959k9d51095",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckqz7qhmxy0w4095966wy1b7j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckqz7qhmyy0w50959djlup34j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckqz7prncy0oc0959cn5a78qy",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "037",
        "description": [
          {
            "id": "ckqz7rpc6y13e0959a8r363pd",
            "title": "Küçük Kafalı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye65hqn91230859kf7cp8e8",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckye65hqq91250859rn5ra0oh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye65hqs91260859lnuyenyh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye66el8919a0859h5puvnta",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqz7rpc4y13d0959gmp9f1a6",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1642150733580-WhatsApp Image 2022-01-14 at 11.58.32.jpeg"
        ]
      },
      {
        "sku": "324",
        "description": [
          {
            "id": "ckqz7z3c8y1kv0959wr2ubzb1",
            "title": "Yanları Değişik Şekilli Dörtgen Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxj3vxnizlbx0859hcj8hyui",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckxj3vxnlzlbz0859uluc7s44",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxj3vxnnzlc00859x583pbla",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckxj3vnxezl9a08594abgwj59",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckxj3vrzjzla70859df7xs6j8",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqz7z3c7y1ku0959j1s5j3v5",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "034",
        "description": [
          {
            "id": "ckqz9okwyyacw0959vvhynkml",
            "title": "el yapımı taşlı zincir  s ve m"
          }
        ],
        "sizePrices": [
          {
            "id": "ckye5h3yd8w440859dkzlxhv7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckye5h3yf8w4608598tlk45ym",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye5h3yh8w470859ghdg0wu9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckye5h3yn8w4b0859jc6dezjs",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckye5h3yp8w4d0859w9ugrtnz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckye5h3yq8w4e0859rzc7xux1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye5hm518w830859h03sgrj0",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckqz9okwwyacv0959uubjci46",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1642149599757-WhatsApp Image 2022-01-14 at 11.39.43.jpeg"
        ]
      },
      {
        "sku": "330",
        "description": [
          {
            "id": "ckr0rr8nq2el109598w2k696g",
            "title": "KARE 3 CIZGILI"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5dglasl1ti0859c7okt0us",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5dglavl1tk0859t8vu10hx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5dglaxl1tl0859w7de87hw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr0rr8nm2el00959rzuturoo",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "961",
        "description": [
          {
            "id": "ckr0rwl9q2fti09592k1r9vqa",
            "title": "Village Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckr0ryf0i2h8a0959pl64edb0",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckr0ryf0l2h8c0959wiea1fs7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr0ryf0n2h8d0959trqc7ad8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz6tzzk5e9wr08591aeswolg",
            "price": 8,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckr0rwl9o2fth0959sryhgsnq",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "369",
        "description": [
          {
            "id": "ckr0sbsf42kft09598bmk3p40",
            "title": "VIDALI YUZUK ARALARI CIZGILI YUZUGUN"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5djwsjl21e08591uqxifer",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5djwsll21g08590jj5ka40",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5djwsml21h08592vw9r55n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz6ybvs2fky40859mkbmej3o",
            "price": 14,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckr0sbsf12kfs095997mcwalj",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "557",
        "description": [
          {
            "id": "ckr1u1afz7ffn0959v2w886d4",
            "title": "İnce  Altıgen Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckr1u2n1i7fu50959f5dthjc7",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckr1u2n1p7fu70959zypj4b1h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr1u2n1v7fu80959n3wkueo8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr1u1afw7ffm0959oalzy1ez",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": []
      },
      {
        "sku": "565",
        "description": [
          {
            "id": "ckr3j4nx93wz60859y3iq9jo6",
            "title": "TEPELIĞI TAŞLI ÇOKLU SARKITLI ÜÇGEN MINELI KÜPE"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5d1nvul0js0859a0ux6zqx",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5d1nvyl0ju0859z1ozlyw3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5d1nw0l0jv0859libu9ecy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr3j4nwz3wz50859wqrwzcyx",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": []
      },
      {
        "sku": "942",
        "description": [
          {
            "id": "ckr4mih2s9fht0859t02et10t",
            "title": "taşlı papyon bilekliğin yuvarlak taşlısı"
          }
        ],
        "sizePrices": [
          {
            "id": "ckr4mjtgk9fr50859k6sruu85",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckr4mjtgq9fr70859tvanyl9r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr4mjtgs9fr808596x9yzqym",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckr4mjth19frb085947snevuh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckr4mjth69frd0859cgfeu4kv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr4mjth89fre0859c0o03k12",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckr4mjthg9frh0859o5e4sju1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckr4mjthj9frj0859xtd2yo01",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr4mjthl9frk0859xt6u0eej",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr4mih2p9fhs08591ymdqpcq",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": []
      },
      {
        "sku": "393",
        "description": [
          {
            "id": "ckr4pdyf39y2u0859mduysecr",
            "title": "KUPE YAMUK YUMUK UCGEN"
          }
        ],
        "sizePrices": [
          {
            "id": "ckr4pelcl9yay08595mdkddu7",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckr4pelcq9yb0085975p6et86",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr4pelcr9yb10859itm4gn3b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr4pdyez9y2t08592hiv5ksn",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": []
      },
      {
        "sku": "477",
        "description": [
          {
            "id": "ckr4toclmalcy0859qu4p8rb8",
            "title": "CCB 280 YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5dkl7jl25o0859rk8ptt2k",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5dkl7ll25q0859ir3ryo3n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5dkl7nl25r0859chewoydl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr4toclialcx0859ec06iqyj",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "371",
        "description": [
          {
            "id": "ckr4tr51wamvy0859n0v9lrc4",
            "title": "UC DELIKLI YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5dla1zl29m0859umiywn7z",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5dla23l29o08590fsm5fub",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5dla2al29q0859wgx8jin2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr4tr51uamvx0859t91y5mc1",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "318",
        "description": [
          {
            "id": "ckr4tswxgan3o0859qi84iehf",
            "title": "TOP TOP YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0gqg5vokykd0859yjcsitr8",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl0gqg5vskykf08590vm4cm3r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0gqg5vukykg0859yf9uov8z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl0gqeny9kxz80859ngcfvubg",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckr4tswxean3n0859ke6ql3s1",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1646659321634-20220307_162112.jpg"
        ]
      },
      {
        "sku": "338",
        "description": [
          {
            "id": "ckr4tvc7qaneu0859bapw200u",
            "title": "DRAGON RING"
          }
        ],
        "sizePrices": [
          {
            "id": "ckr4tvzp7anij0859a6sioref",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckr4tvzpbanil085997lzb965",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr4tvzpdanim0859qbapo5qg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz6ssjhrdzgg0859j69uzw4e",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckr4tvc7panet0859yy1jf0mh",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "975",
        "description": [
          {
            "id": "ckr4wcblmb0v50859bjzigq9a",
            "title": "Delgeç Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54flr0cj3lb084037zlre4i",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54flr0jj3ld0840eumyrubl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54flr0nj3le0840123cx1ma",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54flr15j3li08400f6fmv51",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54flr1aj3lk0840zt65m87e",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54flr1dj3ll08403akvq6i8",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54flr1rj3lp08400w138wya",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54flr1vj3lr0840o5ocxgte",
                "price": 12,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54flr1xj3ls0840c73ndzqo",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckr4we18tb1a90859prhfph1m",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktwscn2xtvfc0859iho31n25",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckr4wcblfb0v408592hzq6m7w",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1626352514450-CCN-975 1.jpg",
          "1626352514670-CCN-975.jpg"
        ]
      },
      {
        "sku": "541",
        "description": [
          {
            "id": "ckr50y56ubpyd0859ev3vx7f3",
            "title": "UCGEN MINELI DOKUM YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5egk14l4h30859b843ve4k",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cku5egk16l4h50859p8prmr6e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5egk18l4h60859kohh1vzs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz6u1a5ieaao0859g0qdo2ba",
            "price": 24,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckr50y56sbpyc0859tqv134g1",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": []
      },
      {
        "sku": "856",
        "description": [
          {
            "id": "ckr54u5sfcajk0859h2zfwkt9",
            "title": "CCB-855IN TEK PARÇALI IPLI BK"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5b4pkqkoii0859q116a9yt",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5b4pkukoik0859ytgnpbbl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b4pkwkoil0859djhlqor9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b4pl4koio0859va276e0s",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5b4pl7koiq0859522saijf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b4pl8koir0859bt7xhlkm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b4plfkoiu0859zuvhm6pd",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5b4plhkoiw0859l5syysp1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b4plkkoix0859kikb7xfn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr54u5sccajj0859cxexcjel",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": []
      },
      {
        "sku": "710",
        "description": [
          {
            "id": "ckr61pijbeu2q0859q7alefnb",
            "title": "Lovers Rock Bilezik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5b58jqkopn0859cfq23xm3",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5b58jtkopp0859j21rotzg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b58jukopq08592apjusks",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b58k0kopt0859ygxuvy0j",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5b58k3kopv0859vl712fwu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b58k8kopw0859aqv7wi9t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5b58kekopz0859jbz1yf99",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5b58kgkoq108591qpvuz2v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5b58kikoq20859bljvf5u4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr61pij8eu2p0859ehm9bsqa",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": []
      },
      {
        "sku": "374",
        "description": [
          {
            "id": "ckr63ulenf6ig0859wj5oxnw3",
            "title": "Yuvarlak halka yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckr63vagjf6rz08593hy7t0ad",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckr63vagof6s10859qzmxkd2p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckr63vagrf6s208599949afq6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr63ulekf6if0859bq5j5wby",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "068",
        "description": [
          {
            "id": "ckr67yiyffz4o0859rmptj3lz",
            "title": "Ahşap Boncuklu Ve Taşlı Eski Bileklikler"
          }
        ],
        "sizePrices": [
          {
            "id": "cku6mw8sioqcx0859s5hduzwf",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku6mw8sooqcz0859ajby2h7u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku6mw8sqoqd00859cwgevcsd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku6mw8t0oqd308598zsjpmat",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku6mw8t3oqd50859ebam8hkk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku6mw8t5oqd60859oa1mgf4m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku6mw8tdoqd90859x0x2x42n",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku6mw8tgoqdb0859g8sz35fw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku6mw8tioqdc0859kznopwue",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckr67yiycfz4n0859akoqoiia",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": []
      },
      {
        "sku": "468",
        "description": [
          {
            "id": "ckrklm1lpkvj70859v848ffhf",
            "title": "buz bar halka bilezik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckrklnmedkw9h0859gdbrjrva",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckrklnmehkw9j08598r7qg7cd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckrklnmejkw9k0859dtg3yeyp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckrklnmeokw9n0859v9p8ggcl",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckrklnmeqkw9p08599z8p1o77",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckrklnmeskw9q08594lc0dnzk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckrklnmeykw9t0859nki498m8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckrklnmf0kw9v0859xixy6o47",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckrklnmf1kw9w08593fvfvnwf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckrklm1llkvj60859dppb7908",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": []
      },
      {
        "sku": "1050",
        "description": [
          {
            "id": "ckrknqqhale4u0859ks3cmrpa",
            "title": "Juju Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt9z1zveowl50859r39tbpxa",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt9z1zvjowl70859j6re31mv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt9z1zvlowl80859kw4413nv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrknvkorlfzb0859ltfev7ha",
            "price": 79,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrknvrzjlg2p0859wk9t4wnc",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrknqqh4le4t08592dsfh3mf",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": []
      },
      {
        "sku": "1051",
        "description": [
          {
            "id": "ckrlvb4cyqbtn0859mi5uyez0",
            "title": "Home Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt9z22h5ownm08598eq43lz4",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt9z22h8owno0859cqmgcgyp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt9z22haownp0859ga49am59",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrlvdyt2qc6j08591zg9ari9",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrlve6b9qc7t0859vt3dsvtc",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrlvb4csqbtm0859rtx2vsn9",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1627378705596-CCH-1051.jpeg"
        ]
      },
      {
        "sku": "1052",
        "description": [
          {
            "id": "ckrlvhiqfqec00859grvv1qcj",
            "title": "Space Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt9z256jowq50859toae9974",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt9z256mowq70859r8g9i4w8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt9z256oowq808590j9h553m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrlvib7qqej00859vsehojmi",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrlvihwdqel50859c1onzvf7",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrlvhiqbqebz08595n20qce9",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1627379238349-CCH-1052.jpeg"
        ]
      },
      {
        "sku": "1053",
        "description": [
          {
            "id": "ckrlvkpndqezf0859qtv72p1y",
            "title": "Winky Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt9z27p4owsk0859p59jb8tm",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt9z27p7owsm0859xc4086zh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt9z27p9owsn0859s1xuxrcy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrlvmqieqfgk0859tdzulr0u",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrlvn93lqfim0859okzgorgr",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrlvkpn9qeze0859rse2kn6x",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1627379185697-CCH-1053.jpeg"
        ]
      },
      {
        "sku": "1054",
        "description": [
          {
            "id": "ckrlvoypsqfs608596ws8ohxe",
            "title": "Pinky Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt9z2a7cowv708595hj44jbt",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt9z2a7fowv90859p1yc592m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt9z2a7gowva08593lbxnyjb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrlvpo94qfya0859a5cvxle9",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrlvpu9rqfz40859t5142ej9",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrlvoyppqfs50859t6s9u7o8",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1627379420473-CCH-1054.jpeg"
        ]
      },
      {
        "sku": "1055",
        "description": [
          {
            "id": "ckrlvsd11qgeh085983b41d9m",
            "title": "Snake Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt9z2dexowxv08595m2tc3xx",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt9z2df0owxx0859ywchnmvn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt9z2df3owxy08595y5xdch9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrlvvv6vqnw30859isea9td8",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrlvw4z3qny308596qbma0oa",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrlvsd0zqgeg0859140p6im5",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1627379536635-CCH-1055.jpeg"
        ]
      },
      {
        "sku": "1056",
        "description": [
          {
            "id": "ckrlvx0c2qo430859mmegodtv",
            "title": "Wind Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt9z2g5pox0a08591of7cshi",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt9z2g5sox0c0859jkfhih5c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt9z2g5uox0d0859egzob7a0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrlvxseiqoau085957buareu",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrlvxz8jqoc708598uh1iaol",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrlvx0bxqo420859v2p5b5et",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1627379782973-CCH-1056.jpeg"
        ]
      },
      {
        "sku": "1057",
        "description": [
          {
            "id": "ckrlvynxcqofk085944154xij",
            "title": "Bloom Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt9z2jl2ox390859cuvl403a",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt9z2jl7ox3b0859fggzgy8t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt9z2jl8ox3d0859jq0nl7d6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrlvzq95qoo8085917obavmc",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrlw0a3uqoqq0859rroj54lp",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrlvynxbqofj0859fu8bs1he",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1627379892454-CCH-1057.jpeg"
        ]
      },
      {
        "sku": "1048",
        "description": [
          {
            "id": "ckry12fquygxi0859nui2h39q",
            "title": "Hayalet bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0kzppj828j70859ogh3ved5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0kzppjb28j908594ngtwgl7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0kzppjc28ja08594c5j22ns",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0kzppji28je08599kkmdx2k",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0kzppjk28jg0859oz8njux5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0kzppjl28jh0859p7jjdr4c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0kzppjq28jl0859ycbzd6mo",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0kzppjs28jn0859658t8zum",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0kzppjt28jo0859l0fxuyny",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckry15rnryh8y085983hjqvbg",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckry15vviyh9h0859xowwv5qq",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckry12fqpygxh0859ft4qnf7x",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1628113915785-CCB-1048-3.jpg",
          "1628113915518-0.jpg",
          "1628113915747-CCB-1048.jpg"
        ]
      },
      {
        "sku": "1058",
        "description": [
          {
            "id": "ckry1p3loyibi0859h2m0pf3c",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j794l1vdrr08597nr6u9se",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0j794l4vdrt085958r8v9fj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j794l5vdru0859dkxo6l7d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j794lcvdry0859u00by5kr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0j794levds00859n6rtkf90",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j794lfvds10859ieugrgmt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j794lkvds50859d39bzcrc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0j794lmvds708597ywgk5a1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j794lnvds80859dtwpdvgz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckry1rhb0yijp0859zu4wzvz1",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckry1rzp6yilo08599kv2u2w6",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckry1p3lhyibg0859g7m095jq",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1628115058460-CCB-1058.jpg",
          "1628115058483-CCB-1058-2.jpg"
        ]
      },
      {
        "sku": "1059",
        "description": [
          {
            "id": "ckry1u3oayis50859p8d535vs",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j7981jvdvv08597mdtbp7k",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0j7981lvdvx08593bygufsm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j7981mvdvy08592vy9xbjn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j7981svdw20859qeuytu2p",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0j7981uvdw40859qolpg5k2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j7981vvdw50859kwq0vjmw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79821vdw90859j1gpzvt3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0j79823vdwb0859a4pxqjf1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79824vdwc0859fom1mztg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckry1viq9yiyg0859c9j8ib4y",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckry1vsbyyj0l0859oguljtnl",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckry1u3o7yis40859bs5xz46h",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1628115296398-CCB-1059.jpg",
          "1628115296364-CCB-1059-2.jpg"
        ]
      },
      {
        "sku": "1060",
        "description": [
          {
            "id": "ckry1xrg9yj7d085935trbg1h",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j79b1pvdzn0859cjzozsto",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0j79b1svdzp0859zetirun9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79b1tvdzq0859nm81lz4x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79b1zvdzu0859ak8abzkg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0j79b20vdzw08596k16tdra",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79b22vdzx0859knhdxhcb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79b28ve0108594aes20ex",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0j79b29ve030859hp59jnf8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79b2ave040859y84n9j5i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckryu3f3d1tjv0859q087ucl2",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckryu3prc1tmb08594ilobv9g",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckry1xrg6yj7b085922nvus8i",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1628115458943-CCB-1060.jpg",
          "1628115458883-CCB-1060-2.jpg"
        ]
      },
      {
        "sku": "1061",
        "description": [
          {
            "id": "ckrytu0541red08598bfd7r40",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j79duxve3n0859svohz6cu",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0j79dv0ve3p0859razaqg7i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79dv1ve3q0859cpp0pzf6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79dv6ve3u08599gg6jvqv",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0j79dv8ve3w0859d3cys84p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79dv9ve3x0859ag7domso",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79dvdve410859s88fqvom",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0j79dvfve4308594tnkjc28",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79dvgve44085976oxkg32",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckrytuyxb1rlw0859z7sk8zik",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckrytwa6n1rvc0859tum581wb",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckrytu04y1rec0859qu50tbsf",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1628162251491-CCB-1061.jpg",
          "1628162251460-CCB-1061-2.jpg"
        ]
      },
      {
        "sku": "1062",
        "description": [
          {
            "id": "ckryu1pax1t0t08595trqfimo",
            "title": "Dumble Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzpp24o520q90859lib90to0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzpp24o720qb08595taun6uo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp24o820qc0859lfh1xtsx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpp24of20qg0859ylt15qgm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzpp24og20qi0859cej9qh8s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp24oi20qj0859ginddwg5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpp24oo20qn085921d24ayw",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzpp24op20qp0859egcwi0b4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp24oq20qq0859k89hridh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckryu4ib51tt20859udb7w4xx",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckryu4t2k1tw20859e6nl9xea",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckryu1pat1t0s0859bhe6e9vc",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1630741423370-CCB-1062 (2).jpg",
          "1630741429367-CCB-1062.jpg",
          "1645024395703-1062.png"
        ]
      },
      {
        "sku": "1063",
        "description": [
          {
            "id": "ckryu8f5m1um50859t1ict3mu",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j79i5vve7r0859yrriff2m",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0j79i5xve7t0859l53grage",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79i5zve7u0859npj4dvmr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79i64ve7y0859s8h6e2ru",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0j79i65ve800859ot98k4mm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79i66ve810859yh1dvf87",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79i6cve8508591n13zb2p",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0j79i6dve8708597sfl7fcu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79i6eve8808594rje22tp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckryu9c561usp08595zf0s1ux",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckryu9g891uts08593tj2ghkw",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckryu8f5j1um408596197bq83",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1628163020892-CCB-1054.jpg",
          "1628163024771-CCB-1054-2.jpg"
        ]
      },
      {
        "sku": "1064",
        "description": [
          {
            "id": "ckryuan0u1v2d0859wno1mh2t",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j79kt3vebk0859ayfrjmuo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0j79kt5vebm0859u3gah6xl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79kt6vebn0859b3velq2f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79ktbvebr0859q6bux5nz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0j79ktdvebt08595jycha0y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79ktevebu08594b4676na",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79ktiveby0859435hlyj0",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0j79ktkvec00859sbbof9n9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79ktlvec10859nhh1n7v7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckryubeno1v8608596iy4dz9y",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckryuboaj1vas0859rgybg2p8",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckryuan0r1v2c0859ur3tsfau",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1628163102685-CCB-1055.jpg",
          "1628163102587-CCB-1055-2.jpg"
        ]
      },
      {
        "sku": "1065",
        "description": [
          {
            "id": "ckryuco1h1vg50859gndt7z41",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j79o6pvefn0859bt0q1852",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0j79o6svefp08595asqisea",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79o6uvefq0859oea8p6w9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79o70vefu0859k8uzo6nw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0j79o72vefw08598tkihbsk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79o73vefx0859woiik9sq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79o79veg10859lsu3fkb1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0j79o7bveg30859ybw1w6us",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79o7cveg40859n1rltuqp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckryufs9h1w320859oimsjhus",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckryug21g1w550859q8a10klf",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckryuco1e1vg40859373avv2g",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1628163202909-CCB-1056.jpg",
          "1628163202914-CCB-1056-2.jpg"
        ]
      },
      {
        "sku": "1066",
        "description": [
          {
            "id": "ckryura541yds08594nfqedlg",
            "title": "Renkli İp Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0j79rddvejp0859hf3wdsoc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0j79rdfvejr0859bs9axwbj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79rdhvejs08592pjvayks",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79rdovejw085920n7ark1",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0j79rdqvejy0859rp12gpxw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79rdrvejz0859u3vu9tzg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0j79rdyvek30859yl5c45hh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0j79re0vek50859fay099lz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0j79re1vek60859p5o2fyeo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckryusc3i1ymd0859ygfb49z3",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckryut7jm1ys50859ch89x889",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckryura4z1ydr085987ptx9m8",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1628163828319-CCB-1057.jpg",
          "1628163828343-CCB-1057-2.jpg"
        ]
      },
      {
        "sku": "1067",
        "description": [
          {
            "id": "cksg397xnj67d0859a69a54iz",
            "title": "Till Dawn Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5benm3kr0k08592hg8c0wh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5benmakr0m0859vlskspjr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5benmckr0n0859ckfapn7a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5benmkkr0q08591dz87h6h",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5benmrkr0s0859gedvvbby",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5benmukr0t08599806dqaj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5benn5kr0w0859e4bkaq82",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5benn8kr0y0859j0yv00dj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5bennokr0z0859exl3r7jy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cksg3b8sdj6if0859v6ozy6g4",
            "price": 8,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cksg3bfxzj6ix08590ix1bo8s",
            "price": 22,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cksg397xfj67b0859z8y4tp99",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1629206084137-WhatsApp Image 2021-08-17 at 16.12.54.jpeg"
        ]
      },
      {
        "sku": "663",
        "description": [
          {
            "id": "cksiwz1jkrv650859thh6bz7b",
            "title": "Nora Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5iuhjaluna0859bzzwr15t",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5iuhjclunc0859q7xkqec9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5iuhjelund0859du6vhpa0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5iuhjklung0859ouh0eg7s",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5iuhjmluni0859h7f95vno",
                "price": 25,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5iuhjolunj08590n3reumj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5iuhjulunm08592mnhyrvk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5iuhjwluno0859lze9xjpv",
                "price": 47,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5iuhjylunp0859fqszf2jc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cksix0sacrvhb0859uyu2rcbn",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cksix148brvid08593bw82dah",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cksiwz1jgrv640859zlob3p1m",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1629376922444-WhatsApp Image 2021-08-19 at 11.34.57.jpeg"
        ]
      },
      {
        "sku": "1068",
        "description": [
          {
            "id": "cksix6s1srw4t08596ukloomn",
            "title": "Lovefool Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cksixad45rwks0859dk2j8022",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cksixad48rwku08591qkbqisi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cksixad49rwkv085975nzsypo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cksixad4grwky0859dg5yzhr2",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cksixad4jrwl008595vp0khai",
                "price": 22,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cksixad4lrwl10859gd36aqx6",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cksixad4trwl408598lrap0f6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cksixad4vrwl60859o6wy0ww6",
                "price": 39,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cksixad4xrwl70859d7xukp32",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cksix9trcrwg808595b7qwaxy",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cksix9ym2rwgy0859dsin69jv",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cksix6s1prw4s085970a4xxju",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1629377288018-WhatsApp Image 2021-08-19 at 11.29.58.jpeg"
        ]
      },
      {
        "sku": "1069",
        "description": [
          {
            "id": "ckskel494vgbx0859f3qll21i",
            "title": "Bad Guy Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5bf9aikr5x08595j8yl9kl",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5bf9akkr5z0859bu904vkc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5bf9amkr600859bvxhd7kt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskel491vgbw08598ant1wpz",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1629466991530-WhatsApp Image 2021-08-20 at 16.37.42.jpeg"
        ]
      },
      {
        "sku": "1070",
        "description": [
          {
            "id": "ckskem3ijvgft0859dn4p324p",
            "title": "After Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5bfwxrkrb208590v6szjsz",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5bfwxvkrb408596538qut1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5bfwxxkrb50859f0xj4lks",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskem3igvgfs0859uk214kl1",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1629467039483-WhatsApp Image 2021-08-20 at 16.37.50.jpeg"
        ]
      },
      {
        "sku": "1071",
        "description": [
          {
            "id": "ckskeo8m1vgqw0859iyvx829a",
            "title": "Feeling Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5bhcijkrkn0859jnn11uuu",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5bhcimkrkp0859s5cy3p2p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5bhcipkrkq0859ic92t4hf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskeo8lzvgqv0859eqb63ytb",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1629467140869-WhatsApp Image 2021-08-20 at 16.37.59.jpeg"
        ]
      },
      {
        "sku": "1072",
        "description": [
          {
            "id": "ckskewik6vh4n08595rysc7ae",
            "title": "Now Or Never Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxsqoulnowt40859dc2i9mkf",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxsqoulqowt60859rh3xg1yi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxsqoulsowt70859pkd5kqot",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskewik3vh4m0859gucm1pfq",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1629467523252-WhatsApp Image 2021-08-20 at 16.38.09.jpeg"
        ]
      },
      {
        "sku": "1073",
        "description": [
          {
            "id": "ckskfzz0ovmei0859ktyn5ydz",
            "title": "Wonder Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckskg0i2xvmlw085907twdfxc",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckskg0i30vmly0859qd2x4e8v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckskg0i32vmlz0859chbude8s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskfzz0nvmeh0859buznargw",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": []
      },
      {
        "sku": "1074",
        "description": [
          {
            "id": "ckski5oi3vvia0859clygyyiz",
            "title": "Mambo Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxsqpovaox0l0859g2noqghi",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckxsqpoveox0n0859pw9r8ude",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxsqpovgox0o0859pdxth2ic",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckski5oi0vvi90859s6lpgyjo",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1629472996443-WhatsApp Image 2021-08-20 at 18.22.37.jpeg"
        ]
      },
      {
        "sku": "1075",
        "description": [
          {
            "id": "ckskig22yvxcs0859rz3vsme9",
            "title": "Ragnar Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5iywbrlv7u0859ee0l1ide",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5iywbwlv7w08596ukk049w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5iywbzlv7x0859uqrb652k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskig22vvxcr0859dahp7yo7",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1629473481206-WhatsApp Image 2021-08-20 at 18.30.58.jpeg"
        ]
      },
      {
        "sku": "1076",
        "description": [
          {
            "id": "ckskih2dovxml0859zxyjuvyj",
            "title": "Stella Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5j3sw6lvj70859xkbus5xt",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5j3swalvj90859bhikyf8q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5j3swclvja08598v5h7in3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskih2dmvxmk0859s6tmwe09",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1629473524845-WhatsApp Image 2021-08-20 at 18.31.29.jpeg"
        ]
      },
      {
        "sku": "1077",
        "description": [
          {
            "id": "ckskiifkzvy0d0859xcl1uc0a",
            "title": "Wet Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5izo18lvbx0859ywkqti87",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5izo1clvbz08594l097ma6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5izo1elvc00859d509rirf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskiifkyvy0c0859t0bayxe7",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1629473575630-WhatsApp Image 2021-08-20 at 18.32.03.jpeg"
        ]
      },
      {
        "sku": "1078",
        "description": [
          {
            "id": "ckskijhgkvydz0859az2vugy8",
            "title": "Located Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cktyiigpr18mk0859htk1p9uj",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cktyiigpu18mm0859rwy4pafd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cktyiigpw18mn08599gv0re5o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskijhgivydy0859g3i5zyod",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1629473637491-WhatsApp Image 2021-08-20 at 18.32.20.jpeg"
        ]
      },
      {
        "sku": "1079",
        "description": [
          {
            "id": "ckskilybtvyny0859o8p17mte",
            "title": "Gang Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5bkcyfks6k0859wv6yyl33",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cku5bkcyjks6m08597sdo8g1s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5bkcylks6n08599izztdap",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskilybqvynx0859xn5l4580",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1629473727962-WhatsApp Image 2021-08-20 at 18.33.30.jpeg"
        ]
      },
      {
        "sku": "1080",
        "description": [
          {
            "id": "ckskin26tvytf0859g64xp2jt",
            "title": "Balthazar Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckskinfghvywh08598ay2n126",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckskinfgkvywj0859qcdgc0ts",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckskinfgmvywk08595it21z8y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckskin26rvyte0859nq0r4mmn",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1629473801394-WhatsApp Image 2021-08-20 at 18.34.01.jpeg"
        ]
      },
      {
        "sku": "1097",
        "description": [
          {
            "id": "cksokw3074td70859ekand026",
            "title": "Fence Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wdf8bo6le70840xcuabpvo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wdf8bv6le908400jgmxh06",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdf8by6lea0840c2vyy61b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdf8cc6lee0840n35p5dwq",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5wdf8cf6leg0840hujkl35i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdf8ci6leh0840joik7ur2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdf8cw6lel0840q06123o1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5wdf8d16len0840i08bl8sq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdf8d46leo0840avgtbbmv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cksokx06v4tf70859fuweyttn",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cksokx4984tfl0859u3gxw18u",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cksokw3054td60859ewowiau9",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646839825482-2.png",
          "1631011061414-CCB-1097-2.jpg",
          "1645024482478-1097.png"
        ]
      },
      {
        "sku": "934",
        "description": [
          {
            "id": "cksor3a975i5l0859qiskkem5",
            "title": "Saved Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cksor3xj25i8t08590xvx259c",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cksor3xj85i8v0859awrzdalz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cksor3xja5i8w08594h3q2akg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckx5wvafm0tp80859dxhtzx06",
            "price": 4,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckx5wvpss0tr00859cn6bu4cx",
            "price": 11,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cksor3a945i5k0859jxmo3eb0",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1629729860231-WhatsApp Image 2021-08-23 at 17.44.03.jpeg"
        ]
      },
      {
        "sku": "1082",
        "description": [
          {
            "id": "ckspwpuex9cma0859bfaapps7",
            "title": "Castle Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wdn6rj6nl90840gsg07zc4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wdn6rm6nlb0840potzdjdi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdn6rp6nlc0840rnn0t5j2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdn6rv6nlg0840dkgjczl3",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5wdn6rx6nli0840fktzma0v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdn6ry6nlj0840c5gqpulp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wdn6s46nln0840y2tghfsk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5wdn6s76nlp0840vz6962d0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdn6s86nlq0840pdy5gmv3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckspwqcrg9cob0859kp0hkocl",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckspwql579cpg0859ym57sc32",
            "price": 117,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckspwpueu9cm90859udt51iuf",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646839849150-3.png",
          "1631009395534-CCB-1082-2.jpg"
        ]
      },
      {
        "sku": "1083",
        "description": [
          {
            "id": "cksq31ptuado10859arwya138",
            "title": "Amy Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cksrllw9chg280859snvuui7c",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cksrllw9hhg2a0859cg03k3hr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cksrllw9nhg2b0859c1y0ngtq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cksq31ptsado00859rm846bun",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1629810392705-WhatsApp Image 2021-08-24 at 16.04.58.jpeg"
        ]
      },
      {
        "sku": "1084",
        "description": [
          {
            "id": "cksyqunq5ngs10859xjhnt1am",
            "title": "Caterpillar Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54a3fzsi9zz08401n64ew2l",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54a3fzyia010840yoyvvesl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54a3g01ia0208408cvf9fpa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54a3g0cia060840airnhwmw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54a3g0eia0808402g48gpcm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54a3g0hia0908400ddwnyuv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54a3g0tia0d0840uxku03o2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54a3g0yia0f0840ox8tfaf4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54a3g12ia0g0840g8ek4qnk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cksyqw5iinh5f08592vy09req",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cksyqwhwhnh830859o1hznf89",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cksyqunq2ngs0085948lhylor",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1651085601699-39.png",
          "1630334090646-CCB-1084-1.jpeg"
        ]
      },
      {
        "sku": "1085",
        "description": [
          {
            "id": "cksyspeoqnxd60859obkldp25",
            "title": "Without It Kolye Ucu"
          }
        ],
        "sizePrices": [
          {
            "id": "cksyss1fznxxo0859iw04ptlk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cksyss1g2nxxq08593ywrk86w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cksyss1g4nxxr08592dfrcdc7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cksyss1gbnxxu0859naknm609",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cksyss1gdnxxw08598yt2d91w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cksyss1gfnxxx08596hie1ig8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cksyss1gmnxy008595qwrthge",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cksyss1gonxy20859ag9pgfvw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cksyss1gqnxy30859hjmgf24g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cksysroq2nxtf0859urt29jez",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cksysrwn8nxuo08596b26oacf",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cksyspeoonxd50859pz3t727e",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": []
      },
      {
        "sku": "1086",
        "description": [
          {
            "id": "ckt2w6mdp5zqe08592hvsqqx9",
            "title": "Renkli İpli Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt2w7vzr603c0859hoq7xgjk",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt2w7vzw603e0859pcf72t5t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt2w7vzy603f0859jj44b59f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckt2w6mdm5zqd08591hvkavng",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": []
      },
      {
        "sku": "1087",
        "description": [
          {
            "id": "ckt2wsvsx64zo0859fo1kikao",
            "title": "Zincirli Still Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckt2wtb3v654v0859xggaxowh",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckt2wtb3y654x0859idvumfin",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckt2wtb41654y0859klvexdej",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckt2wsvsu64zn0859p8wmff9t",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1630585972119-WhatsApp Image 2021-09-02 at 15.31.21.jpeg"
        ]
      },
      {
        "sku": "1088",
        "description": [
          {
            "id": "ckt5hglm8ebdw085956ykxa7c",
            "title": "Ladybug Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8vr0f788jmo0840zyk1js00",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl8vr0f7b8jmq0840t7mgyuk0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vr0f7d8jmr08408vd0ng4t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8vr0f7l8jmv0840owr14ob0",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl8vr0f7o8jmx0840rbd3xhg2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vr0f7q8jmy0840gzx2d6iv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8vr0f808jn2084050btzm8n",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl8vr0f838jn40840otc05tj8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8vr0f858jn50840jcu5yq9a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt5hibeyebjt0859jfxilg2q",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt5hiqjjebkl0859za6d6iqf",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt5hglm4ebdv08594jt8nlqg",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1630741559150-CCB-1088.jpg",
          "1630741557909-CCB-1088 2.jpg"
        ]
      },
      {
        "sku": "1089",
        "description": [
          {
            "id": "ckt5htsj7eby20859qizgsbcr",
            "title": "Sonsuzluk Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac4e2yevhw0859q98i107e",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac4e30evhy0859yhft27ht",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac4e31evhz085911wqnyfa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac4e37evi30859vz558n2l",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac4e38evi50859lb92jt03",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac4e39evi608598fy01jw7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac4e3jevia085960cnoiuo",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac4e3kevic08593kba17wm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac4e3mevie0859qtdd186i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt5hv2fqec3w08593cisydai",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt5hxgv5ec4m0859uj9ne4zv",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt5htsj4eby10859tg7nbecg",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1630742237124-CCB-1089 2.jpg",
          "1630742223440-CCB-1089 3.jpg",
          "1630742231406-CCB-1089 (1).jpg"
        ]
      },
      {
        "sku": "1092",
        "description": [
          {
            "id": "ckt9w8ijao8300859892nbqcc",
            "title": "Goldfish Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77k9nliijyy0840d6pdtefl",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77k9nlnijz00840473u3koz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77k9nlrijz10840208lbj5b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77k9nm2ijz50840q7s8czte",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77k9nm4ijz708405ylyiv8b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77k9nm7ijz80840fxeqsoxc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77k9nmkijzc0840o0jb66hm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77k9nmnijze0840bhebhj6e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77k9nmqijzf0840gjrtatu9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl31a2w2lrui40840l1iaivry",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl31a3ed4rujb0840yey0p11s",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9w8ij5o82z0859dird7815",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646734386130-2.png",
          "1631008249366-CBM-1092-4.jpg",
          "1631008249294-CBM-1092.jpg",
          "1631008249358-CBM-1092-2.jpg"
        ]
      },
      {
        "sku": "1093",
        "description": [
          {
            "id": "ckt9wcwjao8wo0859cw5ccf1f",
            "title": "Pisagor Bileklik "
          }
        ],
        "sizePrices": [
          {
            "id": "cl0hzpsf4pv6d08590k9sz5kb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0hzpsf7pv6f0859rk8gx6xi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzpsf8pv6g08599toyn2cz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hzpsfgpv6k0859j8k02ebg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0hzpsfipv6m0859uc9pjhov",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzpsfjpv6n0859lh52wuvr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hzpsfqpv6r0859m4h4zqrp",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0hzpsfrpv6t0859jlm4paaf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzpsftpv6u0859hussex6t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9weddlo94z08598c7e8qw3",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9wem4do97e0859xswvm5tw",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9wcwj8o8wn0859ky8428tk",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646735351101-4.png",
          "1631008493451-CBM-1093-2.jpg"
        ]
      },
      {
        "sku": "1094",
        "description": [
          {
            "id": "ckt9wi4v8oa190859fx1672tn",
            "title": "Geometrik Bileklik "
          }
        ],
        "sizePrices": [
          {
            "id": "cl77ga6fyi5450840sl2opf7o",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77ga6g3i5470840rmi9s0wf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77ga6g6i5480840u5d43l6z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77ga6gji54c0840txjfdmbq",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77ga6gmi54e08402a629d9b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77ga6gni54f0840z3phqn9b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77ga6gui54j0840srrwtyc3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77ga6gwi54l0840qk8halno",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77ga6gyi54m08406iznlwpl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9wjhhjoabm08593agdxw9d",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9wjrikoadx0859cjel7288",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9wi4v6oa1808595hpny2l8",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1631008622014-CBM-1094-4.jpg",
          "1646734509453-3.png",
          "1645024446931-1094.png"
        ]
      },
      {
        "sku": "1095",
        "description": [
          {
            "id": "ckt9wo71mobd00859tpjyh08f",
            "title": "Piston Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckz5nmzs89e670859aw0erfzn",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckz5nmzsa9e6908596qyuwswj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nmzsb9e6a0859wbzyw00g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5nmzsh9e6e08590q3h4wz1",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckz5nmzsj9e6g0859bq6qbjkg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nmzsl9e6h0859y0ck79n8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckz5nmzsr9e6l0859hdcvfy9k",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckz5nmzst9e6n0859pvzv3dkx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckz5nmzsu9e6o085902szptvn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9wqeeiobtb0859v7d3ckgh",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9wqndkobv30859r5epb7tv",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9wo71kobcz0859y7079nal",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1631008999367-CCB-1095.jpg",
          "1636370500506-1.png",
          "1631008999373-CCB-1095-2.jpg"
        ]
      },
      {
        "sku": "1096",
        "description": [
          {
            "id": "ckt9wxpqrodbv0859wn4egb97",
            "title": "İpli Ladybug Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl42f50grjxao0840lgwlas4s",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl42f50gwjxaq0840sux9ztx2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42f50gyjxar0840zdjyu3rr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42f50h7jxav0840s19a8ttm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl42f50h9jxax0840ug2cuzxx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42f50hajxay08408mbcyfmq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42f50hgjxb20840vjcmx5wa",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl42f50hijxb408400uxfk48c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42f50hjjxb50840swe1f81j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9wz38todjp08595cot67km",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9wz9cwodli0859881m2pbv",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9wxpqoodbt0859pe3igrhj",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1631009434183-CCB-1096.jpg",
          "1631009434211-CCB-1096-2.jpg"
        ]
      },
      {
        "sku": "000002",
        "description": [
          {
            "id": "ckt9x0uj0oe1q08596f5qiajk",
            "title": "Fence Bileklik"
          }
        ],
        "sizePrices": [],
        "price": [],
        "id": "ckt9x0uiyoe1p08595qs0inln",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": []
      },
      {
        "sku": "1091",
        "description": [
          {
            "id": "ckt9x7bwtofot0859obl0pnys",
            "title": "Papyon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0hzqayfpveq0859gabdzi3g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0hzqayhpves0859syeqq9zg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzqayipvet0859qwphf8gn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hzqayppvex0859qx0k7brw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0hzqayqpvez0859x9k4v3qm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzqayrpvf008597a9ndtdf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hzqayypvf40859cur8pld7",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0hzqaz0pvf60859jzo5l9pt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzqaz1pvf70859hwvinaok",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9xarwiogp70859gt14ujyk",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9xb1myogs00859v9moouj2",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9x7bwrofos0859fej01j9s",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646735379676-5.png",
          "1631009867441-CBM-1091-2.jpeg"
        ]
      },
      {
        "sku": "1098",
        "description": [
          {
            "id": "ckt9xmjtdojv00859mwt08iwq",
            "title": "Marble Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac4i4uevt30859n8jf9teb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac4i4xevt50859jsdb5a5d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac4i4zevt60859wfwho9w6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac4i5eevta0859upew9rfp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac4i5gevtc0859mtcj8hbj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac4i5hevtd085984fbepnq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac4i5mevth0859jqri56ry",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac4i5nevtj0859m0bgk5dq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac4i5oevtk0859e07m9wax",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9xnfv5ok6k08593ojotpba",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztwlmzoela70859stxda99z",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9xmjt7ojuz085962i9ty3q",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1631010604113-CCB-1098.jpg",
          "1631010604162-CCB-1098-2.jpg"
        ]
      },
      {
        "sku": "1099",
        "description": [
          {
            "id": "ckt9xpvjbol130859p73wtf9e",
            "title": "Crown Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gahbci5870840p0w4nnqc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gahbgi5890840tnhyazzb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gahbji58a0840ogqaqm0f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gahbsi58e0840pm82n07w",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gahbui58g0840ouhq1bkr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gahbwi58h0840a3521y57",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gahc5i58l08405gsyh4p7",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gahc8i58n0840p7oodhvj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gahcai58o08402pos5qnt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9xqn0volai0859d4a28if4",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9xr942olef0859d6rw2dcs",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9xpvj9ol120859if32n7um",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1646733972039-2.png",
          "1631010763558-CBS-1099-2.jpg"
        ]
      },
      {
        "sku": "1100",
        "description": [
          {
            "id": "ckt9y1cw6onp00859h8btjkja",
            "title": "Zımba Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54e9vzeiu2r0840jghfl7j5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54e9vziiu2t0840j1rpcnqu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e9vzkiu2u0840xlpic8ya",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e9vzsiu2y0840do2l63ma",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54e9vzuiu300840qce3ydsj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e9vzviu310840bxyfwzks",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e9w01iu350840ofj0tixw",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54e9w03iu3708403fuxzl65",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e9w04iu380840q0ll3dm1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9y25mwonud0859trpx996k",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9y2fjlonvq08591ycq2io5",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9y1cw3onoz0859lajkyq1b",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1631011291280-CCB-1100.jpg",
          "1631011291274-CCB-1100-2.jpg"
        ]
      },
      {
        "sku": "1107",
        "description": [
          {
            "id": "ckt9ybqfkopgw08592h8sfyk3",
            "title": "Never Mind Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckta08gq8p7as0859yg499my1",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckta08gqcp7au0859ie5nrnkn",
                "price": 17,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckta08gqep7av0859pwq07ueq",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckta08gqlp7ay0859ytr4htex",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckta08gqop7b008590awli0ot",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckta08gqqp7b10859t7dsu3d8",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckta08gqyp7b40859a2wdpwlp",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckta08gr0p7b60859s3ne1rti",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckta08gr2p7b708590twtaoxh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckta09c6dp7iy0859mdn3c5p1",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckta09otpp7mz08597k02rwvf",
            "price": 89,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9ybqfiopgv0859ishba11i",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631011472391-WhatsApp Image 2021-09-07 at 13.41.00.jpeg"
        ]
      },
      {
        "sku": "1101",
        "description": [
          {
            "id": "ckt9ydaw4oq0408599lmesdwr",
            "title": "Draje Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya7go8jxn3f08594iptvocz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya7go8lxn3h0859xz7oqv81",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7go8mxn3i08597ub43csy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7go8txn3m08596nuujfil",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya7go8vxn3o085981ix3jvl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7go8wxn3p08599innm34h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7go93xn3t0859sayqbf5f",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya7go94xn3v0859rurvlj5r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7go96xn3w0859evp1knmu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9yeejwoqdl0859qpcx10v4",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9yer9hoqif0859osyc2v2p",
            "price": 117,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9ydaw2oq03085916mv58dr",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1631011858973-CBM-1101.jpg",
          "1631011859048-CBM-1101-2.jpg"
        ]
      },
      {
        "sku": "1102",
        "description": [
          {
            "id": "ckt9z7cq5oye108594155ufcj",
            "title": "Candy Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwr3mb14pr7o08592q87x6c4",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckwr3mb17pr7q0859fsxpcdq0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwr3mb18pr7r0859jmcg7twj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9ze1fdp05i08595b9jj5hj",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9ze8irp07j0859ojxjqj3k",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9z7cq2oye00859ekptamhn",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1631013103564-CCH-1102-3.jpg",
          "1631013103516-CCH-1102-4.jpg",
          "1631013103444-CCH-1102.jpg",
          "1631013103501-CCH-1102-2.jpg"
        ]
      },
      {
        "sku": "1103",
        "description": [
          {
            "id": "ckt9zl2x4p1o908598a97jly7",
            "title": "Play Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya76msjxkks0859b6mzlsg8",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckya76mslxkku0859awlt2ig8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya76msnxkkv0859v6e6qsms",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9zn0htp25u0859b4s4yk8h",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9zn8xgp28408591ruuxzjd",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9zl2x1p1o8085908lb29il",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1631013832545-CRM-1104.jpg",
          "1631013832540-CRM-1104-2.jpg"
        ]
      },
      {
        "sku": "1105",
        "description": [
          {
            "id": "ckt9zrccyp34i0859q0au02h6",
            "title": "Ritm Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzo04u8nxbug0859lr049i83",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckzo04u8qxbui0859bjs72q36",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzo04u8sxbuj0859pxa48hu2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9zsqcbp3f70859f5sx2p1x",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9zszgmp3gi0859kgwxccfx",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9zrccwp34h0859r83oybwy",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1631014142093-CCR-1105.jpg",
          "1631014142149-CCR-1105-2.jpg"
        ]
      },
      {
        "sku": "1106",
        "description": [
          {
            "id": "ckt9zwmmup46b0859ms16cxhp",
            "title": "Candy Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7q73bad0e8o0840caai48rh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7q73baj0e8q0840oann1rfh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7q73bal0e8r0840haf8tkn2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7q73bay0e8v0840qm98w614",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7q73bb00e8x0840wcjyi5t2",
                "price": 39,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7q73bb10e8y0840m10lqols",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7q73bb80e920840mu4t3h38",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7q73bba0e940840pptjyyn3",
                "price": 67,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7q73bbb0e950840uilozvh5",
                "price": 24,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7q73bbo0e990840ulqi7zlv",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7q73bbu0e9b08403rltvq8l",
                "price": 11,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7q73bbw0e9c0840tr5v7eyh",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckt9zzk2kp4rh0859wxm0ivg6",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckt9zzqjcp4tp08596yihgrko",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckt9zwmmrp46a0859x6kezphi",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1631014384322-CCN-1106.jpg",
          "1631014384392-CCN-1106-2.jpg"
        ]
      },
      {
        "sku": "1108",
        "description": [
          {
            "id": "ckta0lyyppamy0859vf7vf4p7",
            "title": "September Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckta0pri9pbkf085972gjwb40",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckta0pridpbkh0859862lxdfv",
                "price": 17,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckta0prifpbki0859m52wdmj6",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckta0primpbkl08593v29mtjj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckta0prippbkn0859dfowhchl",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckta0prirpbko08599qblxumn",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckta0prixpbkr0859ojwszr2f",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckta0prj0pbkt0859x3nobvvg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckta0prj1pbku0859w4po7l7c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckta0mipupatm0859qr96ril9",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckta0okcdpba00859qx91qhni",
            "price": 89,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckta0lyynpamx0859tz9evm7c",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631015591444-WhatsApp Image 2021-09-07 at 13.41.07.jpeg"
        ]
      },
      {
        "sku": "1104",
        "description": [
          {
            "id": "cktisjjyghcq90859khe5r18w",
            "title": "Positano Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j45fo9h6z30840tn2d2919",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7j45fojh6z508407hnmh550",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j45fonh6z60840tlh9donp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktislyxuhdbs08599c0gw9an",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktismadmhddv08590yiax11r",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktisjjydhcq80859kvtmrxl2",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1631546266992-cem-1105-1.jpeg",
          "1648627048028-27.png"
        ]
      },
      {
        "sku": "1109",
        "description": [
          {
            "id": "cktiswr00hfjs0859kpfy4e7d",
            "title": "Kafes Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0huortooglp08592to0r08i",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0huortroglr08595leqlfvv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huorttogls08596cxqat7k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0huoru1oglw085956r5dfj9",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0huoru3ogly0859fimd994i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huoru5oglz08597af3293r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0huorucogm30859rl9yd52y",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0huorufogm508597am6kjt7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huorugogm60859i04z7unh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktisyoo6hg050859js8g8tkc",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktisyy41hg1l08590kf9bf4m",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktiswqzyhfjr08596z6g5gka",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646726910389-4.png",
          "1631546862121-CCB-1109-2.jpg"
        ]
      },
      {
        "sku": "1110",
        "description": [
          {
            "id": "cktit562thhb70859nrskb8li",
            "title": "Up Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j4kp75h8le0840tady5cn6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j4kp7fh8lg084080nkxgd4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j4kp7kh8lh0840u1re3omt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j4kp81h8ll0840c8vr59ru",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j4kp84h8ln0840kp1s4r6r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j4kp85h8lo0840yaisdbol",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j4kp8bh8ls0840352yyyqd",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j4kp8eh8lu0840jwuwb7wh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j4kp8gh8lv0840rel936ry",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktit6vfyhhqs0859ry9459hi",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktit78c1hhud0859d37oo1vc",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktit562qhhb60859lyve73us",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648456332304-4.png",
          "1631547213918-CBS-1110-1.jpg"
        ]
      },
      {
        "sku": "1111",
        "description": [
          {
            "id": "cktitficvhk6j0859vw709f4h",
            "title": "Oryantal Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0hupeviogsg08590gw44hi9",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0hupevlogsi0859x97b46lm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hupevsogsj0859jscwst2b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hupevzogsn08591imf41wk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0hupew0ogsp0859s0nl7c3f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hupew1ogsq0859n8q53nht",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hupew7ogsu0859vme37ugk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0hupew9ogsw0859igf2yrjc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hupewaogsx0859vkwly7o1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktith7d5hknj085905q6j5cl",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktithifohkr30859me8jcqx8",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktitficthk6i0859bc2qo8zl",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646726934404-5.png",
          "1631547733067-CCB-1111-2.jpg"
        ]
      },
      {
        "sku": "1112",
        "description": [
          {
            "id": "cktitlk7uhlut0859l0shkvp0",
            "title": "Kaotik Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0huqnmhoh2b085903cd87st",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0huqnmkoh2d0859bpufrjsa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huqnmmoh2e0859d3t2c0i8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0huqnmtoh2i08593hktuop2",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0huqnmwoh2k0859ouelbbyj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huqnmxoh2l0859jbp25ivx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0huqnn5oh2p0859tskh2gy1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0huqnn7oh2r08592qwhev53",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huqnn8oh2s08594l2rfkjj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktitmtwnhm7l0859adphmgpt",
            "price": 36,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktitn31lhmad0859vtfrbtpa",
            "price": 101,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktitlk7rhlus0859ivedgh4w",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646726993895-6.png",
          "1631548015579-CCB-1112-2.jpg"
        ]
      },
      {
        "sku": "1124",
        "description": [
          {
            "id": "cktitpq8qhmya08591wwnpwcm",
            "title": "Alice Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckuwqwjggm9zy0859tfcps5wd",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckuwqwjgjma000859mbd3b3vi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckuwqwjglma010859mwhzl8o3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckuwqwjgtma040859t743cxq6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckuwqwjgwma0608599rhfz5k4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckuwqwjgyma070859jpbtwo99",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckuwqwjh7ma0a0859yezqb5zs",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckuwqwjh9ma0c0859vcs0kc6x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckuwqwjhbma0d08590fklvnsr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwrotmzmcoo0859zmmg9q6h",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwrp0wxmcqd0859lqbz3frg",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktitpq8ohmy90859abl7pily",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1631548208418-WhatsApp Image 2021-09-13 at 15.33.55.jpeg"
        ]
      },
      {
        "sku": "1113",
        "description": [
          {
            "id": "cktj7wdtsjj1708596buqwk0h",
            "title": "Duplicate Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl39xsdyah5qq0840yqwkz204",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl39xsdyhh5qs08407954ev3t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xsdylh5qt08404d43t2bg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xsdyzh5qx0840sozk0dvo",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl39xsdz3h5qz0840k84kky2l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xsdz7h5r00840ik7siu6m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl39xsdzmh5r40840nho8lpc9",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl39xsdzqh5r608401geepedj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl39xsdzth5r70840550q4ce1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktj804f5jjcy0859xo397x34",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktj80f4tjjdr0859yd32icv8",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktj7wdtqjj1608598oqoieg4",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1631571994953-CCB-1113.jpg",
          "1631571994962-CCB-1113-2.jpg"
        ]
      },
      {
        "sku": "1114",
        "description": [
          {
            "id": "cktj86ev7jjy208593jv12y3c",
            "title": "Mountain Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckya7gpy9xn7k08591v89a0sz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckya7gpybxn7m0859kpn3v5dx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7gpydxn7n0859qgyqmp71",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7gpyjxn7r0859hdg3ij30",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckya7gpykxn7t08597b6ea5jh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7gpylxn7u0859ix9m9xp8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckya7gpyrxn7y0859fik66irm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckya7gpytxn80085989i9cs7a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckya7gpyuxn8108599oh527n8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktj8825rjk3r0859y57jrqye",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktj889u2jk4j0859ct0e4f9k",
            "price": 117,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktj86ev5jjy10859rbvueh86",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1631572453689-CCB-1114.jpg",
          "1631572453645-CCB-1114-2.jpg"
        ]
      },
      {
        "sku": "1115",
        "description": [
          {
            "id": "cktj8ei7cjkr90859w1ldgx5g",
            "title": "Naive Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cku5c45v9kvme0859ek9aa8tr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cku5c45vdkvmg0859vy0vnwk4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5c45vgkvmh0859spi1tk9l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5c45vwkvmk08593rylcgi0",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cku5c45vykvmm0859fhdiluzf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5c45w0kvmn0859mxrelitn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cku5c45w6kvmq0859a040th7x",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cku5c45w9kvms0859h7sqn7s6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cku5c45wakvmt0859h6nu92x0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktj8f732jkum0859gkv1p51d",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktj8fei7jkv90859u7k4ddky",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktj8ei7ajkr80859kas8t1ld",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1631572857803-CCB-1115.jpg",
          "1631572857810-CCB-1115-2.jpg"
        ]
      },
      {
        "sku": "1116",
        "description": [
          {
            "id": "cktj8ux8tjlye08594q3tv655",
            "title": "Mountain High Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1vsegd9dxzl084081p5jkzv",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1vsegdddxzn0840ynkxsqmy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1vsegdfdxzo08406koa3vsv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1vsege1dxzs0840kao6aspx",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1vsege5dxzu0840gz5lzjtn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1vsege9dxzv0840ea0yvfka",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1vsegendxzz0840m7r7j0lw",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1vsegeqdy010840zs0f94oz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1vsegesdy020840fsgdupbl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktj8wjy8jm280859z07qn6ik",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktj8wuu7jm2t0859ifp44sab",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktj8ux8qjlyd0859zcxayeyz",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1632320337248-cbs-1116.png",
          "1631573674980-CBS-1116-2.jpg"
        ]
      },
      {
        "sku": "1117",
        "description": [
          {
            "id": "cktj938xljmms0859tfvkksi8",
            "title": "Good Luck Kolye "
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcoprmrxz8k0859dkola1fo",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckzcoprmtxz8m08591yz1af9n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcoprmvxz8n0859cqtsm73n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktj94lwijmqi0859ormqar14",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktj94uiwjmrm0859s5mbrn1c",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktj938xjjmmr085906t36p8d",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631573965893-IMG_4813.jpg",
          "1631573968588-IMG_4814.jpg"
        ]
      },
      {
        "sku": "1118",
        "description": [
          {
            "id": "cktkf5bmvp4h40859gje6dnyk",
            "title": "Wide Cursor Kolye "
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbepprkbtd0840qqqilylm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7kbeppzkbtf0840jzg3353n",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbepq3kbtg0840uxlaj48m",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbepqkkbtk0840otopmqyy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kbepqpkbtm084075xce6uj",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbepqskbtn0840jhyi8eph",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbepr6kbtr0840e7c9ke15",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7kbeprakbtt0840l73jur6f",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbeprckbtu0840jrsdgpis",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbeprmkbty0840umk6etav",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7kbeprpkbu00840wwn92yae",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbeprqkbu10840obotwl8j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktkfn5ztp6vs0859q0h3zz7e",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktkfnhtpp6x40859vsatc2cv",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktkf5bmsp4h30859u2dzv0ig",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631574307045-CCN-1118.jpg",
          "1631574311446-CCN-1118-2.jpg"
        ]
      },
      {
        "sku": "1119",
        "description": [
          {
            "id": "cktkfw9pgp8p60859cxra5g5g",
            "title": "Spear Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j5755wharm0840f9hr3v73",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j57561haro08403qs7gvqy",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j57564harp0840oaeybi93",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5756jhart0840sb50u8yl",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j5756nharv0840lx4sdsbp",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5756qharw0840t0u3b8wr",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j57572has00840c9bw2k5h",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7j57575has20840qkhy0o3h",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j57577has308403q9lmvzy",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5757khas70840u02clicx",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7j5757nhas90840fqudp7t8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5757phasa0840kzz2akza",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j57582hase0840e103l5sg",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7j57588hasg0840gmz4vt1g",
                "price": 23,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5758chash0840n4361vtl",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktkg0arhp9fs0859ghm714bn",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktkg0ivtp9h40859cav0pyzk",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktkfw9pep8p50859217u1t6w",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631645868611-CCN-1119.jpg",
          "1631645871921-CCN-1119-2.jpg"
        ]
      },
      {
        "sku": "1121",
        "description": [
          {
            "id": "cktkg9pmspalz0859myaq13qt",
            "title": "Big Shark Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbgj5qkc1i084077dajemh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7kbgj5ykc1k0840cgvp07hx",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbgj62kc1l0840lmkia4y9",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbgj6hkc1p0840tlpqn5f5",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kbgj6kkc1r0840cuwlh350",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbgj6mkc1s0840dq0gzs7g",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbgj71kc1w0840p90f8jq7",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7kbgj75kc1y0840gfijhvh9",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbgj77kc1z0840ufqjhttx",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbgj7jkc230840948x3h5r",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7kbgj7lkc250840jtm0y4y7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbgj7mkc260840w7c3ika6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbgj82kc2a08405e7ldd43",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7kbgj84kc2c0840ozlpsq0n",
                "price": 23,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbgj86kc2d0840728617es",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktkgaz12paqu0859hptiq5ab",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktkgb3r8parg0859qibf0llw",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktkg9pmppaly08598feug2rd",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631646536749-CCN-1120.jpg",
          "1631646537336-CCN-1120-2.jpg"
        ]
      },
      {
        "sku": "1122",
        "description": [
          {
            "id": "cktkgjczapbp70859oxo9g3db",
            "title": "Short Spear Kolye "
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j5bc50hb040840wrhfokpg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j5bc56hb060840dxa69z1d",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5bc59hb070840r30cskbt",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5bc5lhb0b0840qbwyilw8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j5bc5ohb0d0840zsa2bg5f",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5bc5qhb0e0840asn9i2s1",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5bc61hb0i0840rpc8x3co",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7j5bc66hb0k0840qofjugzd",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5bc6ahb0l08401hir9e7s",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5bc6mhb0p0840ssebqbns",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7j5bc6phb0r08404gfo4grc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5bc6rhb0s084092fm0xvo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5bc71hb0w0840gu2c1jln",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7j5bc73hb0y0840bnrxukaj",
                "price": 23,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5bc74hb0z0840ztlsrtol",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktkglbntpbxe0859z9pi30pt",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktkglhq7pby00859jlw70vtt",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktkgjcz8pbp608596d7axy7j",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631646936135-CCN-1122.jpg",
          "1631646936368-CCN-1122-2.jpg"
        ]
      },
      {
        "sku": "1123",
        "description": [
          {
            "id": "cktkgt25zpd2g0859tq4oj9x8",
            "title": "Cursor Kolye "
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j5hf7jhbdf0840ptysm87y",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j5hf7qhbdh08403yi0dzty",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5hf7shbdi0840vyzjyluk",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5hf83hbdm084068j10pj0",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j5hf85hbdo0840zzpvselo",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5hf87hbdp0840vsiy72e4",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5hf8ehbdt0840n1he1kp8",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7j5hf8ghbdv08407ypdtlc1",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5hf8hhbdw08401doc1w2b",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5hf8ohbe00840rjrt72xa",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7j5hf8qhbe20840nmhnv3wy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5hf8rhbe30840ac2iz348",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5hf8yhbe70840gl7ooyio",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7j5hf90hbe90840svo3bzi3",
                "price": 23,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5hf91hbea0840r6hh33eh",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktkgvi28pdca0859buleusay",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktkgvqqmpdda0859vhw2l826",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktkgt25wpd2f085972mov6a9",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631647464198-CCN-1123.jpg",
          "1631647464478-CCN-1123-2.jpg"
        ]
      },
      {
        "sku": "1125",
        "description": [
          {
            "id": "cktkh4qj6petr085992k1oty4",
            "title": "Shark Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j5vut2hcj30840lgenvoo5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j5vut8hcj508407zyx8u9k",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5vutbhcj60840trdt2ml0",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5vutohcja0840e3egm3pm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j5vutshcjc0840brnt39h5",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5vutuhcjd0840nra18ssj",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5vuu4hcjh0840su9b5gpb",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7j5vuu7hcjj0840tn853glj",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5vuu9hcjk08408p9delt7",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5vuuhhcjo0840beh9s032",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7j5vuukhcjq08408pkp77vj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5vuumhcjr08401dz6hysp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j5vuushcjv08403ki5ne1i",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7j5vuuvhcjx08401386bffi",
                "price": 23,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j5vuuxhcjy0840odlxguq8",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktkh6an8pfa00859xhae3fqo",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktkh6siapffb08590nbxiwzy",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktkh4qj4petq0859ir0w5nxu",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631647982819-CCN-1125.jpg",
          "1631647982290-CCN-1125-2.jpg"
        ]
      },
      {
        "sku": "1126",
        "description": [
          {
            "id": "cktkhj046pid20859knw3jkbr",
            "title": "Long Spear Kolye "
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbi0y3kc9i08408hhvvnqq",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7kbi0y9kc9k0840z5qg9rky",
                "price": 14,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbi0ydkc9l08404oo9dlti",
                "price": 5,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbi0yqkc9p08405y6c9d9c",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kbi0ytkc9r0840s4eliecc",
                "price": 38,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbi0ywkc9s0840fwzaau6g",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbi0z4kc9w0840qqrtjtgp",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7kbi0z7kc9y08404d2un2c2",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbi0z9kc9z084035a2ku7r",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbi0zokca308407nsspfe0",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7kbi0ztkca50840yzrpk9rs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbi0zwkca60840vb8mcnt0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbi10akcaa08401p0etfz0",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7kbi10ekcac08407xsjk5bz",
                "price": 23,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbi10hkcad0840nbabt2wz",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cktkhlox2pitf08593a32dcna",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cktkhltjopiu10859bwsgs7kk",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cktkhj043pid10859ldvhiohj",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1631648672343-CCN-1126.jpg",
          "1631648672106-CCN-1126-2.jpg"
        ]
      },
      {
        "sku": "864",
        "description": [
          {
            "id": "cktm1edfdx9af08596zkd5yuc",
            "title": "Uprising Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3457x02mgp80840hgnsjqlm",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3457x06mgpa084047rapju9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3457x08mgpb0840uj3wmbs5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3457x0gmgpf084055rfnlt5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3457x0imgph0840n8b3u8ef",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3457x0jmgpi0840pwleft1n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3457x0rmgpm0840hxjjxakr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3457x0umgpo0840km43m7bz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3457x0wmgpp08403wpz8cin",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cktm1edfax9ae0859b2icboch",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1631742516679-ccb-864.jpeg"
        ]
      },
      {
        "sku": "1127",
        "description": [
          {
            "id": "ckttvqnecjmg608593vszi59q",
            "title": "Mineli Kilit"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i6w9xdsgx70859uj0485uh",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i6w9xfsgx90859cbi65lug",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6w9xhsgxa0859gh4ynut8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckttvqne9jmg50859bnqy7k7m",
        "category": {
          "id": "ckm2cvuad2gl70897zbpuuubq",
          "sku": "CLM"
        },
        "photos": [
          "1632216773013-WhatsApp Image 2021-09-21 at 11.38.23.jpeg"
        ]
      },
      {
        "sku": "1000",
        "description": [
          {
            "id": "ckuwdfo3el1mj0859dkwh9zk5",
            "title": "İpli X Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g9meri4vi0840at6z2x5n",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77g9mevi4vk0840bbyltmm5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g9meyi4vl0840bpqos2ji",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77g9mfei4vp0840bkcko11r",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77g9mfji4vr0840wuv9jgr3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g9mfmi4vs0840tzmlzdiy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77g9mg0i4vw0840exod4iul",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77g9mg3i4vy0840dmhnhdmf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g9mg6i4vz0840dewdhvdq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckx63dz291to608595a8kszqc",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckx63ehm71tqb0859ju4pb0v1",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwdfo3cl1mi0859piv3eo6l",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646727044775-7.png",
          "1635770512030-ccb-1000-2.jpg"
        ]
      },
      {
        "sku": "1128",
        "description": [
          {
            "id": "ckuwdm52ml2960859ek8757k1",
            "title": "X Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzpp4hin21d208596a5i71l0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzpp4hiq21d40859xmonhveh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp4his21d50859g60z48bz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpp4hj021d90859a8oro2jp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzpp4hj321db08590vr3vdec",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp4hj521dc0859o5siysot",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzpp4hjd21dg0859zvkrwf7k",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzpp4hjg21di0859l2izyzz5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzpp4hji21dj0859lo5ftmif",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwdo7xkl2f80859ctygp2xj",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwdorm7l2hi0859jdstxnpu",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwdm52jl2950859b882i31l",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1635770944216-ccb-1128-1.jpg",
          "1635770951099-ccb-1128-2.jpg",
          "1645024511262-1128.png"
        ]
      },
      {
        "sku": "1129",
        "description": [
          {
            "id": "ckuwdt3ntl2uw0859qgzlc0ll",
            "title": "Treat Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5493xrji5ld0840ohpyrf4g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5493xrni5lf0840uacvqlgq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5493xrpi5lg0840esy0inlj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5493xrzi5lk084069u5ogv8",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5493xs1i5lm0840i5x1asmq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5493xs3i5ln0840hswjos75",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5493xsbi5lr0840tkwgcpxm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5493xsdi5lt0840l7z3mq2t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5493xsfi5lu0840vgakg1jy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwduqksl2xe0859h1nhch3g",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwdvd5gl2yg08592g7doapc",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwdt3nrl2uv0859yj41ohsf",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1651085636906-38.png",
          "1635771139450-ccb-1129-2.jpg"
        ]
      },
      {
        "sku": "1130",
        "description": [
          {
            "id": "ckuwe2lq6l3870859nqpep4cr",
            "title": "Balance Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbk8y9kcoy0840lj256esz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7kbk8yfkcp00840evf5hzpm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbk8yikcp10840dzp5l4z4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbk8ytkcp50840e283q8sz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7kbk8ywkcp70840fw71ld5j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbk8yykcp808404pfxheyi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbk8z6kcpc0840wuizj4or",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kbk8z8kcpe08400wrvke4m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbk8zakcpf08407u0b3tb8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwe3gdkl3be0859jq6ccw6u",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwe3prrl3cu0859x54ue8qx",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwe2lq4l3860859zwtr75ue",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646727104855-8.png",
          "1635771586167-CCB-1130-2.jpg"
        ]
      },
      {
        "sku": "1131",
        "description": [
          {
            "id": "ckuwe9wbul3rx0859f6kkeaeo",
            "title": "Noktalı Sınır Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0th4rk9zwnn0859chpxsl1f",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0th4rkfzwnp0859la5vqf3r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0th4rkozwnq0859fg6j3c1l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0th4rkyzwnu08590gj2t6kp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0th4rl0zwnw0859xz9ga6im",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0th4rl2zwnx0859q50ymgh2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0th4rlazwo10859oo3jtfj5",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0th4rldzwo308592j2ldjmk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0th4rlhzwo4085970lnox51",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuweb0tfl3va08594haryk3f",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwebag1l3vn0859pvrmblhx",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwe9wbsl3rw0859978ti7k3",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646735414894-6.png",
          "1635772899406-CBM-1131-2.jpg"
        ]
      },
      {
        "sku": "1132",
        "description": [
          {
            "id": "ckuwenfbul4iq0859fkskcaac",
            "title": "Tent Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl12aurp7prmh0859na7dod6p",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl12aurpaprmj0859qmnic25g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl12aurpdprmk085965ied26y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl12aurpkprmo08594n4n7akc",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl12aurpmprmq0859tcekykbz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl12aurpnprmr0859snobow3y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl12aurptprmv085959wgj22z",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl12aurpvprmx0859o2t8d7ql",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl12aurpwprmy08599qmion3s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwepldkl4xg0859orlril89",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwepxwfl4yf08598uhq22mc",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwenfbql4ip0859h306o8as",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1635771824931-CCB-1132.jpg",
          "1635771826269-CCB-1132-2.jpg",
          "1645024574107-1132.png"
        ]
      },
      {
        "sku": "1133",
        "description": [
          {
            "id": "ckuwer1u6l52f0859nucnipn6",
            "title": "Tune Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl53zoqdfhfhs08400pqs5ywk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl53zoqduhfhu0840dyzt0b3x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zoqdxhfhv08400ivrma8v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl53zoqeahfhz0840ill4pxjt",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl53zoqedhfi10840qtc5yoht",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zoqeehfi20840pstge2cc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl53zoqelhfi60840sij0otns",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl53zoqenhfi808409tpen30z",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl53zoqeohfi90840yi29zwrq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwerdpwl53v0859ixtzl4sp",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwerwgil55k0859uohbxsyq",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwer1u4l52e0859wg776jw8",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646839418751-1133.png",
          "1635771958854-CCB-1133-1.jpg"
        ]
      },
      {
        "sku": "1134",
        "description": [
          {
            "id": "ckuwew9qfl5ha0859ww7ioesk",
            "title": "Köşe Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0huuzrloi9b08593dqx4obs",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0huuzrooi9d0859uzqkfg8q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huuzrqoi9e0859nf7cs8xj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0huuzryoi9i0859x4jdrdm7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0huuzs0oi9k0859r3hgxhtv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huuzs2oi9l0859lgt7y07s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0huuzs8oi9p0859vtfn3sgc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0huuzsaoi9r0859gib8qv5n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0huuzscoi9s0859rp5oqg16",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwey11pl5kz0859srf7mleg",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwez9fpl5nu0859iwswfw5p",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwew9qcl5h90859lrjbp89n",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646727164158-10.png",
          "1637321994047-ccb-1134-2.jpeg"
        ]
      },
      {
        "sku": "1135",
        "description": [
          {
            "id": "ckuwf0vubl5um0859ukanw4jq",
            "title": "Mineli Treat Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0hzy69tpx8m0859bc0g87tu",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0hzy69wpx8o085917qj89n1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzy69xpx8p085926fb1t1s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hzy6a3px8t0859f4dlrq09",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0hzy6a5px8v085903a2d6p3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzy6a6px8w0859kl0gndxj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hzy6acpx900859a4si4ht4",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0hzy6aepx920859p411kap9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzy6agpx930859wymdm7ne",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwf2yrbl646085927slrgwq",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwf37kyl64r0859qn0iu738",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwf0vu8l5ul08598nvtbolr",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646735746304-8.png",
          "1635773184707-CBM-1135-2.jpg"
        ]
      },
      {
        "sku": "1136",
        "description": [
          {
            "id": "ckuwfczp9l6qf08598trvsoyh",
            "title": "Döngü Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl2hxuuqgd7jj0840wghm60c3",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl2hxuuqmd7jl0840y2svbzpq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2hxuuqqd7jm0840g85qni6s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2hxuur1d7jq0840um7jpv1n",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl2hxuur4d7js0840rsfzkg4i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2hxuur6d7jt08402vpbg9ay",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2hxuurgd7jx0840hmh34jbc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl2hxuurid7jz0840m5oug8hv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2hxuurkd7k00840wu73a1l3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwfdv81l6u60859rwwvrp2u",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwfelpdl6wo0859l6n1j41s",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwfczp5l6qe0859ddv5e0h0",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1651085797565-37.png",
          "1637320375092-ccb-1136-2.jpeg"
        ]
      },
      {
        "sku": "1137",
        "description": [
          {
            "id": "ckuwfiot4l79i0859z73edlu3",
            "title": "Mineli Marble Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g9up7i4zd0840tl7v0ino",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77g9upai4zf0840il5raczd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g9upci4zg0840586ovupi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77g9upki4zk0840eskk0v1y",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77g9upni4zm08408nakoxc6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g9uppi4zn0840it86dro7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77g9upyi4zr0840mwyrpy0g",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77g9uq0i4zt084022m8u2th",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g9uq1i4zu0840u0ryx5sn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwfjd6ll7eh0859hix88zw2",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwfkl8ll7ka0859pykht950",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwfiot1l79h0859ahtt0j7j",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646735764169-12.png",
          "1637322386393-cbm-1137.jpeg",
          "1637322386446-cbm-1137-2.jpeg"
        ]
      },
      {
        "sku": "1138",
        "description": [
          {
            "id": "ckuwkqxbelnsd08595cd7lovm",
            "title": "Satışta Yok Kaldırıldı"
          }
        ],
        "sizePrices": [
          {
            "id": "cl63dllffnv2s0840xqaqihrv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl63dllflnv2u0840ggyvqh1i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl63dllfonv2v084057y2y421",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwkt5dylo020859ge8nbq8h",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwkte38lo0q08598x8k9q3n",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwkqxbalnsc0859zv7sq6e1",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1634556482433-1138.jpeg"
        ]
      },
      {
        "sku": "1139",
        "description": [
          {
            "id": "ckuwkxa5plodu0859q47eyq5k",
            "title": "Dreamy Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i0a349q08u0859dn0cf086",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl0i0a34bq08w0859x7ce0kaf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i0a34cq08x0859nky2ws4w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwky3cklohq08596aafsuh9",
            "price": 78,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwkyj86lojc0859bds5mijq",
            "price": 218,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwkxa5mlodt0859ggs6wxea",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1635776478565-CCN-1139.jpg",
          "1635776490334-CCN-1139-2.jpg"
        ]
      },
      {
        "sku": "1140",
        "description": [
          {
            "id": "ckuwl0dc2lopm08592tq8nfkf",
            "title": "Knot Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kblqt5kd180840gbtbxhm0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7kblqt8kd1a0840iruyxwl4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kblqtbkd1b0840srgkq9de",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kblqtikd1f0840vmher38t",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7kblqtkkd1h0840y9b3vcdt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kblqtmkd1i0840k8erlsct",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kblqttkd1m0840fkf17o6b",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kblqtvkd1o0840zaxqj4hd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kblqtxkd1p0840vx2d31yn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckuwl1noxlotn08598soubwxy",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckuwl219yloue0859wjf63j0l",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwl0dc0lopl0859y4dxlell",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646840514402-1.png",
          "1635772184354-CCB-1140-2.jpg"
        ]
      },
      {
        "sku": "1141",
        "description": [
          {
            "id": "ckuwmufi2lth10859q9h3qm3y",
            "title": "Ufo Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0uxupzu5jha0859b03i3ij6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0uxupzz5jhc0859orpzpysr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0uxuq015jhd0859a6sb2vo7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0uxuq075jhh0859b0moy2qe",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0uxuq095jhj0859nhdnsfiu",
                "price": 44,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0uxuq0a5jhk0859yogr8n18",
                "price": 16,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0uxuq0g5jho085952qp4b68",
            "size": {
              "id": "ckr29cpxvakbh09594nr6tkqz",
              "sku": "XXL"
            },
            "prices": [
              {
                "id": "cl0uxuq0h5jhq0859m56gcbd8",
                "price": 53,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0uxuq0i5jhr08591izpn2q6",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl0uxpjts5ily08593eaji5vr",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl0uxrs8m5j220859zsh6a5b9",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckuwmufhzlth00859hq5yknfj",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1635777408530-ccn-1141.jpg",
          "1635777424539-ccn-1141-3.jpg"
        ]
      },
      {
        "sku": "1142",
        "description": [
          {
            "id": "ckux0bqk1mzp308595ofhsind",
            "title": "Satışta Yok Kaldırıldı "
          }
        ],
        "sizePrices": [
          {
            "id": "cl63dku33nuz0084028fiektz",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl63dku38nuz20840o6s929nx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl63dku3anuz30840oxi3ej1o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckux0ccz5mzu608593cp1ekcu",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckux0d6d9mzxf085995fu7m5n",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckux0bqjymzp208590iys0qqg",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1634582661270-1142.jpeg"
        ]
      },
      {
        "sku": "1143",
        "description": [
          {
            "id": "ckux0g12en0b10859jvdpb1mg",
            "title": "Muse Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckux0xur9n2cp08596wocu4m4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckux0xurcn2cr08599q0jlqj4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckux0xuren2cs085987pm8etz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckux0xurln2cv08599794wxpw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckux0xurnn2cx0859k6blvlsr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckux0xurpn2cy0859u4sj9suk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckux0xurvn2d10859zr9z2p2c",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckux0xurxn2d3085984uc44u8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckux0xurzn2d408593dia9v46",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckux0gloen0en0859mvoter2u",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckux0wsvln2860859ulbnpfxu",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckux0g12bn0b008598hqw1sno",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1634582854296-1143.jpeg"
        ]
      },
      {
        "sku": "1144",
        "description": [
          {
            "id": "ckux0zj13n2gi0859ge5hi93v",
            "title": "Milkyway Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0hzsbkqpw210859p79a3kom",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0hzsbkspw230859hdko8d9r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzsbkupw240859jw9wms0w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hzsbkzpw280859cwlrn2ql",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0hzsbl1pw2a0859cnjjdtxm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzsbl2pw2b08599atkpauw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0hzsbl7pw2f0859b68n5a41",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0hzsbl9pw2h0859b6e3v99d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0hzsblapw2i0859idoguupm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckux10k2dn2ix0859bmehifde",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckux10r5en2j90859jpt5didh",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckux0zj10n2gh085914xl9zvi",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1646735474051-9.png",
          "1635776256717-CBM-1144-2.jpg"
        ]
      },
      {
        "sku": "1145",
        "description": [
          {
            "id": "ckux14k9ln2xf0859z6vpszgk",
            "title": "Wings Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxac7q8mexoo08597ame78t9",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxac7q8oexoq0859nkebc06h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac7q8pexor08594snc047e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac7q8vexov08590x5pdvvj",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxac7q8wexox085922y5c41j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac7q8yexoy08592j2y60yu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxac7q93exp20859n56bsgt4",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxac7q94exp40859bu7f2dl8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxac7q96exp50859oovgrwwr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckux180ihn37u0859ut7ptg3d",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckux19v39n3bv0859nakzd4wf",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckux14k9jn2xe0859cskg29lx",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1635772586596-CCB-1145.jpg",
          "1635772596241-CCB-1145-2.jpg"
        ]
      },
      {
        "sku": "1146",
        "description": [
          {
            "id": "ckux1uokvn4vo0859ic5ts759",
            "title": "Faithful Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl06p8fhxhvd30859c3vkk971",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl06p8fi4hvd50859ag4uewo3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl06p8fi6hvd60859s97fam0v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckux1vrxen55708591iw63v9u",
            "price": 78,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckux1w098n57408598w1rhf5u",
            "price": 218,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckux1uoktn4vn08591330976p",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1634584627666-1146.jpeg"
        ]
      },
      {
        "sku": "1147",
        "description": [
          {
            "id": "ckux1xez3n5f40859t4gebamt",
            "title": "Cheesecake Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3a9x89sjowv0840rfw52bop",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl3a9x89yjowx08409gvd8ohy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3a9x8a2jowy0840e2tv9frc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckux1z4w8n5px0859suqtrmre",
            "price": 434,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckux1z9fgn5qs08594zq8ij8j",
            "price": 155,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckux1xez1n5f30859s0tyhuds",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1652799109431-IMG_4108.jpg",
          "1652799110404-IMG_4109.jpg",
          "1635778562892-ccn-1147-2.jpg"
        ]
      },
      {
        "sku": "1148",
        "description": [
          {
            "id": "ckux24x0kn6hg0859432egk1k",
            "title": "Unity Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7ogrzzwvbxw0840p13gkt3b",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7ogs005vbxy08402pbwk7eo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7ogs007vbxz0840jkypta66",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7ogs00kvby30840t4u4shsc",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7ogs00ovby50840bkzqj7p1",
                "price": 84,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7ogs00qvby60840wlj9qn09",
                "price": 30,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckux26nbrn6q9085942s8vgr2",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckux26to8n6r10859f1zbfktf",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckux24x0hn6hf0859qc5ltogq",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1635778824088-ccn-1148.jpg",
          "1635778833385-ccn-1148-1.jpg"
        ]
      },
      {
        "sku": "1149",
        "description": [
          {
            "id": "ckux28nfjn71k0859hnys4x4f",
            "title": "Wings Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzcoqh8txzgp0859i8i87rg4",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckzcoqh8vxzgr08591g9yj87n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzcoqh8xxzgs08590q96tn91",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1no9sxkpd1f0859229y8ioq",
            "price": 255,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1noa2kcpd2v0859e68y2u3w",
            "price": 91,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckux28nfhn71j0859h9sheoe4",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1635779144796-ccn-1149.jpg",
          "1635779152743-ccn-1149-2.jpg"
        ]
      },
      {
        "sku": "0387",
        "description": [
          {
            "id": "ckuxxt2o6oe41085935344dap",
            "title": "Zincir Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckuxxwduuof310859cvkx2jtc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckuxxwduzof330859wog4nczj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckuxxwdv1of3408591gzn0jec",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckuxxwdv7of37085999uljbmn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckuxxwdvbof390859d1mgwaet",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckuxxwdvcof3a0859s0gi2nmb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckuxxwdviof3d0859l67e85my",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckuxxwdvkof3f0859888ijnq3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckuxxwdvmof3g0859et19svx2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckuxxt2o3oe400859m2o32og5",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1634638915796-ccb-387-1.jpg"
        ]
      },
      {
        "sku": "1150",
        "description": [
          {
            "id": "ckv6ttrep6dsn08596x4i2nra",
            "title": "Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckv6txzan6e5m0859dn5dr2b1",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckv6txzax6e5o0859p7x1n00q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckv6txzb06e5p0859xutlt59q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckv6txzba6e5s0859ogbv86v3",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckv6txzbe6e5u0859vw2o6d6l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckv6txzbg6e5v08590rtvpj7m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckv6txzbp6e5y0859ssp2z3kv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckv6txzbt6e600859nmk9a9fu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckv6txzbw6e610859lyrh6udq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckv6tv6iv6dxh08595pk7q9o5",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckv6tw2z76dzx0859s4zx5wcn",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckv6ttrej6dsm085924z5s10h",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1635176407745-IMG-20211025-WA0032.jpg"
        ]
      },
      {
        "sku": "1151",
        "description": [
          {
            "id": "ckv6u0gx26efg085910o40fz7",
            "title": "Wonderland Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckv6u6fvl6f300859h182b8gw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckv6u6fvr6f320859ed7eccb3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckv6u6fvv6f330859t5e44zky",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckv6u6fw86f360859dxne8ont",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckv6u6fwd6f380859s94bgknp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckv6u6fwh6f390859eqarjry0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckv6u6fwy6f3c08598r3woabq",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckv6u6fx36f3e0859scxdd3xd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckv6u6fx86f3f0859urr5osfb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckv6u5h8a6ey70859etxcbyrb",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckv6u69yx6f040859v594l5do",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckv6u0gwy6eff0859nncno2gu",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1635176666981-IMG-20211025-WA0033.jpg"
        ]
      },
      {
        "sku": "1152",
        "description": [
          {
            "id": "ckv80enaw8abg0859el1m094i",
            "title": "Shiny Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbmd7ykd6e0840jhiv59k4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7kbmd83kd6g08409o4q5ih9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbmd87kd6h08402mcjlm0d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbmd8kkd6l0840t6mnfkor",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7kbmd8nkd6n0840tavcs61p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbmd8okd6o0840uanj05s9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbmd8xkd6s0840txllr47p",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kbmd8zkd6u0840ai0uhtcq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbmd91kd6v0840fzdhnvhq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1lz2zwrf8v90859ndez23ev",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1lz3hd6f8yr08596klfrnu8",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckv80enar8abf0859f3wipwc2",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1646735914145-1152.png",
          "1638344782480-IMG_0790.jpg"
        ]
      },
      {
        "sku": "1153",
        "description": [
          {
            "id": "ckv80hx438arf0859y2m24353",
            "title": "Glam Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54ellm2iwzj0840sr0dc5sn",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54ellm7iwzl0840g5u1vuz8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ellm9iwzm08409i7juavn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ellmkiwzq0840dwzp529m",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54ellmniwzs0840iseyemqg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ellmoiwzt0840gqubcf7c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54ellmwiwzx0840ad8u0wvr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54ellmyiwzz0840v7orh2t1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54ellmzix0008403mxbqga0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1lza8uwfabe0859q4uipm5d",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1lzakn6fadg0859rrfjxehz",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckv80hx408are0859xc6kuy3l",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1638359308846-1153.png",
          "1638345091805-IMG_0782.jpg"
        ]
      },
      {
        "sku": "1154",
        "description": [
          {
            "id": "ckv80nea08bfm08596jr31mnq",
            "title": "Shiny Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl2uah1oiw6uq0840lwmzw9em",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl2uah1onw6us0840tg08oaix",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2uah1oqw6ut0840qbt7aqno",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1lzfumpfb5g0859u2l09fux",
            "price": 106,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1lzg1jvfb6q0859vk9kmt51",
            "price": 297,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckv80ne9w8bfl0859lc0ytdf2",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1638345321120-IMG_0763.jpg",
          "1638345321919-IMG_0766.jpg"
        ]
      },
      {
        "sku": "1155",
        "description": [
          {
            "id": "ckvh14fptvr9108599p9j71fh",
            "title": "Halkalı Geometrik Kiss Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbmz61kdda0840jgvr51zb",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7kbmz68kddc0840yje4hfia",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbmz6ckddd0840xby76d5i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh168nqvrna08591eiz5lh6",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvh16guyvroc0859lekug158",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh14fppvr900859s32dyv1i",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1635793325833-cem-1155-1.jpg",
          "1648627054710-28.png"
        ]
      },
      {
        "sku": "1156",
        "description": [
          {
            "id": "ckvh1c0jlvsb30859o6qutr22",
            "title": "Halkalı Geometrik Rose Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbozh3kdsn08404l61mu99",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7kbozhakdsp0840oyngjcgu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbozhdkdsq0840ffz21o24",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh1dxtfvsml0859wn8baugw",
            "price": 95,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckkj07qpm4u4b0919qys303u5",
              "code": "USD"
            }
          },
          {
            "id": "ckvh1e4xqvsoi0859xpuh8bnb",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh1c0jhvsb20859qjjcdbzf",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1635793692755-CEM-1156.jpg",
          "1648627057472-29.png"
        ]
      },
      {
        "sku": "1157",
        "description": [
          {
            "id": "ckvh1j5d5vtlg0859eanyqlid",
            "title": "Halkalı Geometrik Fresh Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbpoz7ke1c0840qqdxkhvu",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7kbpozcke1e0840i49qxe7x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbpozeke1f0840faiboa1r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh1khghvtrz0859t309j676",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvh1kzmdvtv50859tezkl090",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh1j5d1vtlf0859b18sjkzv",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1635793993586-cem-1157.jpg",
          "1648627059851-30.png"
        ]
      },
      {
        "sku": "1158",
        "description": [
          {
            "id": "ckvh98xm2wgd40859tmjpb6za",
            "title": "Halkalı Geometrik Dream Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbq5ezke570840z49wiqq5",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7kbq5f4ke59084048biv8vc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbq5f8ke5a0840y31n6o8n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh9a1vawgfp0859ber30kxg",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvh9akxtwgh70859qpicslmr",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh98xlzwgd30859jcbjaupt",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1635806968307-CEM-1158.jpg",
          "1648627065769-31.png"
        ]
      },
      {
        "sku": "1159",
        "description": [
          {
            "id": "ckvh9ea30wgph0859djg53i8u",
            "title": "Halkalı Geometrik Ice Cream Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1da0jhjgh930859alsym57s",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1da0jhlgh950859zu5pnzoe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1da0jhngh96085991pqq09r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh9f1m7wgrw0859h7745kqa",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvh9fni9wgu8085968k0x6wx",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh9ea2xwgpg08596uddv5o9",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1635807197539-CEM-1159.jpg",
          "1648627101251-32.png"
        ]
      },
      {
        "sku": "1160",
        "description": [
          {
            "id": "ckvh9hhdlwgzz0859q00kiwp7",
            "title": "Halkalı Geometrik Shaped Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbqqkgked30840v8w3rddm",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7kbqqklked50840ci04uj0y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbqqkpked60840ehd636q9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh9i74awh2m0859dz2kp9kf",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvh9ir25wh4a0859tnytpxxx",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh9hhdiwgzy085997sujrga",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1635807360605-CEM-1160.jpg",
          "1648627103387-33.png"
        ]
      },
      {
        "sku": "1161",
        "description": [
          {
            "id": "ckvh9lpqiwhfr08593r474hss",
            "title": "Halkalı Geometrik Cold Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbr9r8kefs08406w5hzs1b",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7kbr9rckefu0840jckn11bw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbr9rekefv0840g4s8gnhk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh9md4dwhj60859fb7n959w",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvh9mvgmwhll0859jk1un2yi",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh9lpqgwhfq0859cj2imwhx",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1635807519930-CEM-1161.jpg",
          "1648627105596-34.png"
        ]
      },
      {
        "sku": "1162",
        "description": [
          {
            "id": "ckvh9phafwhwj0859tbv7eenz",
            "title": "Halkalı Geometrik Cherry Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7izuagzgkd508406huft1u2",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7izuah5gkd70840o4j7l8x3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izuah7gkd80840ztyag5g5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7izuahsgkdn0840wbb5k3nl",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7izuahvgkdp0840nq9rrvgz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izuai0gkdq0840pfilx7ow",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh9q2kfwhzn0859gx3vauhy",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvh9qnt5wi20085946frhu2x",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh9phacwhwi0859aysnhmzx",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1635807714667-CEM-1162.jpg",
          "1648627107714-35.png"
        ]
      },
      {
        "sku": "1163",
        "description": [
          {
            "id": "ckvh9vsqlwijd0859x8xplyjo",
            "title": "Shield Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7izvb7agkku0840co9okl66",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7izvb7egkkw0840zdhtn5vp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izvb7hgkkx0840rzpr464v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvh9yk8cwiv80859suoljjh9",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvh9ysxzwiwx0859yestoylo",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvh9vsqiwijc0859f2utuk4w",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1635807950426-CCR-1163.jpg",
          "1635807949354-CCR-1163-2.jpg",
          "1635807949646-CCR-1163-5.jpg",
          "1635807949304-CCR-1163-6.jpg",
          "1635807950312-CCR-1163-3.jpg",
          "1635807949708-CCR-1163-4.jpg"
        ]
      },
      {
        "sku": "1164",
        "description": [
          {
            "id": "ckvha3lidwjg60859n34cfgi2",
            "title": "Signals Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7izw1w9gkog0840zfq2sjaw",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7izw1wdgkoi084060oaxny4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izw1whgkoj0840db6k7k75",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvha5a6xwjnq0859mlmhtjlv",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvhaanh0wkej0859jv8jz84q",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvha3liawjg50859o8wg52ts",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1635808392219-CCR-1164-7.jpg",
          "1635808383355-CCR-1164-8.jpg",
          "1635808388929-CCR-1164-3.jpg",
          "1635808385245-CCR-1164-4.jpg",
          "1635808391488-CCR-1164.jpg",
          "1635808391836-CCR-1164-2.jpg",
          "1635808392860-CCR-1164-5.jpg",
          "1635808389885-CCR-1164-6.jpg"
        ]
      },
      {
        "sku": "1165",
        "description": [
          {
            "id": "ckvhakqfdwkym0859kllmnjw5",
            "title": "Fence Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7izwn4kgkrm08404f8r3bxd",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7izwn4rgkro0840qv793ev1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izwn4vgkrp0840pewann5r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvhallklwl130859hj7sl6v6",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvham90zwl3l0859xaw1s3w3",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvhakqf9wkyl08592k223obp",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1635809209019-CCR-1165.jpg",
          "1635809207278-CCR-1165-2.jpg"
        ]
      },
      {
        "sku": "1166",
        "description": [
          {
            "id": "ckvhapy2swlh008596d7pi7br",
            "title": "Ok Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7izyb0agl7z0840ys5m9p4f",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7izyb0hgl810840n48jeytr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izyb0lgl820840xrojg2af",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7izyb98gl9c0840o508k3qh",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7izyb9cgl9e0840aebd7cks",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izyb9egl9f0840rxxsc8au",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvhaqqvpwlk20859w0wzbkxx",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvhar95zwlm30859ng9nnrx2",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvhapy2owlgz0859ugyhdmc4",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1635809445986-CCR-1166-3.jpg",
          "1635809440035-CCR-1166-4.jpg",
          "1635809445810-CCR-1166.jpg",
          "1635809442242-CCR-1166-2.jpg",
          "1635809447243-CCR-1166-5.jpg",
          "1635809444204-CCR-1166-6.jpg"
        ]
      },
      {
        "sku": "1167",
        "description": [
          {
            "id": "ckvhaudcwwlw10859e0cws68w",
            "title": "Taşlı Ok Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7izz46lglf608402z5me08n",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7izz46qglf808402q0hjw49",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izz46tglf90840fol52ucg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvhav3xgwlyc08590lqda8lb",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvhawclqwm0t0859okwupgiv",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvhaudctwlw00859esxrb9n9",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1635809657656-CCR-1167.jpg",
          "1635809651769-CCR-1167-2.jpg",
          "1635809659429-CCR-1167-3.jpg",
          "1635809655550-CCR-1167-4.jpg",
          "1635809660277-CCR-1167-5.jpg",
          "1635809656759-CCR-1167-6.jpg"
        ]
      },
      {
        "sku": "1168",
        "description": [
          {
            "id": "ckvhaz0uuwm7d085934elc266",
            "title": "Boncuklu Pul Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckvhb3zd0wmx00859yzmebebj",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckvhb3zd3wmx208592qeqobp6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckvhb3zd5wmx30859e8hh4vlf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvhazq67wm960859ozht5ojy",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvhb08dewmax0859ghp4k1pu",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvhaz0urwm7c0859jm8fkb6n",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1635809846310-CCR-1168.jpg",
          "1635809845559-CCR-1168-2.jpg"
        ]
      },
      {
        "sku": "394",
        "description": [
          {
            "id": "ckvi1qeyey49i0859co8rw5r7",
            "title": "Easy To Get Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckvk87i7m2nr10859ukr71443",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckvk87i7q2nr30859iji4a0y5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckvk87i7s2nr4085963no4h3w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckvi1uuzcy4y808599ww5x0q6",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckvi1v0c5y4zf08591w1xvumy",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvi1qeycy49h08591xikucob",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1635855016968-cce-394.jpeg"
        ]
      },
      {
        "sku": "571",
        "description": [
          {
            "id": "ckvwax1gy1c710859dmn8grte",
            "title": "Çizgili Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckvwaxarh1c9v08599fuw4vbz",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckvwaxarn1c9x0859aehetz0d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckvwaxarp1c9y0859ek2frwyr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckye7ecfo99fr0859p7e0ymop",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckvwax1gv1c700859bomprqop",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1636716757596-cizgili-yuzuk.jpeg"
        ]
      },
      {
        "sku": "1169",
        "description": [
          {
            "id": "ckwer24kfcca308591bz2ahpw",
            "title": "Analog Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "ckwer2viwccj90859dkggn55v",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckwer2vj0ccjb0859bpmmf2rm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckwer2vj1ccjc0859fsxvdv7y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckwer24kbcca20859purrseu8",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1637832136232-IMG-20211125-WA0000.jpg"
        ]
      },
      {
        "sku": "1170",
        "description": [
          {
            "id": "ckwm8k74cbx9t0859d6bf5nz7",
            "title": "Glam Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wdjyni6mh40840yk9zqosd",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl5wdjynp6mh60840ycht99wg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wdjynr6mh70840w9j5xbhg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1lzcxa0faqf08597hnzfrok",
            "price": 106,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1lzdazkfas80859eh4htwyy",
            "price": 297,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckwm8k749bx9s0859cvoqprb4",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1647234843600-IMG_0040.jpg",
          "1647234843756-IMG_0041.jpg"
        ]
      },
      {
        "sku": "1171",
        "description": [
          {
            "id": "ckwxhdkv8a2mw0859rmxfhikx",
            "title": "Siyah & Beyaz İpli Taş Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0ti49za0ek60859omquc1mr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0ti49zc0ek80859yz6blw1e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0ti49zd0ek9085917qp9sot",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0ti49zi0ekd0859a2rt9kam",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0ti49zj0ekf0859nrh8mu66",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0ti49zl0ekg0859khg3fkx0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0ti49zq0ekk085951veomfy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0ti49zr0ekm0859z87407yr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0ti49zt0ekn0859u18xvvrb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckwxheag1a2vp085996ngui4z",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckwxheqc3a31708594sdj19bg",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckwxhdkv5a2mv0859bxgqbbyu",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646840361256-2.png",
          "1641885889954-IMG_2125.jpg",
          "1641885898309-IMG_2138.jpg"
        ]
      },
      {
        "sku": "725",
        "description": [
          {
            "id": "ckx5uhwlk0le408592ss781ic",
            "title": "No Turning Back Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckx5unsoe0m3j0859v8q4ucj6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckx5unsoh0m3l0859o64ya5c1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx5unsoj0m3m0859gj6nv43k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckx5unsoq0m3q0859szymemtl",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckx5unsos0m3s0859laojp19b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx5unsot0m3t08594ottkl6c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckx5unsp10m3x0859a5v3u2e8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckx5unsp30m3z0859sstcnmim",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx5unsp50m400859qdbchgyz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckx5ujas30ljf08590vh6ufno",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckx5unmtc0m0w08591701x3fd",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckx5uhwlf0le20859xi6a0rfu",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1639470590143-IMG-20211214-WA0000.jpg"
        ]
      },
      {
        "sku": "1172",
        "description": [
          {
            "id": "ckx62kd0h1ox408595utugva0",
            "title": "Just For Me Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckx62loeu1pa808594cg9ah9b",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckx62loex1paa08591getjr9o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx62loez1pab08593kbq0wnl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckx62l7xh1p5u0859yyaqgc0s",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckx62leqv1p760859z47mzkfu",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckx62kd0d1ox30859dor2lvr4",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1639484101893-WhatsApp Image 2021-12-14 at 15.12.34.jpeg",
          "1639484101861-WhatsApp Image 2021-12-14 at 15.12.34 (1).jpeg"
        ]
      },
      {
        "sku": "1173",
        "description": [
          {
            "id": "ckx62mgr31pip0859ia3gjc54",
            "title": "On Point Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckx62nerc1pqe08595p3ay4gh",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckx62nerf1pqg0859r5y0x9e2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx62nerg1pqh08595ex2t89f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckx62n0jb1pme08592qi3ux42",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckx62nasp1pof0859l5qw8qlf",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckx62mgr11pio0859qcrxq8ur",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1639484276419-WhatsApp Image 2021-12-14 at 15.12.35.jpeg",
          "1639484276427-WhatsApp Image 2021-12-14 at 15.12.35 (1).jpeg"
        ]
      },
      {
        "sku": "1174",
        "description": [
          {
            "id": "ckx63kp7x1ukw0859v90eca4d",
            "title": "Bonnie Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckx63phye1ve30859zptrytks",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckx63phyg1ve508591klm2v60",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx63phyi1ve608591xm5c4j2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckx63phyo1vea0859t6xwhk6h",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckx63phyp1vec0859t00t5nmx",
                "price": 26,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx63phyq1ved0859za9w0wjm",
                "price": 9,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckx63phyw1veh0859guacotc4",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckx63phyy1vej0859sj6kl55i",
                "price": 47,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx63phz01vek0859gv06pfas",
                "price": 17,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckx63phz51veo0859aiz0pjio",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckx63phz61veq08591wms5v9n",
                "price": 13,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx63phz81ver0859f28nttnc",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckx63phzd1vev0859n1vy4bof",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckx63phzf1vex08598cycbuvn",
                "price": 36,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckx63phzg1vey0859aubxb6kh",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckx63nxju1v0e0859wrsu0wzd",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckx63ogzm1v380859jwubaxjq",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckx63kp7o1ukv0859yntscvar",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1639485849731-WhatsApp Image 2021-12-14 at 15.29.01.jpeg"
        ]
      },
      {
        "sku": "1453",
        "description": [
          {
            "id": "ckxenngoynmui0859qfj91pgm",
            "title": "JUJU Test Denemes"
          }
        ],
        "sizePrices": [
          {
            "id": "cl37w0c6k6x5108408skr1sy8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl37w0c6s6x5308407zoue0md",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl37w0c6x6x540840753e55f3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckxennzmanmza0859cb42tje2",
            "price": 10,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckxeno4cvnn0508595het6uau",
            "price": 28,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxenngovnmuh0859ppfhy453",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1640003301688-placeholder.png"
        ]
      },
      {
        "sku": "1175",
        "description": [
          {
            "id": "ckxg4zeoxrd8f08594j9f5sn7",
            "title": "Taşlı Küme Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7izzxzqglmz0840zsdwpdb0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7izzxztgln10840idyt1g27",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izzxzwgln20840n3aiqw2m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7izzy04gln60840pkyr4wgp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7izzy06gln80840kamemasx",
                "price": 16,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izzy0cgln90840w0bko356",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7izzy0kglnd08405yi3sy1b",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7izzy0pglnf0840y3pbky4s",
                "price": 44,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izzy0sglng0840ehvvqbdu",
                "price": 16,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7izzy12glnk0840bqgoloy5",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7izzy15glnm0840wrykwgdq",
                "price": 5,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izzy16glnn0840x31ll4ws",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7izzy1eglnr0840wy7nig16",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7izzy1hglnt0840p1v32x4k",
                "price": 30,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izzy1iglnu0840dvbz6iql",
                "price": 11,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3fo093l4xlb08402z7ia5hi",
            "price": 62,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3fo0vwy4xph0840g5fjflyu",
            "price": 174,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxg4zeourd8e0859k2ytxm8g",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1641902084403-IMG_2104.jpg",
          "1641902085096-IMG_2106.jpg"
        ]
      },
      {
        "sku": "1176",
        "description": [
          {
            "id": "ckxg5rt9wrgn608592jrqh7jc",
            "title": "Morning Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0i6hmhpsea80859nmbzwvlg",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl0i6hmhsseaa0859vir9hg42",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0i6hmhtseab0859ile2fm5r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckxg5s8iargpr0859a9j1w1hi",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckxg5shw6rgqq0859x0g050an",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxg5rt9urgn50859vuy7m7zw",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1640094229244-60994935-1e43-4da1-8d5e-6fd65ab79e7d.jpg"
        ]
      },
      {
        "sku": "1177",
        "description": [
          {
            "id": "ckxhcx2a7u7q30859hoghosue",
            "title": "Şarap Kadehi Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wctsmy6gph0840y4epw7uu",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl5wctsn16gpj08408ylqah4s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wctsn36gpk08406yyto3i5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckxhcy4pzu7wd085999htaq9d",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky9w2du0vnvf0859ywvtug9k",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxhcx2a4u7q20859o1d4kyc6",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1641885576462-IMG_2146.jpg"
        ]
      },
      {
        "sku": "1178",
        "description": [
          {
            "id": "ckxhd01rhu8750859ks91ywy3",
            "title": "Konyak Kadehi Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54fv61sj4tz0840w8rld245",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl54fv61wj4u10840jqai5vqj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54fv61zj4u20840wl7hv51g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckxhd0payu89q0859lofnu63z",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky9w46lpvo4c0859s2i7x9ob",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxhd01rfu874085933q3a1ur",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1641885623337-IMG_2145.jpg"
        ]
      },
      {
        "sku": "1179",
        "description": [
          {
            "id": "ckxhd42thu8nb0859dbxbho3z",
            "title": "Martini Kadehi Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzesqu3g4cs20859xscnpn4e",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckzesqu3j4cs40859v1638ylv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzesqu3l4cs50859ejlzb8v1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky9w5jwjvoap08599fjibam0",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3aa52j6jqde0840vi0efmbj",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxhd42tfu8na0859nban4y7y",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1641885661722-IMG_2150.jpg"
        ]
      },
      {
        "sku": "1180",
        "description": [
          {
            "id": "ckxhdeqtru9e40859tzoa87lr",
            "title": "Bira Bardağı Uç "
          }
        ],
        "sizePrices": [
          {
            "id": "cl54f5sddj0ok084051w8cukp",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl54f5sdjj0om08405ev51jlt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f5sdnj0on0840ivhqitmk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckxhdfnhmu9ji0859pmtxd8wg",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckxhdfu9ju9ke0859xghhzr8f",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxhdeqtpu9e30859f7ah7snh",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1641885769006-IMG_2123.jpg"
        ]
      },
      {
        "sku": "1181",
        "description": [
          {
            "id": "ckxhdi41zu9t50859sbrynot3",
            "title": "Mineli Güneş Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzesrveo4cwy08599cpywlmd",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckzesrveq4cx00859sviz66s3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzesrves4cx108596tbzv8ei",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckxhdip5au9vp0859ocvqb007",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckxhdj17lu9wt0859nx9w5fgc",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxhdi41xu9t40859846gdysl",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1641885839739-IMG_2122.jpg"
        ]
      },
      {
        "sku": "1182",
        "description": [
          {
            "id": "ckxhgd9ytukcr0859nrpr2hl6",
            "title": "Mineli Güneş Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzessrxc4d1v0859iyz671fq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "ckzessrxe4d1x085990rufkov",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzessrxf4d1y0859t4kz07du",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky9w6k5zvog60859xnqww7qf",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3aa1afsjplq0840ogl229t5",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckxhgd9yrukcq0859vndxyykc",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1641885724764-IMG_2151.jpg"
        ]
      },
      {
        "sku": "1183",
        "description": [
          {
            "id": "ckxj0l6ifz64a0859ztxrg79i",
            "title": "Passionate Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "ckxj0lk8kz68t0859j7kpiefe",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckxj0lk8nz68v0859214ezjq4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxj0lk8pz68w0859dzd6xb0x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxj0lk8vz6900859eujbbabr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckxj0lk8wz6920859iz27fyno",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxj0lk8xz6930859idt05j4o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckxj0lk92z6970859mq1s5rvy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckxj0lk94z6990859n78mfj7b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckxj0lk95z69a08592o6fxc9w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckz71u8dmgb1w0859pu9qdkb1",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl30ak5utmpj70840yio609im",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckkj017754u3m0919z9kpmm52",
              "code": "TRY"
            }
          }
        ],
        "id": "ckxj0l6icz64908593w0v1c0l",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1640266913089-834ea851-5918-4786-8b6f-6b57d368d2d1.jpg"
        ]
      },
      {
        "sku": "1184",
        "description": [
          {
            "id": "cky9wvsrivs1d0859l7pzu2mc",
            "title": "Pembe & Lila İpli Taş Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1g9rsqurbqi0859nrwz5avp",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1g9rsqyrbqk0859uf11yw90",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g9rsqzrbql0859515z1tfq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g9rsr4rbqp0859og9b039l",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1g9rsr6rbqr08593pf8l64j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g9rsr7rbqs0859nf73c25b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1g9rsrcrbqw0859ttz3yxsi",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1g9rsrdrbqy0859wam0gqh4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1g9rsrerbqz08599npn0jvr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky9wz8xvvsru0859nxqi7yuu",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky9wzfe7vst00859i8ln4e6s",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cky9wvsrgvs1c0859t8fwo4rf",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646840382895-3.png",
          "1641894192897-IMG_2138.jpg"
        ]
      },
      {
        "sku": "1185",
        "description": [
          {
            "id": "cky9x0f7wvt2t0859wwryajer",
            "title": "Beyaz & Mavi Renkli Taş Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0stgftwwufw0859c1zvrom2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0stgftzwufy0859dgzvsh3q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0stgfu1wufz0859r75iwf2p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0stgfu8wug308594p540h1b",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0stgfu9wug50859jkdtk5ua",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0stgfuawug60859lrn3pbum",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0stgfufwuga08593xyyunoy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0stgfugwugc0859pselxrpp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0stgfuhwugd085968v4y9or",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky9x163vvt8y08591jpjyswx",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky9x1ad0vtax085961xag86u",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cky9x0f7uvt2s0859hofooe3o",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646840181269-2.png",
          "1641894617101-IMG_2138.jpg"
        ]
      },
      {
        "sku": "1186",
        "description": [
          {
            "id": "cky9x266uvtj90859j7lvmfx0",
            "title": "Karışık İp Renkli Taş Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0sth67nwukg08591rbin10g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0sth67pwuki0859l0doired",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sth67rwukj0859og702kjl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0sth67xwukn0859qq3ow9x6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0sth683wukp0859eic79bvd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sth685wukq0859y1hyi3br",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0sth68owuku08593hbghulq",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0sth68qwukw0859x0qqx0us",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sth68swukx0859fj1ooz4l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky9x8jw4vur00859zzhdo289",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky9x8pc0vus20859fii7tmn1",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cky9x266svtj808594o516wg4",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1641894721398-IMG_2131.jpg",
          "1641894730832-IMG_2138.jpg"
        ]
      },
      {
        "sku": "1187",
        "description": [
          {
            "id": "cky9x9yp4vv5r085967z28l43",
            "title": "Sarı & Kırmızı Renkli Taş Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0sti13iwutb0859o6oeato5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0sti13kwutd0859pvz319rj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sti13lwute08592dztqjpd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0sti13rwuti0859psutgniy",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0sti13swutk0859gp4pzky8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sti13twutl08595h3jxuvo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0sti13zwutp08593simlx87",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0sti140wutr0859mzkffk6c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sti141wuts0859o8qhfbna",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky9xaltivvcg0859gpfywml6",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky9xard6vvdo0859vdvsckxu",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cky9x9yp3vv5q0859rer8napw",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646728586207-19.png",
          "1641894848243-IMG_2138.jpg"
        ]
      },
      {
        "sku": "1188",
        "description": [
          {
            "id": "cky9xc2w2vvtn0859c37c2mvd",
            "title": "Sarı & Mavi İp Renkli Taş Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl0sthq45wuoy0859qciepw9k",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl0sthq47wup00859skz3dq10",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sthq49wup10859aicxxgn9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0sthq4gwup50859crbth6os",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl0sthq4hwup70859lskkdekm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sthq4iwup80859ekl30cqf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl0sthq4owupc0859qs8xd4z3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl0sthq4pwupe0859cwyk14f8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl0sthq4qwupf08598hqm91bd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cky9xcv63vw0a0859cb0an7v7",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cky9xczjjvw160859ih1p30g5",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cky9xc2w1vvtm0859zjzl7rt3",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1646840152948-3.png",
          "1641894973046-IMG_2138.jpg"
        ]
      },
      {
        "sku": "314",
        "description": [
          {
            "id": "ckyjyk65hnmqo08595x2i1tp8",
            "title": "Ercanın Yüzüğü Kare Boşluk"
          }
        ],
        "sizePrices": [
          {
            "id": "ckyjykjwrnmv80859zzgae6e3",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "ckyjykjwvnmva0859ynk77am8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckyjykjwwnmvb0859w3wxuf38",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "ckyjyk65fnmqn0859w19h72dt",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "1193",
        "description": [
          {
            "id": "ckzt6vw7edjfj085973yn5ndq",
            "title": "Sentosa Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j02ujxgmbj0840i1hb9lci",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j02uk1gmbl0840vhmufvj5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j02uk3gmbm0840cdbd9sos",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j02uk9gmbq0840xp16r7fe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j02ukbgmbs08408c4c81h7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j02ukdgmbt0840e7ywpfsv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j02ukigmbx0840jlgejauo",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j02ukkgmbz0840zp43r708",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j02uklgmc008407isli1sh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzt6wncldjgz0859n7cf21ij",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzt6wwtidjh90859s3rvstqj",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt6vw79djfi0859rlq16qc6",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1647233914318-IMG_0005.jpg",
          "1647233914291-IMG_0006.jpg"
        ]
      },
      {
        "sku": "1202",
        "description": [
          {
            "id": "ckzt70esddjmt0859t4lrgewz",
            "title": "Frame Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j03yxkgmh60840k9wa1xna",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j03yxogmh80840z6vqxcjr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j03yxqgmh908400d2y2x5t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j03yxygmhd0840nl406qyf",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j03yy0gmhf08409ebrpdm2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j03yy1gmhg0840phalciy2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j03yy7gmhk0840xy3jyalc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j03yy9gmhm0840gczo98js",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j03yybgmhn0840emytzm22",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzt71krkdjpj0859joviez3y",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzt72gmndjqj0859e7uee792",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt70escdjms0859t86oy8s4",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1647234411660-IMG_0015.jpg",
          "1647234411693-IMG_0016.jpg"
        ]
      },
      {
        "sku": "1203",
        "description": [
          {
            "id": "ckzt75p1jdjsg0859xr7c0unk",
            "title": "Petit Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j04nihgmlm084023oallfa",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j04nilgmlo0840a8gpybr3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j04ningmlp08403kjlyxw1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j04niygmlt08401bwc96uk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j04nj2gmlv0840tjwvs1qh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j04nj5gmlw0840bwge0k9s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j04njjgmm00840a14k1x6q",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j04njlgmm208403eoi6lyt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j04njngmm308402bari7r2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl31o49ccuhtg0840j2h18ool",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl31o4ic1uhvq084092pqb8w6",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt75p1gdjsf085931j55psb",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1647235092523-IMG_9979.jpg",
          "1647235092562-IMG_9980.jpg"
        ]
      },
      {
        "sku": "1189",
        "description": [
          {
            "id": "ckzt7efnmdk080859iohl0lia",
            "title": "Honeycomb Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j05i1wgmrq0840plyx41jc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j05i23gmrs0840t59p3pgc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j05i26gmrt0840plmhcvwp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j05i2hgmrx084038jmsutk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j05i2kgmrz0840unbcrzq2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j05i2mgms00840z955y969",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j05i2tgms40840s2ebo9f3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j05i2vgms60840zwqy28kr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j05i2wgms70840k8sapb8n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzt7f14vdk1h085950jwgacf",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzt7fpkzdk2d0859qd6j3jdy",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt7efnjdk070859ps4u24su",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647233591013-IMG_0001.jpg",
          "1647233598728-IMG_0002.jpg"
        ]
      },
      {
        "sku": "1194",
        "description": [
          {
            "id": "ckzt7ijl3dk3w0859xurziynn",
            "title": "Jazzy Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77ge5cui6hu0840bviv9t4v",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77ge5cxi6hw0840hlrkvg1m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77ge5d0i6hx0840i1msnr8v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77ge5dhi6i20840wtzf7n4q",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77ge5dji6i40840poo4icaj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77ge5dmi6i508401aekn4xw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77ge5dzi6i90840np3d7e1x",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77ge5e0i6ib0840l6g4lkud",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77ge5e2i6ic0840ez0h25ff",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzt7j9exdk570859e2eh162w",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzt7jxcbdk620859cs01z2nb",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt7ijl1dk3v0859j25qyset",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647234324022-IMG_0008.jpg",
          "1647234324084-IMG_0009.jpg"
        ]
      },
      {
        "sku": "1195",
        "description": [
          {
            "id": "ckzt7mm3mdk8l0859tt60sg9o",
            "title": "Whisk Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j087jcgn780840s7l45ps6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j087jhgn7a084085muup2c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j087jjgn7b0840xrtpql3s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j087k0gn7f0840yrbo1s3f",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j087k4gn7h0840sc0q93lv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j087k8gn7i0840wi7o1w2p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j087klgn7m0840t1kbjgii",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j087kngn7o0840ajbk7o0h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j087kpgn7p0840nkh1c6lz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzt7n2nfdk9x0859epnqg7e1",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzt7o5ildkd30859c0kkz19v",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt7mm3kdk8k0859sygtfrt4",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647234506276-IMG_0021.jpg",
          "1647234507059-IMG_0023.jpg"
        ]
      },
      {
        "sku": "1196",
        "description": [
          {
            "id": "ckzt7qfoqdkhq0859vitnuxh7",
            "title": "Panini Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77k9xv9ik4v0840a62is2y6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77k9xveik4x0840ahzlsj4m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77k9xvhik4y0840haqylhms",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77k9xvuik520840bw5g4gyz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77k9xvyik540840bj7xrhbk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77k9xw0ik550840txkrzorx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77k9xwbik590840gryqbhyr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77k9xweik5b0840bzq67n68",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77k9xwgik5c08404cut0238",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1lyzijof8av0859z9wx2v11",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1lz061hf8ew0859o8cefdwe",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt7qfoodkhp085926ys6ejq",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647234664609-IMG_0024.jpg",
          "1647234664601-IMG_0025.jpg"
        ]
      },
      {
        "sku": "1197",
        "description": [
          {
            "id": "ckzt7udhadklg0859t9tb6med",
            "title": "Sparkle Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j09ebegncu08401b1uz3g5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j09eblgncw08402c2a2qt5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j09ebpgncx0840re2j43bv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j09ec7gnd10840okkscnaz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j09ecbgnd30840yl9tt6oi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j09ecegnd408400zhp3tp9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j09ecmgnd808407naexvgr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j09ecpgnda0840si7mjqhm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j09ecrgndb0840hfih3uqt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzt7v0hhdkmo0859bpqcxf4u",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzt7vmludknj08596laiu4r2",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt7udh8dklf08594jtkzva7",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647234911198-IMG_9971.jpg",
          "1647234911247-IMG_9972.jpg"
        ]
      },
      {
        "sku": "1198",
        "description": [
          {
            "id": "ckzt7yax9dkp30859riqz5qdr",
            "title": "Margarita Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77geh8bi6m10840yicwxag7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77geh8fi6m30840csg5prsx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77geh8ii6m40840y6o6ktfa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77geh8ui6m80840jb3jfdsb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77geh8xi6ma0840p8r8kz6v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77geh8zi6mb0840jq586p4q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77geh99i6mf084059ky6d29",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77geh9ci6mh08406pkeeyh6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77geh9fi6mi0840eszb9kaj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzt7yyjgdkqj0859amg2vk2w",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzt7zlhfdkrf0859yhxen9mb",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt7yax6dkp20859fg0zjj8d",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1657016348833-CBS-1198.jpg",
          "1647235014771-IMG_9977.jpg",
          "1647235014784-IMG_9978.jpg"
        ]
      },
      {
        "sku": "1199",
        "description": [
          {
            "id": "ckzt81rdidksy0859ae1zude9",
            "title": "Genuine Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0b48kgnl90840mhcagy3j",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0b48tgnlb0840qqpzg8mx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0b48wgnlc0840gv3sopy8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0b495gnlg0840ihzw67x0",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0b497gnli0840wbqraigf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0b499gnlj08406e5rwck8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0b49ggnln0840mlggmny8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0b49hgnlp08404ajjsr51",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0b49jgnlq0840yi7fpk2q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzt8290gdku70859j3xr5rha",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzt82tp7dkv40859c00tvi7j",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzt81rdgdksx0859urh8o2mr",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647235220924-IMG_9984.jpg",
          "1647235220939-IMG_9985.jpg"
        ]
      },
      {
        "sku": "1201",
        "description": [
          {
            "id": "ckztutliqegx00859cpd9vd3u",
            "title": "Flirty Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0c7ctgnr30840fiptbcy6",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0c7czgnr508409ey6qyko",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0c7d1gnr60840olzn3obm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0c7d8gnra0840hmktt4i7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0c7dagnrc0840nzzaj97g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0c7dbgnrd08401oo9f2em",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0c7dhgnrh0840vy4wi0li",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0c7djgnrj0840nxai5yr2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0c7dkgnrk08403nvq680g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztv1uf9ehbj0859tn3p79y0",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztv3fadehf2085923daefzg",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztutlioegwz0859to3mqd6j",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647238447178-IMG_9996.jpg",
          "1647238447233-IMG_9997.jpg"
        ]
      },
      {
        "sku": "1204",
        "description": [
          {
            "id": "ckztv7dq4ehk90859g09pjo5q",
            "title": "Treasure Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77geqjui6q908409ywidb63",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77geqjwi6qb0840gcq3rbvg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77geqjyi6qc0840f2xwbi1y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77geqk7i6qg0840zzndmelb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77geqkai6qi08408at6ls4f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77geqkci6qj0840j4veaea6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77geqkpi6qn0840lssnwpsd",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77geqkti6qp08409sdl6zvi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77geqkwi6qq08405aldz7kv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztv8f4jehmq0859ue4kf6ay",
            "price": 200,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztv93orehon0859vownwjcd",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztv7dq1ehk80859esu9n0fv",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1647234721898-IMG_0026.jpg",
          "1647234721597-IMG_0027.jpg"
        ]
      },
      {
        "sku": "1205",
        "description": [
          {
            "id": "ckztvdrxaehwb0859tlc2j9ih",
            "title": "Indulge Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gf5z2i6x60840fabbyhm1",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gf5z5i6x80840i2k7ewtm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gf5zbi6x90840kila1eqp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gf5zji6xd0840q58sg18a",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gf5zli6xf0840huslr8fn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gf5zni6xg08401zlnwt37",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gf5zvi6xk08400f8a30at",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gf5zxi6xm0840g1rsx557",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gf5zyi6xn0840hwoees5f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztvf1s2ei3i0859qcoca9lr",
            "price": 200,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztvt9ttej2v0859v6xxkhff",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztvdrx7ehwa0859tt6b6i3p",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647235274498-IMG_9989.jpg",
          "1647235279874-IMG_9988.jpg"
        ]
      },
      {
        "sku": "1190",
        "description": [
          {
            "id": "ckztw0gi8ejc40859elr05006",
            "title": "Funky Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0drl2gnys0840tydt0z2p",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0drl8gnyu0840avm1bbtw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0drlcgnyv0840qtb94uwo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0drlrgnyz0840cyovjeqw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0drlvgnz10840css56shk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0drlzgnz20840hq5ebkdn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0drmdgnz60840ri2gfndk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0drmhgnz80840rat88x0e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0drmkgnz90840w3fu0rkl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztw0zudeje80859hssymbb1",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztw7ti9ejxv0859mi0ypx9n",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztw0gi6ejc308590zy2hh8u",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1647234377294-IMG_0012.jpg",
          "1647234377319-IMG_0013.jpg"
        ]
      },
      {
        "sku": "1191",
        "description": [
          {
            "id": "ckztwb99yek7k08591b5scffq",
            "title": "Spring Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gfdf9i7160840x9kpycad",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gfdfci7180840k2g8hzih",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gfdffi7190840x14c47tq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gfdfni71d0840a07q9x0q",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gfdfqi71f0840lnsh1b2v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gfdfri71g0840742jzefv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gfdfzi71k084095jbvura",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gfdg1i71m0840oyjx85p9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gfdg2i71n084006bv40tl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztwcae5ek9o0859ww2txw08",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztwdqzkekd708590chpaavp",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztwb99wek7j0859302t9ey6",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1647234438132-IMG_0017.jpg",
          "1647234438124-IMG_0018.jpg"
        ]
      },
      {
        "sku": "1192",
        "description": [
          {
            "id": "ckztwg527ekld0859q2aw60tz",
            "title": "Twist Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gfmnxi756084082klnx5o",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gfmo0i7580840rvmz1ase",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gfmo2i75908400cksk864",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gfmo9i75d0840plup0nbn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gfmobi75f0840x9jz7tqb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gfmoci75g0840ur3snp0d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gfmooi75k0840rw34khe6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gfmoqi75m084028y04wp1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gfmosi75n0840g9k17gh0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztwh10heknp0859r6wasign",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztwhqf6ekqm0859u3get10i",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztwg521eklc0859l7mff0wk",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1647235151955-IMG_9981.jpg",
          "1647235152015-IMG_9982.jpg"
        ]
      },
      {
        "sku": "1206",
        "description": [
          {
            "id": "ckztwkk9pel330859yujipayp",
            "title": "Palm Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gb6voi5cn0840oh7nvx5j",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gb6vti5cp0840efrfdpqz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gb6vwi5cq08400mhxscq5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gb6w6i5cu0840g5llxodt",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gb6w8i5cw0840yy38ni22",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gb6wai5cx0840kfkyhynj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gb6wfi5d10840e36p4u4n",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gb6whi5d30840chwumqae",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gb6wii5d4084025g3gt8p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztwltd7elbd0859t0s82oop",
            "price": 89,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztwmh88ele70859f5afv6nl",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztwkk9nel3208597igl6kno",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1647233840729-IMG_0003.png",
          "1647233737936-IMG_0004.jpg"
        ]
      },
      {
        "sku": "1207",
        "description": [
          {
            "id": "ckztwu4ynem8a0859j03fl0yb",
            "title": "Taşlı Brackets Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0g9fsgo8n0840kycxg5yf",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7j0g9fxgo8p0840r6mjxu6k",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0g9g0go8q0840pbbrhxsj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztwwlevemn60859al83ldv6",
            "price": 36,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztwx8euempr0859yuz96kew",
            "price": 101,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztwu4ylem890859g3dyi0u6",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1647234774068-IMG_0028.jpg"
        ]
      },
      {
        "sku": "1208",
        "description": [
          {
            "id": "ckztx0mriemxd0859e48rliva",
            "title": "Brackets Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0ibjngodh0840irl8qhmv",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7j0ibjsgodj08402gmuhzq0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0ibjvgodk0840p2d3xrk9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztx18wsemzn0859bbgwwc37",
            "price": 101,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckztxekx6enqg0859lmm8to7h",
            "price": 36,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztx0mrgemxc0859rae8cb3t",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1647234794975-IMG_0032.jpg"
        ]
      },
      {
        "sku": "1209",
        "description": [
          {
            "id": "ckztxkk4zenxl0859cwt0nyel",
            "title": "Raisa Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0j3kkgom20840fit4iqcd",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7j0j3kpgom40840llydvssk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0j3ksgom50840xfgkkt65",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1nfhawgnjra08590g7x79fy",
            "price": 96,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1nfifejnjzv0859wpjx3987",
            "price": 269,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztxkk4xenxk0859qya3kdtv",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1647234867233-IMG_0047.jpg",
          "1647234867372-IMG_0048.jpg"
        ]
      },
      {
        "sku": "1210",
        "description": [
          {
            "id": "ckztxrwfueocr08593pxquo9t",
            "title": "Flawless Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0m41igpgu0840v5zu37of",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0m420gph00840weh706hm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0m42egph10840pte2fm72",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0m42ogph50840x78drrrz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0m42ugph80840xlz9tnmd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0m42ygph90840lbcgs7gr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0m442gphj0840fs2bmi21",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0m44hgphl08401w172c65",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0m44mgphm0840b8qlr3d6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckztxugojeokq0859oltc4ezg",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzwmn020kj000859gsnmk1dv",
            "price": 135,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckztxrwfseocq0859k05ehjyy",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1647235308891-IMG_9990.jpg",
          "1647235309528-IMG_9991.jpg"
        ]
      },
      {
        "sku": "1211",
        "description": [
          {
            "id": "ckzy2clu7q8320859sd0m88mx",
            "title": "Noir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzy2cxbtq8610859m2uctj6q",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "ckzy2cxc1q8640859969hg984",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzy2cxc4q86508592neyt5ov",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzy2df2iq8940859c3sm3yot",
            "price": 155,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzy2dwltq8bz08593gg4dnn8",
            "price": 434,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzy2clu3q831085900b2m55u",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1645530350822-0b2dbc1d-e668-4773-98a2-ffdfb6a40fa5.jpg"
        ]
      },
      {
        "sku": "1212",
        "description": [
          {
            "id": "ckzzkgh4qv2fs0859zp5fcbnv",
            "title": "Pasha Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzzko0bjv3wo08597qphu26p",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzzko0bnv3wq0859l7yrd8pa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzko0bpv3wr0859x4bi99h5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzzko0bwv3wv08599ume7j76",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzzko0byv3wx0859mwpriqvm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzko0bzv3wy0859txdqne9o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzzko0c6v3x208591s2b4exx",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "ckzzko0c8v3x40859xr3u97nk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzko0c9v3x50859u0yq7bb2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzzko0cgv3x90859g8i2f0k5",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckzzko0chv3xb0859mt3p55sg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzko0cjv3xc0859maw5852n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzzko0cpv3xg0859shbgv687",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckzzko0cqv3xi08598xxxt4aj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzko0csv3xj0859snt716cb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzzkwsaqv6590859xhjyhygo",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzzkygnpv6g20859ynylz4te",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzzkgh4nv2fr08590v0fk2l6",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1645621329697-IMG_20220223_160032.jpg"
        ]
      },
      {
        "sku": "1213",
        "description": [
          {
            "id": "ckzzkkw4mv33c0859vzs7h88w",
            "title": "Heartbreaker Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "ckzzkrf9sv51l0859bjke1299",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "ckzzkrf9vv51n08598l8h9kq1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzkrf9xv51o08592i1g9g14",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzzkrfa3v51s0859hh2bb5j3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "ckzzkrfa5v51u0859fzlzmtbu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzkrfa6v51v0859pcwjazqv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzzkrfacv51z0859ruqrkr1e",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "ckzzkrfaev5210859hgctinhh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzkrfafv5220859bpzv5n3b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzzkrfalv5260859j167n0y0",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "ckzzkrfanv52808590kgbu3qi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzkrfaov5290859rrci06vy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "ckzzkrfauv52d0859xqvb03qr",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "ckzzkrfavv52f08591lb3l24l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "ckzzkrfaxv52g085956hssvrt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzzkyno4v6he085900c6roo9",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzzl10fiv6zu0859fe6ebq68",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzzkkw4kv33b0859bi4wqq6v",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1645621452874-IMG_20220223_160315.jpg"
        ]
      },
      {
        "sku": "1214",
        "description": [
          {
            "id": "ckzzknir2v3pi08592wj6mmv7",
            "title": "Voodoo Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl347psqongko0840d33evzw2",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl347psqungkq0840q3ax23xu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl347psqxngkr08400e3uzn4l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl347psr9ngkv0840bsd9yfxn",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl347psrcngkx08408e55nfeh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl347psrdngky08402ejvup6r",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "ckzzkytjev6iu08599vad3xqs",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "ckzzl14kyv70z0859xoc6xywo",
            "price": 107,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "ckzzknir0v3ph0859vjcpnyfw",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1645621623417-IMG_20220223_160638.jpg"
        ]
      },
      {
        "sku": "1215",
        "description": [
          {
            "id": "cl0kvm0t31nk40859gyvi4j0z",
            "title": "Konyak Kadehi Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gbgkwi5fn0840z4mq8wqt",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77gbgkzi5fp0840r4pcyxeg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gbgl3i5fq0840fxbk285j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl0kw31o31q5r0859a7ezsbpy",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl0kw5ej51qg00859skkgi91e",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl0kvm0t01nk30859le8io23v",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1646908467138-1215.jpg"
        ]
      },
      {
        "sku": "1216",
        "description": [
          {
            "id": "cl0kw83441qza0859hbbrgzxn",
            "title": "Şarap Kadehi Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gbnesi5ia08401rywd3xg",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77gbnevi5ic0840posrcv6k",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gbnewi5id0840fgqp82dj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl0kw8w541r690859sux368zp",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl0kwaten1rjq0859ci2iau6o",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl0kw83421qz90859tdxycyq1",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1646910831167-1216.jpg"
        ]
      },
      {
        "sku": "1217",
        "description": [
          {
            "id": "cl0kwehv71s570859jdhio8or",
            "title": "Martini Kadehi Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gbuzfi5km0840wo9xnmo5",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77gbuzji5ko0840arnci66p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gbuzli5kp0840tylvu3up",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl0kwfsbe1sbh08592x9syr29",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl0kwmsv41t4x0859jfflx8s9",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl0kwehv51s560859hiddqg8u",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1646911122263-1217.jpg"
        ]
      },
      {
        "sku": "1218",
        "description": [
          {
            "id": "cl0rtj8ncrqbu0859y3h7djwe",
            "title": "Bloody Mary Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ag9fha3fdj08598ik87944",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1ag9fhd3fdl0859w56cs9dv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ag9fhf3fdm085923292uiw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ag9fho3fdq08599763bs4o",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1ag9fhs3fds0859nx6rmehp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ag9fhv3fdt08591lzucyx8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1ag9fi33fdx0859oy1we5g8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1ag9fi53fdz0859coqdtbwr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ag9fi63fe0085955qkomjk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl0rtlb73rqov0859k0t6v5s4",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl0rtljd6rqq10859sqo5tw6y",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl0rtj8n9rqbt0859wbe2rhl8",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648456196963-2.png",
          "1648453865620-IMG_0970.jpg"
        ]
      },
      {
        "sku": "1219",
        "description": [
          {
            "id": "cl11ymsw5o0zc08590hc6i72w",
            "title": "Sangria Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0oarlgpuu0840gijxl1rs",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0oarpgpuw0840sma9az7a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0oartgpux0840oa3fvsre",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0oas3gpv10840i8wqpt2z",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0oas5gpv30840y7zs7q7y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0oas6gpv408400pw9dcq5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0oascgpv80840rux1c59g",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0oasdgpva0840tl90m2ig",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0oasfgpvb0840snhd29li",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl11yryf4o25t08590cfihwx3",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl11ys8lco28c0859o0hqbyc8",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl11ymsw2o0zb0859lx61pf4x",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648456072559-3.png",
          "1648454088861-IMG_0960.jpg"
        ]
      },
      {
        "sku": "1220",
        "description": [
          {
            "id": "cl1alnex14lh40859x2kst5l5",
            "title": "Negroni Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gc2zki5ns08404n3nh1lc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gc2zoi5nu08409n9xv381",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gc2zsi5nv08405suy1mpn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gc304i5nz0840vek5mzck",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gc308i5o10840psrz9f4l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gc30di5o20840xje3o75i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gc30ri5o60840ob1frkad",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gc30wi5o808404exe54ui",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gc30zi5o90840el5yub1h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1alnwxe4lld0859fvtibwlm",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1alo1e24lm2085943pl55ul",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1alnewy4lh30859599pgrg2",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648537585876-2.png",
          "1648465208620-1220-1.jpg"
        ]
      },
      {
        "sku": "1221",
        "description": [
          {
            "id": "cl1alpxpj4m3c08595oatjh6d",
            "title": "Bramble Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gcbaji5ru084002avbil5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gcbaqi5rw0840xccduloe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gcbaui5rx0840nlrmjqvk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gcbbhi5s10840b3n4zjj7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gcbbni5s30840rdpswmzb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gcbbqi5s408403jy2qvmh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gcbc2i5s80840ssvx8all",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gcbc5i5sa0840bwnsyokq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gcbc8i5sb08409gr5ub1i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1alr08f4mau08592crty1qe",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1alr56l4mco0859d41a986j",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1alpxpg4m3b0859mzwvwx7x",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648537705523-3.png",
          "1648465338052-1221.jpg"
        ]
      },
      {
        "sku": "1222",
        "description": [
          {
            "id": "cl1alsr214myj0859d7lzw8ka",
            "title": "Bellini Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0q8g4gq700840bda555es",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0q8gagq7208400rd5ifto",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0q8gdgq730840utyc1plj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0q8gugq770840yfanru81",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0q8h0gq790840oalbjb90",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0q8h3gq7a0840eczt9mwu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0q8hcgq7e0840nx8t8grw",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0q8hegq7g08405ciihc8g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0q8hggq7h0840ssqqteyy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1altsqi4na60859ebpq12eu",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1altxac4nb80859pyv4dp07",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1alsr1n4myi0859hpvntvni",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648537897471-4.png",
          "1648465469669-1222.jpg"
        ]
      },
      {
        "sku": "1223",
        "description": [
          {
            "id": "cl1alwxd94o4d0859e4tnvpdi",
            "title": "Jungle Bird Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0v6kogqqz0840urbxs9t2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0v6kvgqr10840z8vrz6ko",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0v6kygqr20840l432l84h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0v6ldgqr60840x2e3ntwy",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0v6lggqr80840pp5bn6gl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0v6lhgqr908403tzwoek4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0v6lugqrd0840jc07qnl1",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0v6lygqrf0840zr9f91xm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0v6m0gqrg084032jz2zl8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1alxpkc4odc085960jd8r72",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1alxus64oer0859886yb53w",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1alwxd74o4c0859c5079cci",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648537934697-5.png",
          "1648465597661-1223-2.jpg"
        ]
      },
      {
        "sku": "1224",
        "description": [
          {
            "id": "cl1am02my4p7s0859lewp7y24",
            "title": "Siesta Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gcwm8i61e0840rt7vrog7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gcwmci61g0840oggsbqn7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gcwmfi61h084089boc6si",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gcwmqi61l0840tqdxluht",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gcwmti61n08406iueetlc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gcwmvi61o0840378porwq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gcwn2i61s0840z10kl9tl",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gcwn4i61u0840x5gchadx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gcwn5i61v0840uyv6iksc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1amaig94s570859p5yy5fdt",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1amamkx4s650859dpdw6y9d",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1am02mw4p7r0859t0i2lnco",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648537949776-6.png",
          "1648465802174-1224.jpg"
        ]
      },
      {
        "sku": "1225",
        "description": [
          {
            "id": "cl1amgv3q4th30859tg0y2b92",
            "title": "Sazerac Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77gd80mi65k0840iqypwc0b",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77gd80si65m0840yhi9wfd6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gd80wi65n0840l0id25mq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gd819i65r0840qrldj7ur",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77gd81di65t0840fw494peb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gd81hi65u0840qjinrdt4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77gd81si65y084019zrz6xv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl77gd81wi6600840pa9ngr5b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77gd81yi6610840bjcdyimn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1amhlkh4tll0859sm2z4c6a",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1amhprn4tmb0859txhlwcow",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1amgv3o4th20859x0wxgemi",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648537964365-7.png",
          "1648466567512-1225.jpg"
        ]
      },
      {
        "sku": "1226",
        "description": [
          {
            "id": "cl1amp9mo4vb50859r6bcxjyb",
            "title": "Gimlet Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0xbwmgrgn0840gcmmllef",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0xbwqgrgp0840wd0e5753",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0xbwsgrgq0840jnrl1uh3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0xbx1grgu0840dnjmbv95",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0xbx4grgw084086u0cqhn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0xbx6grgx0840txtcpqvt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0xbxfgrh10840vtjdrt7q",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0xbxigrh308404puf3ar9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0xbxjgrh4084077kf43zy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1ampx814vje08592705cb77",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8cuzl5btu1o0840j3fd4vf5",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1amp9ml4vb40859dvxidujm",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648537980289-8.png",
          "1648466736419-1226.jpg"
        ]
      },
      {
        "sku": "1227",
        "description": [
          {
            "id": "cl1amr65y4vyc08598z0e66a6",
            "title": "Transfusion Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0xx20grou0840q7zpumn4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0xx24grow0840lsuy5qih",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0xx27grox0840ocqrdvh3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0xx2igrp10840ojydijui",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0xx2mgrp30840539ru47q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0xx2pgrp40840telj60wj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0xx2ygrp80840sqdfklcs",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0xx31grpa0840iu18494h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0xx33grpb0840jf6amrhb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1amrrz74w3h0859spbsjfpx",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1amrwxw4w5d0859uerrovia",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1amr65v4vyb0859frb80q9o",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538052311-9.png",
          "1648467092460-1227-2.jpg"
        ]
      },
      {
        "sku": "1228",
        "description": [
          {
            "id": "cl1amtba74wiv0859kvppn6i8",
            "title": "Strike Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0ymrugrt00840pfe23kni",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0yms0grt20840mh9jhall",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0yms3grt30840syyhzyld",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0ymsegrt708405lnu5322",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0ymshgrt90840w439df42",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0ymsngrtb08407is5l3i5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0ymsugrtf0840cfp6ur8v",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0ymsxgrth0840u92w5hil",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0ymsygrti08401xkesrz5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1amu05s4wph0859vdo7tp6w",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1amu4kx4wq70859cie9y7xn",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1amtba44wiu0859a51eozdp",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1648538069809-10.png",
          "1648467185814-1228-2.jpg"
        ]
      },
      {
        "sku": "1229",
        "description": [
          {
            "id": "cl1amvqzf4x4y0859bw15x9vx",
            "title": "Hurricane Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0z5edgryw0840whcwtq6j",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0z5elgryy0840vlc1lykn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0z5epgryz0840t45hw38b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0z5f6grz30840zk0vr7a1",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0z5fbgrz508404dnxtfyz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0z5fdgrz60840if6wq066",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0z5fpgrza0840xxuh7oe3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0z5fsgrzc0840x2yqh4gf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0z5fugrzd0840598divbc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1amwh9n4xc40859l18yv70w",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1amxszc4xlr08597h1fdf1p",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1amvqzd4x4x085911oy4igl",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538091639-11.png",
          "1648467300198-1229-2.jpg"
        ]
      },
      {
        "sku": "1230",
        "description": [
          {
            "id": "cl1an0ag24y9a0859qys1eg9d",
            "title": "Mimosa Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j0zyvags3s0840sgii1bdo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j0zyvhgs3u0840j08x0yq8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0zyvkgs3v0840n89xybon",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0zyw1gs3z0840p5dd7rhu",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j0zyw5gs410840l5nirmjh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0zyw8gs4208404dpxbilx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j0zywhgs460840uwapnvbo",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j0zywjgs480840chpbsoe0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j0zywlgs490840dl1vmgkx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1an10fs4yfa0859xx2fetqf",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1an160v4ygd08595l5lnstu",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1an0ag04y990859ohmv08n1",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648538126957-12.png",
          "1648467505143-1230-2.jpg"
        ]
      },
      {
        "sku": "1231",
        "description": [
          {
            "id": "cl1aob77s597s0859z4v47knr",
            "title": "Provence Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j10t7ygs9x0840lmh5mypm",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j10t83gs9z08402ro3pokc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j10t86gsa0084013opzcag",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j10t8mgsa40840akq28dyr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j10t8qgsa60840je5vvmns",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j10t8tgsa70840b9u742jk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j10t96gsab0840qwq4xnyh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j10t9agsad0840o325teul",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j10t9cgsae0840u5yvl5j7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j10t9xgsb40840l6e2xong",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j10ta1gsb608402r23p6lc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j10ta3gsb70840nhy2npvz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j10ta9gsbb0840btq453r7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j10tacgsbd0840jy7lqted",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j10tadgsbe0840zsnc8snl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j10talgsbi0840jvoumpyh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j10tangsbk0840hcy70ewu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j10taogsbl0840b71yqy2v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1apirxy5n80085946o60hty",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1apiwq45naa0859adnatfxo",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1aob77o597r0859sjvpcr4s",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538142213-13.png",
          "1648469691964-1231.jpg"
        ]
      },
      {
        "sku": "1232",
        "description": [
          {
            "id": "cl1aplu0w5nsm0859pk86kftm",
            "title": "Corsica Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j11kg9gsl708405eapyxjy",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j11kgcgsl90840wr9i5txt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j11kgegsla0840m1h2rewi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j11kgpgsle0840midh7dq1",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j11kgsgslg0840klx9zxnd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j11kgvgslh0840pjmgb9ii",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j11kh9gsll08407lb110wk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j11khegsln084023naffvi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j11khhgslo08403bs03nfb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j11ki6gsme0840jkchw9ol",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j11ki8gsmg0840eo48npxr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j11kiagsmh0840nseru9ir",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j11kiigsml0840ka1agtnp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j11kilgsmn0840ejdq97fn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j11kiugsmo084005khxtcq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j11kj1gsms08400hh63tpr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j11kj3gsmu0840jif9z33n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j11kj5gsmv0840s3evc2u2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1apmp6s5o2q0859nqe0f89g",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1apmtlq5o3i0859pmyskugs",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1aplu0s5nsl08596mr5xdmz",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538157505-14.png",
          "1648471877152-1232-2.jpg"
        ]
      },
      {
        "sku": "1233",
        "description": [
          {
            "id": "cl1apt40n5p6x0859dkjnklmy",
            "title": "Santander Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j12botgstg08407me1guhc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j12bp0gsti0840nqdwwq88",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j12bp4gstj0840k75umpp2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j12bpjgstn0840ijl4ki33",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j12bpmgstp0840x4nihdbn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j12bppgstq0840z4qaqqwm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j12bpzgstu0840sivcf0ls",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j12bq1gstw0840b5ep6zor",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j12bq2gstx08402b9b3i3d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1aptvbq5pbg0859x9y990cv",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1apu0mf5pd008592c4dafww",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1apt40d5p6w08598q7gjck6",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538401152-1233.png",
          "1648538406777-1233-2.jpg"
        ]
      },
      {
        "sku": "1235",
        "description": [
          {
            "id": "cl1apygba5q520859g2x49wb4",
            "title": "Paloma Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7izl3y3giu10840s59v4b2j",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7izl3y7giu308406svpbu4o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izl3y8giu40840oxf4tdgh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7izl3yggiu80840qnroh35z",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7izl3yigiua0840t0ozp4ap",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izl3yjgiub0840kdgggugo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7izl3yqgiuf0840kkt9eimx",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7izl3ysgiuh0840mnf0o31i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7izl3ytgiui0840wsyryj7h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1apzcpn5qb108594w144eh6",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1apzgzv5qcd085957iubmpu",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1apygb65q510859tc0wnnn5",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648538632058-16.png",
          "1648472445051-1235-2.jpg"
        ]
      },
      {
        "sku": "1236",
        "description": [
          {
            "id": "cl1aq18am5qv40859bq5kdozc",
            "title": "White Lady Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j1kiqbgv520840p74vny4g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j1kiqggv540840x4bkd0dj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j1kiqigv550840yyhe6bhz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j1kiqsgv5908405io8g5ip",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j1kiqugv5b0840gvtq0sol",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j1kiqwgv5c0840k6ohpsp1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j1kir2gv5g08406pow28nr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j1kir5gv5i0840noyk8b3y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j1kir7gv5j08406b383oq4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1aq7ms15rwu08595i9fbivj",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1aq7rqf5rxq0859udb190fw",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1aq18ak5qv30859jug2951k",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648538659174-17.png",
          "1648472573768-1236-2.jpg"
        ]
      },
      {
        "sku": "1237",
        "description": [
          {
            "id": "cl1aq9cxq5sev085971nzxh0g",
            "title": "Spritz Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j14753gt2g0840xcofy4k5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j14758gt2i08408q1tu1i5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j1475bgt2j0840b3stu1rn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j1475lgt2n0840u9c015bj",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j1475pgt2p0840g0srcbwi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j1475rgt2q08404ucn6w05",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j14760gt2u08403g8fiyh9",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j14762gt2w0840uzz09o21",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j14764gt2x08408u91eh69",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1aqa0hg5smn08599q9vc4fx",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1aqa5ln5snw0859681ayp4x",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1aq9cxn5seu0859ogr2iicn",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538685424-18.png",
          "1648472960188-1237.jpg"
        ]
      },
      {
        "sku": "1238",
        "description": [
          {
            "id": "cl1aqi3l25uj60859uvxd0dky",
            "title": "Vesper Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j1zm1tgx85084080hkdkj5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j1zm1zgx870840uj33q2qu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j1zm21gx88084069a3w5dx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j1zm2bgx8c0840gkk27yn6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j1zm2egx8e0840r6ojh590",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j1zm2fgx8f08405dmbpaeo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j1zm2mgx8j084038zsgaoo",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j1zm2pgx8l0840nfx85jsd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j1zm2sgx8m0840xxfjrx96",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1aqmjom5vif0859fnkinyep",
            "price": 22,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1aqnpcz5vq60859qyuho7z2",
            "price": 62,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1aqi3kz5uj50859ykvyczwq",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538713058-19.png",
          "1648473352597-1238.jpg"
        ]
      },
      {
        "sku": "1239",
        "description": [
          {
            "id": "cl1aqqpoa5wcp0859lgae4mhp",
            "title": "Stormy Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j27tgtgz0n0840730dtiux",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j27th0gz0p08400hj85sad",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j27th4gz0q0840by7p8c2l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j27thjgz0u0840plvfhf63",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j27thngz0w0840a9r2jbgp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j27thqgz0x0840tajxsisr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j27ti1gz110840swnhbd36",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j27ti3gz130840ldulsb45",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j27ti6gz140840wszq2izy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1aqs3z35wlx0859zpwlixtj",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1aqs7yw5wmr0859my3jmg9k",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1aqqpo75wco0859msrqzfm5",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538739855-20.png",
          "1648473744201-1239-2.jpg"
        ]
      },
      {
        "sku": "1240",
        "description": [
          {
            "id": "cl1ar0pav5yi508595y9trh40",
            "title": "Gibson Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j26yy3gyuq08401xaqkc2u",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j26yy9gyus0840icdaxfx8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j26yybgyut0840eq6a2png",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j26yykgyux0840160blwye",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j26yymgyuz0840gp5tsx8x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j26yyogyv00840i8dmbd7m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j26yyygyv40840sg1agyw4",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j26yz0gyv60840exwwa560",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j26yz3gyv708404e933o09",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1ar21ak5yr80859atl0zss3",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1ar26vt5yse0859bjd7cdwe",
            "price": 67,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1ar0pas5yi40859kixkhjw8",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538761395-21.png",
          "1648474217313-1240-2.jpg"
        ]
      },
      {
        "sku": "1241",
        "description": [
          {
            "id": "cl1ar3u4f5z5208595tkwsm58",
            "title": "Cantarito Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j161g1gtcz0840l1kbx7l4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j161g6gtd10840iqb04194",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j161g9gtd20840tjk3a2pl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j161gkgtd60840jqbiuo1k",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j161gngtd80840pi8r170s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j161gpgtd908409kg45bwz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j161gygtdd0840up5eoc2t",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j161h0gtdf0840opja6r9c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j161h2gtdg08409yu64g9a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1arb3u460cp0859hsityd6g",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1arb9nc60ds0859927vzlny",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1ar3u4b5z510859j1m3p3f9",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1648538967423-26.png",
          "1648474388196-1241-2.jpg"
        ]
      },
      {
        "sku": "1242",
        "description": [
          {
            "id": "cl1areopl612r08597yetr63b",
            "title": "Glow Up Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7j2ggv1gzzu0840qppy6o2r",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7j2ggv7gzzw0840mk0l7h0i",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ggvagzzx0840o74i5u2b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j2ggvnh0010840i5jez77w",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7j2ggvqh0030840oyijhgkz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ggvsh0040840grgzispx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7j2ggw1h0080840y00ncxj3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7j2ggw3h00a0840fehko5ya",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7j2ggw4h00b08402965xy4y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1arpvoy63g70859cdfffn68",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1arq5xp63ie0859clhqj9ku",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1areopi612q0859rvpa61gq",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1648474892872-1242.jpg",
          "1648474892236-1242-2.jpg"
        ]
      },
      {
        "sku": "1243",
        "description": [
          {
            "id": "cl1artxj3646108593v35xt8c",
            "title": "Pom Poms Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1arvo3n64qy0859kw69z4vz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1arvo3q64r00859u2o1l5ut",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arvo3s64r10859liv67tmx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1arvo3y64r50859x5bcc44n",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1arvo4064r70859rn4m0ub0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arvo4164r808595rrow7jn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1arvo4664rc0859cr4bgejo",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1arvo4864re0859nev4jwqo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1arvo4964rf0859pskmrkvf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1aruolb64ds0859ym5rnql1",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1aruv1164fm0859kn29nlyk",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1artxj164600859bjcadbbs",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "1648475587915-1243.jpg",
          "1648475587905-1243-2.jpg"
        ]
      },
      {
        "sku": "1244",
        "description": [
          {
            "id": "cl1arwqsz651f0859yccqlzwx",
            "title": "Vibe Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1avscwd6wi708595pjvzhh4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1avscwg6wi90859mbf6a0nd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1avscwh6wia0859q1vxiig3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1avscwn6wie0859wst6w08f",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1avscwp6wig08599si97wxk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1avscwq6wih08598ogwvx45",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1avscww6wil08595b2fxu4u",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1avscwy6win0859k7oy2arm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1avscwz6wio0859rv8q5mic",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1arxhyp659b08594udjkfvj",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1arxnga65ar08599dznau1j",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1arwqst651e0859u9vtqf6a",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1648475727207-1244.jpg",
          "1648475727256-1244-2.jpg"
        ]
      },
      {
        "sku": "1245",
        "description": [
          {
            "id": "cl1aryz2f65pj0859dpg5suqf",
            "title": "Breeze Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1avt9yi6wyz0859klechb96",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1avt9yl6wz10859fydbkukz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1avt9ym6wz20859npcd0qbe",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1avt9ys6wz608596fw28qtn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1avt9yt6wz808596p4tjqjy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1avt9yu6wz908594sh40f1p",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1avt9yz6wzd0859irs2s1gd",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1avt9z16wzf0859xc3fwyq2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1avt9z26wzg0859f5uhnvjs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1as18zj66h508593c94dx1b",
            "price": 28,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1as1e8g66ia0859r70c8au7",
            "price": 78,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1aryz2465pi0859gfogjnk1",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1648475849795-1245.jpg",
          "1648475849960-1245-2.jpg"
        ]
      },
      {
        "sku": "1246",
        "description": [
          {
            "id": "cl1asctpk69i30859uhg2pl7f",
            "title": "Twizlers Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54f8kyyj15w0840b13w70pj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54f8kz3j15y0840a8q8aybw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8kz6j15z0840di3pib2c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54f8kzij1630840aixfsuy2",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54f8kzkj1650840pomga990",
                "price": 39,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8kznj1660840tx24br13",
                "price": 14,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54f8l03j16a0840785i1pmk",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54f8l07j16c084040wbtql1",
                "price": 67,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8l0aj16d0840jq2tvrnc",
                "price": 24,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54f8l0kj16h0840qs8j375m",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl54f8l0nj16j08404d4ds409",
                "price": 23,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54f8l0pj16k08406tpd70s0",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1asdlt069pn0859r5wdl5m0",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1aseexm69wb08596a2kitq2",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1asctph69i20859ttdeq9sn",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1648476057904-1246.jpg",
          "1648476058082-1246-2.jpg"
        ]
      },
      {
        "sku": "1247",
        "description": [
          {
            "id": "cl1asizy46avh0859h15w91at",
            "title": "Elevate Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1asknk96b8u0859qvwnyv6a",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1asknkb6b8w0859w7m4u50j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1asknkc6b8x0859ig89bnqs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2t41rcyqd3j0840lxowdsg9",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2t429nbqd8n084062i2rwvl",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1asizy26avg0859y8lcqubb",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1648476737600-1247.jpg"
        ]
      },
      {
        "sku": "1248",
        "description": [
          {
            "id": "cl1asngk06boq08591raxm3nn",
            "title": "Ladder Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1asp16w6bzi085945dqvh3c",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1asp16z6bzk0859msckj2wv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1asp1726bzm0859722adc5h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1asoctb6buo0859r9za0l5b",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1asogs06bvj0859nji4cklh",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1asngjy6bop0859w4ichw2k",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1648476987811-1248.jpg"
        ]
      },
      {
        "sku": "1249",
        "description": [
          {
            "id": "cl1asq4hs6c6v0859sz7z2xzj",
            "title": "Deep Rise Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1asrdxa6cir0859qobnrsso",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1asrdxd6cit0859dqidowmq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1asrdxf6ciu0859mjk587e9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1asqvxv6cd50859cb6hmxdl",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1asr0686cej0859n80mx1hu",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1asq4hq6c6u0859qs6xpeui",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1648477113121-1249.jpg"
        ]
      },
      {
        "sku": "1250",
        "description": [
          {
            "id": "cl1ass4z16cpa0859lfyo081s",
            "title": "Rapunzel Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1astj3n6d3k08593ig6br77",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1astj3q6d3m0859apt999zy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1astj3r6d3n0859iekyvhcc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1asstuh6cwg085980t536yp",
            "price": 36,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1assznt6cxv08592pj9l8qc",
            "price": 101,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1ass4yy6cp90859m6q2erl5",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1648477205238-1250.jpg"
        ]
      },
      {
        "sku": "1251",
        "description": [
          {
            "id": "cl1asubjb6daw08599yrg73pn",
            "title": "Gimlet Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1btpdme97vf0859k7ifraya",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl1btpdmg97vh0859sohejr04",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1btpdmh97vi0859rvr5mvd9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1asvws86dm60859gm66vfdk",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1asw4bs6dof0859wm30jm1z",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1asubj36dav0859ifiwp9n6",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1648539237274-12.png",
          "1648477311583-1251-2.jpg"
        ]
      },
      {
        "sku": "1234",
        "description": [
          {
            "id": "cl1bt9iog93r30859wimytlps",
            "title": "Sardinia Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1btk1ut972p0859xir0osyf",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1btk1uw972r08593zbc551t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1btk1v0972s085936jgv8ua",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1btk1v7972w0859h80xtzfa",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl1btk1v9972y0859coc3wfy9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1btk1va972z0859g7a3j320",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl1btk1vg9733085997ahpcda",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl1btk1vi97350859n07dntnk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1btk1vj97360859qfi00dso",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1btap0b944c0859oxi6bn3c",
            "price": 32,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1btatj5945b08591hjckune",
            "price": 90,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1bt9iod93r20859g8cpdkx0",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1648538987158-27.png",
          "1648538447930-1234-2.jpg"
        ]
      },
      {
        "sku": "1266",
        "description": [
          {
            "id": "cl1ddjbb0h7bv085955ubl8va",
            "title": "Spear Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ddjnjwh7h10859fma3dp3a",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1ddjnk0h7h30859kzb9nu3r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ddjnk2h7h408599kloqy6u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl1ddjbaxh7bu0859t4mpm9gm",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1648632718459-1631645871921-CCN-1119-2.jpg"
        ]
      },
      {
        "sku": "1267",
        "description": [
          {
            "id": "cl1ddltkuh7we0859q88zb96f",
            "title": "Big Shark Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ddm3e6h8080859u6q3uhyn",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1ddm3e8h80a0859cbd8o9tr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ddm3e9h80b0859fghagu8y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl1ddltksh7wd0859so7ywup4",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1648633058562-1631646537336-CCN-1120-2.jpg"
        ]
      },
      {
        "sku": "1268",
        "description": [
          {
            "id": "cl1ddo0byh8cv08590j2qbao4",
            "title": "Short Spear Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ddohmuh8iz0859h5rbhhkb",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1ddohmxh8j108591at3af2m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ddohmzh8j20859pkqiwp7n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl1ddo0bwh8cu0859mmte7814",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1648633209931-1631646936368-CCN-1122-2.jpg"
        ]
      },
      {
        "sku": "1269",
        "description": [
          {
            "id": "cl1ddpcpdh8q508594xymlut5",
            "title": "Cursor Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ddpo53h8uc0859n3cyqlzi",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1ddpo55h8ue0859ps785eiw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ddpo56h8uf0859fj0h21p0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl1ddpcpah8q40859t2ihpob2",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1648633284155-1631647464478-CCN-1123-2.jpg"
        ]
      },
      {
        "sku": "1270",
        "description": [
          {
            "id": "cl1ddqq0vh9330859ekzyncqw",
            "title": "Shark Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl42jhvi8kfpi08405qyzbu9c",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl42jhvickfpk08408ayodabx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42jhviekfpl0840pmftrpz0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl1ddqq0th9320859y6kjzno1",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1648633347409-1631647982290-CCN-1125-2.jpg"
        ]
      },
      {
        "sku": "1271",
        "description": [
          {
            "id": "cl1dduxa7ha4a0859erhpw67q",
            "title": "Long Spear Uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1ddva2kha9h0859dlm1dwy8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl1ddva2nha9j08599on6wx4w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1ddva2pha9k0859vrorrpxe",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl1dduxa1ha4908595ovpkr00",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1648633532921-1631648672106-CCN-1126-2.jpg"
        ]
      },
      {
        "sku": "1272",
        "description": [
          {
            "id": "cl1yrcy0tua5v08405nmzw7dr",
            "title": "Doğal Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl1yrrsosudjq08406r9bjrcr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl1yrrsowudjs0840v0110wov",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl1yrrsoyudjt08401vwki0j0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl1yrdjxkuacf08401wkqxd7w",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl1yrdz9duaem0840lq9xcxtx",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl1yrcy0nua5u0840jnm50iyy",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1649924022360-1273.jpeg"
        ]
      },
      {
        "sku": "1276",
        "description": [
          {
            "id": "cl24pfmlsqwpz0840i7dhzb23",
            "title": "Mineli taşlı küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl24qj9c2r3ug0840fucag9b4",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl24qj9c9r3ui0840f4150tz1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl24qj9cdr3uj0840gckesfrr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl24ph7jiqx5t0840611tki6a",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl24phsorqxaf0840wxtmnrm7",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl24pfmlmqwpy0840fbl4ybxc",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1650285565174-3023e628-7774-4e1b-a465-7a8b953f37e1.jpg"
        ]
      },
      {
        "sku": "1277",
        "description": [
          {
            "id": "cl2g11b0w2cn00840srf4eyo7",
            "title": "Bulut Taşlı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl487kr3gw5640840lxv9l13o",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl487kr3nw5660840eib1toas",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487kr3rw56708405rhj2q4e",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487kr49w56b0840irohaqf2",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl487kr4dw56d08404j97lnrr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487kr4gw56e0840hgf5rbgb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487kr4pw56i0840nf3u2gvi",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl487kr4sw56k0840p6qvccol",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487kr4vw56l0840gh4evjh4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g30r8r2o2o08405a11oird",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2g31fmi2o7j0840ybfvaor8",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g11b0j2cmx0840kzij7ra9",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1654851011653-IMG_4538.jpg",
          "1654851011645-IMG_4539.jpg"
        ]
      },
      {
        "sku": "1278",
        "description": [
          {
            "id": "cl2g14shl2dbl0840njz6s55k",
            "title": "Ay Işığı Taşlı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl487o6h8w5l30840npksjq86",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl487o6hgw5l50840xi2f4jz2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487o6hjw5l608401ifoja3n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487o6hsw5la0840ejqm87yb",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl487o6huw5lc0840xnto8hvj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487o6hww5ld0840ki99zupa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487o6i6w5lh0840p5la60hr",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl487o6i9w5lj0840im4ybl9x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487o6icw5lk0840fhdfsajw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g333ie2oml0840yce979pp",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2g33pzw2ot90840nwbhmm6s",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g14shf2dbk0840tvto4wqq",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1654851175037-IMG_4555.jpg",
          "1654851174985-IMG_4556.jpg"
        ]
      },
      {
        "sku": "1279",
        "description": [
          {
            "id": "cl2g1ckvz2ehv0840o7lw0r4i",
            "title": "Oklu Taşlı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl487p0l9w5rl0840lnxvuuwg",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl487p0lew5rn0840kopx718s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487p0lhw5ro0840dafgulcg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487p0luw5rs08406livt8k6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl487p0lyw5ru0840sneql70c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487p0m2w5rv0840gz6vxvi1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487p0mhw5rz0840ebe01z7i",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl487p0mkw5s10840rqwib6xw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487p0mmw5s20840989in0v2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g34u0f2p2c08409d4lokk2",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2g35bnp2p7t08407liuedyo",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g1ckvu2ehu08401527w99i",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1654851220221-IMG_4540.jpg",
          "1654851220565-IMG_4541.jpg"
        ]
      },
      {
        "sku": "1280",
        "description": [
          {
            "id": "cl2g1f83r2exa08401emdy1ua",
            "title": "Sign Taşlı Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl487to5uw6550840z7nznpwp",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl487to62w6570840zmoifg4c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487to66w6580840rju5dfyf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487to6jw65c0840p3vfdyws",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl487to6nw65e0840fyyj7z89",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487to6pw65f0840li8wcl52",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487to74w65j0840avgge4lu",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl487to7aw65l0840tqf140c5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487to7dw65m0840nl9v98g7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g36bro2pff0840snadutg0",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2g37za82pt40840ldujfgfz",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g1f83m2ex90840abf3bmf3",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1654851440785-IMG_4542.jpg",
          "1654851440777-IMG_4543.jpg"
        ]
      },
      {
        "sku": "1281",
        "description": [
          {
            "id": "cl2g1invl2fgq0840vvvr7ydk",
            "title": "Tornado Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl487u9wpw6bu08407kx9fi79",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl487u9www6bw0840uwwos4qv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487u9x0w6bx084086aoswjn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487u9xfw6c10840ks9zwqoe",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl487u9xiw6c3084043hjk2qp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487u9xlw6c40840fbr5dmvg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487u9xsw6c80840sw8if6mm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl487u9xuw6ca08400fyleob7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487u9xvw6cb0840c1ccp7ri",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g399vx2q4q0840me85cliq",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2g3a8me2qeu08408t2uaety",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g1invd2fgp0840bnaf7vu5",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1654851467562-IMG_4557.jpg",
          "1654851467845-IMG_4558.jpg"
        ]
      },
      {
        "sku": "1282",
        "description": [
          {
            "id": "cl2g1k5a62frb0840yjory2gf",
            "title": "CBS-1282"
          }
        ],
        "sizePrices": [
          {
            "id": "cl487unjww6g40840we4hecyh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl487unk1w6g60840rb2mfc25",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487unk4w6g70840fooovmos",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487unkcw6gb0840meic2e1n",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl487unkfw6gd0840u5g24c7z",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487unkgw6ge08407irq3dm4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl487unknw6gi0840vx3m90ci",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl487unkpw6gk0840ty5k1bdo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl487unkqw6gl0840kcd6mulq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g3bwop2qyc08407bfotdq9",
            "price": 146,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2g3cfcc2r4s08402cpq4ndi",
            "price": 52,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g1k5a12fra0840ilg4habu",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1654851487723-IMG_4552.jpg",
          "1654851488026-IMG_4553.jpg"
        ]
      },
      {
        "sku": "1283",
        "description": [
          {
            "id": "cl2g1okol2g8n0840cw9uf19e",
            "title": "Taç Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3abfxhzk3yx0840v0xmd0ml",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl3abfxi5k3yz0840192y450a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3abfxi8k3z00840673xym6j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g3demt2reg084075j1e1ds",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2g3dq112rij0840mg8h5nyl",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g1okof2g8m08406m00z07s",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1652801645869-IMG_4071.jpg",
          "1652801645671-IMG_4070.jpg"
        ]
      },
      {
        "sku": "1284",
        "description": [
          {
            "id": "cl2g1tkcl2gvw0840m78727el",
            "title": "Tepki Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc8untkj0z0840ux8exeah",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7kc8unwkj1108402a1opjru",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc8unzkj120840z1xglyv1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kc8uo7kj160840faagh9bg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl7kc8uoakj180840p9tcsd11",
                "price": 22,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc8uobkj190840n0mprkpn",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kc8uojkj1d08408x9dg4wl",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kc8uolkj1f0840e2zcarrr",
                "price": 50,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc8uomkj1g0840grjnrt6c",
                "price": 18,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kc8uovkj1k08406cior88b",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl7kc8uoykj1m0840bz3y7pjf",
                "price": 11,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc8up1kj1n0840e5slyhrq",
                "price": 4,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kc8updkj1r0840t1axtr9i",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7kc8uphkj1t0840aqtn931g",
                "price": 34,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc8upjkj1u084097pc5eyz",
                "price": 12,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4e8hm720k1k0840w77ql0q2",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4e8hsvg0k1x0840hg0ata1k",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g1tkcg2gvv0840v4y2ezws",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1652799740790-IMG_4097.jpg",
          "1652799741527-IMG_4099.jpg"
        ]
      },
      {
        "sku": "1285",
        "description": [
          {
            "id": "cl2g242d02ioh084013nx9dio",
            "title": "Hawai Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kgaxckkxnz0840h8033lnu",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl7kgaxcrkxo10840ujdetbn2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kgaxcvkxo20840vbrozyoy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kgaxdbkxo60840txoiloof",
            "size": {
              "id": "cknmfqoc60usl0993y614r8vi",
              "sku": "C"
            },
            "prices": [
              {
                "id": "cl7kgaxdekxo808405f93t0vr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kgaxdgkxo90840626d9fu6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g3sotu2utd08406qadtpux",
            "price": 174,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3aaq8a0jx3j0840fbp9alf7",
            "price": 62,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g242cu2iog08407zm1n3ma",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1652800546603-IMG_4102.jpg",
          "1652800547285-IMG_4103.jpg"
        ]
      },
      {
        "sku": "1286",
        "description": [
          {
            "id": "cl2g299zl2jk00840bb7q3kco",
            "title": "Dolunay Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl848rhvq3ujn0840b3ntc4fk",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl848rhvw3ujp08406c5gurqr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl848rhvz3ujq084088oopr9g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2g3v4m32vc60840ski47gtz",
            "price": 96,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2g3vde42vdp0840li0yu8nx",
            "price": 269,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g299zf2jjz0840a2fjetrd",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "141997-img-8510.jpg",
          "1980884-img-8511.jpg"
        ]
      },
      {
        "sku": "1287",
        "description": [
          {
            "id": "cl2g2dnnp2kep0840jxkbptjf",
            "title": "Carmen Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8bnpn4yqx5k0840qalibxth",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl8bnpn54qx5m0840zwr53659",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8bnpn56qx5n0840m6prln8y",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4e8fktz0jq10840ttb4tj82",
            "price": 106,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4e8fxj10jqq0840hp0yzw6w",
            "price": 296,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g2dnnk2keo08404ob3hcha",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1655032037917-IMG_4571.jpg",
          "1655032038007-IMG_4573.jpg"
        ]
      },
      {
        "sku": "1288",
        "description": [
          {
            "id": "cl2g4oht42zp20840sag418cg",
            "title": "Taşlı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4fmxq2s35kx0840uecguhnx",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl4fmxq3035kz0840gp6ek6lf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4fmxq3335l00840xipeskj9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4fmxq3j35l4084080r6638l",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl4fmxq3m35l60840vphs53ws",
                "price": 16,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4fmxq3p35l70840ni8b8e2j",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl31bd2xcs2s00840vxzy53ha",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl31bdsias2up08409b9id05u",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g4ohsy2zp10840bz02hfoi",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1655300086846-5aca7d51-b499-4ed9-9df1-c42913f6678c.jpg"
        ]
      },
      {
        "sku": "1289",
        "description": [
          {
            "id": "cl2g4rmfc301k0840ly8tg49z",
            "title": "Piramit Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g86y9i4fm0840q7x1e7ue",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77g86yei4fo0840itjyh9bg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g86yii4fp0840s4up0kfv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77g86z0i4ft0840hf990tra",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl77g86z5i4fv0840phwglv9u",
                "price": 6,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g86z7i4fw0840monrd4gz",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2hmmr12alg20840itbcf9i0",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2hmngzwallx0840bu2i09hc",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g4rmf6301j08406vd0175w",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1654851744926-IMG_4560.jpg",
          "1654851745505-IMG_4561.jpg"
        ]
      },
      {
        "sku": "1290",
        "description": [
          {
            "id": "cl2g4ufgk30e00840igne7mmb",
            "title": "CNS-1290"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g8g5si4ka0840dioxf3qj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77g8g5xi4kc08404h0smyo0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g8g60i4kd0840zh04w5r2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77g8g6di4kh0840ap6r6fhv",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl77g8g6gi4kj0840cv93b0cn",
                "price": 28,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g8g6ji4kk0840o4zsrw7h",
                "price": 10,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77g8g6vi4ko0840fodiiaue",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl77g8g6yi4kq0840mzolef8n",
                "price": 6,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g8g71i4kr08409yawcp9v",
                "price": 2,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2hmv7rnaoak0840quwg5kai",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2hmwczkaonq08403ir80992",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g4ufge30dz0840z3s1bex3",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1652798899814-IMG_4093.jpg",
          "1652798900169-IMG_4095.jpg",
          "1654853782041-IMG_4563.jpg",
          "1654853788691-IMG_4564.jpg"
        ]
      },
      {
        "sku": "1291",
        "description": [
          {
            "id": "cl2g4y0go30zy084080zn0un5",
            "title": "Eski Taşlı Kolyeler"
          }
        ],
        "sizePrices": [
          {
            "id": "cl2hn2bw6aqju0840l2kxpuvw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl2hn2bwaaqjw0840n0q072g4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2hn2bwdaqjx08403qkdignq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2hn2bwoaqk10840hz3ocw89",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl2hn2bwsaqk30840mc33vf3j",
                "price": 8,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2hn2bwtaqk408404zbzf96a",
                "price": 3,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2sxnzwgor8108404pxs3mxs",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2sxog6morf10840qxw3txtd",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g4y0gi30zx0840x3klu7s4",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1650976728261-IMG_20220426_153734.jpg"
        ]
      },
      {
        "sku": "1292",
        "description": [
          {
            "id": "cl2g4z0s131ab0840dr6jpff8",
            "title": "Eski Taşlı Kolyeler"
          }
        ],
        "sizePrices": [
          {
            "id": "cl2hn7d5mas090840rpp0le00",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl2hn7d5uas0b0840rw33o9ou",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2hn7d5yas0c0840besndszl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl2hn7d6cas0g0840923ebmu2",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl2hn7d6gas0i084059nl7ibs",
                "price": 8,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl2hn7d6jas0k0840crtezqxi",
                "price": 3,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2sxrtnnosqe08408yvvxp96",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2sxs7dcosvt0840vq98v1xk",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g4z0rm31aa0840qlz7dv4a",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1650976814604-IMG_20220426_153725.jpg"
        ]
      },
      {
        "sku": "714",
        "description": [
          {
            "id": "cl2g52d1y32820840cvb2ee05",
            "title": "Şeker Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g8nivi4nr0840e0wlwc17",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl77g8nizi4nt0840ynrpb33o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g8nj2i4nu0840p6itfhqi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl77g8njei4ny0840vieelg0q",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl77g8njgi4o00840xrx30a2d",
                "price": 16,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g8njji4o10840hszry900",
                "price": 6,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2hnem4lau9208409wqokn02",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2hnhlnkav0e0840sgg2ebmm",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g52d1r3281084093o2wd4i",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1652800736800-IMG_4100.jpg",
          "1652800736771-IMG_4101.jpg"
        ]
      },
      {
        "sku": "1294",
        "description": [
          {
            "id": "cl2g57q3d33i30840ivixjbcl",
            "title": "Aura Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbwi0jkfo70840n768njk7",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7kbwi0pkfo90840yt47151e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbwi0tkfoa0840kktj327x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2hnn10eawxz0840pcc04rfm",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3a9zx4jjpbm0840j4u3ok97",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g57q3733i20840ge9ogb09",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1652799339050-IMG_4126.jpg"
        ]
      },
      {
        "sku": "1295",
        "description": [
          {
            "id": "cl2g5fbr3349h0840w5ac5xdd",
            "title": "Bağlantı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbxfrwkfv30840ah8p9mow",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kbxfs2kfv50840w1t2rc22",
                "price": 44,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbxfs5kfv60840ir6huv30",
                "price": 16,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbxfsgkfva084001x02rd9",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7kbxfsikfvc08407adq4pwj",
                "price": 52,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbxfskkfvd08401vc8i19v",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kbxfsskfvh084032ypvl2v",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7kbxfsukfvj0840s1h50lzk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbxfswkfvk0840yscfxlvf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2hnrjzgaypv0840rw2udws7",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2hnrsneaysg0840vxklajrh",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g5fbqw349g0840vhefqymc",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1652800939139-IMG_4120.jpg",
          "1652800939380-IMG_4122.jpg"
        ]
      },
      {
        "sku": "1296",
        "description": [
          {
            "id": "cl2g5hqy034ii08409azmcq3a",
            "title": "Peri Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3abablwk2tr0840oc37qrja",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3ababm9k2tt0840o9inw5h7",
                "price": 44,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3ababmck2tu0840obakphde",
                "price": 16,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3ababmmk2ty0840xsth8l9v",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl3ababmpk2u00840zzy3hork",
                "price": 52,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3ababmrk2u10840htkuc1ct",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3ababn4k2u50840ieotfefy",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl3ababn9k2u70840ny4yhuph",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3ababnck2u80840gnb3hcae",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2hnz430b0sk0840u33iutx8",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2ho1qzwb1f90840brksw88g",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g5hqxu34ih0840mrfi9lh3",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1652801401249-IMG_4110.jpg",
          "1652801401607-IMG_4111.jpg"
        ]
      },
      {
        "sku": "1297",
        "description": [
          {
            "id": "cl2g5mwyr35bb08403vob9b8r",
            "title": "Açılı Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kby3khkg0q0840kzkpb0d3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl7kby3kokg0s0840vq8d1egb",
                "price": 44,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kby3kskg0t0840mah7wzcy",
                "price": 16,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kby3lckg0x0840yvbs90xj",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl7kby3lekg0z08400e9iwxge",
                "price": 52,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kby3lgkg100840ygfeul9a",
                "price": 19,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl7kby3lpkg1408406hme6nk8",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl7kby3lskg1608408cghb24w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kby3lukg170840ore4zj4w",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl2ho5o96b2k9084062138xap",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl2ho68bxb2q208409prxw5dt",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl2g5mwym35ba0840nm3gxfvq",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1652801278628-IMG_4116.jpg",
          "1652801278710-IMG_4117.jpg"
        ]
      },
      {
        "sku": "1298",
        "description": [
          {
            "id": "cl31k7w42tlj40840v30sbizh",
            "title": "Mineli Akış Kolyenin Bilekliği"
          }
        ],
        "sizePrices": [
          {
            "id": "cl31k8q9xtlub0840ievsv2aw",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl31k8qa2tlud0840931jgc9v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl31k8qa5tlue0840iltdf2sq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl31k8qagtlui0840ev4t3bxk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl31k8qajtluk08403vzoa6ql",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl31k8qamtlul0840075nfx8j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl31k8qaztlup08407cc8kyqz",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl31k8qb2tlur0840difkq4jv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl31k8qb4tlus0840dgk8gxgu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3iokfwed5ii08403qnaj4g4",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl31k7w3ptlj30840dmu7m1zb",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": []
      },
      {
        "sku": "1300",
        "description": [
          {
            "id": "cl3abkhm3k5sw0840d9y6nani",
            "title": "Boşluk Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbym2xkg4b0840aynxk2w3",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kbym32kg4d08402ktjnalm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbym35kg4e0840l894s1mt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3aboahfk6lg0840tzhddvy3",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3abohy0k6mr08409dfn46f5",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3abkhlyk5sv0840nxlu1o04",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1652801879390-IMG_4073.jpg",
          "1652801879381-IMG_4072.jpg"
        ]
      },
      {
        "sku": "1301",
        "description": [
          {
            "id": "cl3abqd8mk6xk0840so3dg09x",
            "title": "Üçüz Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kbz908kga20840gane9zmg",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kbz90ekga408408k615v51",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kbz90jkga50840y0vt5zvf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3abradek73t08407bmacohc",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3abrtnzk77z0840q69140f7",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3abqd8hk6xj0840s70namj7",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1652802118086-IMG_4055.jpg",
          "1652802118266-IMG_4054.jpg"
        ]
      },
      {
        "sku": "1302",
        "description": [
          {
            "id": "cl3abt1dkk7if0840svxwngdo",
            "title": "Uzun Mineli Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc01ndkges0840mrh68jwr",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kc01njkgeu08402wj389c2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc01nnkgev0840gw09exjl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3abum9wk7rs0840daurnri0",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3aburbwk7sc0840tki59hpo",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3abt1dgk7ie0840oxrcr84k",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1652802259955-IMG_4059.jpg",
          "1652802260007-IMG_4058.jpg"
        ]
      },
      {
        "sku": "1303",
        "description": [
          {
            "id": "cl3abwhyck85h0840155mgej7",
            "title": "Sandviç Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc0yshkgn60840x0589v3p",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kc0ysmkgn80840fswbq2mb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc0yspkgn90840u4l74gcr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3abxraok8bx08403oedmg3t",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3abxyemk8d60840ieoiuk8b",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3abwhy8k85g08409y6tiowk",
        "category": {
          "id": "ckku4pi390itz091929n938wi",
          "sku": "CRM"
        },
        "photos": [
          "1652802399797-IMG_4053.jpg",
          "1652802400048-IMG_4052.jpg"
        ]
      },
      {
        "sku": "1304",
        "description": [
          {
            "id": "cl3ac2b12k9bu084048mbisbx",
            "title": "Pırıltı Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc1ph9kgw508404xk8pgvj",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kc1phekgw70840ebi1sapb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc1phhkgw808401pnrahh3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3ac3d2ok9k30840ti5jaf56",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3ac3k44k9lc08405y1n4kx3",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3ac2b0xk9bt0840leoqp938",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1652802592876-IMG_4065.jpg",
          "1652802593129-IMG_4064.jpg"
        ]
      },
      {
        "sku": "1305",
        "description": [
          {
            "id": "cl3ac7g13kaed08408mp9w0sy",
            "title": "Uzun Baget Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc2kcakh2b0840u1r5i2z2",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kc2kcgkh2d08404zqds9a1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc2kcjkh2e0840736jf2v3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3ac8k43kaog0840ikmyb4ub",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3ac8rnukaqh0840c6kow2kc",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3ac7g0xkaec0840r12sn9fn",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1652802814238-IMG_4061 - Copy.jpg",
          "1652802813114-IMG_4060 - Copy.jpg"
        ]
      },
      {
        "sku": "1306",
        "description": [
          {
            "id": "cl3aceoy7kbwr0840236bk4za",
            "title": "Nilüfer Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc382jkh6p0840t6q2zrsa",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kc382nkh6r084096x4ftz9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc382qkh6s08406cqusihu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3acf4gxkc0g0840caoqikq9",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3acf8tzkc1o0840kwvjypvf",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3aceoy1kbwq08402i7wkqth",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1652803233925-IMG_4049.jpg",
          "1652803233720-IMG_4048.jpg"
        ]
      },
      {
        "sku": "1307",
        "description": [
          {
            "id": "cl3achma1kcgz0840cqelq7af",
            "title": "Peri Gözü Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3x5k6ambh3208401kkvzelv",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl3x5k6arbh340840x2humoqt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3x5k6aubh350840qtxz0gw2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3aci8f1kcks0840q0whzxzn",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3acicvvkclk084037mz9l01",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3achm9wkcgy0840950rebnc",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1652803396891-IMG_4069.jpg",
          "1652803396654-IMG_4068.jpg"
        ]
      },
      {
        "sku": "1308",
        "description": [
          {
            "id": "cl3ackawzkcw70840x23rjoo3",
            "title": "İç İçe Kare Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc3pq5khdy084096gloibp",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kc3pq7khe008405qxwn233",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc3pq9khe10840zi21ews5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3ackx86kd0e0840y9kfu04c",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3acl53okd1g08407tgzwigg",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3ackawskcw608400htcfzp8",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1652803505936-IMG_4063.jpg",
          "1652803506253-IMG_4062.jpg"
        ]
      },
      {
        "sku": "1309",
        "description": [
          {
            "id": "cl3acn4r1kddq0840ajswjbjx",
            "title": "Sonsuz Gece Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc471ykhlx0840vzsa0yam",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kc4721khlz0840hy4yzpl6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc4722khm008409e4dlm51",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3aconqskdmm08401yqa0whu",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3acorltkdn70840yx5i5vq9",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3acn4qukddo0840m2whp9rw",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1652803630923-IMG_4057.jpg",
          "1652803630939-IMG_4056.jpg"
        ]
      },
      {
        "sku": "1310",
        "description": [
          {
            "id": "cl3acrs74kdx60840dama73xg",
            "title": "Tesadüf Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3baeumwncun0840scuberin",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl3baeun0ncup0840jyrs2cli",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3baeun2ncuq08405syb0e6s",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3actgh8ke350840srfft14i",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3actjjrke3k0840ltyz4b7b",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3acrs6xkdx50840bmz0a0kd",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "1652803901144-IMG_4051.jpg",
          "1652803900668-IMG_4050.jpg"
        ]
      },
      {
        "sku": "1311",
        "description": [
          {
            "id": "cl3acvzlekeh10840qab19qi3",
            "title": "Venüs Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7kc4rfnkhuq0840co1jvvh1",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl7kc4rfskhus084095nh2rts",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7kc4rfvkhut0840f4q6f9uz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3acwvnekeld0840ne83cek3",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3acwzb8kemo08405ofjhm6x",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3acvzlakeh00840xzavqxce",
        "category": {
          "id": "cl0i6cusoscwd0859j65x6zgo",
          "sku": "CRS"
        },
        "photos": [
          "1652804068787-IMG_4047.jpg",
          "1652804068852-IMG_4046.jpg"
        ]
      },
      {
        "sku": "617",
        "description": [
          {
            "id": "cl3bgow0opf1w0840289f7jwb",
            "title": "CCE-617"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3bgpj1kpf8e0840c6h65bec",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl3bgpj1ypf8g0840ggem2mpi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3bgpj23pf8h0840dlvrgr2o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl3bgow0kpf1v0840qudt6s3h",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": []
      },
      {
        "sku": "1312",
        "description": [
          {
            "id": "cl3egfogr18sf08404j18w8q9",
            "title": "1312"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3egg16i18uy0840j0sqit7e",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl3egg16m18v00840al04hq16",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3egg16o18v108405h7io68m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl3egfogl18se0840vr2evk8i",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1653051912769-7ab71964-1eea-451a-bab9-6ebbf8b11175.jpg"
        ]
      },
      {
        "sku": "1313",
        "description": [
          {
            "id": "cl3k3xeflhcqg08408as6ruj1",
            "title": "1234 Düğümsüz"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3k3xscghctt0840y8v1gbd4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3k3xscnhctv0840j2uxqh83",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3k3xscphctw0840zpxpgwi8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3k3xscyhcu00840cl1qqxlz",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3k3xsd0hcu20840nol555ui",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3k3xsd2hcu30840fw2t71i4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3k3xsdehcu70840fao4nyjc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3k3xsdjhcu90840yvzhhu2y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3k3xsdmhcua0840ombxxis4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl3k3xeffhcqf0840w8kbfkva",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1653393631643-6af496f1-103c-456b-b7eb-1f02c2066e72.jpg"
        ]
      },
      {
        "sku": "1314",
        "description": [
          {
            "id": "cl3skp5e532wa08408ghu0s0e",
            "title": "Halkalı Tuscany Küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3skpxip33320840qvswszkq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl3skpxiy33340840s6w3zd0f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3skpxj133350840jvg1woa8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl3skp5e032w90840ec21t14d",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": []
      },
      {
        "sku": "1318",
        "description": [
          {
            "id": "cl3trzdy44zsi0840l7bnid86",
            "title": "CCP-1318"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4883vvmw7o00840le2qc5w2",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4883vvtw7o20840ec1vej3n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4883vvxw7o30840h0em8kb2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl488dfqqw8vw0840to8uv0bn",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl488du19w8we0840sew0a0cg",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3trzdxx4zsh0840kzkrjozm",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1654851901724-IMG_4575.jpg",
          "1654851901935-IMG_4576.jpg"
        ]
      },
      {
        "sku": "1319",
        "description": [
          {
            "id": "cl3ts0q1v4zxs0840eey2ae4y",
            "title": "CCP-1319"
          }
        ],
        "sizePrices": [
          {
            "id": "cl488820vw8b30840yqsieko3",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4888211w8b508401buo1ygt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4888214w8b60840wnl9zlue",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl488g2x0w8yz0840yr9j0onm",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl488gogzw8zq0840h0ekk5zi",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3ts0q1q4zxr0840giacgmrb",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1654851996210-IMG_4585.jpg",
          "1654851995927-IMG_4584.jpg",
          "1654852105530-IMG_4595.jpg"
        ]
      },
      {
        "sku": "1315",
        "description": [
          {
            "id": "cl3ts1sy150230840ycwt6flz",
            "title": "CCP-1315"
          }
        ],
        "sizePrices": [
          {
            "id": "cl48862vrw83d0840hlwcedih",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl48862vxw83f08401im3y2ed",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl48862vzw83g0840x9k32jjc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl488hs34w90j0840rz3v03vy",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl488iqgjw91e0840sh9o71he",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3ts1sxw50220840jepwcisg",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1654852019465-IMG_4588.jpg",
          "1654852019906-IMG_4590.jpg"
        ]
      },
      {
        "sku": "1316",
        "description": [
          {
            "id": "cl3ts38wj506y0840uj0g51nb",
            "title": "CCP-1316"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4886u0aw8630840fj3krtle",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4886u0gw8650840yyfxw0tj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4886u0jw8660840vb1dp1ym",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl488jgz8w92p08402snwlj9u",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl488k5d5w93f0840i1p7znx2",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3ts38wf506x0840re5bs94w",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1654852056420-IMG_4580.jpg",
          "1654852050558-IMG_4594.jpg"
        ]
      },
      {
        "sku": "1317",
        "description": [
          {
            "id": "cl3ts4sx850cn084023l74fe3",
            "title": "CCP-1317"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4887ia3w88o0840g9u4ssbs",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4887ia6w88q0840qcr2s8py",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4887ia8w88r0840bc2v3r2b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl488l47mw94v0840vntepmxh",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl488liv4w95q0840zbxzbvjr",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3ts4sx350cm08406wo3nowf",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1654852084248-IMG_4579.jpg"
        ]
      },
      {
        "sku": "734",
        "description": [
          {
            "id": "cl3tyiyk45l0c0840ixztt5ro",
            "title": "cbm-734"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3tyjdbt5l6n0840ljeb1y87",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3tyjdbx5l6p0840dsn864cf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3tyjdbz5l6q0840mqco50wr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3tyjdcb5l6u0840kt48qagj",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3tyjdcd5l6w08404zzvo35x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3tyjdcf5l6x08404nn9x2bo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3tyjdco5l7108400nq3p738",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3tyjdcr5l730840w4b5rtlp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3tyjdcs5l740840k70l0in3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl3tyiyk05l0b0840d0inucsw",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": []
      },
      {
        "sku": "1320",
        "description": [
          {
            "id": "cl3vbjli57t6y08407db8w0he",
            "title": "Ufo Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4sgyxkrttm908403zwqaya8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4sgyxkyttmb0840ic7je96t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4sgyxl0ttmc08409f30pmke",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3vbko8j7t8b0840pp5zphnk",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3vbl10d7t8n0840uxrrfewp",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3vbjli07t6x0840qeycanpa",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1656076125226-IMG_4918.jpg",
          "1656076125251-IMG_4916.jpg"
        ]
      },
      {
        "sku": "1321",
        "description": [
          {
            "id": "cl3vbm4a97tch0840s8xkot39",
            "title": "Mood Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4sh0d2ltttx0840cjcjt80a",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4sh0d2utttz0840ck327ow9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4sh0d2yttu008407xm8g3pm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3vbme457tdg0840uoxb4owx",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3vbmvr77te80840bqkhvux2",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3vbm4a47tcg0840dro0fywu",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1656076165810-IMG_4931.jpg",
          "1656076166135-IMG_4932.jpg"
        ]
      },
      {
        "sku": "1322",
        "description": [
          {
            "id": "cl3vbyhcj7uny0840y4cg0qz8",
            "title": "Kalın Zincir Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4sh0vzlttyf0840xkq35445",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4sh0vzqttyh0840wy2ufp7f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4sh0vztttyi084009hjwp8u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3vbywcb7upb0840p8isf70u",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3vbz0jw7upm0840szzckv91",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3vbyhcf7unx0840bwvzz02h",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1656076218413-IMG_4907.jpg",
          "1656076218691-IMG_4912.jpg"
        ]
      },
      {
        "sku": "1323",
        "description": [
          {
            "id": "cl3vc083s7uwi0840w4feg17f",
            "title": "Düğme Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4pg4z8ancaz08402vt9hg1v",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4pg4z8fncb108403upptbqd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4pg4z8incb20840ndgktjki",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3vc0lp07uxw084056xbhihw",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3vc0v2h7v0n08401f4q1n2s",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3vc083m7uwh0840qx6a3net",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1655893304608-IMG_4897.jpg",
          "1655893305309-IMG_4899.jpg"
        ]
      },
      {
        "sku": "1324",
        "description": [
          {
            "id": "cl3wscs8aaal508406dauln08",
            "title": "Linkage Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4shazagtv3e0840nwqt50rf",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4shazantv3g0840h8r1nfka",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4shazastv3h08408rlek31h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3wsda8baapp08407oob73ef",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3wsdj25aar70840k7g0a9p8",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3wscs85aal40840jm8e32r6",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1656076688348-IMG_4928.jpg",
          "1656076688357-IMG_4927.jpg"
        ]
      },
      {
        "sku": "1325",
        "description": [
          {
            "id": "cl3wsg963ab0l0840kk330zez",
            "title": "Desenli Halhal"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g5o9xi3xd0840ljvnc2pz",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g5oa1i3xf0840flqnslfh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g5oa3i3xg08402n9tlebg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl3wsgmy7ab2a0840gdiqsbnj",
            "price": 48,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl3wsgsziab2w0840ywzpjhmu",
            "price": 134,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl3wsg95yab0k0840142n4vh7",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1656076715275-IMG_4919.jpg",
          "1656076715303-IMG_4921.jpg"
        ]
      },
      {
        "sku": "1004",
        "description": [
          {
            "id": "cl3wz1mprav7c0840wao1kw8b",
            "title": "CCB-1004"
          }
        ],
        "sizePrices": [
          {
            "id": "cl3wz2gesavf20840qw7e6ua7",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl3wz2geyavf40840zu9h8jxj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wz2gf1avf50840nqz7g9bh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3wz2gfdavf908403ht6545v",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl3wz2gfgavfb0840rr5cs73t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wz2gfjavfc0840zddyn2oi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl3wz2gfzavfg08402ygxadhh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl3wz2gg2avfi0840i6ky4zch",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl3wz2gg4avfj0840uqg0ixi9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl3wz1mplav7b0840ziye01xy",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "1654171607555-5fe8cd40-990c-498f-a7c7-d39eaa0d050c.jpg"
        ]
      },
      {
        "sku": "1326",
        "description": [
          {
            "id": "cl42ljkd2kmim08409bfdz9s3",
            "title": "1316nın ipli kolyesi"
          }
        ],
        "sizePrices": [
          {
            "id": "cl42llnmxkmpp084019806c3l",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl42llnn2kmpr0840s3th69vh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42llnn4kmps0840xrng4236",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42llnnckmpw0840tjddlz5d",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl42llnnekmpy0840id0foosp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42llnngkmpz0840qqhnrxjl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42llnnlkmq30840ke9bq1ui",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl42llnnmkmq50840tqux088u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42llnnnkmq60840v5bb7cix",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42llnntkmqa08401cq69wqw",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl42llnnukmqc0840m9tbhe92",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42llnnvkmqd0840zmni3qi3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42llno0kmqh0840zz9jhj91",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl42llno2kmqj0840t22q5gzl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42llno3kmqk0840hgl1qpxg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl42ljkcxkmil0840dzpjhr7q",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1654511619925-1653978455912-a37cc520-eddc-4f73-9fee-008bafa952be.jpg"
        ]
      },
      {
        "sku": "1327",
        "description": [
          {
            "id": "cl42lr3cukn290840gumojd8r",
            "title": "1317'nin kolyesi"
          }
        ],
        "sizePrices": [
          {
            "id": "cl42lrcppkn5x0840rf2r8ecp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl42lrcpukn5z0840a7y5s3zn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lrcpwkn600840x7vvivo0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lrcq7kn640840wls0pqsy",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl42lrcqbkn660840w4kr3ttb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lrcqekn670840fg6bta8i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lrcqpkn6b08403q92i2z4",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl42lrcqrkn6d0840de5o4rnv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lrcqtkn6e0840pcvkmuc4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lrcr2kn6i08407o2vrazr",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl42lrcr5kn6k0840sxyirazi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lrcr7kn6l0840bqb8o3su",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lrcrmkn6p0840x8d92sc7",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl42lrcrqkn6r0840z41b1wf2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lrcrtkn6s08407uinomld",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl42lr3cnkn2808400gqdjnbn",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1654512003149-1653978549345-1f823af2-fc7b-4444-959a-dcc5be8376f8.jpg"
        ]
      },
      {
        "sku": "1328",
        "description": [
          {
            "id": "cl42lvxweknm508407boy3yr0",
            "title": "1318'in Kolyesi"
          }
        ],
        "sizePrices": [
          {
            "id": "cl42lwdwaknrw0840vam3rl6y",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl42lwdwgknry0840q7zinwg8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lwdwjknrz0840sh23ya18",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lwdwxkns308400ay0dm5e",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl42lwdx1kns50840blrqxxn5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lwdx4kns60840vh5epz39",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lwdxeknsa0840qt902j2f",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl42lwdxhknsc084039l89hr8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lwdxiknsd0840xcb5zad2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lwdxpknsh0840cmgzlydv",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl42lwdxqknsj0840zj7b8c5j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lwdxsknsk08400w8xcvad",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lwdy2knso0840syd1y6ph",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl42lwdy6knsq0840u6x3bk5j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lwdyaknsr0840oyo96ptb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl42lvxw7knm408408sbn8dzf",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1654512239775-1653978239481-fc6765b0-ac04-4787-9fa4-0d510cd35773.jpg"
        ]
      },
      {
        "sku": "1329",
        "description": [
          {
            "id": "cl42lz33dko0v08407m4oylqj",
            "title": "1319 kolyesi"
          }
        ],
        "sizePrices": [
          {
            "id": "cl42lzkjbko5f0840p411kacw",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl42lzkjgko5h0840126ep5ch",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lzkjiko5i0840dyhacozs",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lzkjuko5m0840w1g1lj9h",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl42lzkjyko5o0840pt5j33zb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lzkk1ko5p0840cpb1o7mk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lzkkako5t0840bs43rd68",
            "size": {
              "id": "ckkjugt364yxs0919v4l7kk7m",
              "sku": "XL"
            },
            "prices": [
              {
                "id": "cl42lzkkdko5v0840i595r354",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lzkkeko5w0840a6og78zy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lzkkkko60084048a9gdhv",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl42lzkknko620840do3if38h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lzkkpko630840551vauzn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl42lzkkwko670840p5tx3vm9",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl42lzkkyko690840600navje",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl42lzkl0ko6a0840smy0jxkc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl42lz335ko0u0840wz0rt3vr",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1654512386017-1653978347577-2a3019cb-caac-46bd-9da8-c1b0693333a6.jpg"
        ]
      },
      {
        "sku": "1330",
        "description": [
          {
            "id": "cl477rg6rulwh08408roemyx1",
            "title": "CLM-704 küpesi"
          }
        ],
        "sizePrices": [
          {
            "id": "cl477rw1tulyq0840rrtwa71l",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl477rw20ulys0840zw5qfzg0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl477rw23ulyt084098ywazmn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl477rg6mulwg0840pmecre4m",
        "category": {
          "id": "ckku4y7vj0ixu0919m0bmjad1",
          "sku": "CEM"
        },
        "photos": [
          "1654790766447-bdfb93b1-c397-4c4a-acc3-8d4a6d3539a0.jpg"
        ]
      },
      {
        "sku": "1331",
        "description": [
          {
            "id": "cl4chgb395l350840ujmasp3d",
            "title": "CBS-1331"
          }
        ],
        "sizePrices": [
          {
            "id": "cl589d7umr2z50840nj5qymi2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl589d7uqr2z70840jjnlf5fn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl589d7urr2z808404myjbfde",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl589d7uzr2zc0840uw6zzr3l",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl589d7v1r2ze0840b0xzm5g1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl589d7v2r2zf0840irxsa2a9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl589d7v9r2zj08407afu0c8g",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl589d7vbr2zl08400tjj5oda",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl589d7vcr2zm0840vj6h3tzu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4chhyzv5li80840s5xej0o2",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4chi56g5lj80840pg0fplo0",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4chgb355l340840rt23wzg4",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "2540452-ccb-350.jpg",
          "4891416-ccb-350-1.jpg"
        ]
      },
      {
        "sku": "1332",
        "description": [
          {
            "id": "cl4chka3r5m2k0840uvev3j10",
            "title": "CCH-1332"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4chmmn55md90840qpofs1rv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4chmmnb5mdb084006n083f6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4chmmne5mdc08409ns84oof",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4chlov55m880840dbf0ppsf",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4chm5ke5m940840br43pzyk",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4chka3n5m2j0840q9k57rk3",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1655109611935-20220613_113619.jpg"
        ]
      },
      {
        "sku": "1046",
        "description": [
          {
            "id": "cl4grwtxj58ks08403ngum9fo",
            "title": "YARI YUVARLAK KESIK KÜPE 1 SM"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4gspv9d5dyp0840bw79llw3",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4gspv9k5dyr0840kmgk2v5b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4gspv9o5dys0840qd95316b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl4grwtxa58kr08407jg8hztd",
        "category": {
          "id": "ckkvpg93b0rx80919kt1a5bxi",
          "sku": "CCE"
        },
        "photos": [
          "1655368911921-79fa917e-d702-4cdb-8614-9595dd773923.jpg"
        ]
      },
      {
        "sku": "1333",
        "description": [
          {
            "id": "cl4i43rhr80w908407yp5ajb1",
            "title": "CCN-1333"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6vtpo8incgj08407ww5zx7n",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl6vtpo8oncgl08407xh50ase",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6vtpo8rncgm084029zjrqxt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6vtpo93ncgq0840tdjvc3hq",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl6vtpo96ncgs084081v8f1qa",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6vtpo97ncgt0840n11g793a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6vtpo9fncgx0840569ye5mc",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl6vtpo9incgz0840mcz0ksw5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6vtpo9knch00840uqxfe4nh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6vtpo9unch40840heovwcrz",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl6vtpo9ynch6084094r4j9rf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6vtpoa1nch70840vgd0jabd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl5s0kmdywbs00840aq6mwk4x",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4i43rhh80w80840nx1dtv7r",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "4322510-img-8506.jpg",
          "5483873-img-8506-1.jpg"
        ]
      },
      {
        "sku": "204",
        "description": [
          {
            "id": "cl4o86bcbl3qb0840a2zsczg9",
            "title": "CCI-204"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4o8f72jl4qk08409gs4x0ew",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl4o8f72ql4qm0840onnlceso",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4o8f72tl4qn08403zhtb3dg",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4o8f734l4qr0840681mrzcg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl4o8f738l4qt08403vyfvrlx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4o8f73al4qu0840sih63t3n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4o8f73kl4qy0840pxgt45gn",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl4o8f73nl4r00840t5vnk4pb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4o8f73pl4r10840hhucbtj2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ts4krpw60008400t3m3pai",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4o86bc5l3qa08400umdc901",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1655819462192-20220621_164910.jpg"
        ]
      },
      {
        "sku": "088",
        "description": [
          {
            "id": "cl4o87k45l3te0840p8r14625",
            "title": "CCI-088"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4o87ut6l3wg0840yno00nuz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl4o87utal3wi08408y8uyslh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4o87utel3wj0840wfj8370k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4o87uttl3wn0840le5l2ac5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl4o87uu7l3wp0840z5ddrw78",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4o87uual3wq0840flzuytor",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4o87uukl3wu084019h9gazf",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl4o87uuml3ww08401urc3q4o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4o87uuol3wx0840qkzvn2wy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl4o87k42l3td0840no9v5q86",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1655819508591-20220621_165041.jpg"
        ]
      },
      {
        "sku": "1334",
        "description": [
          {
            "id": "cl4sdp5kdti5i08400ir8dpc5",
            "title": "Alesta Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6cah3by96ex0840a87nr3pz",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl6cah3c596ez084095ssg4hh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6cah3c996f0084083x6mp99",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6cah3cm96f40840xgf46vgi",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl6cah3cp96f60840p50kyho4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6cah3cr96f70840d87fbsd1",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6cah3cz96fb08402n7fr7ko",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl6cah3d196fd0840uxcp5m52",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6cah3d396fe08405ewsihzk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycclkz548808403mx9q7pk",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ycglne54jk0840cmfdmd98",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4sdp5k6ti5h08405463c86a",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424157324-1334.jpg",
          "1656424229648-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1335",
        "description": [
          {
            "id": "cl4sdr66ftif40840oafznv6c",
            "title": "Vira Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4y89q1u4loh0840qvc8plwe",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl4y89q234loj08403x2v7jlu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y89q274lok0840shqyw9pt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4y89q2j4loo0840ppyj3l8x",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl4y89q2m4loq0840nf83yjlh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y89q2o4lor0840gi3gdn0t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4y89q2u4lov08400kzh42rj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl4y89q2w4lox084069io12r9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y89q2y4loy0840dbu3t7lh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4yccr2t548k0840zi2vishj",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ycgqlr54jw0840h98v54du",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4sdr66atif30840o58f6tlx",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424274483-IMG_5103.jpg",
          "1656424292326-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1336",
        "description": [
          {
            "id": "cl4sdu1vftin10840093mx1gq",
            "title": "Arya Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54e6mz3it1p0840s03wqvx2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54e6mz9it1r0840vr7da9c9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e6mzeit1s0840k89gqbdt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e6mzsit1w0840qaw48axc",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54e6mzvit1y0840ou6v8aqp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e6mzzit1z0840tv9nsk8l",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e6n0dit23084069747pah",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54e6n0hit250840nny672g8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e6n0jit260840tpu4fe3q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycczso548z0840pufn2kto",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ycguor54k708400ht5r172",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4sdu1vbtin00840dnp80zij",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424380591-IMG_5109.jpg",
          "1656424395876-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1337",
        "description": [
          {
            "id": "cl4sdwrt3tizc0840zgs226zz",
            "title": "Avara Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4y8dcwn4mec0840gt13h8gg",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl4y8dcwr4mee0840jouuj0az",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y8dcwu4mef0840hn3jvuel",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4y8dcx44mej0840axpezqf9",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl4y8dcx64mel08408vhmd17t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y8dcx84mem0840o6qt7typ",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4y8dcxg4meq0840wrzc4oc8",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl4y8dcxi4mes0840kh7oqb6f",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y8dcxj4met0840vbwonz0j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycdihv54aw08404xqz1f1h",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ycgyz954kh0840nrtdgj9a",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4sdwrsytizb08408cthes2d",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424477218-IMG_5102.jpg",
          "1656424480287-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1338",
        "description": [
          {
            "id": "cl4sdyjz9tj8l08404ggga3re",
            "title": "Bosa Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54epj3sixvz0840rqh90kmo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54epj3xixw108403qm0j1yl",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54epj3zixw20840qkc2bsgp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54epj4aixw60840dszmbl35",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54epj4cixw808407ke9jaxz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54epj4eixw908401k0l7yt0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54epj4lixwd0840cnlww87t",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54epj4mixwf0840w9p43588",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54epj4oixwg0840yefz7epb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycdo5654bm0840s8uugi5n",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ych40j54ks0840ch47jskr",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4sdyjz0tj8k0840xefu4mee",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424515084-IMG_5108.jpg",
          "1656424523341-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1339",
        "description": [
          {
            "id": "cl4se0a48tjho0840c8p61awl",
            "title": "Camadan Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54e6xjbit790840qfmfazdc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54e6xjhit7b0840ofendnf0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e6xjlit7c0840cmz2jzqo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e6xjxit7g084029gwb4rg",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54e6xk0it7i0840gnatn3o7",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e6xk2it7j084018htk4sr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54e6xkbit7n0840u9cqynpz",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54e6xkdit7p0840clgoy5em",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54e6xkfit7q0840vlpz6j2f",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycdu8o54bx08409jaxbc6z",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ych9q754l508402kypwj1c",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4se0a43tjhn0840e6324hgr",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424615701-1339.jpg",
          "1656424616018-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1340",
        "description": [
          {
            "id": "cl4se17bitjok0840elcc4p7t",
            "title": "Flama Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4y8vgq74onz0840c3jgb7ez",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl4y8vgqb4oo10840fuuagz1y",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y8vgqe4oo20840sst94kss",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4y8vgqr4oo60840lpl49xwk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl4y8vgqw4oo80840pnmve36p",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y8vgqz4oo90840ayj050wk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl4y8vgrc4ood0840g4r4hb06",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl4y8vgre4oof0840omxc2ji6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4y8vgrh4oog0840mvlix9n6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4yce03i54ce0840kg7fvxrl",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ychc9z54lf0840x71ba7no",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4se17bctjoj0840gqe5i12s",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656425318377-1340.jpg",
          "1656425324442-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1341",
        "description": [
          {
            "id": "cl4se9f3qtlib0840sko1sehz",
            "title": "Genova Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54eq4efiy3r0840nfcop8vt",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54eq4ekiy3t0840touy8iwb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eq4eniy3u0840rwr2g3hr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54eq4f1iy3y0840cdps53cm",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54eq4f5iy4008400zesgb2x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eq4f7iy41084081bavixq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54eq4fiiy450840kf0rlw1h",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54eq4fkiy470840zrhrawwv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eq4fmiy480840n4iqla5b",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4yce6r254ek0840bjpedapo",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ychf9454lp08408qt9raor",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4se9f3ktlia08403cfug945",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424834067-IMG_5101.jpg",
          "1656424843064-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1342",
        "description": [
          {
            "id": "cl4seakl1tloq0840g8c193fi",
            "title": "Neta Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54dvtsqir0k084043d1ihst",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54dvtsvir0m0840ag4m5985",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dvtsyir0n0840xhhd8pq0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dvttair0r0840jwgzkm7a",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54dvttdir0t08404m4sjbms",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dvttfir0u0840t4b5kmir",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dvttpir0y0840hhctuczj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54dvttrir100840480qum9g",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dvttsir110840ey4gwwe6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycfokt54i40840r94wc0b1",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ychhr854m0084007jqwrpx",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4seakkxtlop0840xxucagei",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424914090-1342.jpg",
          "1656424915570-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1343",
        "description": [
          {
            "id": "cl4sedcvrtlyx084068ord76h",
            "title": "Limbo Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54eptczixzu08402jfk4o4k",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54eptdcixzw0840kpj6bmcx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eptdfixzx08407gpfyswx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54eptdmiy0108408egfmlq0",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54eptdoiy0308405luzk9yc",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54eptdqiy040840my4antaw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54eptdyiy080840i6g1nsg6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54epte2iy0a0840y4tu9lev",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54epte4iy0b0840fvdxoi8u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycftk354ie0840kgja9jmp",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ychl1l54mb0840ku8grfbt",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4sedcvntlyw084086t33kef",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656424981458-1343.jpg",
          "1656424982359-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1344",
        "description": [
          {
            "id": "cl4seegqytm4x0840y43nwgvf",
            "title": "Roda Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5wd7t036jy80840tt2u2lki",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5wd7t096jya0840c0hj44mw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wd7t0d6jyb0840elm9w39t",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wd7t0n6jyf0840plm7jq2q",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5wd7t0q6jyh0840zi39vcuq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wd7t0s6jyi08403y2kxa98",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5wd7t146jym08409l9aocby",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5wd7t176jyo0840fc0efugw",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5wd7t196jyp0840q1em6y6h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycfyh854ip084004s4jm9a",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ychor954ml0840fv9fc0y2",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4seegqstm4w0840twxycqwa",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656425035119-1344.jpg",
          "1656425035821-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1346",
        "description": [
          {
            "id": "cl4tqiaejvy3n0840a0j0q8zb",
            "title": "Savlo Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl54dwi57ir8p08402pdsi34t",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl54dwi5bir8r0840bpp8qvts",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dwi5dir8s08401ps7dpc4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dwi5nir8w08409kra67ag",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl54dwi5pir8y0840ml37ujhm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dwi5rir8z0840928m6qkl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl54dwi5yir930840tyjm2vb7",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl54dwi60ir950840evmwqsl6",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl54dwi61ir960840nr2b3zef",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4ycg26254iz0840bsdpe8d2",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4ychs9s54n108408467n1hl",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4sefx69tmc0084065cjsjzp",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "1656425091996-1346.jpg",
          "1656425093423-IMG_5116.jpg"
        ]
      },
      {
        "sku": "1345",
        "description": [
          {
            "id": "cl4seggyctmhw08400hihclwo",
            "title": "test ürünü"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4segt9xtml3084076nl6yyu",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl4segta1tml50840s60tgvez",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4segta3tml60840tp25c9m6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl4seggy9tmhv0840pgn1tu2b",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "1347",
        "description": [
          {
            "id": "cl4skfridu63c0840kctbwcn2",
            "title": "1347"
          }
        ],
        "sizePrices": [
          {
            "id": "cl4skg7jbu65m08404ez2fhwm",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl4skg7jlu65o0840fert9cyd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl4skg7jqu65p0840ezx5r0bq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl4skgkbmu66z0840dfsetdom",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl4skgvstu67b0840lstib4ru",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl4skfri5u63b0840ntwbb0dz",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1656081903261-30e7ca13-38a1-472a-a216-ccb19067bc8a.jpg"
        ]
      },
      {
        "sku": "1348",
        "description": [
          {
            "id": "cl52d38ope5nh0840gkk82t98",
            "title": "1348"
          }
        ],
        "sizePrices": [
          {
            "id": "cl52d4vbte5rx0840ydgj214d",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl52d4vbxe5rz0840k1znrl9u",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl52d4vc0e5s00840oq9jb11d",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl52d4566e5pq0840o1n93qlw",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl52d4eaye5q20840zq4zprcl",
            "price": 106,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl52d38oie5ng0840rcq4pnh5",
        "category": {
          "id": "ckt9yzazgovfo0859a9p8yqzr",
          "sku": "CCH"
        },
        "photos": [
          "1656674269797-337f3a09-b0ba-449d-91fc-8f059f950cd0.jpg"
        ]
      },
      {
        "sku": "1349",
        "description": [
          {
            "id": "cl52d9ulge61l0840kq3238kl",
            "title": "1349"
          }
        ],
        "sizePrices": [
          {
            "id": "cl58aswwnr7gi0840g8cp9tqc",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl58aswwqr7gk0840eo8uqbvk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58aswwsr7gl0840vazqddyo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58aswwyr7gp0840aonfozb6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl58aswx0r7gr0840x4pvu749",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58aswx1r7gs0840ji2qluyp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58aswx7r7gw0840cgzw6u5g",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl58aswx9r7gy08401zmvo50v",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58aswxar7gz0840o8omulqk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl52dd6u8e6et0840rnx8uyen",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl52ddq3ae6i40840djkd22k3",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl52d9ulae61k08403fq68qzn",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "1656674569196-b7d551c0-cc70-4adb-aa22-3a2d3c5fcabf.jpg"
        ]
      },
      {
        "sku": "1350",
        "description": [
          {
            "id": "cl52dkv8ue7f708403tj1ko4z",
            "title": "1350"
          }
        ],
        "sizePrices": [
          {
            "id": "cl52dp86we8co0840dri2gv0k",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl52dp871e8cq084061owrtck",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl52dp873e8cr0840tdwbpeba",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl52dp87ge8cv0840dxu649kf",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl52dp87ie8cx08400684du46",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl52dp87ke8cy0840iscbskn6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl52dp87ue8d20840j8rf281q",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl52dp87we8d40840mz1qtrkk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl52dp87ye8d50840m51n9z7z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl52dmrk9e7vz0840sotvc9yp",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl52dom9ge87q08401a9wxc98",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl52dkv8oe7f60840bvpbkgjg",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "1656675055357-a78fa839-8214-493e-8f43-db0fb28dcb16.jpg"
        ]
      },
      {
        "sku": "1351",
        "description": [
          {
            "id": "cl52dqh63e8o60840j8oivemx",
            "title": "1351"
          }
        ],
        "sizePrices": [
          {
            "id": "cl58ascvhr7cb0840ki7mxb64",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl58ascvmr7cd0840lllzumax",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58ascvpr7ce08403sqkfy6x",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58ascvzr7ci0840y7xk0fat",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl58ascw0r7ck0840q2ryb0z3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58ascw2r7cl0840sm8ltwfv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58ascw7r7cp0840mpfsyevv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl58ascw8r7cr0840wkxiag5c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58ascw9r7cs08401eh2jzxh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl52drsdke8yo0840862phi2r",
            "price": 60,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl52ds5nxe91908402ong450h",
            "price": 168,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl52dqh5ze8o50840mg3x7unj",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "1656675350340-53de0033-baaf-44cb-b83a-61f77efcfcd0.jpg"
        ]
      },
      {
        "sku": "1352",
        "description": [
          {
            "id": "cl56n2botmziz0840iu4ea7f0",
            "title": "1352"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56n3epqmzo20840jpp0hqat",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl56n3epwmzo408409fq6jcup",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56n3eq0mzo508402u9b15ce",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl56n2bonmziy0840vpy81vku",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1656932835139-20220704_140638.jpg"
        ]
      },
      {
        "sku": "1353",
        "description": [
          {
            "id": "cl56n85i8mzzj0840s03moqh7",
            "title": "1353"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56n8xs8n01z0840ka9htv2q",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl56n8xsen02108409g9tehe3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56n8xshn02208409viqlhvw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl56n85i4mzzi0840qu06msik",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1656933062910-20220704_141047.jpg"
        ]
      },
      {
        "sku": "1354",
        "description": [
          {
            "id": "cl56ncubzn0av0840mle3tl84",
            "title": "1354"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56ndaogn0db0840x4dh9s7n",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl56ndaomn0dd08404ls689og",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56ndaorn0de0840jokibdo6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl56ncubtn0au0840p2x085gk",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1656933328029-20220704_141504.jpg"
        ]
      },
      {
        "sku": "1355",
        "description": [
          {
            "id": "cl56nj67tn0u108404614ot46",
            "title": "1355"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56nkkyjn0y50840knogeofe",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl56nkkypn0y70840jyfod9f0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56nkkysn0y80840b2tm46lv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl56nj67ln0u00840g7yocfw1",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "1656933621326-20220704_141858.jpg"
        ]
      },
      {
        "sku": "1356",
        "description": [
          {
            "id": "cl56no4wsn18608407gtpzapv",
            "title": "1356"
          }
        ],
        "sizePrices": [
          {
            "id": "cl56nopffn1az0840b20yyko1",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl56nopfjn1b10840rgin2npj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl56nopfmn1b2084042l87ao2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl56no4won1850840v4meuwcd",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "1656933903829-20220704_142432.jpg"
        ]
      },
      {
        "sku": "1357",
        "description": [
          {
            "id": "cl58aeht5r5w008401196g809",
            "title": "1357"
          }
        ],
        "sizePrices": [
          {
            "id": "cl58af7imr5zs08400vwo614s",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl58af7ipr5zu0840g43r8wd3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58af7isr5zv08404d8w567g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58af7izr5zz0840o71nrsj6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl58af7j1r6010840efziw1ue",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58af7j2r6020840a81a5sng",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58af7j8r6060840plp2r8o2",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl58af7j9r6080840jqe01ehg",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58af7jar60908401uxz4eye",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl58aeht0r5vz0840lc9swo9o",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "5260739-20220705-174800.jpg"
        ]
      },
      {
        "sku": "1358",
        "description": [
          {
            "id": "cl58agbgzr65d0840moq1l9fo",
            "title": "1358"
          }
        ],
        "sizePrices": [
          {
            "id": "cl58agpjdr69j0840zlw9l9zr",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl58agpjhr69l084019xfm3ao",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58agpjjr69m0840k6n3zn7v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58agpjqr69q0840n7bd4yog",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl58agpjsr69s0840luh2e7h8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58agpjur69t084005cyu962",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58agpjzr69x0840yk7zmezq",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl58agpk0r69z0840lymj34s2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58agpk2r6a00840j5zfkluz",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl58agbgvr65c0840ulxzy927",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "6228583-20220705-174814.jpg"
        ]
      },
      {
        "sku": "1359",
        "description": [
          {
            "id": "cl58ahmnqr6ep0840gs5o0sqr",
            "title": "1359"
          }
        ],
        "sizePrices": [
          {
            "id": "cl58ai4gcr6i10840z0bpsvjv",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl58ai4gfr6i30840r5frt2mq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58ai4ghr6i40840xso34pdo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58ai4gnr6i80840n1p6g0lk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl58ai4gqr6ib08402sxq63w2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58ai4grr6ic0840a8uvy5mr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl58ai4gxr6ig0840mmu86oxc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl58ai4gzr6ii0840ic9cg0xd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl58ai4h0r6ij084007aaurgx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl58ahmnlr6eo0840n6woewwd",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "7643121-20220705-174838.jpg"
        ]
      },
      {
        "sku": "1360",
        "description": [
          {
            "id": "cl58c7fuarb9x084017w2o3eu",
            "title": "1360"
          }
        ],
        "sizePrices": [
          {
            "id": "cl59c1ib6t7jq0840m6qsxxkn",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl59c1ibbt7js0840n5twy8e3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c1ibet7jt0840o4idpdwi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59c1ibrt7jx08405547i662",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl59c1ibtt7jz0840u4b10exr",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c1ibvt7k00840ch4q1adu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl58c7fu5rb9w0840jzk53t2j",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "2691599-20220705-183718.jpg"
        ]
      },
      {
        "sku": "1361",
        "description": [
          {
            "id": "cl58cjo6trclt0840lqendgoj",
            "title": "1361"
          }
        ],
        "sizePrices": [
          {
            "id": "cl59c3ce4t7pj08405n7b6cyg",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl59c3ce7t7pl0840wlbgq4gd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c3ce9t7pm0840oycucaa9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59c3cegt7pq0840pqnidpj4",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl59c3ceit7ps0840a8gngvwk",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c3cekt7pt0840ng93ucfm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl58cjo6orcls084097jtkhv2",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "2379837-20220705-183804.jpg"
        ]
      },
      {
        "sku": "1362",
        "description": [
          {
            "id": "cl58cn1hzrd280840u63vssk1",
            "title": "1362"
          }
        ],
        "sizePrices": [
          {
            "id": "cl59c3zvit7u50840pr0n670s",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl59c3zvmt7u70840b976sf3e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c3zvot7u8084059uicya3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59c3zvyt7uc08406tv6ko6s",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl59c3zw0t7ue08403bbsa9n9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c3zw2t7uf08405bu1tobc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl58cn1hvrd270840blxpucpb",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "6959-20220705-183824.jpg"
        ]
      },
      {
        "sku": "1363",
        "description": [
          {
            "id": "cl58cp9onrdbq0840wr21i0eg",
            "title": "1363"
          }
        ],
        "sizePrices": [
          {
            "id": "cl59c4miit80g0840m9ict4s9",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl59c4mint80i0840i66xq2za",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c4miqt80j08402z2y7f13",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59c4mj0t80n0840h2qyyn9u",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl59c4mj2t80p0840d35qs21w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c4mj4t80q084042sstjmy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl58cp9oirdbp0840dqno8flo",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "6331013-20220705-183846.jpg"
        ]
      },
      {
        "sku": "1364",
        "description": [
          {
            "id": "cl58cto87rdny0840a1nf2ew3",
            "title": "1364"
          }
        ],
        "sizePrices": [
          {
            "id": "cl59c5dwkt89k0840l3o2efqn",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl59c5dwqt89m08407is004m2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c5dwst89n0840pnrafk3a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59c5dx2t89r084012a38bag",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl59c5dx4t89t08401uzgu60t",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59c5dx5t89u0840phhvkd3r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl58cto81rdnx08406mc6uh9l",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "9989255-20220705-183905.jpg"
        ]
      },
      {
        "sku": "1365",
        "description": [
          {
            "id": "cl59f4lgitir70840lou7faps",
            "title": "1365"
          }
        ],
        "sizePrices": [
          {
            "id": "cl59f6icvtj280840mxupmeu4",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl59f6icztj2a0840xu1240nb",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59f6id1tj2b08405i6xsbh6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59f6id9tj2f0840n7lmhlc8",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl59f6idbtj2h08403jitonka",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59f6idctj2i0840y27grppr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59f6iditj2m0840t9r9b35g",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl59f6idktj2o0840yjz2auif",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59f6idltj2p08403crk01fi",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl59f4lgetir60840i01m3ppg",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "490035-e2b060f7-867f-4cd0-b098-0cdb0cfcfe8c.jpg"
        ]
      },
      {
        "sku": "1366",
        "description": [
          {
            "id": "cl59f5sz2tiy50840lhlvg33e",
            "title": "1366"
          }
        ],
        "sizePrices": [
          {
            "id": "cl59f6oc8tj6308406spvulyo",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl59f6ocbtj650840h3t6gvef",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59f6ocdtj660840m6iaipru",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59f6ocltj6a0840jsseh07x",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl59f6ocntj6c0840s3x8galz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59f6ocotj6d0840r61wj28z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl59f6ocutj6h0840ant51atv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl59f6ocvtj6j08406uqbdb15",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl59f6ocwtj6k0840b4g9tbmu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl59f5syvtiy40840radg0brm",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "6663612-f492a06e-768a-4aeb-9110-bd0c933575b1.jpg"
        ]
      },
      {
        "sku": "1367",
        "description": [
          {
            "id": "cl5kp6yn4gko40840g2679fhx",
            "title": "1367"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5kp79pcgkre08406vvqtu80",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5kp79pggkrg0840dmppz6b5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kp79pigkrh0840gienmebh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5kp79pqgkrl0840mbrdqdrq",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5kp79psgkrn08408f41vww5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kp79pugkro0840535a3hkc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5kp79pzgkrs08404hk4x64r",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5kp79q1gkru0840ihoovvo1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kp79q2gkrv08402458enq7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl5kp6ymzgko30840wrm3zsqz",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "857367-270bef4d-9e7e-49f2-8dc4-20f7401fbd67.jpg"
        ]
      },
      {
        "sku": "1368",
        "description": [
          {
            "id": "cl5kp8ed3gkvc0840bu8h7e3s",
            "title": "1368"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5kpedf6gl600840qlxdt39j",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5kpedfcgl620840ixw6yml2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kpedffgl6308401hwysx7r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5kpedfsgl670840hvdk87xk",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5kpedfwgl690840pkzvlf6q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kpedfygl6a0840v87djsrn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5kpedgbgl6e08408gv0y858",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5kpedgfgl6g0840vxygibap",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kpedghgl6h08404zhzxnzl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl5kp8ecygkvb08405i0vcz6o",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "5672492-c2f74ea3-9405-426e-be13-428b73884569.jpg"
        ]
      },
      {
        "sku": "1369",
        "description": [
          {
            "id": "cl5kw78r0h8o20840hctrmdlz",
            "title": "1369"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5kw7thmh8sk08400qm34aqh",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl5kw7thuh8sm08409273b7eo",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kw7thxh8sn0840vx8346a9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5kw7ti8h8sr0840zkh4smyo",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl5kw7tiah8st0840os9sneqd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kw7tich8su0840olqqghsh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl5kw7tihh8sy08400cbq44oj",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl5kw7tikh8t0084046zfr34h",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kw7tilh8t10840ft0l0uip",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl5kw78qsh8o10840pbkjn89g",
        "category": {
          "id": "cl52d79jne5x80840ji9hnme5",
          "sku": "CBD"
        },
        "photos": [
          "1895550-38f830b9-72d8-40d9-a6e3-8cbab932bed0.jpg"
        ]
      },
      {
        "sku": "1370",
        "description": [
          {
            "id": "cl5kxpsixhges0840h8kryhmr",
            "title": "1370"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5kxq7tfhgh308400m8ybdwh",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl5kxq7tkhgh50840hpww87w5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5kxq7tohgh60840trv9d36r",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl5kxpsishger0840vx7jfke6",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "7561611-e1b1d46f-aad1-449c-87ac-a2b35abed396.jpg"
        ]
      },
      {
        "sku": "1371",
        "description": [
          {
            "id": "cl5qfcsjpssli08402d9toduy",
            "title": "1371"
          }
        ],
        "sizePrices": [
          {
            "id": "cl5qfdl2qssqh0840cvmpfqnp",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl5qfdl2ussqj0840fxw2a29e",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl5qfdl2wssqk0840o1g8nxgl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl5qfcsjmsslh0840utfjtox0",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "6429726-20220718-102540.jpg"
        ]
      },
      {
        "sku": "1372",
        "description": [
          {
            "id": "cl60mggiqgyr60840749a6jbt",
            "title": "1372"
          }
        ],
        "sizePrices": [
          {
            "id": "cl627ahbkl7c00840vbd3mc06",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl627ahbql7c20840xlt3ynsh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl627ahbtl7c308409wob8fp3",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl627ahc4l7c708402tlxmjfk",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl627ahc7l7c908404fm2va3x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl627ahcal7ca0840ebxr8dya",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl627ahcll7ce0840218dmvav",
            "size": {
              "id": "cknlpyrsn0n1m0993k6jhcfp5",
              "sku": "MP"
            },
            "prices": [
              {
                "id": "cl627ahcol7cg08400v5uexti",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl627ahcql7ch0840pteiqwcc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl627ahczl7cl0840hk6jbwno",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl627ahd1l7cn08403uuninh3",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl627ahd3l7co0840l6xy3q5o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl60mgyftgysu0840vaz0ocgn",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl60mh5g2gyt50840jpge4spg",
            "price": 154,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl60mggiigyr50840u83aty7k",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "9974064-20220725-134331.jpg"
        ]
      },
      {
        "sku": "1373",
        "description": [
          {
            "id": "cl63hnnwdo94j0840qlz28lt0",
            "title": "Renkli Bonibon Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6vtuxknncs208406rtrpaen",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl6vtuxktncs40840alzg77ls",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6vtuxkyncs50840x38w9hzh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6vtuxlcncs90840hsmd8q30",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl6vtuxlencsb0840degkouii",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6vtuxlgncsc0840dpjutvue",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl6vtuxlnncsg0840ug9pilqv",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl6vtuxlpncsi0840ij0yfrlv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6vtuxlrncsj0840y9ands1c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6th65r3hzjy0840cc46wi7j",
            "price": 118,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6th6jryhzkj08403x2upgbv",
            "price": 42,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl63hnnw7o94i08404aogjn5z",
        "category": {
          "id": "ckku4olo90itq09197biksde5",
          "sku": "CBM"
        },
        "photos": [
          "689107-img-8723.jpg",
          "6446441-img-8724.jpg"
        ]
      },
      {
        "sku": "1374",
        "description": [
          {
            "id": "cl66avzscutpi084089f1xyot",
            "title": "1374"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g5w8pi3zt0840su9dswxt",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g5w8wi3zv0840xc68v8ue",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g5w90i3zw0840xhtu8ppq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thw88ti30e08408rpckxgx",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thwei6i32n0840y06hz2lp",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66avzs4utph084054mvm55v",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "5355969-img-8457.jpg"
        ]
      },
      {
        "sku": "1375",
        "description": [
          {
            "id": "cl66axm5suubx08401yljapv1",
            "title": "1375"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g6fd3i42908409lh93l2d",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g6fd6i42b0840l12swi6o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g6fd7i42c0840tqgz0rul",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thxpovi3bu0840tou5qd4w",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thxww9i3c60840o7jcrtnz",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66axm5ouubw0840bn8b44a5",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "3131496-img-8458.jpg"
        ]
      },
      {
        "sku": "1376",
        "description": [
          {
            "id": "cl66ayjk2uul2084009tuo6v3",
            "title": "1376"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g6qjei44p08405x41y8e8",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g6qjki44r0840qteg8lst",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g6qjni44s0840mf53xeva",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thz02fi3hi0840b4bat09k",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thz5fai3hs08408uptadgi",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66ayjjzuul10840t1tf99j4",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "782004-img-8474.jpg"
        ]
      },
      {
        "sku": "1377",
        "description": [
          {
            "id": "cl66b0e1auuvc0840h6w0eo0p",
            "title": "1377"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g70gri4780840jd8q9hqg",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g70gui47a0840ju3t9c26",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g70gwi47b0840n5kzc40n",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6th8jcchzsy0840hs9ep0fm",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6th8rtghztb08404z9ft5mo",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66b0e18uuvb08409h5pk0wb",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1639175-img-8464.jpg",
          "1621580-1377-1.jpg"
        ]
      },
      {
        "sku": "1378",
        "description": [
          {
            "id": "cl66b2sfpuvjz0840h1rkicu2",
            "title": "1378"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g06rfi2sb0840y6qkz29w",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g06rki2sd0840652dg6xp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g06rni2se0840u1lf8rrw",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6ti0n4bi3mz0840z95aucom",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6ti0rbmi3n90840mt0pmtyp",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66b2sfluvjy08401urduyk6",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "8790498-img-8484.jpg"
        ]
      },
      {
        "sku": "1379",
        "description": [
          {
            "id": "cl66b3si4uvom08400qb0lpdp",
            "title": "1379"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g15bpi2vc0840xgd8n8ss",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g15bui2ve0840biisccx8",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g15bwi2vf08408t5jyjjm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6ti2szji3wp0840ozknfobl",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6ti2wa7i3x308400xccpunc",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66b3shyuvol0840bxx2eyee",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "4217599-1379.jpg"
        ]
      },
      {
        "sku": "1380",
        "description": [
          {
            "id": "cl66b64gnuvzm0840iaxriwp7",
            "title": "1380"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g1fdhi2xq0840282hnqzz",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g1fdli2xs0840tpkl7p49",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g1fdni2xt08401veb0eo2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6ti3n03i40s08409kjd0lr7",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6ti3qabi41208402gl7ld4m",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66b64giuvzl0840unfc112h",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "5179685-1380.jpg"
        ]
      },
      {
        "sku": "1381",
        "description": [
          {
            "id": "cl66b89xnuw3x08402gje8tiv",
            "title": "1381"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g1pnyi2zz0840zpeltibl",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g1po0i3010840btxjdg6l",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g1po2i30208406byuczw6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6ti4m7xi43p08405bsgylq5",
            "price": 50,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6ti4rb7i43z0840kjy0myic",
            "price": 18,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66b89xiuw3w0840g8mv4ls3",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "9876558-1381.jpg"
        ]
      },
      {
        "sku": "1382",
        "description": [
          {
            "id": "cl66kb31nvnv00840w9hgrhek",
            "title": "1382"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g2ahui3360840z5op11k4",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g2ahxi3380840ateds2mt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g2ahzi3390840c05xv0yo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thcd53i06l0840p5eojb23",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thcgp7i06y0840kl5esnv5",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66kb31gvnuz0840maingqjh",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "6028648-img-8466.jpg"
        ]
      },
      {
        "sku": "1383",
        "description": [
          {
            "id": "cl66ke1lvvo9x0840fmy0noo3",
            "title": "1383"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g2lu2i35f0840fxecn0po",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g2lu8i35h0840a3e1jl7d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g2lubi35i084057278ciy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thgimbi0io0840fjczjn0g",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thgmhri0jl0840mju2jold",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66ke1lrvo9w0840fl2jpu8w",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "6190962-1383.jpg",
          "837666-img-8488.jpg",
          "7252543-img-8496.jpg"
        ]
      },
      {
        "sku": "1384",
        "description": [
          {
            "id": "cl66kewumvohh0840su959xn3",
            "title": "1384"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g3dmqi3do0840uighn93l",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g3dmxi3dq08409lz0f324",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g3dn0i3dr0840cqzv9aeb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thgrb5i0kw0840wk75j1iw",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thgud4i0l60840j84q1cel",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66kewuivohg08405icey5hh",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "390618-img-8462.jpg",
          "825433-img-8491.jpg"
        ]
      },
      {
        "sku": "1385",
        "description": [
          {
            "id": "cl66kfszavomr0840lvwaiber",
            "title": "1385"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g3mmci3fx08407jkvy23u",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g3mmgi3fz0840mdn8hae9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g3mmii3g00840yc9mf2t4",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thidh2i0si0840db4ujae7",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thih0wi0st0840jv09dezr",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66kfsz7vomq0840d7an0kwm",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "4448989-img-8471.jpg"
        ]
      },
      {
        "sku": "1386",
        "description": [
          {
            "id": "cl66kgmdavoso0840intc5i61",
            "title": "1386"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g45fxi3jr0840nk9blwf2",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g45fzi3jt0840jn424sz0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g45g2i3ju0840ksmmdoah",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thk651i0y008407vdq7iwr",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thkcjki0ya08404jxoabh6",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66kgmd6vosn0840acr3tb1c",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "7070190-img-8478.jpg",
          "5695572-img-8485.jpg"
        ]
      },
      {
        "sku": "1387",
        "description": [
          {
            "id": "cl66khb8pvp0w0840qp7la9xh",
            "title": "1387"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g4e42i3m10840d3ysvu66",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g4e48i3m308407zyo2em1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g4e4bi3m408408k6ycf1j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thaq6vhzyp0840vztgy7e4",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thay0uhzyz0840iun2og0t",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66khb8ivp0v0840reof7xe3",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "1486564-img-8450.jpg",
          "4756123-img-8498.jpg"
        ]
      },
      {
        "sku": "1388",
        "description": [
          {
            "id": "cl66ki0d4vp7p08407wgubolo",
            "title": "1388"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g4mkci3o90840hv7apsfv",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g4mkhi3ob0840lezj565r",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g4mkji3oc0840dzdr6ohh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6tho0pfi17i08405g3t9j1y",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6tho3o3i17t0840eaweiwx3",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66ki0d0vp7o0840gzxi7siu",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "7504443-img-8470.jpg"
        ]
      },
      {
        "sku": "1389",
        "description": [
          {
            "id": "cl66kir46vpgj0840k4h4rir9",
            "title": "1389"
          }
        ],
        "sizePrices": [
          {
            "id": "cl77g4xfei3t50840p6fyjux0",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl77g4xfji3t70840pjeyj708",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl77g4xfmi3t808408xwo6q9m",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6thpjtwi1il0840g8d2u2p1",
            "price": 39,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6thpodoi1iv0840i30pkcry",
            "price": 14,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl66kir42vpgi0840jmo1s5an",
        "category": {
          "id": "ckm2el8y92h7r0897imbhgp7u",
          "sku": "CCP"
        },
        "photos": [
          "6074777-img-8469.jpg"
        ]
      },
      {
        "sku": "1390",
        "description": [
          {
            "id": "cl6cac2tt95u60840zmk1nmzu",
            "title": "1390"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6cacyum95y30840wwbkqj99",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl6cacyus95y50840ks7ovc25",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6cacyuv95y60840hhxuxa3i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl6cac2to95u50840avainpw1",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1158379-fdb0a8e3-7804-419f-93bb-4852a03e9ba0.jpg"
        ]
      },
      {
        "sku": "1391",
        "description": [
          {
            "id": "cl6w271ypo3ub0840kvyv60ik",
            "title": "Filli uç"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6w28pm8o42s0840mqqzmkaf",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl6w28pmco42u0840naqpy6hj",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6w28pmeo42v0840yw71prfd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl6w28cgto3zi0840hhjbcyz1",
            "price": 68,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl6w28i20o3zt08403t3uut92",
            "price": 24,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl6w271yho3ua0840oo5ha1j6",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "7704552-ce50bf98-a4ef-4291-be09-d66a155452ff.jpg"
        ]
      },
      {
        "sku": "1392",
        "description": [
          {
            "id": "cl6w2frwoo4yf0840vay56eqa",
            "title": "Gümüş Mineli Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6w2iu5co5tp0840huxzjogh",
            "size": {
              "id": "cknlpy0tr0n1609934onarj56",
              "sku": "SP"
            },
            "prices": [
              {
                "id": "cl6w2iu5io5tr0840nzx7taw5",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6w2iu5ko5ts0840lvqjc0wa",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl6w2frwio4ye0840q3zcdtyt",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "2573923-20220816-134724.jpg"
        ]
      },
      {
        "sku": "1393",
        "description": [
          {
            "id": "cl6w6el1monpv0840homvw02m",
            "title": "Çok taşlı uzun arap kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6w8qmbbp3oz0840r9thr05j",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl6w8qmbip3p10840qzpr2oz2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6w8qmblp3p20840o8uttrmx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl6w6el1gonpu0840gd9kzv1u",
        "category": {
          "id": "cl0i0c6d6q0na08596ioo36pb",
          "sku": "CNS"
        },
        "photos": [
          "2834952-20220816-160032.jpg"
        ]
      },
      {
        "sku": "1394",
        "description": [
          {
            "id": "cl6w6tstaopij0840fkc8bai5",
            "title": "taşlı yaprak küpe"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6w8req0p3rc084008j738eq",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl6w8req4p3re0840dzymno9n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6w8req6p3rf084074bddcqm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl6w6tst5opii0840h1ev42w3",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "9056321-20220816-155505.jpg"
        ]
      },
      {
        "sku": "1395",
        "description": [
          {
            "id": "cl6w6x3broq3d0840vcxvzf42",
            "title": "arap taşlı küpeler"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6w8ryn4p3tn0840zgndgbol",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl6w8ryn9p3tp0840nqwelxjn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6w8rynbp3tq0840jhiiy80g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl6w6x3bnoq3c0840mmdf6zan",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "3799872-20220816-155849.jpg"
        ]
      },
      {
        "sku": "344",
        "description": [
          {
            "id": "cl6ysw1a7uvzb0840l86kn22i",
            "title": "INCE DUZ EZIK YUZUK"
          }
        ],
        "sizePrices": [
          {
            "id": "cl6yswgvcuw1w084065wugjla",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl6yswgvkuw1y08400dxm0l7m",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl6yswgvouw1z0840itlbddal",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl6ysw1a1uvza08402d577iqb",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": []
      },
      {
        "sku": "368",
        "description": [
          {
            "id": "cl74hxzo3af2k084013r8ecnp",
            "title": "Yılan Yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl74hycm8af4z0840dyw2ohrf",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl74hycmeaf5108401crfi6vu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl74hycmhaf5208408f3guors",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl74hxznvaf2j0840x58ldzkp",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "4103844-20220822-112801.jpg"
        ]
      },
      {
        "sku": "1396",
        "description": [
          {
            "id": "cl74ibx5eafzz0840f3ethzd6",
            "title": "Yanı desenli yüzük"
          }
        ],
        "sizePrices": [
          {
            "id": "cl74icagvag2l08402w7jba0a",
            "size": {
              "id": "ckl6rwyor0o7s08198ydl5mgb",
              "sku": "RS"
            },
            "prices": [
              {
                "id": "cl74icagzag2n0840xlbel2gq",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl74icah2ag2o0840e0a5phrq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl74ibx58afzy0840n0gpztce",
        "category": {
          "id": "ckku4qzc80iui0919kad900d2",
          "sku": "CCR"
        },
        "photos": [
          "5925170-20220822-113734.jpg"
        ]
      },
      {
        "sku": "1397",
        "description": [
          {
            "id": "cl75xuehedxsq08407zez5kk6",
            "title": "Gümüş Zincir Kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl75xx46cdy260840u7v84y4z",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl75xx46idy280840f00kpklx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl75xx46ldy290840500coqe9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl75xueh9dxsp084044tplb2d",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "510148-20220823-114208.jpg"
        ]
      },
      {
        "sku": "1398",
        "description": [
          {
            "id": "cl7g1ecjc87ot0840y6d4kafo",
            "title": "CBS-1398"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8045i5oqob60840c4q7y8q2",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl8045i5tqob80840abhrv8po",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8045i5wqob90840f01zmy7z",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8045i67qobd0840y74fdcqh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl8045i6cqobf084023ay2mk2",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8045i6gqobg0840tqd7znny",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8045i6rqobk0840sxxkt1be",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl8045i6uqobm0840588tz8l0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8045i6wqobn0840rv1boigj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl8044z9cqo8q0840fcu6ljkk",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8cv02cltu3j0840aag4pzxd",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1ecj587os0840l0m3qnpw",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "2708928-img-0593.jpg"
        ]
      },
      {
        "sku": "1399",
        "description": [
          {
            "id": "cl7g1h3sk88lp0840aefwn81a",
            "title": "CBS-1399"
          }
        ],
        "sizePrices": [
          {
            "id": "cl804j0ktqpab0840dh2tzp1t",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl804j0kyqpad0840i1bmujgp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804j0l1qpae0840075tpgpc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804j0leqpai0840vl06lrct",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl804j0liqpak0840tlsqn3xx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804j0llqpal0840ga13n353",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804j0lwqpap0840979cxsxp",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl804j0m0qpar0840z9rjan0w",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804j0m3qpas0840ylpz5p47",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804a99tqoo80840jwq9u7er",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804ag6tqoon084000uvt7ir",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1h3s988lo0840y4mk8uvx",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "4767323-img-0613.jpg"
        ]
      },
      {
        "sku": "1400",
        "description": [
          {
            "id": "cl7g1is2r894f08404gfe0p1i",
            "title": "CBS-1400"
          }
        ],
        "sizePrices": [
          {
            "id": "cl804lceoqpka0840ogwzhv9l",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl804lceuqpkc084056zt5jf9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804lcexqpkd0840wt8jeg4k",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804lcf7qpkh08405jl4gomn",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl804lcfbqpkj08407g0etm3d",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804lcfdqpkk08406nwiiszh",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804lcfoqpko0840h733egj6",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl804lcfqqpkq0840zsdun7os",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804lcfsqpkr0840s85d3pqk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804jmobqpfj0840skd3a5v2",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804kz0lqphw0840imq7lv5w",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckkj017754u3m0919z9kpmm52",
              "code": "TRY"
            }
          }
        ],
        "id": "cl7g1is2o894e0840etk5tlhz",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "4109998-img-0605.jpg"
        ]
      },
      {
        "sku": "1401",
        "description": [
          {
            "id": "cl7g1kyts89tk0840xnpv10y0",
            "title": "CBS-1401"
          }
        ],
        "sizePrices": [
          {
            "id": "cl804n59jqps70840z5mwol41",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl804n59oqps90840en94200c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804n59sqpsa08407c5sf48o",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804n5a6qpse0840vsayedo4",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl804n5a8qpsg0840alr3h4ng",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804n5abqpsh0840n18heiwj",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804n5amqpsl0840pd9tyret",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl804n5apqpsn0840qnjh9uye",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804n5asqpso084061me4tne",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804meoaqpo80840674d5d6m",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804mno1qpoo0840dmpv37oj",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1kyto89tj0840nio3yayj",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "6102987-img-0595.jpg"
        ]
      },
      {
        "sku": "1402",
        "description": [
          {
            "id": "cl7g1ncui8a470840iwjuz6ld",
            "title": "CBS-1402"
          }
        ],
        "sizePrices": [
          {
            "id": "cl804pohyqq2108402x10iuhv",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl804poi2qq2308401jfon8p4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804poi4qq240840mt6bez43",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804poicqq280840qct62io6",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl804poigqq2a08408f43q9tm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804poiiqq2b0840luhhi8ol",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804poipqq2f0840i9kfryrc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl804poirqq2h0840m2ql88ni",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804poitqq2i0840xeb36243",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804o85bqpx40840w0h3t3vl",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804olijqpz60840zlijdepa",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1ncue8a46084010no65vl",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "8489098-img-0617.jpg"
        ]
      },
      {
        "sku": "1403",
        "description": [
          {
            "id": "cl7g1pjsp8ab20840reem5cxm",
            "title": "CBS-1403"
          }
        ],
        "sizePrices": [
          {
            "id": "cl804rmh2qq820840k7wyxt9v",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl804rmh7qq840840xd97zu2s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804rmhaqq850840yylwhqqd",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804rmhoqq89084023ndxojr",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl804rmhsqq8b0840qs0q7vma",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804rmhuqq8c0840u4mw07zq",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804rmi5qq8g0840q13vl2qm",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl804rmi8qq8i0840afefea12",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804rmibqq8j0840ntxy06a5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804qw0uqq5h084050xzbimt",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804r8n3qq5v0840h6n7ml9x",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1pjsl8ab10840k5yegk2e",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "6900477-img-0599.jpg"
        ]
      },
      {
        "sku": "1404",
        "description": [
          {
            "id": "cl7g1to9r8b060840760ja77z",
            "title": "CBS-1404"
          }
        ],
        "sizePrices": [
          {
            "id": "cl804tl7iqrph0840d6qeuzvj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl804tl7qqrpj0840luhmbxvf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804tl7uqrpk0840evrbpvnl",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804tl89qrpo08400hczhyoh",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl804tl8fqrpq08406e2u8yx1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804tl8kqrpr0840pfc56q3i",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804tl8xqrpv08406g89br4w",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl804tl8zqrpx0840ymsfrmxu",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804tl91qrpy0840hjbjzziy",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804stieqrhj0840whjsp6ul",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804t0obqrju0840xelmo50r",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1to9m8b050840pm6gx0wl",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "4189788-img-0623.jpg"
        ]
      },
      {
        "sku": "1405",
        "description": [
          {
            "id": "cl7g1vv1k8bco0840wzrbgfhu",
            "title": "CBS-1405"
          }
        ],
        "sizePrices": [
          {
            "id": "cl804v9qlqrzb0840y46mnw7z",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl804v9qoqrzd0840o5si7y4j",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804v9qrqrze0840vdlz33bo",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804v9r0qrzi08406oslekq9",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl804v9r4qrzk08408vuwevd1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804v9r6qrzl08405oj7g9af",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804v9rfqrzp08408irt0nmx",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl804v9rhqrzr0840l0i8p4vt",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804v9riqrzs0840wnnolole",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804ufcfqrv10840ew1gq6fh",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804uln0qrw20840wx27bf1c",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1vv1f8bcn0840w89s94gx",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "1739655-img-0619.jpg"
        ]
      },
      {
        "sku": "1406",
        "description": [
          {
            "id": "cl7g1x42k8bik0840o037m64f",
            "title": "CBS-1406"
          }
        ],
        "sizePrices": [
          {
            "id": "cl804y0hzqskf0840bn1p9y9o",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl804y0i4qskh0840kj1drllz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804y0i6qski0840dtxzeeg8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804y0ieqskm0840gsrdzn8e",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl804y0ifqsko0840vroio68c",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804y0ihqskp0840fcpz9epu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl804y0inqskt0840hrdymv0q",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl804y0ioqskv0840y59ypova",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl804y0ipqskw08403m1htxo2",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804w6g1qs9x0840bb261gz7",
            "price": 58,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804wfqnqsbh0840769wc789",
            "price": 162,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1x42g8bij0840x77l4dco",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "4520179-img-0609.jpg"
        ]
      },
      {
        "sku": "1407",
        "description": [
          {
            "id": "cl7g1ydau8bn50840x3nd6ce6",
            "title": "CBS-1407"
          }
        ],
        "sizePrices": [
          {
            "id": "cl80277jrqhro084013jwl4z5",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl80277jzqhrq0840s50vaziz",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl80277k1qhrr08408mqax97a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl80277kcqhrv0840t6jn2irx",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl80277kgqhrx0840n473qx9q",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl80277kiqhry0840b02ono1h",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl80277krqhs208403ln4xbwg",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl80277kuqhs40840rx9m98jp",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl80277kwqhs50840vze2xogb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl8024d5jqher0840ex5men01",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl80259muqhjx084088l9vr5u",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g1ydap8bn40840sjdjc54e",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "5594221-img-0603.jpg"
        ]
      },
      {
        "sku": "1408",
        "description": [
          {
            "id": "cl7g20k748box0840wbvbiehe",
            "title": "CBS-1408"
          }
        ],
        "sizePrices": [
          {
            "id": "cl803qmnwqmli084074r5azj9",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl803qmo3qmlk08407uhvymtf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803qmo5qmll0840axcm2dzn",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl803qmohqmlp0840iikaads3",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl803qmokqmlr0840t1l28p45",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803qmomqmls0840xlulek05",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl803qmouqmlw0840p5uxz0n3",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl803qmowqmly0840cli310kv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803qmoyqmlz08407x6pyvvm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl8028wk7qhun08401ek5mtzj",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8029e0eqhv60840dqxkpbgk",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g20k6y8bow0840c0c61za2",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "4379622-img-0607.jpg"
        ]
      },
      {
        "sku": "1409",
        "description": [
          {
            "id": "cl7g21n0i8bt8084075pc89rm",
            "title": "CBS-1409"
          }
        ],
        "sizePrices": [
          {
            "id": "cl805xjq8qwoa08406s2dnf3g",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl805xjqdqwoc084022whdz8x",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl805xjqgqwod08403jgyhkb6",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl805xjqwqwoh0840a4sfsdqi",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl805xjqzqwoj0840yr6qfvoi",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl805xjr2qwok08408ent0ucr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl805xjraqwoo0840ja2b76ft",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl805xjrcqwoq0840n40a39pm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl805xjreqwor0840gosvoch0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl805wlgwqwgu0840u058l2nu",
            "price": 68,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl805x78gqwlj0840d251g3m1",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g21n0b8bt70840ndkrfc0e",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "7880296-img-0621.jpg"
        ]
      },
      {
        "sku": "1410",
        "description": [
          {
            "id": "cl7g22ssv8bxp0840pjta2gxi",
            "title": "CBS-1410"
          }
        ],
        "sizePrices": [
          {
            "id": "cl803vbguqn4r0840v6tnnwkj",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl803vbh0qn4t084042m940cf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803vbh4qn4u0840n4mzk57a",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl803vbhjqn4y08405bv2esz7",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl803vbhnqn500840t6w1o8eh",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803vbhpqn510840efc9xd26",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl803vbi2qn550840pg6l8dct",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl803vbi7qn570840dfqhckhy",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803vbiaqn580840281fro9g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl803se4aqmqx0840q8j0gwvu",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl803skhtqmr908407567b0gf",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g22ssp8bxo0840y7utx912",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "8313931-img-0615.jpg"
        ]
      },
      {
        "sku": "1411",
        "description": [
          {
            "id": "cl7g23n5x8c4508406bbjaz7n",
            "title": "CBS-1411"
          }
        ],
        "sizePrices": [
          {
            "id": "cl803z4n4qnml0840jjmeq7yd",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl803z4n8qnmn0840qtj00e1k",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803z4naqnmo0840yfll4ipx",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl803z4nmqnms084038rbnfqp",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl803z4npqnmu08407x5pmc7s",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803z4nqqnmv0840ubdrfzm5",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl803z4nzqnmz0840f6xs8gwh",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl803z4o2qnn108405porn8ol",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl803z4o3qnn2084094ns60k0",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl803w3euqn930840acfodukj",
            "price": 64,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl803xuupqnhp0840by90qexz",
            "price": 179,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7g23n5u8c4408407kucc9aj",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "7140226-img-0626.jpg"
        ]
      },
      {
        "sku": "1412",
        "description": [
          {
            "id": "cl7oochxmwg1z0840li7t4vgl",
            "title": "CBS-1412"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8043a9yqnzc0840ogjcwv21",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl8043aa5qnze0840ua7vs8vd",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8043aa8qnzf0840tq2flfs9",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8043aaqqnzj0840z237khha",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl8043aavqnzl0840upsm6ezn",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8043aazqnzm0840s6ue0l1v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl8043abdqnzq0840unbf79gc",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl8043abgqnzs08406p7lmabx",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8043abiqnzt08403xo8agjp",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl8042efmqnw30840btnji1ci",
            "price": 190,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8cv0d1gtu480840pg2ul24m",
            "price": 55,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7oochxewg1y0840vakpkoyq",
        "category": {
          "id": "ckl54nd1e1aoe0919k06j02et",
          "sku": "CBS"
        },
        "photos": [
          "5335779-img-0630.jpg"
        ]
      },
      {
        "sku": "1413",
        "description": [
          {
            "id": "cl7oomfu7wh460840l951vmm3",
            "title": "1413"
          }
        ],
        "sizePrices": [],
        "price": [],
        "id": "cl7oomfu2wh4508401ni5d1em",
        "category": {
          "id": "ckkjtoepy4yh80919271691sn",
          "sku": "CCB"
        },
        "photos": [
          "2937623-20220905-142911.jpg"
        ]
      },
      {
        "sku": "1414",
        "description": [
          {
            "id": "cl7ooox1ewho608408th59kv2",
            "title": "CNE-1414"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8050capqsqw08405aplq20h",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8050cawqsqy0840ggu1fo1n",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8050cazqsqz0840dac0xs8v",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl804zplhqsow0840k21f8var",
            "price": 80,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl804zwuuqsp90840afizjui3",
            "price": 224,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7ooox1awho50840w77v1j7g",
        "category": {
          "id": "cl0i06wzjpz7h0859pl5i0mvk",
          "sku": "CNE"
        },
        "photos": [
          "262735-img-0638.jpg",
          "6876365-img-0640.jpg"
        ]
      },
      {
        "sku": "1415",
        "description": [
          {
            "id": "cl7ootgoawi4z0840vojferch",
            "title": "CES-1415"
          }
        ],
        "sizePrices": [
          {
            "id": "cl805rd1oqvf40840ajfw84yl",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl805rd1uqvf60840buwgzfe9",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl805rd1wqvf708404cf1neja",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl805qykpqvbd0840dt59qong",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl805r2u5qvc208405ufy3tqu",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7ootgo5wi4y0840c8xxc4my",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "1732403-img-0661.jpg"
        ]
      },
      {
        "sku": "1416",
        "description": [
          {
            "id": "cl7ooyu33wicj0840u1vah3c3",
            "title": "CES-1416"
          }
        ],
        "sizePrices": [
          {
            "id": "cl805hf98qu5j08404h5wjz9x",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl805hf9cqu5l08406kr0l6su",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl805hf9fqu5m08406yz6quqr",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl805h079qu410840glt1lcxx",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl805h48squ4c0840ieuv1sl8",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7ooyu2xwici08401siruxdu",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "8908601-img-0653.jpg"
        ]
      },
      {
        "sku": "1417",
        "description": [
          {
            "id": "cl7op1lfiwimk0840lmkfz28z",
            "title": "CES-1417"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8056rcyqt970840v7u21va5",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8056rd5qt9908409jbr92fm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8056rd9qt9a0840mvecazms",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl805675mqt7j0840r8uka5ok",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8056cc0qt7u0840i50kbfyt",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7op1lfcwimj0840vk80muj4",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "3560412-img-0648.jpg"
        ]
      },
      {
        "sku": "1418",
        "description": [
          {
            "id": "cl7op44ejwjcl0840xjnoyw30",
            "title": "CES-1418"
          }
        ],
        "sizePrices": [
          {
            "id": "cl805cs8cqtrn0840s5e0treu",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl805cs8hqtrp0840l3y9esfm",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl805cs8jqtrq0840gw3qw8jv",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl805c9qgqtpo0840omim18x1",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl805cdzeqtq00840k64th5nt",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7op44ecwjck08406bdy8c8z",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "6396633-img-0649.jpg"
        ]
      },
      {
        "sku": "1419",
        "description": [
          {
            "id": "cl7op60m1wjjn0840gu6uht1v",
            "title": "CES-1419"
          }
        ],
        "sizePrices": [
          {
            "id": "cl805fnidqtwr0840kgl404or",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl805fniiqtwt0840t1vbk86b",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl805fnilqtwu0840qz90ndhf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl805f46bqtuy0840xksbjetx",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl805f8g6qtvf0840ne7vjcoo",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7op60lwwjjm0840l9g8sluu",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "5891465-img-0652.jpg"
        ]
      },
      {
        "sku": "1420",
        "description": [
          {
            "id": "cl7opbzy3wkhu0840tlwh9fk1",
            "title": "CES-1420"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8052d34qsww0840gpv9y3zf",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8052d39qswy0840vu9cj0lf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8052d3cqswz0840zg0bnjcm",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl8059b1lqti30840p1k0r7lh",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8059doiqtih084022gwu0ts",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7opbzxxwkht08402qgfqxsb",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "9825016-img-0643.jpg"
        ]
      },
      {
        "sku": "1421",
        "description": [
          {
            "id": "cl7opfzpiwl3e0840477on8yj",
            "title": "CES-1421"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8054lwpqt2908404cinxwvz",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8054lwzqt2b0840nmte2oqe",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8054lx2qt2c0840dy67q4eu",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl80576fmqtav08401q6fncww",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl8057aitqtcu0840h328iia5",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7opfzpbwl3d0840dr1nz1zl",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "4210735-img-0645.jpg"
        ]
      },
      {
        "sku": "1422",
        "description": [
          {
            "id": "cl7opizmuwldd0840pgs6yii7",
            "title": "CES-1422"
          }
        ],
        "sizePrices": [
          {
            "id": "cl805pum3qv3a0840qo1v1140",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl805pumaqv3c0840v49e1vst",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl805pumdqv3d0840zpu1ocur",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl805ovykquzc0840x6mrf6o3",
            "price": 72,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          },
          {
            "id": "cl805p03pquzt0840ucir1f13",
            "price": 202,
            "priceType": "FOREIGN",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl7opizmpwldc0840b9wv8uf3",
        "category": {
          "id": "cl0i5spvss8tv0859ini5ahc5",
          "sku": "CES"
        },
        "photos": [
          "4937667-img-0660.jpg"
        ]
      },
      {
        "sku": "1451",
        "description": [
          {
            "id": "cl7ua7lhu9ul708402u1oojop",
            "title": "1451"
          }
        ],
        "sizePrices": [
          {
            "id": "cl7ua7x049upt0840pc83t1cn",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl7ua7x0g9upv0840pwdopky1",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl7ua7x0j9upw0840puaqq5tt",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl7ua7lhp9ul608407lyvw92j",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "2873639-f11d1f94-2739-463b-8e41-29325df79371.jpg"
        ]
      },
      {
        "sku": "1423",
        "description": [
          {
            "id": "cl81mllafv44h0840xwe23fq2",
            "title": "Taşlı İpli Bileklik"
          }
        ],
        "sizePrices": [
          {
            "id": "cl81mn91qv4k908403r5zovwb",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl81mn91xv4kb0840gulfxd99",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl81mn922v4kc0840kx9y61cb",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl81mn92hv4kg0840t6m5rht2",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl81mn92kv4ki0840rq1ragby",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl81mn92mv4kj08402s3xzc42",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl81mn930v4kn0840ibyy91yb",
            "size": {
              "id": "ckkjugp1z4yxj0919i63f0fju",
              "sku": "L"
            },
            "prices": [
              {
                "id": "cl81mn935v4kp0840indqq8dv",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl81mn939v4kq0840fa590tce",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl81mlla4v44g08405f6shy18",
        "category": {
          "id": "cl0j71xqkvcgh08593k1o1ms7",
          "sku": "CCI"
        },
        "photos": [
          "9208878-d3c6e395-786b-4334-bf1d-9917c37c1067.jpg"
        ]
      },
      {
        "sku": "1424",
        "description": [
          {
            "id": "cl82uri84z6a308400lxm9y6f",
            "title": "Kırılgan kolye"
          }
        ],
        "sizePrices": [
          {
            "id": "cl83338m90smd0840me8pjzx5",
            "size": {
              "id": "ckkjugm0m4yxa0919sefzmq9r",
              "sku": "M"
            },
            "prices": [
              {
                "id": "cl83338mf0smf0840gwjrv7py",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl83338mj0smg0840dqjmq8sj",
                "price": 8,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          },
          {
            "id": "cl83338mu0smk08409ob6fyk0",
            "size": {
              "id": "ckkjugiix4yx1091961cinw2c",
              "sku": "S"
            },
            "prices": [
              {
                "id": "cl83338mx0smm0840fztx52s4",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl83338n00smn0840v6feguyc",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [
          {
            "id": "cl833328a0sjf0840icbazh7h",
            "price": 38,
            "priceType": "WHOLESALE",
            "currency": {
              "id": "ckl479yd812xx0919ws8gh4qk",
              "code": "EUR"
            }
          }
        ],
        "id": "cl82uri7yz6a20840sadgxr0h",
        "category": {
          "id": "ckkjtoi444yhh0919hfslxd25",
          "sku": "CCN"
        },
        "photos": [
          "1560220-20220915-122927.jpg"
        ]
      },
      {
        "sku": "1452",
        "description": [
          {
            "id": "cl8a014imlbas0840xp1d9g7s",
            "title": "1452"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8a022kllbey0840te2xa2gb",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8a022krlbf008405d35pbxs",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8a022ktlbf10840zpox2u3j",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8a014ielbar0840atbhhvuy",
        "category": {
          "id": "ckl54r78m1arc0919m9j9jbjb",
          "sku": "CBC"
        },
        "photos": [
          "4715904-whatsapp-image-2022-09-09-at-10.jpg"
        ]
      },
      {
        "sku": "1454",
        "description": [
          {
            "id": "cl8nsj3f3nikv0840y301je05",
            "title": "1454"
          }
        ],
        "sizePrices": [],
        "price": [],
        "id": "cl8nsj3etniku0840oobhtt68",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "3277894-77684c95-caf5-45f7-b273-ee3f221a2f42.jpg"
        ]
      },
      {
        "sku": "1455",
        "description": [
          {
            "id": "cl8nsjt3wnilx08407g7l0v0d",
            "title": "1455"
          }
        ],
        "sizePrices": [],
        "price": [],
        "id": "cl8nsjt3qnilw08401axztcaq",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "151155-35188800-3ddf-4662-962d-58f188dc222f.jpg"
        ]
      },
      {
        "sku": "1456",
        "description": [
          {
            "id": "cl8nskedsnin008401btofdk4",
            "title": "1456"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nssk12nj3y0840ud6wmehm",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nssk18nj4008407ayczb31",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nssk1anj4108405zxwneib",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nskednnimz08407le4zwoj",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "6695175-3eaac403-50bd-40bd-90b5-b6d9f961a4d9.jpg"
        ]
      },
      {
        "sku": "1457",
        "description": [
          {
            "id": "cl8nsl0k9nio20840ucqmk97q",
            "title": "1457"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nssmgznj5z0840trbvum5w",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nssmh3nj610840exi66qaf",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nssmh6nj620840vcmgyw7g",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nsl0k2nio10840ybbyd080",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "3006624-1ded0738-7220-46f0-a06e-56f0a435e586.jpg"
        ]
      },
      {
        "sku": "1458",
        "description": [
          {
            "id": "cl8nsljsznip50840nbkck8v7",
            "title": "1458"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nssqbvnj800840uampkajl",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nssqc1nj8208407fu7640z",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nssqc5nj830840688b88bk",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nsljsunip40840mcx2nu99",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "990980-9b123e7f-b62f-41ee-8ba7-3507d9f5de03.jpg"
        ]
      },
      {
        "sku": "1459",
        "description": [
          {
            "id": "cl8nsmqaknirp0840gu9pxsuf",
            "title": "1459"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nst5nznjbs08405tvx4vhr",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nst5o3njbu0840gnkctb40",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nst5o5njbv0840lhac338u",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nsmqagniro08402xsb3u47",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "5982141-3141e869-72dc-4427-897d-df5196ed7b96.jpg"
        ]
      },
      {
        "sku": "1460",
        "description": [
          {
            "id": "cl8nsnbppnist0840dkwjdkcr",
            "title": "1460"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nst7jznjds0840lqzaz6wg",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nst7k6njdu0840scfe0865",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nst7k9njdv0840aanzl9m8",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nsnbpkniss08404xssfrcs",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "1490737-8a32c980-4ed6-4300-acd0-c7dcc21fd19f.jpg"
        ]
      },
      {
        "sku": "1461",
        "description": [
          {
            "id": "cl8nsocadnitz0840zjaucfzu",
            "title": "1461"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nstaspnjfs084003vksoeb",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nstaswnjfu0840msrcds5a",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nstasznjfv08403pkg3x5q",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nsoca8nity0840b82glrhq",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "4294451-e245af4a-4c52-49c1-ac44-aa5ff9faabf5.jpg"
        ]
      },
      {
        "sku": "1462",
        "description": [
          {
            "id": "cl8nspem7nivb0840wuz7iwlf",
            "title": "1462"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nstd3nnjhs0840c07879hw",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nstd3pnjhu0840fmh0h6b0",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nstd3qnjhv0840o3080be7",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nspem1niva0840l4t9741x",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "8814587-efdd10b2-2c90-4aa6-8aaf-18607d4a78fd.jpg"
        ]
      },
      {
        "sku": "1463",
        "description": [
          {
            "id": "cl8nspzn2niwe0840h1log9fw",
            "title": "1463"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nstn33njkl084053rc6y5w",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nstn36njkn0840kl67kgln",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nstn38njko08401lk2jzbf",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nspzmwniwd0840fmroazrx",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "6448026-4118cb77-137a-4bd7-bd20-bf7a94e6147f.jpg"
        ]
      },
      {
        "sku": "1464",
        "description": [
          {
            "id": "cl8nsqii8nixh0840vm10q2yj",
            "title": "1464"
          }
        ],
        "sizePrices": [
          {
            "id": "cl8nstp3dnjml0840cn94n2rg",
            "size": {
              "id": "ckkjugww14yy20919l9uyvqyp",
              "sku": "ONE"
            },
            "prices": [
              {
                "id": "cl8nstp3jnjmn0840wnhgsv5o",
                "price": 0,
                "priceType": "FOREIGN",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              },
              {
                "id": "cl8nstp3nnjmo0840jmrrfy6c",
                "price": 0,
                "priceType": "WHOLESALE",
                "currency": {
                  "id": "ckl479yd812xx0919ws8gh4qk",
                  "code": "EUR"
                }
              }
            ]
          }
        ],
        "price": [],
        "id": "cl8nsqii2nixg084060y0hoeo",
        "category": {
          "id": "ckku4rh4r0iv009194l4xl9mb",
          "sku": "CPM"
        },
        "photos": [
          "2042064-69672e82-1cf3-4a1a-9d52-4d580e950e4c.jpg"
        ]
      }
    ]
  }
}