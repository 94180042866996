import React from "react";
import { Box } from "@chakra-ui/react";

interface Props {
  title?: string;
}
const PageHeading: React.FC<Props> = ({ title = "Yükleniyor" }) => {
  return (
    <Box
      fontSize={"2xl"}
      fontWeight="bold"
      my={4}
      borderBottom="1px solid"
      borderColor={"gray.200"}
      pb={2}
    >
      {title}
    </Box>
  );
};

export default PageHeading;
