import React, { useState, useEffect } from "react";
import { Layout } from "../Layout";
import {
  OrderTypeType,
  useInvoicesQuery,
  useStoresQuery
} from "../../generated/graphql";
import formatMoney from "../../utils/formatMoney";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag,
  Select
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";
import Pagination from "../ui/Pagination";
import { useSearchQuery } from "../../utils/useQuery";

const DeliveryList = () => {
  const recordsPerPage = 20;
  const [page, setPage] = useState(1);
  const query = useSearchQuery();
  const [storeId, setStoreId] = useState<string | null | undefined>(
    query.get("storeId")
  );
  const [totalPages, setTotalPages] = useState(0);
  const skip = (page - 1) * recordsPerPage;

  const { data, loading, error, refetch } = useInvoicesQuery({
    variables: {
      orderType: OrderTypeType.Consignee,
      first: recordsPerPage,
      storeId: storeId ? storeId : null,
      skip
    }
  });
  const { data: storesData, loading: storesLoading } = useStoresQuery();

  useEffect(() => {
    if (!loading && data) {
      setTotalPages(Math.ceil(data.invoices.orderCount / recordsPerPage));
    }
  }, [data, loading]);
  useEffect(() => {
    refetch();
  }, [refetch, storeId]);
  let total = 0;
  const renderOrders = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={"auto"} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu. {error.message}</Td>
        </Tr>
      );
    }
    if (data) {
      return data.invoices.orders.map((order, i) => {
        let totalPrice = 0;
        let currency = "TRY";
        total += order.products.length;
        order.products.map((p) => {
          totalPrice += p.price?.price || 0;
        });
        currency = order?.products[0]?.price?.currency?.code || "TRY";

        return (
          <Tr key={order.id}>
            <Td
              display={{
                base: "table-cell",
                md: "none"
              }}
            >
              <Box textAlign={"center"} pb={4}>
                <Box mb={2}>
                  {dayjs(order.createdAt).format("DD MMMM YYYY")} <br />
                  {dayjs(order.createdAt).format("HH:mm")}
                </Box>
                <Box fontWeight={"semibold"} mb={2}>
                  {order.store.title}
                </Box>
                <Box color="gray.600" mb={2}>
                  {order.user?.name} {order.user?.surname}
                </Box>
                <Box mb={2}>{order.products.length} adet</Box>
                <Box mb={2} fontWeight="bold">
                  {formatMoney(totalPrice, currency)}
                </Box>
                <Box>
                  <Link to={`/sevkler/${order.id}`}>
                    <Tag
                      textAlign={"center"}
                      size="sm"
                      justifyContent="center"
                      variant={"solid"}
                      py={2}
                      colorScheme={"blue"}
                      mx="auto"
                    >
                      Detay
                    </Tag>
                  </Link>
                </Box>
              </Box>
            </Td>
            <Td
              display={{
                base: "none",
                md: "table-cell"
              }}
            >
              {skip + i + 1}
            </Td>
            <Td
              display={{
                base: "none",
                md: "table-cell"
              }}
            >
              {dayjs(order.createdAt).format("DD MMMM YYYY")} <br />
              {dayjs(order.createdAt).format("HH:mm")}
            </Td>
            <Td
              display={{
                base: "none",
                md: "table-cell"
              }}
            >
              {order.store.title}
            </Td>
            <Td
              display={{
                base: "none",
                md: "table-cell"
              }}
            >
              {order.user?.name} {order.user?.surname}
            </Td>
            <Td
              display={{
                base: "none",
                md: "table-cell"
              }}
            >
              {order.products.length} adet
            </Td>
            <Td
              display={{
                base: "none",
                md: "table-cell"
              }}
            >
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(totalPrice, currency)}
              </Box>
            </Td>

            <Td
              display={{
                base: "none",
                md: "table-cell"
              }}
              width="140px"
            >
              <Flex flexDir={"column"} experimental_spaceY={1} width="140px">
                <Box>
                  <Link to={`/sevkler/${order.id}`}>
                    <Tag
                      textAlign={"center"}
                      justifyContent="center"
                      variant={"solid"}
                      py={2}
                      colorScheme={"blue"}
                      width="100%"
                    >
                      Detay
                    </Tag>
                  </Link>
                </Box>
              </Flex>
            </Td>
          </Tr>
        );
      });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Sevkler" />
        <Select
          value={storeId ? storeId : ""}
          onChange={(e) => setStoreId(e.target.value)}
        >
          {storesData?.stores.map((store) => {
            return (
              <option key={store.id} value={store.id}>
                {store.title}
              </option>
            );
          })}
        </Select>
        <Box>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead
              display={{
                base: "none",
                md: "table-header-group"
              }}
            >
              <Tr>
                <Th>#</Th>
                <Th>Tarih</Th>
                <Th>Sevk Yeri</Th>
                <Th>Sevk Yapan</Th>
                <Th>Ürünler</Th>
                <Th>Toplam</Th>

                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderOrders()}</Tbody>
          </Table>
          {total}

          <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        </Box>
      </Container>
    </Layout>
  );
};

export default DeliveryList;
