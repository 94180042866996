import { useStoreQuery } from "../../generated/graphql";
import { Layout } from "./../Layout";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/tr";

import {
  Box,
  Container,
  Spinner,
  FormLabel,
  SimpleGrid
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";

dayjs.locale("tr");

const Dashboard = () => {
  const params = useParams();

  const { data, loading } = useStoreQuery({
    variables: {
      id: params.storeId as string
    }
  });

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title={data && data.store.title} />
        {loading ? (
          <Box>
            <Spinner mx="auto" />
          </Box>
        ) : (
          <Box>
            <Box bgColor="gray.50" p={4} mb={4}>
              <SimpleGrid
                columns={{
                  base: 2,
                  md: 8
                }}
                gap={4}
              >
                <Box>
                  <img
                    src={`${process.env.REACT_APP_CDNURL}/${data?.store.logo}?width=250&height=250&quality=60&format=webp`}
                    alt={data?.store.title}
                  />
                </Box>
                {data?.store.photos.map((p) => {
                  return (
                    <Box key={p}>
                      <img
                        src={`${process.env.REACT_APP_CDNURL}/${p}?width=250&height=250&quality=60&format=webp`}
                        alt={data?.store.title}
                      />
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Box>
            <SimpleGrid
              columns={{
                base: 1,
                md: 3
              }}
              gap={4}
            >
              <Box bgColor="gray.50" p={4}>
                <FormLabel>Başlık</FormLabel>
                {data?.store.title}
              </Box>
              <Box bgColor="gray.50" p={4}>
                <FormLabel>E-Posta</FormLabel>
                {data?.store.email}
              </Box>
              <Box bgColor="gray.50" p={4}>
                <FormLabel>Telefon</FormLabel>
                {data?.store.phoneNumber}
              </Box>

              <Box bgColor="gray.50" p={4}>
                <FormLabel>Adres</FormLabel>
                {data?.store.address} {data?.store.city}{" "}
                {data?.store.country.title}
              </Box>
              <Box bgColor="gray.50" p={4}>
                <FormLabel>Vergi Dairesi</FormLabel>
                {data?.store.taxOffice}
              </Box>
              <Box bgColor="gray.50" p={4}>
                <FormLabel>Vergi Numarası</FormLabel>
                {data?.store.taxNumber}
              </Box>
              <Box bgColor="gray.50" p={4}>
                <FormLabel>Para Birimi</FormLabel>
                {data?.store.currency.code}
              </Box>
              <Box bgColor="gray.50" p={4}>
                <FormLabel>Satış Noktalarında Gösterim</FormLabel>
                {data?.store.isVisible ? "Evet" : "Hayır"}
              </Box>

              <Box bgColor="gray.50" p={4}>
                <FormLabel>Mağaza Türü</FormLabel>
                {data?.store.isLocal ? "Juju" : "Satış Noktası"}
              </Box>

              <Box bgColor="gray.50" p={4}>
                <FormLabel>Mağaza Yapısı</FormLabel>
                {data?.store.isOnline ? "Online Mağaza" : "Fiziksel Mağaza"}
              </Box>

              <Box bgColor="gray.50" p={4}>
                <FormLabel>Komisyon Oranı</FormLabel>% {data?.store.percentage}
              </Box>
            </SimpleGrid>
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default Dashboard;
