import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  BrowserRouter as Router
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraTheme } from "./../styles/theme";
import client from "../lib/index";

import { Login } from "../components/Login";
import Dashboard from "../components/Dashboard";
import AllOrders from "../components/order/All";
import OrderDetail from "../components/order/Detail";
import OrderReport from "../components/order/Report";
import Store from "../components/order/Store";

//Stock Router
import AllStocks from "../components/stock/All";
import ProductGroup from "../components/stock/ProductGroup";
import Sku from "../components/stock/Sku";
import Detail from "../components/stock/Detail";

// Invoice
import DeliveryDetail from "../components/order/DeliveryDetail";
import DeliveryList from "../components/order/DeliveryList";

// Product Groups
import { ProductGroups } from "../components/productGroups/ProductGroups";
import { ProductGroupDetail } from "../components/productGroups/ProductGroupDetail";
import StoreProduct from "../components/stock/StoreProduct";
import CalculatedPrice from "../components/productGroups/CalculatedPrice";
import CalculatedPriceK from "../components/productGroups/CalculatedPriceK";
import ProductGroupList from "../components/productGroups/ProductGroupList";
import ProductGroupPrice from "../components/productGroups/ProductGroupPrice";
import Prim from "../components/Prim";
import MarketPlaceOrders from "../components/order/MarketPlaceOrders";

function RequireAuth() {
  const token = localStorage.getItem("token");
  const location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return <Outlet />;
}

const AppRouter = () => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/prim" element={<Prim />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/siparisler/:storeId" element={<AllOrders />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route
                path="/siparis-detayi/:orderId"
                element={<OrderDetail />}
              />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/trendyol" element={<MarketPlaceOrders />} />
              <Route path="/satis-raporu/:storeId" element={<OrderReport />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/magaza/:storeId" element={<Store />} />
            </Route>

            <Route element={<RequireAuth />}>
              <Route path="/stok" element={<AllStocks />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route
                path="/magaza-urunleri/:storeId"
                element={<StoreProduct />}
              />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/stok/:productGroupId" element={<ProductGroup />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/fiyat-hesapla" element={<CalculatedPrice />} />
              <Route path="/fiyat-hesapla-18k" element={<CalculatedPriceK />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route
                path="/stok/:categoryId/:productGroupId"
                element={<Sku />}
              />
            </Route>
            <Route element={<RequireAuth />}>
              <Route
                path="/stok/:categoryId/:productGroupId/:sku"
                element={<Detail />}
              />
              <Route path="/sevkler" element={<DeliveryList />} />
              <Route path="/sevkler/:id" element={<DeliveryDetail />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/urunler" element={<ProductGroups />} />
              <Route path="/urun-listesi" element={<ProductGroupList />} />
              <Route path="/urun/:id" element={<ProductGroupDetail />} />
              <Route path="/yurt-disi" element={<ProductGroupPrice />} />
            </Route>
          </Routes>
        </Router>
      </ApolloProvider>
    </ChakraProvider>
  );
};

export default AppRouter;
