import React, { useEffect, useState } from "react";
import { Layout } from "../Layout";
import formatMoney from "../../utils/formatMoney";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag,
  Select,
  Button,
  Input,
  FormLabel
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";
import axios from "axios";
import "dayjs/locale/tr";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.locale("tr");
dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.utc("z");

const MarketPlaceOrders = () => {
  const [data, setData] = useState([]);
  const [startAt, setStartAt] = useState(
    dayjs().format("YYYY-MM-DDT00:00:00.sss")
  );
  const [endAt, setEndAt] = useState(dayjs().format("YYYY-MM-DDT23:59:59.sss"));
  const [tempStartAt, setTempStartAt] = useState(
    dayjs().format("YYYY-MM-DDT00:00:00.sss")
  );
  const [tempEndAt, setTempEndAt] = useState(
    dayjs().format("YYYY-MM-DDT00:00:00.sss")
  );

  async function getOrders() {
    if (startAt && endAt) {
      const data = await axios(
        `${process.env.REACT_APP_MARTKETPLACE_API}/marketPlaces/orders?startDate=${startAt}&endDate=${endAt}`
      );
      console.log("🌵💜🐢", dayjs.tz.guess());

      if (data.data) {
        setData(data.data.data.data.content);
      }
    } else {
      const data = await axios(
        `${process.env.REACT_APP_MARTKETPLACE_API}/marketPlaces/orders`
      );
      if (data.data) {
        setData(data.data.data.data.content);
      }
    }
  }

  useEffect(() => {
    getOrders();
  }, [startAt, endAt]);

  const handleDateChange = (e: any) => {
    setTempStartAt(dayjs(e).format("YYYY-MM-DDT00:00:00.sss"));
  };
  const handleEndDateChange = (e: any) => {
    setTempEndAt(dayjs(e).format("YYYY-MM-DDT23:59:59.sss"));
  };
  let total = 0;
  const handleSubmitDate = () => {
    console.log("🌵💜🐢", "");

    setStartAt(tempStartAt);
    setEndAt(tempEndAt);
  };
  if (data.length > 0) {
    data.map((s: any) => {
      total += s.totalPrice;
    });
  }
  return (
    <Layout>
      <Container maxW={"container.xl"}>
        <PageHeading title="Trendyol Siparişleri" />
        <Box>
          <Box bgColor={"gray.100"} py={4} px={4} mb={6}>
            {/* <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Flex
                alignItems={"center"}
                flexDirection={{
                  base: "column",
                  md: "row"
                }}
                justifyContent="start"
              >
                <Flex
                  flexDirection={{
                    base: "column",
                    md: "row"
                  }}
                  alignItems={"center"}
                >
                  <FormLabel>Başlangıç: </FormLabel>
                  <Input
                    type="date"
                    defaultValue={dayjs(tempStartAt.toString()).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(e: any) => handleDateChange(e.target.value)}
                  />
                </Flex>
                <Flex
                  mt={{
                    base: 2,
                    md: 0
                  }}
                  flexDirection={{
                    base: "column",
                    md: "row"
                  }}
                  ml={{
                    base: 0,
                    md: 4
                  }}
                  alignItems="center"
                >
                  <FormLabel>Bitiş: </FormLabel>
                  <Input
                    type="date"
                    defaultValue={dayjs(tempEndAt.toString()).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(e: any) => handleEndDateChange(e.target.value)}
                  />
                </Flex>
                <Box ml={4}>
                  <Button
                    mt={{
                      base: 2,
                      md: 0
                    }}
                    variant={"outline"}
                    colorScheme="blue"
                    onClick={handleSubmitDate}
                  >
                    Raporu Oluştur
                  </Button>
                </Box>
              </Flex>
            </Flex> */}
          </Box>
        </Box>
        Toplam: {total}
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Müşteri</Th>
                <Th>Tarih</Th>
                <Th>Tutar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.length > 0 &&
                data?.map((s: any, i) => {
                  return (
                    <Tr key={s.id}>
                      <Td>{i + 1}</Td>
                      <Td>
                        {s.customerFirstName} {s.customerLastName}
                      </Td>
                      <Td>
                        {dayjs(
                          s.packageHistories[s.packageHistories.length - 1]
                            .createdDate
                        )
                          .locale("tr")
                          .format("DD-MM-YYYY")}
                      </Td>
                      <Td>{s.totalPrice}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default MarketPlaceOrders;
