import React from "react";
import { Box, Flex, Container, IconButton } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { HiLogout } from "react-icons/hi";
const pages: { name: string; path: string }[] = [];

export const Layout: React.FC = ({ children }) => {
  function logOut() {
    localStorage.removeItem("token");
    window.location.reload();
  }

  return (
    <Box>
      <Box bg="#862337">
        <Container maxW="container.xl">
          <Flex
            color="white"
            justifyContent="space-between"
            alignItems="center"
            p={4}
          >
            <Box bgColor={"white"} p={2}>
              <Link to="/dashboard">
                <img
                  src="https://images.jujumood.com/logo.png?format=webp&width=35&height=50"
                  alt="Juju Logo"
                />
              </Link>
            </Box>
            <Flex experimental_spaceX={4}>
              <Link to="/dashboard">
                <Box fontWeight={"bold"}>Mağazalar</Box>
              </Link>
              <Link to="/sevkler">
                <Box fontWeight={"bold"}>Sevkler</Box>
              </Link>
              <Link to="/stok">
                <Box fontWeight={"bold"}> Stoklar</Box>
              </Link>
              <Link to="/urunler">
                <Box fontWeight={"bold"}> Ürünler</Box>
              </Link>
              <Link to="/fiyat-hesapla">
                <Box fontWeight={"bold"}> Fiyat Hesabı</Box>
              </Link>
              <Link to="/prim">
                <Box fontWeight={"bold"}> Prim</Box>
              </Link>
              <Link to="/urun-listesi">
                <Box fontWeight={"bold"}> Ürün Listesi</Box>
              </Link>
              <Link to="/trendyol">
                <Box fontWeight={"bold"}> Trendyol</Box>
              </Link>
              {pages.map((page) => (
                <Link key={page.name} to={page.path}>
                  {page.name}
                </Link>
              ))}
            </Flex>
            <Flex color="black" alignItems="center" p={2}>
              <IconButton
                aria-label="LogOut"
                icon={<HiLogout />}
                onClick={logOut}
                mx={2}
                fontSize="17px"
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <hr />
      <Box>{children}</Box>
    </Box>
  );
};
