const countDecimals = (amount: number) => {
  const length = amount.toString().length;

  return {
    length: length - 1,
    power: Math.pow(10, length - 1)
  };
};

export const calculateRoundUp = (
  amount: number | undefined | null,
  showConsole: boolean = false
): number => {
  if (!amount) {
    return 0;
  }

  const information = countDecimals(amount);
  if (showConsole) {
    console.log(amount, "INFO");
  }
  let realRemainder = 0;
  let remainder = 0;
  let riser = 0;

  remainder = amount - (information.power === 10 ? 0 : information.power);
  riser = remainder / 100;

  if (showConsole) {
    console.log(remainder, "REMAINDER");
    console.log(riser, "RISER");
  }

  if (riser > 1) {
    if (showConsole) {
      console.log("K");
    }
    realRemainder = remainder - Math.floor(riser) * 100;
  } else {
    if (showConsole) {
      console.log("B");
    }
    realRemainder = remainder;
  }

  if (showConsole) {
    console.log(realRemainder, "REAL REMAINDER");
    console.log(Math.ceil(riser), "MATH CEIL RISER");
  }
  if (Math.ceil(riser) === 0) {
    riser = 1;
  }

  const theNumber = Math.floor(amount / information.power);
  const rem = amount % information.power;

  return Math.round(rem / 5) * 5 + theNumber * information.power;

  if (realRemainder >= 0 && realRemainder <= 25) {
    return (
      (Math.ceil(riser) - 1) * 100 +
      25 +
      (information.power === 10 ? 0 : information.power)
    );
  }

  if (realRemainder >= 0 && realRemainder <= 25) {
    return (
      (Math.ceil(riser) - 1) * 100 +
      25 +
      (information.power === 10 ? 0 : information.power)
    );
  }
  if (realRemainder >= 26 && realRemainder <= 50) {
    return (
      (Math.ceil(riser) - 1) * 100 +
      50 +
      (information.power === 10 ? 0 : information.power)
    );
  }
  if (realRemainder >= 51 && realRemainder <= 75) {
    if (showConsole) {
      console.log("real reminder burada");
    }
    return (
      (Math.ceil(riser) - 1) * 100 +
      75 +
      (information.power === 10 ? 0 : information.power)
    );
  }
  if (realRemainder > 75) {
    return (
      (Math.ceil(riser) - 1) * 100 +
      100 +
      (information.power === 10 ? 0 : information.power)
    );
  }
  return 0;
};
