import React, { useState, useEffect } from "react";

import { Layout } from "../Layout";
import Heading from "../ui/PageHeading";
import {
  Box,
  Container,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Button
} from "@chakra-ui/react";
import formatMoney from "../../utils/formatMoney";
import axios from "axios";
import { data } from "./data";
function isAtBottom({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean {
  return (
    currentTarget.scrollTop + 10 >=
    currentTarget.scrollHeight - currentTarget.clientHeight
  );
}
const Prices = ({
  data,
  size,
  priceType
}: {
  size?: string;
  priceType: string;
  data: any;
}) => {
  const xSmall = data.prices.find((xs: any) => xs.sizeSku === "XS");
  const small = data.prices.find((s: any) => s.sizeSku === "S");
  const smallP = data.prices.find((sp: any) => sp.sizeSku === "SP");
  const medium = data.prices.find((md: any) => md.sizeSku === "M");
  const mediumP = data.prices.find((md: any) => md.sizeSku === "MP");
  const large = data.prices.find((lg: any) => lg.sizeSku === "L");
  const xLarge = data.prices.find((xl: any) => xl.sizeSku === "XL");
  const xxLarge = data.prices.find((xxl: any) => xxl.sizeSku === "XXL");
  const xxxLarge = data.prices.find((xxxl: any) => xxxl.sizeSku === "XXXL");
  const one = data.prices.find((o: any) => o.sizeSku === "ONE");
  const ringsize = data.prices.find((r: any) => r.sizeSku === "RS");
  const custom = data.prices.find((c: any) => c.sizeSku === "C");

  const xs =
    xSmall && xSmall.prices.find((x: any) => x.priceType === priceType);
  const s = small && small.prices.find((x: any) => x.priceType === priceType);
  const sp =
    smallP && smallP.prices.find((x: any) => x.priceType === priceType);
  const md =
    medium && medium.prices.find((x: any) => x.priceType === priceType);
  const mp =
    mediumP && mediumP.prices.find((x: any) => x.priceType === priceType);
  const lg = large && large.prices.find((x: any) => x.priceType === priceType);
  const xl =
    xLarge && xLarge.prices.find((x: any) => x.priceType === priceType);
  const xxl =
    xxLarge && xxLarge.prices.find((x: any) => x.priceType === priceType);
  const xxxl =
    xxxLarge && xxxLarge.prices.find((x: any) => x.priceType === priceType);
  const onep = one && one.prices.find((x: any) => x.priceType === priceType);
  const ringsizep =
    ringsize && ringsize.prices.find((x: any) => x.priceType === priceType);
  const customp =
    custom && custom.prices.find((x: any) => x.priceType === priceType);

  return (
    <ul style={{ listStyle: "none" }}>
      {xs && (
        <li>
          <strong>XS:</strong>{" "}
          {formatMoney(xs.price, xs.code === "$" ? "USD" : xs.code)}
        </li>
      )}
      {s && (
        <li>
          <strong>S:</strong>{" "}
          {formatMoney(s.price, s.code === "$" ? "USD" : s.code)}
        </li>
      )}
      {sp && (
        <li>
          <strong>SP:</strong>{" "}
          {formatMoney(sp.price, sp.code === "$" ? "USD" : sp.code)}
        </li>
      )}
      {md && (
        <li>
          <strong>M:</strong>{" "}
          {formatMoney(md.price, md.code === "$" ? "USD" : md.code)}
        </li>
      )}
      {mp && (
        <li>
          <strong>MP:</strong>{" "}
          {formatMoney(mp.price, mp.code === "$" ? "USD" : mp.code)}
        </li>
      )}
      {lg && (
        <li>
          <strong>L:</strong>{" "}
          {formatMoney(lg.price, lg.code === "$" ? "USD" : lg.code)}
        </li>
      )}
      {xl && (
        <li>
          <strong>XL:</strong>{" "}
          {formatMoney(xl.price, xl.code === "$" ? "USD" : xl.code)}
        </li>
      )}

      {xxl && (
        <li>
          <strong>XXL:</strong>{" "}
          {formatMoney(xxl.price, xxl.code === "$" ? "USD" : xxl.code)}
        </li>
      )}
      {xxxl && (
        <li>
          <strong>XXXL:</strong>{" "}
          {formatMoney(xxxl.price, xxxl.code === "$" ? "USD" : xxxl.code)}
        </li>
      )}
      {onep && (
        <li>
          <strong>ONE:</strong>{" "}
          {formatMoney(onep.price, onep.code === "$" ? "USD" : onep.code)}
        </li>
      )}
      {ringsizep && (
        <li>
          <strong>RS:</strong>{" "}
          {formatMoney(
            ringsizep.price,
            ringsizep.code === "$" ? "USD" : ringsizep.code
          )}
        </li>
      )}
      {customp && (
        <li>
          <strong>C:</strong>{" "}
          {formatMoney(
            customp.price,
            customp.code === "$" ? "USD" : customp.code
          )}
        </li>
      )}
    </ul>
  );
};

const ProductGroupList = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Layout>
      <Container maxW={"container.xl"}>
        <Heading title="Ürünler" />

        <Box>
          <Table>
            <Thead position={"sticky"} top={0} bg="#862337">
              <Tr height={"50px"}>
                <Th textColor={"white"} textAlign="center">
                  Görsel
                </Th>
                <Th textColor={"white"}>İsim</Th>
                <Th textColor={"white"}>SKU</Th>
                <Th textColor={"white"}>EUR Toptan</Th>
                <Th textColor={"white"}>Mevcut Satış Fiyatı</Th>
                <Th textColor={"white"}>Yeni Satış Fiyatı</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.data.productGroups.map((d) => {
                const sku = d.sku.split("-")[1];
                const base: any = d.price.find(
                  (p) => p.priceType === "WHOLESALE"
                );
                const baseEur: any = d.price.find(
                  (p) => p.priceType === "FOREIGN"
                );
                return (
                  <Tr key={d.id}>
                    <Td>
                      {d.photos && (
                        <img
                          src={`https://images.jujumood.com/${d.photos[0]}?format=webp&height=150&quality=50`}
                          width={150}
                          height={150}
                          alt=""
                        />
                      )}
                    </Td>
                    <Td textAlign={"left"}>{d.description[0].title}</Td>
                    <Td>
                      {d.category.sku}-{d.sku}
                    </Td>
                    <Td>
                      Base:
                      {formatMoney(
                        d.price.find((p) => p.priceType === "WHOLESALE")
                          ?.price || 0,
                        "Eur"
                      )}
                      <br />
                      {d.sizePrices.map((s) => {
                        const base = d.price.find(
                          (p) => p.priceType === "WHOLESALE"
                        );
                        const category = d.category.sku;

                        const price = s.prices.find(
                          (p) => p.priceType === "WHOLESALE"
                        );
                        if (price && base) {
                          if (price.price + base.price === base.price) {
                            return <></>;
                          }
                          return (
                            <div>
                              {s.size.sku}:
                              {formatMoney(price.price + base.price, "Eur")}
                            </div>
                          );
                        }
                        return <>y</>;
                      })}
                    </Td>
                    <Td>
                      Base:
                      {formatMoney(
                        d.price.find((p) => p.priceType === "FOREIGN")?.price ||
                          0,
                        "Eur"
                      )}
                      <br />
                      {d.sizePrices.map((s) => {
                        const base = d.price.find(
                          (p) => p.priceType === "FOREIGN"
                        );
                        const category = d.category.sku;

                        const price = s.prices.find(
                          (p) => p.priceType === "FOREIGN"
                        );
                        if (price && base) {
                          if (price.price + base.price === base.price) {
                            return <></>;
                          }
                          return (
                            <div>
                              {s.size.sku}:
                              {formatMoney(price.price + base.price, "Eur")}
                            </div>
                          );
                        }
                        return <></>;
                      })}
                    </Td>
                    <Td>
                      Base:
                      {base ? (
                        formatMoney(Math.round(base.price * 2.4), "Eur")
                      ) : (
                        <>
                          {formatMoney(
                            Math.round((baseEur?.price / 2.8) * 2.4),
                            "Eur"
                          )}
                        </>
                      )}
                      <br />
                      {d.sizePrices.map((s) => {
                        const price = s.prices.find(
                          (p) => p.priceType === "WHOLESALE"
                        );
                        if (price && base) {
                          if (price.price + base.price === base.price) {
                            return <div></div>;
                          }
                          return (
                            <div>
                              {s.size.sku}:
                              {formatMoney(
                                Math.round(price.price + base.price * 2.4),
                                "Eur"
                              )}
                            </div>
                          );
                        }
                        return <></>;
                      })}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        {isLoading && <div>Loading more rows...</div>}
      </Container>
    </Layout>
  );
};

export default ProductGroupList;
