import { useEffect, useState } from "react";
import { Layout } from "./../Layout";
import { Link, useParams } from "react-router-dom";
import { useSearchQuery } from "../../utils/useQuery";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import axios from "axios";
import RenderMaterials from "../../utils/renderMaterials";
import "dayjs/locale/tr";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";

import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Button
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";
import { useStoreQuery } from "../../generated/graphql";
import StoreProductDetail from "./StoreProductDetail";

dayjs.locale("tr");

interface StockItem {
  _sum: {
    amount: number;
  };
  sku: string;
}
const StoreProduct = () => {
  const query = useSearchQuery();
  const params = useParams();

  const { data: storeData, loading: storeLoading } = useStoreQuery({
    variables: {
      id: params.storeId as string
    }
  });

  const { isLoading, data, refetch } = useQuery(
    "query-get-stocks-by-category",
    async () => {
      const url = `${process.env.REACT_APP_STOCKURL}/stock/storeProducts?storeId=${params.storeId}`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading
          title={`${
            !storeLoading && storeData && storeData.store.title
          } Mağaza Ürünleri`}
        />
        <Button
          colorScheme={"purple"}
          variant="outline"
          onClick={(e: any) => {
            var table_elt = document.getElementById("printArea");
            var workbook = XLSX.utils.table_to_book(table_elt);
            var ws = workbook.Sheets["Sheet1"];
            XLSX.utils.sheet_add_aoa(
              ws,
              [["Created " + new Date().toISOString()]],
              { origin: -1 }
            );
            XLSX.writeFile(workbook, "Report.xlsx");
          }}
        >
          <SiMicrosoftexcel />
        </Button>
        <Box id="printArea" overflowX={"auto"}>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Ürün </Th>
                <Th>Materyaller</Th>
                <Th textAlign={"right"}>Adet</Th>
                <Th textAlign={"right"}>İşlem</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={3}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : (
                data?.data.data.stockData.map((item: StockItem, i: number) => {
                  return (
                    <Tr key={i}>
                      <Td width="50px">{i + 1}</Td>
                      <Td>{item.sku}</Td>
                      <Td>
                        <RenderMaterials locale="tr" sku={item.sku} />
                      </Td>
                      <Td width="100px" textAlign={"right"}>
                        {item._sum.amount}
                      </Td>
                      <Td width="190px" textAlign={"right"}>
                        <StoreProductDetail
                          sku={item.sku}
                          storeId={params.storeId as string}
                          refetch={refetch}
                          sum={item._sum.amount}
                        />
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default StoreProduct;
