import {
  ApolloClient,
  ApolloProvider as Provider,
  InMemoryCache,
  Observable,
  HttpLink
} from "@apollo/client";
const api_endpoint = process.env.REACT_APP_APIURL;
let headers = undefined;
const token = localStorage.getItem("token");
if (token) {
  headers = {
    authorization: `Bearer ${token}`
  };
}
const httpLink = new HttpLink({
  uri: api_endpoint,
  headers
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink
});

export default client;
