import { useStoresQuery } from "../generated/graphql";
import { Layout } from "./Layout";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag
} from "@chakra-ui/react";
import PageHeading from "./ui/PageHeading";

const Dashboard = () => {
  const { data, loading, error } = useStoresQuery();

  const renderStores = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={"auto"} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu.</Td>
        </Tr>
      );
    }
    if (data) {
      return data.stores
        .filter((s) => s.isLocal === "STORE")
        .sort(function (a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((store, i) => {
          return (
            <Tr key={store.id}>
              <Td
                display={{
                  base: "table-cell",
                  md: "none"
                }}
              >
                <Box p={4} bgColor="gray.100" rounded="md" textAlign={"center"}>
                  <Box fontWeight={"semibold"} fontSize="lg" mb={2}>
                    <Link to={`/magaza/${store.id}`}>
                      <Box color="blue.500" textDecor={"underline"}>
                        {store.title}
                      </Box>
                    </Link>
                  </Box>
                  <Box color="gray.400" fontSize="sm" mb={2}>
                    {store.city} / {store.country.title}
                  </Box>
                  <Box width={"100%"}>
                    <Flex
                      flexDir={"column"}
                      alignItems="center"
                      experimental_spaceY={1}
                      width="100%"
                    >
                      <Box width="100%">
                        <Link to={`/magaza-urunleri/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            textAlign={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"blue"}
                            width="100%"
                          >
                            Mağaza Ürünleri
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/siparisler/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"yellow"}
                            width="100%"
                          >
                            Siparişler
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/satis-raporu/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"purple"}
                            width="100%"
                          >
                            Satış Raporu
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/sevkler?storeId=${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"purple"}
                            width="100%"
                          >
                            Sevkler
                          </Tag>
                        </Link>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Td>
              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                {i + 1}
              </Td>

              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                <Link to={`/magaza/${store.id}`}>
                  <Box color="blue.500" textDecor={"underline"}>
                    {store.title}
                  </Box>
                </Link>
              </Td>

              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                {store.city} / {store.country.title}
              </Td>
              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
                width="140px"
              >
                <Flex flexDir={"column"} experimental_spaceY={1} width="140px">
                  <Box>
                    <Link to={`/magaza-urunleri/${store.id}`}>
                      <Tag
                        textAlign={"center"}
                        variant={"solid"}
                        py={2}
                        colorScheme={"blue"}
                        width="100%"
                      >
                        Mağaza Ürünleri
                      </Tag>
                    </Link>
                  </Box>

                  <Box>
                    <Link to={`/siparisler/${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"yellow"}
                        width="100%"
                      >
                        Siparişler
                      </Tag>
                    </Link>
                  </Box>

                  <Box>
                    <Link to={`/satis-raporu/${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"purple"}
                        width="100%"
                      >
                        Satış Raporu
                      </Tag>
                    </Link>
                  </Box>
                  <Box>
                    <Link to={`/sevkler?storeId=${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"orange"}
                        width="100%"
                      >
                        Sevkler
                      </Tag>
                    </Link>
                  </Box>
                </Flex>
              </Td>
            </Tr>
          );
        });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };

  const renderPoints = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={"auto"} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu.</Td>
        </Tr>
      );
    }
    if (data) {
      return data.stores
        .filter((s) => s.isLocal === "POINT")
        .sort(function (a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((store, i) => {
          return (
            <Tr key={store.id}>
              <Td
                display={{
                  base: "table-cell",
                  md: "none"
                }}
              >
                <Box p={4} bgColor="gray.100" rounded="md" textAlign={"center"}>
                  <Box fontWeight={"semibold"} fontSize="lg" mb={2}>
                    <Link to={`/magaza/${store.id}`}>
                      <Box color="blue.500" textDecor={"underline"}>
                        {store.title}
                      </Box>
                    </Link>
                  </Box>
                  <Box color="gray.400" fontSize="sm" mb={2}>
                    {store.city} / {store.country.title}
                  </Box>
                  <Box width={"100%"}>
                    <Flex
                      flexDir={"column"}
                      alignItems="center"
                      experimental_spaceY={1}
                      width="100%"
                    >
                      <Box width="100%">
                        <Link to={`/magaza-urunleri/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            textAlign={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"blue"}
                            width="100%"
                          >
                            Mağaza Ürünleri
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/siparisler/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"yellow"}
                            width="100%"
                          >
                            Siparişler
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/satis-raporu/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"purple"}
                            width="100%"
                          >
                            Satış Raporu
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/sevkler?storeId=${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"purple"}
                            width="100%"
                          >
                            Sevkler
                          </Tag>
                        </Link>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Td>
              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                {i + 1}
              </Td>

              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                <Link to={`/magaza/${store.id}`}>
                  <Box color="blue.500" textDecor={"underline"}>
                    {store.title}
                  </Box>
                </Link>
              </Td>

              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                {store.city} / {store.country.title}
              </Td>
              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
                width="140px"
              >
                <Flex flexDir={"column"} experimental_spaceY={1} width="140px">
                  <Box>
                    <Link to={`/magaza-urunleri/${store.id}`}>
                      <Tag
                        textAlign={"center"}
                        variant={"solid"}
                        py={2}
                        colorScheme={"blue"}
                        width="100%"
                      >
                        Mağaza Ürünleri
                      </Tag>
                    </Link>
                  </Box>

                  <Box>
                    <Link to={`/siparisler/${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"yellow"}
                        width="100%"
                      >
                        Siparişler
                      </Tag>
                    </Link>
                  </Box>

                  <Box>
                    <Link to={`/satis-raporu/${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"purple"}
                        width="100%"
                      >
                        Satış Raporu
                      </Tag>
                    </Link>
                  </Box>
                  <Box>
                    <Link to={`/sevkler?storeId=${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"orange"}
                        width="100%"
                      >
                        Sevkler
                      </Tag>
                    </Link>
                  </Box>
                </Flex>
              </Td>
            </Tr>
          );
        });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };

  const renderWarehouse = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={"auto"} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu.</Td>
        </Tr>
      );
    }
    if (data) {
      return data.stores
        .filter((s) => s.isLocal === "LOCAL")
        .sort(function (a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((store, i) => {
          return (
            <Tr key={store.id}>
              <Td
                display={{
                  base: "table-cell",
                  md: "none"
                }}
              >
                <Box p={4} bgColor="gray.100" rounded="md" textAlign={"center"}>
                  <Box fontWeight={"semibold"} fontSize="lg" mb={2}>
                    <Link to={`/magaza/${store.id}`}>
                      <Box color="blue.500" textDecor={"underline"}>
                        {store.title}
                      </Box>
                    </Link>
                  </Box>
                  <Box color="gray.400" fontSize="sm" mb={2}>
                    {store.city} / {store.country.title}
                  </Box>
                  <Box width={"100%"}>
                    <Flex
                      flexDir={"column"}
                      alignItems="center"
                      experimental_spaceY={1}
                      width="100%"
                    >
                      <Box width="100%">
                        <Link to={`/magaza-urunleri/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            textAlign={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"blue"}
                            width="100%"
                          >
                            Mağaza Ürünleri
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/siparisler/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"yellow"}
                            width="100%"
                          >
                            Siparişler
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/satis-raporu/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"purple"}
                            width="100%"
                          >
                            Satış Raporu
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/sevkler?storeId=${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"purple"}
                            width="100%"
                          >
                            Sevkler
                          </Tag>
                        </Link>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Td>
              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                {i + 1}
              </Td>

              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                <Link to={`/magaza/${store.id}`}>
                  <Box color="blue.500" textDecor={"underline"}>
                    {store.title}
                  </Box>
                </Link>
              </Td>

              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                {store.city} / {store.country.title}
              </Td>
              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
                width="140px"
              >
                <Flex flexDir={"column"} experimental_spaceY={1} width="140px">
                  <Box>
                    <Link to={`/magaza-urunleri/${store.id}`}>
                      <Tag
                        textAlign={"center"}
                        variant={"solid"}
                        py={2}
                        colorScheme={"blue"}
                        width="100%"
                      >
                        Mağaza Ürünleri
                      </Tag>
                    </Link>
                  </Box>

                  <Box>
                    <Link to={`/siparisler/${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"yellow"}
                        width="100%"
                      >
                        Siparişler
                      </Tag>
                    </Link>
                  </Box>

                  <Box>
                    <Link to={`/satis-raporu/${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"purple"}
                        width="100%"
                      >
                        Satış Raporu
                      </Tag>
                    </Link>
                  </Box>
                  <Box>
                    <Link to={`/sevkler?storeId=${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"orange"}
                        width="100%"
                      >
                        Sevkler
                      </Tag>
                    </Link>
                  </Box>
                </Flex>
              </Td>
            </Tr>
          );
        });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };
  const renderWholesaler = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={"auto"} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu.</Td>
        </Tr>
      );
    }
    if (data) {
      return data.stores
        .filter((s) => s.isLocal === "WHOLESALER")
        .sort(function (a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((store, i) => {
          return (
            <Tr key={store.id}>
              <Td
                display={{
                  base: "table-cell",
                  md: "none"
                }}
              >
                <Box p={4} bgColor="gray.100" rounded="md" textAlign={"center"}>
                  <Box fontWeight={"semibold"} fontSize="lg" mb={2}>
                    <Link to={`/magaza/${store.id}`}>
                      <Box color="blue.500" textDecor={"underline"}>
                        {store.title}
                      </Box>
                    </Link>
                  </Box>
                  <Box color="gray.400" fontSize="sm" mb={2}>
                    {store.city} / {store.country.title}
                  </Box>
                  <Box width={"100%"}>
                    <Flex
                      flexDir={"column"}
                      alignItems="center"
                      experimental_spaceY={1}
                      width="100%"
                    >
                      <Box width="100%">
                        <Link to={`/magaza-urunleri/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            textAlign={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"blue"}
                            width="100%"
                          >
                            Mağaza Ürünleri
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/siparisler/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"yellow"}
                            width="100%"
                          >
                            Siparişler
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/satis-raporu/${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"purple"}
                            width="100%"
                          >
                            Satış Raporu
                          </Tag>
                        </Link>
                      </Box>

                      <Box width="100%">
                        <Link to={`/sevkler?storeId=${store.id}`}>
                          <Tag
                            justifyContent={"center"}
                            variant={"solid"}
                            py={2}
                            colorScheme={"purple"}
                            width="100%"
                          >
                            Sevkler
                          </Tag>
                        </Link>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Td>
              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                {i + 1}
              </Td>

              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                <Link to={`/magaza/${store.id}`}>
                  <Box color="blue.500" textDecor={"underline"}>
                    {store.title}
                  </Box>
                </Link>
              </Td>

              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
              >
                {store.city} / {store.country.title}
              </Td>
              <Td
                display={{
                  base: "none",
                  md: "table-cell"
                }}
                width="140px"
              >
                <Flex flexDir={"column"} experimental_spaceY={1} width="140px">
                  <Box>
                    <Link to={`/magaza-urunleri/${store.id}`}>
                      <Tag
                        textAlign={"center"}
                        variant={"solid"}
                        py={2}
                        colorScheme={"blue"}
                        width="100%"
                      >
                        Mağaza Ürünleri
                      </Tag>
                    </Link>
                  </Box>

                  <Box>
                    <Link to={`/siparisler/${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"yellow"}
                        width="100%"
                      >
                        Siparişler
                      </Tag>
                    </Link>
                  </Box>

                  <Box>
                    <Link to={`/satis-raporu/${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"purple"}
                        width="100%"
                      >
                        Satış Raporu
                      </Tag>
                    </Link>
                  </Box>
                  <Box>
                    <Link to={`/sevkler?storeId=${store.id}`}>
                      <Tag
                        variant={"solid"}
                        py={2}
                        colorScheme={"orange"}
                        width="100%"
                      >
                        Sevkler
                      </Tag>
                    </Link>
                  </Box>
                </Flex>
              </Td>
            </Tr>
          );
        });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="JUJU Mağazaları" />
        <Box>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead
              display={{
                base: "none",
                md: "table-header-group"
              }}
            >
              <Tr>
                <Th>#</Th>
                <Th>Başlık</Th>
                <Th>Şehir / Ülke</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderStores()}</Tbody>
          </Table>
        </Box>

        <PageHeading title="Depo" />
        <Box>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead
              display={{
                base: "none",
                md: "table-header-group"
              }}
            >
              <Tr>
                <Th>#</Th>
                <Th>Başlık</Th>
                <Th>Şehir / Ülke</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderWarehouse()}</Tbody>
          </Table>
        </Box>

        <PageHeading title="Satış Noktaları" />
        <Box>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead
              display={{
                base: "none",
                md: "table-header-group"
              }}
            >
              <Tr>
                <Th>#</Th>
                <Th>Başlık</Th>
                <Th>Şehir / Ülke</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderPoints()}</Tbody>
          </Table>
        </Box>

        <PageHeading title="Toptan Satış" />
        <Box>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead
              display={{
                base: "none",
                md: "table-header-group"
              }}
            >
              <Tr>
                <Th>#</Th>
                <Th>Başlık</Th>
                <Th>Şehir / Ülke</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderWholesaler()}</Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default Dashboard;
