import { useState, useEffect } from "react";
import {
  OrderTypeType,
  useOrdersQuery,
  useStoresQuery
} from "../../generated/graphql";
import { Layout } from "./../Layout";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import formatMoney from "../../utils/formatMoney";
import "dayjs/locale/tr";

import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";
import Pagination from "../ui/Pagination";

dayjs.locale("tr");

const Dashboard = () => {
  const params = useParams();

  const recordsPerPage = 20;
  const [page, setPage] = useState(1);
  const status = "success";
  const [storeTitle, setStoreTitle] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const skip = (page - 1) * recordsPerPage;

  const { data, loading, error } = useOrdersQuery({
    variables: {
      orderType: OrderTypeType.Retail,
      first: recordsPerPage,
      status,
      store: params.storeId as string,
      skip
    }
  });

  const { data: storeData } = useStoresQuery();

  useEffect(() => {
    if (!loading && data) {
      setTotalPages(Math.ceil(data.orders.orderCount / recordsPerPage));
    }
  }, [data, loading]);

  useEffect(() => {
    if (storeData) {
      const findStore = storeData.stores.find((s) => s.id === params.storeId);
      setStoreTitle(findStore ? findStore.title : "Bilinmiyor");
    }
  }, [storeData]);
  const renderOrders = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={"auto"} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu. {error.message}</Td>
        </Tr>
      );
    }
    if (data) {
      return data.orders.orders.map((order, i) => {
        let totalPrice = 0;
        let totalPaidPrice = 0;
        let currency = "TRY";

        order.payment.map((p) => {
          return (totalPrice = totalPrice + p.price);
        });

        order.payment.map((p) => {
          totalPaidPrice = totalPaidPrice + p.paidPrice;
          return (currency = p.currency as string);
        });

        return (
          <Tr key={order.id}>
            <Td>{skip + i + 1}</Td>
            <Td>
              {dayjs(order.createdAt).format("DD MMMM YYYY")} <br />
              {dayjs(order.createdAt).format("HH:mm")}
            </Td>
            <Td>
              {order.paymentFirstName} {order.paymentLastName}
            </Td>
            <Td>{order.products.length} adet</Td>
            <Td width={"200px"} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(totalPrice, currency)}
              </Box>
            </Td>

            <Td width={"200px"} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(totalPaidPrice, currency)}
              </Box>
            </Td>
            <Td textAlign="right">
              {formatMoney(order.discount ? order.discount : 0, currency)}
            </Td>
            <Td>{order.orderStatus}</Td>
            <Td width="140px">
              <Flex flexDir={"column"} experimental_spaceY={1} width="140px">
                <Box>
                  <Link to={`/siparis-detayi/${order.id}`}>
                    <Tag
                      textAlign={"center"}
                      justifyContent="center"
                      variant={"solid"}
                      py={2}
                      colorScheme={"blue"}
                      width="100%"
                    >
                      Detay
                    </Tag>
                  </Link>
                </Box>
              </Flex>
            </Td>
          </Tr>
        );
      });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title={`${storeTitle} Siparişleri`} />

        <Box overflowX={"auto"}>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Tarih</Th>
                <Th>İsim Soyisim</Th>
                <Th>Ürünler</Th>
                <Th textAlign={"right"}>Toplam</Th>
                <Th textAlign={"right"}>Ödenen</Th>
                <Th textAlign={"right"}>İndirim</Th>
                <Th>Durum</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderOrders()}</Tbody>
          </Table>
          <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        </Box>
      </Container>
    </Layout>
  );
};

export default Dashboard;
